import React from "react";
import "./overlap.css";
import skyvector from './images/skyvector.png'
import yellowvector from './images/yellowvector.png'

interface BoxProps {
  imageSrc1: string;
  imageSrc2: string;
  title1: string;
  title2: string;
  description1: string;
  description2: string;
}

export const Box: React.FC<BoxProps> = ({
  imageSrc1,
  imageSrc2,
  title1,
  title2,
  description1,
  description2,
}) => {
  return (
    <div className="box">
      <div className="group">
        <div className="overlap">
          <div className="overlap-group">
              <div className="about-us-overlap-wrapper">
               <img className="about-us-overlap-wrapper-img" alt="Mask group" src={imageSrc1} />
               </div>
               <div className="desc-wrapper">
                <h3 className="montserrat bolds">
                  {title1}
                </h3>
                <h5 className=" regular  montserrat">
                  {description1}
                </h5>
                </div>
            
          </div>

          <div className="overlap-group">
          <div className="about-us-overlap-wrapper">
            <img className="about-us-overlap-wrapper-img" alt="Mask group" src={imageSrc2} />
            </div>
            <div className="desc-wrapper">
               <h3 className=" montserrat bolds">
                {title2}
              </h3>
              <h5 className=" regular montserrat">
                {description2}
              </h5>
              </div>
              </div>
          
        </div>
      </div>
    </div>
  );
};
