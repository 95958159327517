import React from "react";
import "./higheremployee.css"; // Import your CSS file if needed

interface HigherEmployeeProps {
  imageSrc: string;
  title: string;
  designation: string;
  description: string;
  socialLinks: {
    [key: string]: string; // Accepts any social link with a string key
  };
}

export const HigherEmployee: React.FC<HigherEmployeeProps> = ({
  imageSrc,
  title,
  designation,
  description,
  socialLinks,
}) => {
  return (
    <div className="higher-employee">
      <div className="employee-image">
        <img src={imageSrc} alt={title} className="rounded-full" />
        <div className="social-links">
          {Object.entries(socialLinks).map(([socialPlatform, link]) => (
            <a
              key={socialPlatform}
              href={link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className={`fab fa-${socialPlatform}`} />
            </a>
         
          ))}
        </div>
      </div>
      <div className="employee-details">
        <h3 className="employee-name title-custom font-black  montserrat bolder">{title}</h3>
        <h4 className="employee-designation title-custom font-black  montserrat bold">{designation}</h4>
        <h6 className="employee-description title-custom font-sky  montserrat regular">{description}</h6>
     
      </div>
    </div>

  );
};
