
import React, { useState, useEffect } from "react";

const insidesales = () => {
    return (
        <div className="page-job-post content-writer">
            <div className="container">
                <div className="job-post-wrapper">
                    <div className="jobdetail-side">
                        <div className="joblistings-detail">
                            <div className="jobs-prodiver-detail">
                                <div className="job-info-main">
                                    <h4 className="job-name">Inside Sales</h4>
                                    <h5 className="job-provider-name"></h5>
                                </div>
                                <div className="job-provider-info"></div>
                            </div>
                            <div className="jobs-requirment">
                                <div className="uppersection">
                                    <p className="job-detail-minor required-exprence ">
                                        0-3 years
                                    </p>
                                    <p className="job-detail-minor salary-offer">Not Enclosed</p>
                                    <p className="job-detail-minor location">On-Site, Hyderabad, Telangana</p>
                                </div>
                                <div className="lowersections">
                                    <p className="job-detail-minor detail-other ">
                                        Education :  Bachelor's degree in Business Administration, Marketing, Communications, or related field.
                                    </p>
                                    {/* <p className="job-detail-minor detail-other">
                                        Key Skills : Flutter, Dart, Android, iOS
                                    </p> */}
                                </div>
                            </div>
                            <div className="job-apply-section">
                                <div className="job-provider-info post-page">
                                    {/* <p className="job-detail-minor openings"> 1 Opening</p>
                                    <span className="job-detail-minor posted-ago">
                                        4/12/ 2023
                                    </span> */}
                                </div>
                                <div className="job-btns-selection">
                                    <a
                                        className="applynowbtn apply-save-btn"
                                        href="mailto:careers@teraconnects.com"
                                    >
                                        Apply
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className="career-joblisting-detail job-description">
                            <div className="product-specification">
                                <h2 className="product-specs-title-for-product-single for-list-prod montserrat">
                                    Job Description
                                </h2>
                                <ul className="product-desc-list tera-job-description">
                                    We are seeking a dynamic and results-driven Field Marketing Executive to join our team. As a Field Marketing Executive , you will play a crucial role in expanding our brand presence and driving customer engagement through various marketing initiatives in the field. Your responsibilities will include executing marketing campaigns, conducting market research, building and maintaining relationships with key stakeholders, and analyzing campaign performance to optimize results.
                                </ul>

                            </div>
                        </div>

                        <div className="career-joblisting-detail job-description">
                            <div className="product-specification">
                                <h2 className="product-specs-title-for-product-single for-list-prod montserrat">
                                    Responsibilities
                                </h2>
                                {/* <ul className="product-desc-list tera-job-description"> */}
                                <ul className="">
                                    <li className="specs-list-content montserrat">
                                        Manage incoming inquiries via phone, email, and online chat in a timely and professional manner.
                                    </li>
                                    <li className="specs-list-content montserrat">
                                        Understand customer needs and recommend appropriate products or services to meet their requirements.
                                    </li>
                                    <li className="specs-list-content montserrat">
                                        Proactively reach out to prospective customers to generate leads and drive sales.
                                    </li>
                                    <li className="specs-list-content montserrat">
                                        Build and maintain strong relationships with existing customers to ensure satisfaction and loyalty.
                                    </li>
                                    <li className="specs-list-content montserrat">
                                        Provide product demonstrations and presentations to potential customers.
                                    </li>
                                    <li className="specs-list-content montserrat">
                                        Collaborate with the sales team to identify opportunities for upselling and cross-selling.
                                    </li>
                                    <li className="specs-list-content montserrat">
                                        Prepare and follow up on sales quotes, proposals, and contracts.
                                    </li>
                                    <li className="specs-list-content montserrat">
                                        Maintain accurate records of customer interactions and sales activities in the CRM system.
                                    </li>
                                    <li className="specs-list-content montserrat">
                                        Stay informed about industry trends, product updates, and competitive offerings.
                                    </li>
                                    <li className="specs-list-content montserrat">
                                        Assist with marketing campaigns and promotions to support sales efforts.
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="career-joblisting-detail job-description">
                            <div className="product-specification">
                                <h2 className="product-specs-title-for-product-single for-list-prod montserrat">
                                    Qualifications
                                </h2>
                                {/* <ul className="product-desc-list tera-job-description"> */}
                                <ul className="">
                                    <li className="specs-list-content montserrat">
                                        Bachelor's degree in Business Administration, Marketing, Communications, or related field.
                                    </li>
                                    <li className="specs-list-content montserrat">
                                        0 -3 years of experience in sales, customer service, or related fields.
                                    </li>
                                    <li className="specs-list-content montserrat">
                                        Strong interpersonal and communication skills, both verbal and written.
                                    </li>
                                    <li className="specs-list-content montserrat">
                                        Ability to build rapport and establish trust with customers.
                                    </li>
                                    <li className="specs-list-content montserrat">
                                        Proficiency in Microsoft Office Suite (Word, Excel, Outlook).
                                    </li>
                                    <li className="specs-list-content montserrat">
                                        Experience with CRM software and sales automation tools.
                                    </li>
                                    <li className="specs-list-content montserrat">
                                        Highly organized with the ability to multitask and prioritize in a fast-paced environment.
                                    </li>
                                    <li className="specs-list-content montserrat">
                                        Goal-oriented with a track record of meeting or exceeding sales targets.
                                    </li>
                                    <li className="specs-list-content montserrat">
                                        Adaptability and willingness to learn about new products and industry trends.
                                    </li>
                                    <li className="specs-list-content montserrat">
                                        Positive attitude and team player mentality.
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="career-joblisting-detail job-description">
                            <div className="product-specification">
                                <h2 className="product-specs-title-for-product-single for-list-prod montserrat">
                                    Preferred Qualifications
                                </h2>
                                {/* <ul className="product-desc-list tera-job-description"> */}
                                <ul className="">
                                    <li className="specs-list-content montserrat">
                                        Experience in a B2B sales environment.
                                    </li>
                                    <li className="specs-list-content montserrat">
                                        Knowledge of the [industry/niche] market.
                                    </li>
                                    <li className="specs-list-content montserrat">
                                        Previous experience with inside sales or telemarketing.
                                    </li>
                                    <li className="specs-list-content montserrat">
                                        Familiarity with social selling techniques and online networking platforms.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default insidesales;
