import React from 'react';

interface TestimonialProps {
  heading: string;
  name: string;
  organisation: string;
  description: string;
  rating: number;
  imageSrc: string;
}

const Testimonial: React.FC<TestimonialProps> = ({
  heading,
  name,
  organisation,
  description,
  rating,
  imageSrc,
}) => {
  // Split the description into lines
  const descriptionLines = description.split('\n').slice(0, 5);

  return (
    <div className="testimonal-wrap-all">
      <div className="testimonal-wrap-inner">
        <div className="testimonal-image-sec">
          <img src={imageSrc} alt="Testimonial" />
        </div>
        <div className="testimonal-desc-sec">
          <div className="testi-head">
            <h2>{heading}</h2>
          </div>
          <div className="testi-desc">
            {descriptionLines.map((line, index) => (
              <h5 key={index}>{line}</h5>
            ))}
          </div>
          <div className="name-star">
            <div className='name-org-wrapper'>
              <h5>{name}</h5>
              <h6>{organisation}</h6>
            </div>
            <ul className="star">
              {Array.from({ length: 5 }, (_, index) => (
                <li key={index} className={index < rating ? "check" : "default-star"}></li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonial;
