import React, { useState, useEffect } from "react";
import "./pricerange.css";
import { useNavigate } from "react-router-dom";
interface SalaryRangeProps {
  selectedExperienceRange: any;
  handleFilterJobsBasedOnSalaryRange: (
    selectedCategoryId: any,
    updatedSelectedCheckboxes: any,
    experienceRange: any,
    salaryRange: any,
    selectedCompaniesCheckboxes: any
  ) => void;
  selectedCheckboxesValues: any;
  selectedCategoryIdValue: any;
  selectedCompanyCheckboxesValues: any;
}

const PriceRangeSlider: React.FC<SalaryRangeProps> = ({
  handleFilterJobsBasedOnSalaryRange,
  selectedCategoryIdValue,
  selectedCheckboxesValues,
  selectedExperienceRange,
  selectedCompanyCheckboxesValues,
}) => {
  const [minValue, setMinValue] = useState<number>(100);
  const [maxValue, setMaxValue] = useState<number>(10000000);

  const navigate = useNavigate();
  const validateRange = () => {
    const minPrice = Math.max(
      100,
      parseInt(minInputRef.current?.value || "100")
    );
    const maxPrice = Math.min(
      10000000,
      parseInt(maxInputRef.current?.value || "10000000")
    );

    if (minPrice > maxPrice) {
      setMinValue(maxPrice);
      setMaxValue(minPrice);
    } else {
      setMinValue(minPrice);
      setMaxValue(maxPrice);
    }
  };

  const minInputRef = React.createRef<HTMLInputElement>();
  const maxInputRef = React.createRef<HTMLInputElement>();
  const rangeFillRef = React.createRef<HTMLDivElement>();

  useEffect(() => {
    const minPercentage = ((minValue - 100) / (10000000 - 100)) * 100;
    const maxPercentage = ((maxValue - 100) / (10000000 - 100)) * 100;
    rangeFillRef.current!.style.left = minPercentage + "%";
    rangeFillRef.current!.style.width = maxPercentage - minPercentage + "%";
  }, [minValue, maxValue]);
  const handleFilterJobsOnSalaryRange = () => {
    navigate(
      `/jobs/jobs-listing/?selectedCategory=${selectedCategoryIdValue}&selectedExperience=${
        selectedExperienceRange?.max || 50
      }&selectedSalaryRange=${maxValue}&selectedStates=${
        selectedCheckboxesValues || []
      }&selectedOrganizations=${selectedCompanyCheckboxesValues || []}`
    );
    handleFilterJobsBasedOnSalaryRange(
      selectedCategoryIdValue,
      selectedCheckboxesValues,
      selectedExperienceRange,
      { min: minValue, max: maxValue },
      selectedCompanyCheckboxesValues
    );
  };

  return (
    <div className="card">
      <div className="price-content">
        <div>
          <label>Min</label>
          <p id="min-value">
            <i className="fa fa-inr"></i> {minValue?.toLocaleString("en-IN")}
          </p>
        </div>

        <div>
          <label>Max</label>
          <p id="max-value">
            <i className="fa fa-inr"></i> {maxValue?.toLocaleString("en-IN")}
          </p>
        </div>
      </div>

      <div className="range-slider">
        <div className="range-fill" ref={rangeFillRef}></div>

        <input
          type="range"
          className="min-experience"
          value={minValue}
          min={100}
          max={10000000}
          step={10000}
          ref={minInputRef}
          onChange={validateRange}
          onMouseUp={handleFilterJobsOnSalaryRange}
        />
        <input
          type="range"
          className="max-experience"
          value={maxValue}
          min={100}
          max={10000000}
          step={10000}
          ref={maxInputRef}
          onChange={validateRange}
          onMouseUp={handleFilterJobsOnSalaryRange}
        />
      </div>
    </div>
  );
};

export default PriceRangeSlider;
