import React, { FC } from "react";
import NavigationItem from "./NavigationItem";
import { NAVIGATION_DEMO_2, new_NAVIGATION_DESKTOP } from "data/navigation";
import { NAVIGATION_desktop_1} from "data/navigation";
import { NAVIGATION_desktop_2 } from "data/navigation";

interface Props {
  className?: string;
}

const Navigation: FC<Props> = ({ className = "flex" }) => {
  return (
    <ul className={`nc-Navigation items-center justify-between ${className}`}>
      <ul className="navigation-inner-wrapper navigation-inner-wrapper-first">
      {/* {NAVIGATION_desktop_1.map((item) => (
        <NavigationItem key={item.id} menuItem={item} />
      ))} */}
      </ul>
      <ul className="navigation-inner-wrapper navigation-inner-wrapper-second">
        {new_NAVIGATION_DESKTOP.map((item) => (
        <NavigationItem key={item.id} menuItem={item} />
      ))}
      </ul>
    </ul>
  );
};

export default Navigation;


