import React from 'react';
import './moreevent.css'
interface EventCardProps {
  imgUrls: string;
  heading: string;
  description: string;
  eventUrls: string;
}

const EventCard: React.FC<EventCardProps> = ({ imgUrls, heading, description, eventUrls }) => {
  return (
    <div className="more-even-to-be">
      <div className="more-events-img">
        <img src={imgUrls} alt="Event" />
      </div>
      <div className="more-events-details">
        <h2 className='montserrat font-sky-blue'>{heading}</h2>
        <h5 className='montserrat'>{description}</h5>
        <a className='montserrat more-event-btn' href={eventUrls}>Know More</a>
      </div>
    </div>
  );
};

export default EventCard;
