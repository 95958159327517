import React from "react";
import { Link } from "react-router-dom";
import Content from "./data/content.json"

interface Courses {
    programId: number;
    courseId: number;
    courseName: string;
    duration: string;
}

interface ContentFields {
    contentCourseId: number;
    contentId: number;
    contentTitle: string;
    sections: Section[];
}

interface Section {
    sectionId: number;
    sectiontitle: string;
    sectionContent: string;
    subsections: Subsection[];
}

interface Subsection {
    subsectionId: number;
    subsectiontitle: string;
    subsectionContent: string;
}

interface BreadcrumbProps {
    SectionData: Section[];
    courseCurrentSectionIndex: number;
    setCourseCurrentSectionIndex: (index: number) => void;
    currentSubSection: number;
    programName: string;
    courseName: string;
    sectionId : number;
}

const Breadcrumb: React.FC<BreadcrumbProps> = ({
    SectionData,
    courseCurrentSectionIndex,
    setCourseCurrentSectionIndex,
    currentSubSection,
    programName,
    courseName,
    sectionId
}) => {
    const currentSection = Content.find(x => x.contentId === sectionId)?.sections.find(y => y.sectionId === courseCurrentSectionIndex);
    const currentSubsection = currentSection?.subsections?.find(x=> x.subsectionId === courseCurrentSectionIndex);
    const subSectionName = currentSection?.subsections?.find(x => x.subsectionId === courseCurrentSectionIndex)?.subsectiontitle;
    // console.log("breadcrumb")
    // console.log("currindx:",currentSectionIndex);
    // console.log(SectionData);
    // console.log("currentsec: ",currentSection)
    // console.log("currrentsubsection: ",currentSubSection);
    // console.log("programname",programName);
    // console.log("coursename",courseName);


    const handleClick = (index: number) => {
        setCourseCurrentSectionIndex(index);
    };

    return (
        <div className="ml-4 mb-4">
            <Link to="/training" className="text-gray-500 cursor-pointer">
                {programName} {' > '}
            </Link>
            <Link to="/training" className="text-gray-500 cursor-pointer">
                {courseName} {' > '}
            </Link>
            <span className="text-gray-500 cursor-pointer" onClick={() => handleClick(courseCurrentSectionIndex)}>
                {subSectionName ? subSectionName : currentSection?.sectiontitle}
            </span>
        </div>
    );
};

export default Breadcrumb;
