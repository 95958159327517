import React from "react";
import SignupForm from "components/Sections/loginandsingup/signup"
import "./signup.css"
import { Link } from "react-router-dom"; 


const Signup: React.FC = () => {


    const handleSignupSubmit = (firstName: string, lastName: string, email: string, password: string) => {
        // Handle signup logic here
        
      };



  return (
    <div className="login-signp">
    <div className="login-signup-iner">
    <div className="container">
        <div className="login-singup-inner-wrapper main-signup">
        <svg xmlns="http://www.w3.org/2000/svg" width="247" height="41" viewBox="0 0 247 41" fill="none">
<g filter="url(#filter0_d_1141_2749)">
<path d="M68.385 2.46191L65.8536 6.49759H61.3514V17.9637H57.4481V6.49759H51.5352L54.0665 2.46191H68.385Z" fill="#1188A8"/>
<path d="M84.2134 13.9537L81.7013 17.942H69.5664V2.46387H84.1747L81.6434 6.49955H73.489V8.35122H83.4791L81.2376 11.8646H73.489V13.9774H84.2134V13.9537Z" fill="#1188A8"/>
<path d="M103.44 17.9417H98.2227L95.4208 13.8348H89.4306V17.9417H85.5273V10.0128H96.5029C97.7009 10.0128 98.6478 9.34808 98.6478 8.2798C98.6478 7.06909 97.8362 6.47562 96.5029 6.47562H85.5273L88.078 2.43994H96.1744C99.0341 2.43994 102.648 3.55569 102.648 7.97121C102.648 10.3689 101.43 12.3629 99.3429 12.8852C99.7681 13.2888 100.252 13.8348 101.121 14.9268L103.44 17.9417Z" fill="#1188A8"/>
<path d="M110.162 17.9416L112.52 13.9534H115.689C116.443 13.9534 117.332 13.9534 117.931 14.0009C117.582 13.4549 117.061 12.5053 116.655 11.7457L114.395 7.63877L108.481 17.9179H103.863L111.94 3.91167C112.462 3.00958 113.235 2.17871 114.472 2.17871C115.631 2.17871 116.404 2.93836 116.965 3.91167L124.848 17.9179H110.162V17.9416Z" fill="#1188A8"/>
<path d="M141.66 13.7863L139.589 17.6995H134.366C130.764 17.6995 128.094 14.3579 128.094 9.91705C128.094 5.43225 130.764 2.44238 134.366 2.44238H141.689L139.619 6.39954H134.396C132.656 6.39954 131.335 7.93847 131.335 10.1369C131.335 12.2914 132.625 13.7863 134.396 13.7863H141.66Z" fill="#F1B207"/>
<path d="M157.685 10.0492C157.685 15.5013 155.133 17.9635 150.061 17.9635C144.989 17.9635 142.438 15.5013 142.438 10.0492C142.438 4.59703 144.989 2.17871 150.061 2.17871C155.133 2.17871 157.685 4.59703 157.685 10.0492ZM154.472 10.0492C154.472 6.7515 153.302 6.13594 150.061 6.13594C146.819 6.13594 145.649 6.7515 145.649 10.0492C145.649 13.3468 146.819 13.9624 150.061 13.9624C153.302 13.9624 154.472 13.3468 154.472 10.0492Z" fill="#F1B207"/>
<path d="M168.608 17.1281L162.214 8.73008V17.6996H159.273V5.03672C159.273 3.01415 160.114 2.17871 161.044 2.17871C161.434 2.17871 161.825 2.31063 162.335 3.01412L168.728 11.4121V2.44258H171.669V15.1055C171.669 17.1281 170.829 17.9635 169.899 17.9635C169.478 17.9635 169.088 17.8316 168.608 17.1281Z" fill="#F1B207"/>
<path d="M182.835 17.1281L176.441 8.73008V17.6996H173.5V5.03672C173.5 3.01415 174.341 2.17871 175.271 2.17871C175.661 2.17871 176.051 2.31063 176.561 3.01412L182.954 11.4121V2.44258H185.896V15.1055C185.896 17.1281 185.055 17.9635 184.125 17.9635C183.734 17.9635 183.345 17.8316 182.835 17.1281Z" fill="#F1B207"/>
<path d="M199.912 13.7863L197.841 17.6995H187.816V2.44238H199.882L197.781 6.39954H191.028V8.24622H199.281L197.42 11.7198H191.028V13.7863H199.912Z" fill="#F1B207"/>
<path d="M214.289 13.7863L212.218 17.6995H206.995C203.393 17.6995 200.723 14.3579 200.723 9.91705C200.723 5.43225 203.393 2.44238 206.995 2.44238H214.318L212.248 6.39954H207.025C205.284 6.39954 203.963 7.93847 203.963 10.1369C203.963 12.2914 205.255 13.7863 207.025 13.7863H214.289Z" fill="#F1B207"/>
<path d="M228.368 2.44238L226.267 6.39954H222.545V17.6995H219.334V6.39954H214.441L216.543 2.44238H228.368Z" fill="#F1B207"/>
<path d="M239.022 8.51002C241.573 8.51002 242.924 9.91701 242.924 12.6871C242.924 15.8088 241.633 17.6995 238.452 17.6995H228.758L230.829 13.7863H238.722C239.473 13.7863 239.833 13.4785 239.833 12.6871C239.833 11.8956 239.502 11.5879 238.722 11.5879H233.049C230.379 11.5879 229.148 9.82907 229.148 7.19095C229.148 4.33298 230.468 2.44238 233.62 2.44238H242.684L240.613 6.39954H233.38C232.629 6.39954 232.269 6.66339 232.269 7.45482C232.269 8.24626 232.629 8.51002 233.38 8.51002H239.022Z" fill="#F1B207"/>
</g>
<g filter="url(#filter1_d_1141_2749)">
<path d="M31.1345 9.21436H25.2812V14.7307H31.1345V9.21436Z" fill="#F1B207"/>
<path d="M23.5056 9.21436V29.0345C23.5056 32.0428 20.9174 34.482 17.7253 34.482H17.6523V14.7307H17.6723C17.6623 14.7307 17.6523 14.7213 17.6523 14.7119V12.8356C17.6523 10.8343 19.3711 9.21436 21.4948 9.21436H23.5056Z" fill="#F1B207"/>
<path d="M44.6089 9.22999V10.7529C44.6089 12.9483 42.7208 14.7307 40.3881 14.7307H32.9023V9.21436H44.5889C44.5989 9.21436 44.6089 9.22061 44.6089 9.22999Z" fill="#F1B207"/>
<path d="M4.19569 2.17773H27.2901C29.4137 2.17773 31.1325 3.79761 31.1325 5.79901V7.67533C31.1325 7.68471 31.1226 7.69409 31.1126 7.69409H31.1325V27.4485H31.0595C27.8674 27.4485 25.2792 25.0094 25.2792 22.001V7.69722H8.39648C6.06381 7.69722 4.17578 5.91784 4.17578 3.71944V2.19963C4.17578 2.18399 4.18574 2.17773 4.19569 2.17773Z" fill="#1188A8"/>
</g>
<g filter="url(#filter2_d_1141_2749)">
<path d="M84.5908 31.9269C83.9306 31.9269 83.3596 31.7694 82.8778 31.4543C82.396 31.1304 82.0258 30.6939 81.767 30.1449C81.5082 29.5959 81.3789 28.9884 81.3789 28.3223C81.3789 27.6473 81.5217 27.0398 81.8072 26.4998C82.0927 25.9508 82.5031 25.5188 83.0384 25.2037C83.5737 24.8798 84.2161 24.7178 84.9655 24.7178C85.6883 24.7178 86.3083 24.8753 86.8258 25.1903C87.3432 25.5053 87.7403 25.9328 88.0168 26.4728C88.3023 27.0128 88.4451 27.6159 88.4451 28.2818V31.7649H87.2005V30.3609H87.1737C87.0399 30.6219 86.857 30.8739 86.625 31.1168C86.4019 31.3509 86.121 31.5444 85.7819 31.6974C85.4429 31.8503 85.0458 31.9269 84.5908 31.9269ZM84.9121 30.8603C85.3581 30.8603 85.7507 30.7524 86.0897 30.5364C86.4377 30.3113 86.7053 30.0054 86.8927 29.6184C87.089 29.2224 87.1871 28.7814 87.1871 28.2953C87.1871 27.8183 87.0934 27.3908 86.9061 27.0128C86.7276 26.6348 86.4689 26.3378 86.1298 26.1218C85.7909 25.8968 85.3938 25.7843 84.9388 25.7843C84.457 25.7843 84.0421 25.8968 83.6941 26.1218C83.3552 26.3468 83.092 26.6528 82.9046 27.0398C82.7261 27.4179 82.637 27.8499 82.637 28.3359C82.637 28.8039 82.7261 29.2313 82.9046 29.6184C83.083 29.9963 83.3418 30.2979 83.6808 30.5228C84.0198 30.7479 84.4302 30.8603 84.9121 30.8603Z" fill="#111827"/>
<path d="M93.9453 31.7643V21.9092H95.1765V25.7702C95.4532 25.4103 95.7877 25.1447 96.1803 24.9737C96.5818 24.8027 96.9966 24.7172 97.4249 24.7172C98.0316 24.7172 98.5579 24.8477 99.0039 25.1088C99.4498 25.3607 99.798 25.7162 100.048 26.1752C100.298 26.6343 100.423 27.1698 100.423 27.7817V31.7643H99.1913V27.7952C99.1913 27.3813 99.0976 27.0257 98.9101 26.7288C98.723 26.4228 98.4732 26.1887 98.1609 26.0268C97.8576 25.8648 97.5319 25.7838 97.184 25.7838C96.836 25.7838 96.5104 25.8648 96.207 26.0268C95.9037 26.1798 95.6538 26.4047 95.4576 26.7018C95.2703 26.9987 95.1765 27.3543 95.1765 27.7682V31.7643H93.9453Z" fill="#111827"/>
<path d="M105.842 31.7649C105.092 31.7649 104.427 31.6254 103.847 31.3464C103.267 31.0583 102.812 30.6579 102.482 30.1449C102.161 29.6228 102 29.0154 102 28.3223C102 27.6384 102.138 27.0263 102.415 26.4863C102.692 25.9373 103.075 25.5053 103.566 25.1903C104.057 24.8753 104.628 24.7178 105.279 24.7178C105.984 24.7178 106.569 24.8708 107.032 25.1768C107.505 25.4828 107.857 25.8968 108.089 26.4188C108.322 26.9318 108.437 27.5078 108.437 28.1468C108.437 28.2459 108.433 28.3494 108.424 28.4573C108.424 28.5653 108.416 28.6598 108.397 28.7408H103.312C103.357 29.1639 103.495 29.5239 103.726 29.8208C103.958 30.1089 104.253 30.3294 104.609 30.4824C104.975 30.6263 105.377 30.6983 105.814 30.6983H107.595V31.7649H105.842ZM103.298 27.8633H107.193C107.193 27.6923 107.175 27.5123 107.139 27.3234C107.103 27.1254 107.041 26.9363 106.952 26.7563C106.863 26.5674 106.742 26.4008 106.59 26.2568C106.447 26.1038 106.265 25.9823 106.042 25.8923C105.828 25.8023 105.573 25.7573 105.279 25.7573C104.967 25.7573 104.69 25.8203 104.45 25.9463C104.218 26.0633 104.017 26.2253 103.847 26.4323C103.677 26.6303 103.548 26.8553 103.459 27.1073C103.369 27.3594 103.316 27.6113 103.298 27.8633Z" fill="#111827"/>
<path d="M112.848 31.9269C112.188 31.9269 111.617 31.7694 111.135 31.4543C110.654 31.1304 110.283 30.6939 110.024 30.1449C109.766 29.5959 109.637 28.9884 109.637 28.3223C109.637 27.6473 109.779 27.0398 110.065 26.4998C110.35 25.9508 110.76 25.5188 111.296 25.2037C111.831 24.8798 112.474 24.7178 113.223 24.7178C113.946 24.7178 114.566 24.8753 115.083 25.1903C115.6 25.5053 115.998 25.9328 116.274 26.4728C116.56 27.0128 116.702 27.6159 116.702 28.2818V31.7649H115.458V30.3609H115.432C115.298 30.6219 115.115 30.8739 114.882 31.1168C114.659 31.3509 114.378 31.5444 114.04 31.6974C113.7 31.8503 113.304 31.9269 112.848 31.9269ZM113.17 30.8603C113.615 30.8603 114.008 30.7524 114.348 30.5364C114.695 30.3113 114.963 30.0054 115.151 29.6184C115.347 29.2224 115.445 28.7814 115.445 28.2953C115.445 27.8183 115.351 27.3908 115.163 27.0128C114.985 26.6348 114.726 26.3378 114.387 26.1218C114.049 25.8968 113.651 25.7843 113.196 25.7843C112.715 25.7843 112.299 25.8968 111.952 26.1218C111.613 26.3468 111.349 26.6528 111.162 27.0398C110.983 27.4179 110.895 27.8499 110.895 28.3359C110.895 28.8039 110.983 29.2313 111.162 29.6184C111.34 29.9963 111.599 30.2979 111.938 30.5228C112.278 30.7479 112.688 30.8603 113.17 30.8603Z" fill="#111827"/>
<path d="M118.66 31.7643V21.9092H119.891V31.7643H118.66Z" fill="#111827"/>
<path d="M124.181 31.7646C123.574 31.7646 123.097 31.5891 122.75 31.238C122.41 30.887 122.24 30.41 122.24 29.807V23.1514H123.472V29.726C123.472 30.023 123.553 30.2615 123.712 30.4415C123.882 30.6126 124.114 30.698 124.409 30.698H125.787V31.7646H124.181ZM121.062 25.946V24.8794H125.814V25.946H121.062Z" fill="#111827"/>
<path d="M127.305 31.7643V21.9092H128.536V25.7702C128.812 25.4103 129.147 25.1447 129.539 24.9737C129.941 24.8027 130.356 24.7172 130.784 24.7172C131.391 24.7172 131.917 24.8477 132.364 25.1088C132.81 25.3607 133.158 25.7162 133.408 26.1752C133.657 26.6343 133.782 27.1698 133.782 27.7817V31.7643H132.551V27.7952C132.551 27.3813 132.457 27.0257 132.27 26.7288C132.083 26.4228 131.833 26.1887 131.52 26.0268C131.217 25.8648 130.891 25.7838 130.544 25.7838C130.195 25.7838 129.87 25.8648 129.566 26.0268C129.263 26.1798 129.013 26.4047 128.817 26.7018C128.63 26.9987 128.536 27.3543 128.536 27.7682V31.7643H127.305Z" fill="#111827"/>
<path d="M139.031 31.765C138.327 31.765 137.698 31.612 137.144 31.306C136.592 31 136.155 30.5904 135.833 30.0774C135.511 29.5554 135.352 28.9704 135.352 28.3225C135.352 27.6745 135.511 27.0939 135.833 26.5809C136.155 26.0589 136.592 25.645 137.144 25.3389C137.698 25.0329 138.327 24.8799 139.031 24.8799H139.834V25.9464H138.991C138.536 25.9464 138.126 26.0545 137.76 26.2704C137.404 26.4774 137.117 26.7609 136.903 27.1209C136.698 27.4809 136.596 27.8815 136.596 28.3225C136.596 28.7634 136.698 29.164 136.903 29.524C137.117 29.884 137.404 30.1719 137.76 30.388C138.126 30.595 138.536 30.6985 138.991 30.6985H139.834V31.765H139.031Z" fill="#111827"/>
<path d="M144.083 31.9269C143.423 31.9269 142.852 31.7694 142.37 31.4543C141.888 31.1304 141.518 30.6939 141.26 30.1449C141 29.5959 140.871 28.9884 140.871 28.3223C140.871 27.6473 141.014 27.0398 141.299 26.4998C141.585 25.9508 141.996 25.5188 142.53 25.2037C143.066 24.8798 143.708 24.7178 144.458 24.7178C145.18 24.7178 145.801 24.8753 146.318 25.1903C146.836 25.5053 147.232 25.9328 147.51 26.4728C147.795 27.0128 147.938 27.6159 147.938 28.2818V31.7649H146.693V30.3609H146.666C146.532 30.6219 146.349 30.8739 146.118 31.1168C145.895 31.3509 145.613 31.5444 145.274 31.6974C144.935 31.8503 144.538 31.9269 144.083 31.9269ZM144.404 30.8603C144.851 30.8603 145.243 30.7524 145.582 30.5364C145.93 30.3113 146.197 30.0054 146.385 29.6184C146.581 29.2224 146.679 28.7814 146.679 28.2953C146.679 27.8183 146.586 27.3908 146.399 27.0128C146.22 26.6348 145.961 26.3378 145.622 26.1218C145.283 25.8968 144.886 25.7843 144.431 25.7843C143.949 25.7843 143.535 25.8968 143.187 26.1218C142.847 26.3468 142.584 26.6528 142.397 27.0398C142.219 27.4179 142.129 27.8499 142.129 28.3359C142.129 28.8039 142.219 29.2313 142.397 29.6184C142.575 29.9963 142.834 30.2979 143.173 30.5228C143.512 30.7479 143.923 30.8603 144.404 30.8603Z" fill="#111827"/>
<path d="M149.855 31.765V26.8374C149.855 26.2254 150.025 25.7484 150.365 25.4064C150.712 25.0554 151.189 24.8799 151.796 24.8799H153.201V25.9464H152.024C151.729 25.9464 151.497 26.0319 151.327 26.2029C151.167 26.3739 151.087 26.6125 151.087 26.9184V31.765H149.855Z" fill="#111827"/>
<path d="M157.805 31.7649C157.057 31.7649 156.392 31.6254 155.812 31.3464C155.232 31.0583 154.777 30.6579 154.446 30.1449C154.126 29.6228 153.965 29.0154 153.965 28.3223C153.965 27.6384 154.103 27.0263 154.38 26.4863C154.656 25.9373 155.04 25.5053 155.531 25.1903C156.022 24.8753 156.592 24.7178 157.244 24.7178C157.949 24.7178 158.533 24.8708 158.997 25.1768C159.47 25.4828 159.822 25.8968 160.054 26.4188C160.286 26.9318 160.402 27.5078 160.402 28.1468C160.402 28.2459 160.397 28.3494 160.388 28.4573C160.388 28.5653 160.379 28.6598 160.362 28.7408H155.276C155.321 29.1639 155.459 29.5239 155.691 29.8208C155.923 30.1089 156.218 30.3294 156.574 30.4824C156.94 30.6263 157.342 30.6983 157.779 30.6983H159.559V31.7649H157.805ZM155.263 27.8633H159.157C159.157 27.6923 159.139 27.5123 159.104 27.3234C159.068 27.1254 159.006 26.9363 158.916 26.7563C158.828 26.5674 158.707 26.4008 158.555 26.2568C158.412 26.1038 158.23 25.9823 158.007 25.8923C157.793 25.8023 157.538 25.7573 157.244 25.7573C156.931 25.7573 156.655 25.8203 156.413 25.9463C156.182 26.0633 155.981 26.2253 155.812 26.4323C155.642 26.6303 155.513 26.8553 155.424 27.1073C155.334 27.3594 155.281 27.6113 155.263 27.8633Z" fill="#111827"/>
<path d="M168.969 24.7178C169.487 24.7178 169.959 24.8033 170.388 24.9743C170.816 25.1363 171.187 25.3793 171.498 25.7033C171.811 26.0273 172.052 26.4188 172.222 26.8778C172.4 27.3278 172.489 27.8364 172.489 28.4033C172.489 29.1143 172.342 29.7354 172.047 30.2663C171.762 30.7974 171.369 31.2069 170.87 31.4949C170.37 31.7829 169.804 31.9269 169.17 31.9269C168.822 31.9269 168.488 31.8684 168.166 31.7514C167.854 31.6344 167.573 31.4724 167.323 31.2654C167.083 31.0494 166.886 30.8064 166.734 30.5364H166.708V34.7349H165.477V28.3629C165.477 27.5888 165.628 26.9318 165.931 26.3919C166.243 25.8518 166.663 25.4378 167.189 25.1498C167.716 24.8618 168.309 24.7178 168.969 24.7178ZM168.969 25.7843C168.559 25.7843 168.18 25.8878 167.831 26.0948C167.493 26.3018 167.221 26.5943 167.016 26.9723C166.819 27.3503 166.721 27.7958 166.721 28.3088C166.721 28.8308 166.819 29.2853 167.016 29.6723C167.221 30.0504 167.493 30.3429 167.831 30.5499C168.18 30.7569 168.563 30.8603 168.983 30.8603C169.411 30.8603 169.795 30.7524 170.133 30.5364C170.472 30.3204 170.74 30.0234 170.936 29.6454C171.141 29.2674 171.244 28.8264 171.244 28.3223C171.244 27.8004 171.141 27.3503 170.936 26.9723C170.731 26.5943 170.455 26.3018 170.106 26.0948C169.768 25.8878 169.388 25.7843 168.969 25.7843Z" fill="#111827"/>
<path d="M174.062 31.7643V21.9092H175.294V31.7643H174.062Z" fill="#111827"/>
<path d="M180.082 31.9269C179.422 31.9269 178.851 31.7694 178.37 31.4543C177.888 31.1304 177.517 30.6939 177.259 30.1449C177 29.5959 176.871 28.9884 176.871 28.3223C176.871 27.6473 177.013 27.0398 177.299 26.4998C177.584 25.9508 177.995 25.5188 178.53 25.2037C179.065 24.8798 179.708 24.7178 180.457 24.7178C181.18 24.7178 181.8 24.8753 182.318 25.1903C182.835 25.5053 183.232 25.9328 183.509 26.4728C183.795 27.0128 183.937 27.6159 183.937 28.2818V31.7649H182.693V30.3609H182.666C182.532 30.6219 182.349 30.8739 182.117 31.1168C181.894 31.3509 181.612 31.5444 181.274 31.6974C180.935 31.8503 180.538 31.9269 180.082 31.9269ZM180.404 30.8603C180.85 30.8603 181.242 30.7524 181.582 30.5364C181.929 30.3113 182.197 30.0054 182.385 29.6184C182.581 29.2224 182.679 28.7814 182.679 28.2953C182.679 27.8183 182.585 27.3908 182.398 27.0128C182.219 26.6348 181.961 26.3378 181.621 26.1218C181.283 25.8968 180.885 25.7843 180.431 25.7843C179.949 25.7843 179.534 25.8968 179.187 26.1218C178.847 26.3468 178.584 26.6528 178.396 27.0398C178.218 27.4179 178.129 27.8499 178.129 28.3359C178.129 28.8039 178.218 29.2313 178.396 29.6184C178.575 29.9963 178.833 30.2979 179.173 30.5228C179.512 30.7479 179.922 30.8603 180.404 30.8603Z" fill="#111827"/>
<path d="M188.228 31.7646C187.621 31.7646 187.144 31.5891 186.795 31.238C186.457 30.887 186.287 30.41 186.287 29.807V23.1514H187.519V29.726C187.519 30.023 187.598 30.2615 187.759 30.4415C187.929 30.6126 188.161 30.698 188.455 30.698H189.834V31.7646H188.228ZM185.109 25.946V24.8794H189.861V25.946H185.109Z" fill="#111827"/>
<path d="M191.754 31.7643V25.9458H190.562V24.8792H191.754V23.8667C191.754 23.2547 191.923 22.7777 192.262 22.4357C192.611 22.0847 193.088 21.9092 193.695 21.9092H195.381V22.9757H193.936C193.632 22.9757 193.4 23.0612 193.239 23.2322C193.079 23.4032 192.998 23.6417 192.998 23.9477V24.8792H195.112V25.9458H192.998V31.7643H191.754Z" fill="#111827"/>
<path d="M199.134 31.9269C198.437 31.9269 197.822 31.7694 197.287 31.4543C196.751 31.1304 196.328 30.6983 196.015 30.1584C195.704 29.6094 195.547 28.9973 195.547 28.3223C195.547 27.6473 195.704 27.0398 196.015 26.4998C196.328 25.9508 196.751 25.5188 197.287 25.2037C197.822 24.8798 198.437 24.7178 199.134 24.7178C199.829 24.7178 200.445 24.8798 200.981 25.2037C201.525 25.5188 201.949 25.9463 202.252 26.4863C202.564 27.0263 202.72 27.6384 202.72 28.3223C202.72 28.9973 202.564 29.6094 202.252 30.1584C201.949 30.6983 201.525 31.1304 200.981 31.4543C200.445 31.7694 199.829 31.9269 199.134 31.9269ZM199.134 30.8603C199.607 30.8603 200.017 30.7479 200.365 30.5228C200.713 30.2979 200.985 29.9963 201.181 29.6184C201.377 29.2313 201.476 28.7993 201.476 28.3223C201.476 27.8453 201.377 27.4179 201.181 27.0398C200.985 26.6528 200.713 26.3468 200.365 26.1218C200.017 25.8968 199.607 25.7843 199.134 25.7843C198.67 25.7843 198.26 25.8968 197.903 26.1218C197.555 26.3468 197.282 26.6528 197.086 27.0398C196.89 27.4179 196.792 27.8453 196.792 28.3223C196.792 28.7993 196.89 29.2313 197.086 29.6184C197.282 29.9963 197.555 30.2979 197.903 30.5228C198.26 30.7479 198.67 30.8603 199.134 30.8603Z" fill="#111827"/>
<path d="M204.262 31.765V26.8374C204.262 26.2254 204.431 25.7484 204.771 25.4064C205.118 25.0554 205.596 24.8799 206.202 24.8799H207.607V25.9464H206.43C206.135 25.9464 205.903 26.0319 205.734 26.2029C205.573 26.3739 205.493 26.6125 205.493 26.9184V31.765H204.262Z" fill="#111827"/>
<path d="M208.746 31.7649V27.6203C208.746 27.0893 208.866 26.6033 209.107 26.1623C209.348 25.7213 209.696 25.3703 210.151 25.1093C210.606 24.8483 211.155 24.7178 211.797 24.7178C212.109 24.7178 212.422 24.7628 212.734 24.8528C213.046 24.9338 213.337 25.0778 213.604 25.2848C213.871 25.4828 214.085 25.7528 214.246 26.0948H214.26C214.438 25.7528 214.661 25.4828 214.929 25.2848C215.196 25.0778 215.486 24.9338 215.799 24.8528C216.111 24.7628 216.427 24.7178 216.749 24.7178C217.382 24.7178 217.927 24.8483 218.382 25.1093C218.837 25.3703 219.185 25.7213 219.426 26.1623C219.666 26.6033 219.787 27.0893 219.787 27.6203V31.7649H218.555V27.6203C218.555 27.2784 218.475 26.9678 218.315 26.6888C218.154 26.4098 217.936 26.1893 217.659 26.0273C217.391 25.8653 217.083 25.7843 216.735 25.7843C216.397 25.7843 216.084 25.8698 215.799 26.0408C215.513 26.2028 215.286 26.4233 215.116 26.7023C214.955 26.9723 214.876 27.2784 214.876 27.6203V31.7649H213.644V27.6203C213.644 27.2784 213.559 26.9723 213.39 26.7023C213.229 26.4233 213.006 26.2028 212.721 26.0408C212.444 25.8698 212.132 25.7843 211.784 25.7843C211.445 25.7843 211.137 25.8653 210.86 26.0273C210.593 26.1893 210.379 26.4098 210.218 26.6888C210.057 26.9678 209.977 27.2784 209.977 27.6203V31.7649H208.746Z" fill="#111827"/>
</g>
<defs>
<filter id="filter0_d_1141_2749" x="47.5889" y="0.205607" width="199.279" height="23.6776" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
  <feFlood flood-opacity="0" result="BackgroundImageFix"/>
  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
  <feOffset dy="1.9731"/>
  <feGaussianBlur stdDeviation="1.9731"/>
  <feComposite in2="hardAlpha" operator="out"/>
  <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1141_2749"/>
  <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1141_2749" result="shape"/>
</filter>
<filter id="filter1_d_1141_2749" x="0.229573" y="0.20463" width="48.326" height="40.1966" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
  <feFlood flood-opacity="0" result="BackgroundImageFix"/>
  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
  <feOffset dy="1.9731"/>
  <feGaussianBlur stdDeviation="1.9731"/>
  <feComposite in2="hardAlpha" operator="out"/>
  <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1141_2749"/>
  <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1141_2749" result="shape"/>
</filter>
<filter id="filter2_d_1141_2749" x="77.4327" y="19.9361" width="146.299" height="20.7181" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
  <feFlood flood-opacity="0" result="BackgroundImageFix"/>
  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
  <feOffset dy="1.9731"/>
  <feGaussianBlur stdDeviation="1.9731"/>
  <feComposite in2="hardAlpha" operator="out"/>
  <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1141_2749"/>
  <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1141_2749" result="shape"/>
</filter>
</defs>
        </svg>
            <h3 className="montserrat">Create New Account </h3>
            <SignupForm onSubmit={handleSignupSubmit} />
            <div className="sign-up-login-redirection">
            <p>I have an account? <Link to="/login"> Sign in</Link></p>
        
            </div>
        </div>
    </div>
</div>
</div>
  );
};

export default Signup;
