import React, { useState, useEffect, useRef } from 'react';
import CustomCounter from './CustomCounter';
import serviceicon from './images/services-icon.png';
import monthliicon from './images/monthly-icon.png';
import jobicon from './images/job-icon.png';
import clinicalicon from './images/clinical-icon.png';
import industry from './images/industry.png';
import clients from './images/clients.png';
import one from './images/1.png';
import two from './images/2.png';
import three from './images/3.png';
import four from './images/4.png';
import five from './images/5.png';
import six from './images/6.png';
import './countercomp.css';

type CounterData = {
  imageSrc: string;
  counterValue: number;
  counterDescription: string;
};

const CounterView: React.FC = () => {
  const countersData: CounterData[] = [
    {
      imageSrc: one,
      counterValue: 10000,
      counterDescription: 'Professionals',
    },
    {
      imageSrc: two,
      counterValue: 200,
      counterDescription: 'Clients',
    },
    {
      imageSrc: three,
      counterValue: 100,
      counterDescription: 'Partners',
    },
    {
      imageSrc: four,
      counterValue: 50,
      counterDescription: 'Services',
    },
    {
      imageSrc: five,
      counterValue: 10,
      counterDescription: 'States',
    },
    {
      imageSrc: six,
      counterValue: 500,
      counterDescription: 'Live Jobs',
    },
  ];

  const countersPerRow = 6;
  const duration = 3000;

  const [counters, setCounters] = useState(
    countersData.map(() => ({
      counterValue: 1,
      targetValue: 0,
      increment: 0,
    }))
  );

  const countersRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          startCounters();
          observer.disconnect();
        }
      },
      { threshold: 0.05 } // Adjust the threshold as needed (0.05 means 5%)
    );

    if (countersRef.current) {
      observer.observe(countersRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [countersRef]);

  const startCounters = () => {
    const startTime = Date.now();

    const updateCounters = () => {
      const currentTime = Date.now();
      const progress = Math.min(1, (currentTime - startTime) / duration);

      setCounters((prevCounters) =>
        prevCounters.map((counter) => ({
          ...counter,
          counterValue: Math.floor(counter.targetValue * progress),
        }))
      );

      if (progress < 1) {
        requestAnimationFrame(updateCounters);
      }
    };

    counters.forEach((counter, index) => {
      setTimeout(() => {
        setCounters((prevCounters) =>
          prevCounters.map((prevCounter, prevIndex) =>
            prevIndex === index
              ? {
                  ...prevCounter,
                  targetValue: countersData[index].counterValue,
                  increment: countersData[index].counterValue / duration,
                }
              : prevCounter
          )
        );
      }, index * (duration / counters.length));
    });

    updateCounters();
  };

  return (
    <div className='counter-display-wrapper' ref={countersRef}>
      {countersData.map((counterData, index) => (
        <React.Fragment key={index}>
          {index % countersPerRow === 0 && (
            <div className="custom-counter-row flex flex-wrap justify-between">
              {counters.slice(index, index + countersPerRow).map((counter, subIndex) => (
                <CustomCounter
                  key={subIndex}
                  imageSrc={countersData[index + subIndex].imageSrc}
                  counterValue={counter.counterValue}
                  counterDescription={countersData[index + subIndex].counterDescription}
                />
              ))}
            </div>
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

export default CounterView;
