// import React, { useEffect, useState } from 'react';
// import Logo from "components/Logo/Logo";
// import SocialsList1 from "components/SocialsList1/SocialsList1";
// import { CustomLink } from "data/types";
// import mobimage from './images/mobile-image.webp'
// import Currentyear from "components/Sections/currentyear/currentyear"
// import { Link } from 'react-router-dom';
// import './footer.css';
// import { useParams } from 'react-router-dom';
// import { useLocation } from "react-router-dom";

// export interface WidgetFooterMenu {
//   id: string;
//   title: string;
//   menus: CustomLink[];
// }

// const widgetMenus: WidgetFooterMenu[] = [
//   {
//     id: "1",
//     title: "Products",
//     menus: [
//       { href: "/for-clinicians#download-app-now", label: "Clinician App"},
//       { href: "/for-clients#download-app-now", label: " Client App" },
//       { href: "/for-partners#download-app-now", label:  "Partner App" },
//       // { href: "/contact-us", label: "For Associations" },
//     ],
//   },
//   {
//     id: "2",
//     title: "Solution",
//     menus: [
//       { href: "/for-clinicians", label: "For Clinicians" },
//       { href: "/for-clients", label: "For Clients" },
//       { href: "/for-agencies", label: "For Recruitment Agencies" },
//       { href: "/for-partners", label: "For Partners" },
//       // { href: "/contact-us", label: "For Associations" },
//     ],
//   },
//   {
//     id: "3",
//     title: "Resources",
//     menus: [
//       { href: "/blogs", label: "Blogs" },
//       { href: "/pricing", label: "Pricing" },

//     ],
//   },
//   {
//     id: "4",
//     title: "Support",
//     menus: [
//       { href: "/about-us", label: "About Us" },
//       { href: "/contact-us", label: "Contact Us" },
//       { href: "/careers", label: "Careers" },
//       { href: "/terms-and-conditions", label: "Terms & Conditions" },
//       { href: "/privacy-policy", label: "Privacy Policy" },
//       { href: "/cancellation-policy", label: "Cancellation Policy" },
//       { href: "/refund-policy", label: "Refund Policy" },

//     ],
//   },
// ];

// const Footer: React.FC = () => {
//   const {candidateUri}=useParams()
//   console.log(candidateUri,"candidateUri-main")
//   let pathname = useLocation().pathname;
//   console.log(pathname,"path")

//   const renderWidgetMenuItem = (menu: WidgetFooterMenu, index: number) => {
//     return (
//       <div key={index} className="text-sm">
//         <h4 className="title-custom font-black bolds  montserrat">
//           {menu.title}
//         </h4>
//         <ul className="mt-5 space-y-4">
//           {menu.menus.map((item, index) => (
//             <li key={index}>
//               <a
//                 key={index}
//                 className="tera-footer-text montserrat"
//                 href={item.href}
//               >
//                 {item.label}
//               </a>
//             </li>
//           ))}
//         </ul>
//       </div>
//     );
//   };

//   return (
//     <div className="nc-Footer relative back background-white ">

//       <div className="container border-t  border-neutral-200  tera-footer-container ">
//         <div className="footer-logo-side ">
//           <div className="col-span-2 md:col-span-1">
//             <Logo />
//           </div>
//           <div className="logo-side-text-main">
//               <ul className="logo-side-text-container">
//                 <li className="tera-tagline-footer">
//                   Tera Connects is an exclusive platform for the medical industry, connecting clinicians and vendors to medical organizations in PAN India.
//                 </li>
//              </ul>
//           </div>
//           <div className="logo-side-text-main">
//               <ul className="logo-side-text-container">
//                 <li className="tera-tagline-footer tera-tagline-email">
//                  <a href="maito:contactus@teraconnects.com">contactus@teraconnects.com</a>
//                 </li>
//              </ul>
//           </div>
//           <div className="logo-side-text-main">
//               <ul className="logo-side-text-container">
//                 <li className="tera-tagline-footer tera-tagline-location">
//                 #708 7th Floor, Kadiris Apurupa Urban II, Botanical Garden Rd, near CHIREC Public School, Gachibowli, Hyderabad, Telangana 500084
//                 </li>
//              </ul>
//           </div>

//           <div className=" footer-logo-sec col-span-2 flex items-center md:col-span-3">
//             <SocialsList1 className="flex items-center foter-logo lg:items-start" />
//           </div>
//         </div>
//         {widgetMenus.map(renderWidgetMenuItem)}
//       </div>

//       <div className="container flex border-t dark:border-neutral-700  items-center ">
//       <a href="/" className="text-neutral-6000 w-1/1 dark:text-neutral-300 hover:text-black dark:hover:text-white montserrat mx-auto py-6">
//       &copy; <Currentyear />. All Rights Reserved By Tera Connects
//     </a>
//       </div>

//     </div>

//   );
// };

// export default Footer;

import React, { useEffect, useState } from "react";
import Logo from "components/Logo/Logo";
import SocialsList1 from "components/SocialsList1/SocialsList1";
import { CustomLink } from "data/types";
import mobimage from "./images/mobile-image.webp";
import Currentyear from "components/Sections/currentyear/currentyear";
import { Link } from "react-router-dom";
import "./footer.css";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";

export interface WidgetFooterMenu {
  id: string;
  title: string;
  menus: CustomLink[];
}

const widgetMenus: WidgetFooterMenu[] = [
  {
    id: "1",
    title: "Products",
    menus: [
      {
        href: "/hiring-careers/for-professionals#download-app-now",
        label: "MediProHub",
      },
      {
        href: "/hiring-careers/for-clients#download-app-now",
        label: " Client App",
      },
      {
        href: "/ops-maintenance/vendors-suppliers#download-app-now",
        label: "Partner App",
      },
    ],
  },
  {
    id: "2",
    title: "Solution",
    menus: [
      { href: "/hiring-careers/for-professionals", label: "For Professionals" },
      { href: "/hiring-careers/for-clients", label: "For Clients" },
      {
        href: "/hiring-careers/for-agencies",
        label: "For Recruitment Agencies",
      },
      { href: "/ops-maintenance/vendors-suppliers", label: "For Partners" },
      // {href : "/training",label:"Training"},
      // {href : "/placements",label:"Placements"},
    ],
  },
  {
    id: "3",
    title: "Resources",
    menus: [
      { href: "/blogs", label: "Blogs" },
      { href: "/pricing", label: "Pricing" },
      // {href :"/usermanual",label:"User Manual"},
      // {href : "/sucessStories",label:"Success Stories"},
      // {href:"/campusambassador",label:"Campus Embassador Program"},
      { href: "/licenses-required", label: "Hospital Licenses" },
    ],
  },
  {
    id: "4",
    title: "Support",
    menus: [
      { href: "/about-us", label: "About Us" },
      { href: "/contact-us", label: "Contact Us" },
      { href: "/careers", label: "Careers" },
      { href: "/terms-and-conditions", label: "Terms & Conditions" },
      { href: "/privacy-policy", label: "Privacy Policy" },
      { href: "/cancellation-policy", label: "Cancellation Policy" },
      { href: "/refund-policy", label: "Refund Policy" },
    ],
  },
];

const Footer: React.FC = () => {
  const pathname = useLocation()?.pathname;

  // Check if pathname contains '/cp/'
  const hideFooter = pathname.includes("/cp/");

  const renderWidgetMenuItem = (menu: WidgetFooterMenu, index: number) => {
    return (
      <div key={index} className="text-sm">
        <h4 className="title-custom font-black bolds  montserrat">
          {menu.title}
        </h4>
        <ul className="mt-5 space-y-4">
          {menu.menus.map((item, index) => (
            <li key={index}>
              <a
                key={index}
                className="tera-footer-text montserrat"
                href={item.href}
              >
                {item.label}
              </a>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  return !hideFooter ? (
    <div className="nc-Footer relative back background-white ">
      <div className="container border-t  border-neutral-200  tera-footer-container ">
        <div className="footer-logo-side ">
          <div className="col-span-2 md:col-span-1">
            <Logo />
          </div>
          <div className="logo-side-text-main">
            <ul className="logo-side-text-container">
              <li className="tera-tagline-footer">
                Tera Connects is an exclusive platform for the medical industry,
                connecting professionals and vendors to medical organizations in
                PAN India.
              </li>
            </ul>
          </div>
          <div className="logo-side-text-main">
            <ul className="logo-side-text-container">
              <li className="tera-tagline-footer tera-tagline-email">
                <a href="maito:contactus@teraconnects.com">
                  contactus@teraconnects.com
                </a>
              </li>
            </ul>
          </div>
          <div className="logo-side-text-main">
            <ul className="logo-side-text-container">
              <li className="tera-tagline-footer tera-tagline-location">
                #708 7th Floor, Kadiris Apurupa Urban II, Botanical Garden Rd,
                near CHIREC Public School, Gachibowli, Hyderabad, Telangana
                500084
              </li>
            </ul>
          </div>
          <div className=" footer-logo-sec col-span-2 flex items-center md:col-span-3">
            <SocialsList1 className="flex items-center foter-logo lg:items-start" />
          </div>
        </div>
        {widgetMenus.map(renderWidgetMenuItem)}
      </div>
      <div className="container flex border-t dark:border-neutral-700  items-center ">
        <a
          href="/"
          className="text-neutral-6000 w-1/1 dark:text-neutral-300 hover:text-black dark:hover:text-white montserrat mx-auto py-6"
        >
          &copy; <Currentyear />. All Rights Reserved By Tera Connects
        </a>
      </div>
    </div>
  ) : null;
};

export default Footer;
