import React, { useEffect, useState } from 'react';

interface TypingTextProps {
  sentences: string[];
}

const TypingText: React.FC<TypingTextProps> = ({ sentences }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [offset, setOffset] = useState(0);
  const [forwards, setForwards] = useState(true);
  const [skipCount, setSkipCount] = useState(0);

  useEffect(() => {
    const handleAnimation = () => {
      if (forwards) {
        if (offset >= sentences[currentIndex].length) {
          setSkipCount((prevSkipCount) => prevSkipCount + 1);
          if (skipCount === skipDelay) {
            setForwards(false);
            setSkipCount(0);
          }
        }
      } else if (offset === 0) {
        setForwards(true);
        setCurrentIndex((prevIndex) => (prevIndex + 1) % sentences.length);
      }

      if (skipCount === 0) {
        setOffset((prevOffset) => (forwards ? prevOffset + 1 : prevOffset - 1));
      }
    };

    const interval = setInterval(handleAnimation, speed);

    return () => clearInterval(interval);
  }, [forwards, offset, currentIndex, skipCount, sentences]);

  const skipDelay = 15;
  const speed = 70;

  const updateSentence = () => {
    return sentences[currentIndex]?.substring(0, offset);
  };

  return (
    <span className="sentence">{updateSentence()} | </span>
  );
};

export default TypingText;
