import React, { useEffect, useMemo } from "react";
import HeaderLogged from "components/Header/HeaderLogged";
import Header from "components/Header/Header";
import Header2 from "components/Header/Header2";
import logoutheader from "components/Header/logoutheader";
import { useLocation } from "react-router-dom";
import { useUser } from "components/UserContext";

const SiteHeader = () => {
  let pathname = useLocation().pathname;
  let { isAuthenticated } = useUser();

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [pathname]);

  const headerComponent = useMemo(() => {
    let HeadComponent = isAuthenticated ? HeaderLogged:logoutheader ;

    switch (pathname) {
      case "/hiring-careers/staffing-and-hr":
        HeadComponent = Header;
        break;
      case "/services/operational-services":
        HeadComponent = Header;
        break;
      case "/services/branding-and-promotion":
        HeadComponent = Header;
        break;
      case "/services/medical-center-setup":
        HeadComponent = Header;
        break;
      case "/login":
        HeadComponent = logoutheader;
        break;

      case "/home-3":
        HeadComponent = Header2;
        break;

      default:
        break;
    }

    return <HeadComponent />;
  }, [pathname]);

  return <>{headerComponent}</>;
};
export default SiteHeader;
