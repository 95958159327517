import React from "react";
import { Helmet } from "react-helmet";
import "./refund.css";
function refundpolicy() {
  return (
    <div className="container tnC-page">
      <Helmet>
        <title>Refund Policy - Tera Connects </title>
<meta
          name="description"
          content="Our Privacy Policy explains how we collect, use, and protect your personal information. Please review it carefully to understand our practices."
        />
      </Helmet>
      <div className="p-6 bg-gray-100 rounded-lg ">
        <div className="home-hero-section privacy-page refund-policy">
          <div className="container lg:flex head-home-sec gap-extra">
            <div className="lg:w-6/12  head-home-sec-centre">
              <h3 className="font-white regular title-custom title-heading-banner montserrat ">
                {" "}
                <b>Refund Policy</b>
              </h3>
            </div>
            <div className="lg:w-6/12 head-home-sec-centre"></div>
          </div>
        </div>
        <div className="pvcpolcy">
          <h4 className="font-semibold">1. Overview</h4>

          <p className="">
            {" "}
            We do not issue refunds; however, you may withdraw your wallet
            balance when you decide to discontinue with Tera.
          </p>
          <p className="">
            Our refund decision is final and binding and is made at our
            exclusive discretion.All refund amounts will be refunded to your
            account within 7 business days, subject to the limitations set by
            the bank that issued the credit / debit card.If your situation isn't
            covered above, please email us at contactus@teraconnects.com and
            provide your invoice number.
          </p>

          <h4 className="mt-10 font-semibold">Last Updated</h4>
          <p>This Terms and Conditions was last updated on 01 April 2023.</p>
        </div>
      </div>
    </div>
  );
}

export default refundpolicy;
