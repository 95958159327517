import React, { useState, useEffect } from "react";

const mobiledeveloper = () => {
  return (
    <div className="page-job-post content-writer">
      <div className="container">
        <div className="job-post-wrapper">
          <div className="jobdetail-side">
            <div className="joblistings-detail">
              <div className="jobs-prodiver-detail">
                <div className="job-info-main">
                  <h4 className="job-name"> Mobile Developer</h4>
                  <h5 className="job-provider-name"></h5>
                </div>
                <div className="job-provider-info"></div>
              </div>
              <div className="jobs-requirment">
                <div className="uppersection">
                  <p className="job-detail-minor required-exprence ">
                    2-5 years
                  </p>
                  <p className="job-detail-minor salary-offer">Not Disclosed</p>
                  <p className="job-detail-minor location">Hyderabad</p>
                </div>
                <div className="lowersections">
                  <p className="job-detail-minor detail-other ">
                    Education : Any Degree
                  </p>
                  <p className="job-detail-minor detail-other">
                    Key Skills : Flutter, Dart, Android, iOS
                  </p>
                </div>
              </div>
              <div className="job-apply-section">
                <div className="job-provider-info post-page">
                  <p className="job-detail-minor openings"> 1 Opening</p>
                  <span className="job-detail-minor posted-ago">
                    4/12/ 2023
                  </span>
                </div>
                <div className="job-btns-selection">
                  <a
                    className="applynowbtn apply-save-btn"
                    href="mailto:careers@teraconnects.com"
                  >
                    Apply
                  </a>
                </div>
              </div>
            </div>

            <div className="career-joblisting-detail job-description">
              <div className="product-specification">
                <h2 className="product-specs-title-for-product-single for-list-prod montserrat">
                  Responsibilities
                </h2>
                {/* <ul className="product-desc-list tera-job-description"> */}
                <ul className="">
                  <li className="specs-list-content montserrat">
                    Develop and maintain high-quality mobile applications using
                    Flutter, Dart
                  </li>
                  <li className="specs-list-content montserrat">
                    Collaborate with cross-functional teams to identify and
                    prioritize features
                  </li>
                  <li className="specs-list-content montserrat">
                    Build reusable code and libraries for future use
                  </li>
                  <li className="specs-list-content montserrat">
                    Ensure the performance, quality, and responsiveness of
                    applications
                  </li>
                  <li className="specs-list-content montserrat">
                    Identify and correct bottlenecks and fix bugs
                  </li>
                  <li className="specs-list-content montserrat">
                    Continuously discover, evaluate, and implement new
                    technologies to maximize development efficiency
                  </li>
                  <li className="specs-list-content montserrat">
                    Mentor junior developers and ensure code quality and best
                    practices are followed
                  </li>
                </ul>
              </div>
            </div>

            <div className="career-joblisting-detail job-description">
              <div className="product-specification">
                <h2 className="product-specs-title-for-product-single for-list-prod montserrat">
                  Requirements
                </h2>
                {/* <ul className="product-desc-list tera-job-description"> */}
                <ul className="">
                  <li className="specs-list-content montserrat">
                    2+ years of experience in developing mobile applications
                    using Flutter
                  </li>
                  <li className="specs-list-content montserrat">
                    Proficient in Flutter development framework and Dart
                    programming language
                  </li>
                  <li className="specs-list-content montserrat">
                    Experience in building mobile applications for iOS and
                    Android platforms
                  </li>
                  <li className="specs-list-content montserrat">
                    Strong understanding of mobile development frameworks,
                    tools, and technologies, including RESTful APIs, JSON, and
                    OAuth
                  </li>
                  <li className="specs-list-content montserrat">
                    Experience with version control systems such as Git
                  </li>
                  <li className="specs-list-content montserrat">
                    Solid understanding of the software development lifecycle
                  </li>
                  <li className="specs-list-content montserrat">
                    Experience with Agile/Scrum methodologies
                  </li>
                  <li className="specs-list-content montserrat">
                    Experience working in a collaborative team environment
                  </li>
                  <li className="specs-list-content montserrat">
                    Excellent problem-solving and analytical skills
                  </li>
                </ul>
              </div>
            </div>

            <div className="career-joblisting-detail job-description">
              <div className="product-specification">
                <h2 className="product-specs-title-for-product-single for-list-prod montserrat">
                  Technical Skills
                </h2>
                {/* <ul className="product-desc-list tera-job-description"> */}
                <ul className="">

                  <li className="specs-list-content montserrat">
                    Experience with State Management solutions such as Provider,
                    Bloc, and GetIt
                  </li>
                  <li className="specs-list-content montserrat">
                    Understanding of Flutter Widget Tree, Stateful and Stateless
                    widgets, and Flutter Layouts
                  </li>
                  <li className="specs-list-content montserrat">
                    Experience with Google's Material Design Guidelines and
                    Human Interface Guidelines
                  </li>
                  <li className="specs-list-content montserrat">
                    Familiarity with Firebase Cloud Services including Cloud
                    Firestore, Cloud Functions, and Cloud Messaging
                  </li>
                  <li className="specs-list-content montserrat">
                    Knowledge of reactive programming concepts and experience
                    with the Flutter RxDart package
                  </li>
                  <li className="specs-list-content montserrat">
                    Familiarity with integrating third-party packages into
                    Flutter applications
                  </li>
                  <li className="specs-list-content montserrat">
                    Experience with Google Maps API and location-based services
                  </li>
                  <li className="specs-list-content montserrat">
                    Understanding of RESTful API design and development
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default mobiledeveloper;
