import React, { useState, useEffect } from 'react';
import ProductList from 'components/Sections/productlist/productcard';
import productsData from 'components/Sections/productlist/productlist.json';

import Categoryfilter from 'components/Sections/productlist/filter/Categoryfilter/Categoryfilter';
import { Product } from 'components/Sections/productlist/productcard';

import Searchcategoryfilter from 'components/Sections/productlist/filter/Searchfilter/Searchfilter';
import Searchbytcity from 'components/Sections/productlist/filter/searchbycity/searchbycity';

import Advertisebrand from 'components/Sections/productlist/filter/adviertisebrand/advertisebrand';
import TestimonialSlider from 'components/Sections/testimonal-comp/TestimonialSlider';

import advertiseone from './images/advirtiseone.png'
import advertisetwo from './images/advertisetwo.png'


import { Link } from "react-router-dom"; 

import './buysell.css'
import { getListings } from 'services/buyAndSellService';
const Buysell = () => {
  const [latestProducts, setLatestProducts] = useState<Product[]>([]);

  useEffect(() => {
    getListings({
      "orderBy": "UpdatedDate",
      "orderByDirection": "DESC",
      "pageNumber": 1,
      "pageSize": 4,
      "searchText": "",
      "condition": [
        0
      ],
      "listingType": [
        0
      ],
      "brand": [
        0
      ],
      "subcategory": [
        0
      ],
      "category": [
        0
      ],
      "priceRange": {
        "min": 0,
        "max": 0
      }
    }).then((response) =>{
      setLatestProducts(response.data.data);
    });
    // Sort the data by ID in descending order to get the latest products first
    // const sortedData = productsData.sort((a, b) => b.id - a.id);
    // // Take the first 4 items
    // const latestFourProducts = sortedData.slice(0, 4);
    // setLatestProducts(latestFourProducts);
  }, []); // Empty dependency array to run the effect only once

  return (
  <div className='page-buysell'>
    <div className='service-pages-header aboutus-pages-header buysellpage'>
      <div className='container flex items-center justify-center service-pages-header-inner'>
        <div className='hero-inner'>
        <h5 className="font-white regular text-center title-custom montserrat">Find verified sellers near you</h5>
          <h3 className="font-white regular text-center title-custom title-heading-banner montserrat "> Searching For <span className="font-sky-blue bolders">  Medical Equipment
              </span></h3>
          <Searchcategoryfilter/>
         
        </div>
      </div>
    </div>


    <div className='container background-white'>
    <div className='product-list-page for-categories buysell-sections'>
        <div className='prod-listing-four'>
        <h1  className='montserrat buysellheading'>Top Categories</h1>
        </div>
        {/* <Categoryfilter /> */}
        
      </div>
      <div className='buysell-sections product-list-page'>
        <div className='prod-listing-four'>
        <h1  className='montserrat buysellheading'>Product Listing Page</h1>
        <Link to="/shop">
        <a className='montserrat buysellviewall'>View All</a>
        </Link>
        </div>
        <ProductList products={latestProducts} />
       
        
      </div>


      <div className=' buysell-sections advertise-board advertise-board-one'>
        <img src={advertiseone} />
      </div>


      <div className='product-list-page buysell-sections'>
        <div className='prod-listing-four'>
        <h1  className='montserrat buysellheading'>Product Listing Page</h1>
        <Link to="/shop">
        <a className='montserrat buysellviewall'>View All</a>
        </Link>
        </div>
        <ProductList products={latestProducts} />
        
      </div>

    <div className='advertisebrand-tera buysell-sections'>
    <div className='prod-listing-four'>
    <h1  className='montserrat buysellheading'>Explore products from Premium Brands</h1>
    </div>
    <Advertisebrand />
    </div>

    <div className='buysell-sections advertise-board advertise-board-two'>
        <img src={advertisetwo} />
      </div>

    <div className='buysell-sections searchbycity'>
    <div className='prod-listing-four'>
    <h1  className='montserrat buysellheading'>Sellers by Cities</h1>
    </div>
    <Searchbytcity  />
    </div>


    <div className='buysell-sections product-list-page'>
        <div className='prod-listing-four'>
        <h1  className='montserrat buysellheading'>Product Listing Page</h1>
        <a className='montserrat buysellviewall' href='javascipt:void(0);'>View All</a>
        </div>
        <ProductList products={latestProducts} />
        
      </div>

    </div>
    {/* <div className='container background-white buysell-sections'>
  
   

      <TestimonialSlider perView={1} />
    

  </div> */}



    </div>
  );
};

export default Buysell;
