export const BuyAndSell_API_URLS = {
  getListingsUrl: "Listing/GetListings",
};
export const Events_API_URLS = {
  searchEventsUrl: "Events/SearchEvents",
  getTopUpcomingEventsUrl: "Events/GetTopUpcomingEvents",
  getEventDetailsUrl: "Events/GetEventDetails",
};
export const Referral_API_URLS = {
  getInReviewMAClinicianReferralsUrl:
    "MarketingPartner/GeInreviewMAClinicianReferrals",
  getActiveMAClinicianReferralsUrl:
    "MarketingPartner/GeActiveMAClinicianReferrals",
  getInReviewMAClientReferralsUrl:
    "MarketingPartner/GetInreviewMAClientReferrals",
  getActiveMAClientReferralsUrl: "MarketingPartner/GeActiveMAClientReferrals",
  getInActiveMAClientReferralsUrl:
    "MarketingPartner/GeInActiveMAClientReferrals",
  getInReviewMAVendorReferralsUrl:
    "MarketingPartner/GeInreviewMAVendorReferrals",
  getInActiveMAVendorReferralsUrl:
    "MarketingPartner/GeInActiveMAVendorReferrals",
  getActiveMAVendorReferralsUrl: "MarketingPartner/GeActiveMAVendorReferrals",
};
export const Profile_API_URLS = {
  getMyProfileDetailsUrl: "MarketingPartner/GetMyProfileDetails",
};
export const Dashboard_API_URLS = {
  getMyDashBoardSummaryUrl: "MarketingPartner/GetMyDashBoardSummary",
  getMyClinicianSummaryUrl:"MarketingPartner/GetMyClinicianSummary",
  getMyClientSummaryUrl:"MarketingPartner/GetMyClientSummary",
  getMyVendorSummaryUrl:"MarketingPartner/GetMyVendorSummary"
};
export const Login_API_URLS = {
  loginUrl:"MarketingPartner/Login",
  forgotPasswordUrl:"MarketingPartner/ForgotPassword",
  updatePasswordWithOTPUrl: "MarketingPartner/UpdatePasswordWithOTP",
  reAuthUrl:"MarketingPartner/ReAuth"
};
export const Jobs_API_URLS = {
  searchJobsUrl:"Job/SearchJobs",
  getJobDetailsUrl:"Job/GetJobDetails",
  getTopJobOrganizationsUrl:"Job/GetTopJobOrganizations",
  applyJobUrl: "Job/ApplyJob"
};
export const Lookup_API_URLS = {
  getHealthCareCategoriesUrl:"Lookup/GetHealthCareCategories",
  getStatesUrl:"Lookup/GetStates",
  getOrganizationTypeUrl: "Lookup/GetOrganizationType",
  getAllTeraServicesUrl:"Services/GetAllTeraServices",
  getCitiesByStateUrl:"Lookup/GetCitiesByState",
  getCountriesUrl:"Lookup/GetCountries",
  getStatesByCountryUrl:"Lookup/GetStatesByCountry",
  getUserTypeUrl:"Lookup/GetUserType"
};
export const Contact_API_URLS = {
  contactUsUrl:"Leads/ContactUs",
};
export const Clinician_API_URLS = {
  getClinicianProfile:"Clinician/GetProfile",
};
