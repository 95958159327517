import React from "react";
import Layout from "../../layout";
import LayoutDashboard from "../referralslayout"
import VendorHeaderFilter from "./vendorHeaderFilter";
const VendorsReferrals = () => {
  return (
    <Layout>
      <LayoutDashboard>
        <div className="rounded-xl md:border md:border-neutral-100 shadow-md dark:border-neutral-800 md:p-3">
          <VendorHeaderFilter heading={'Vendors'}/>
        </div>
      </LayoutDashboard>
    </Layout>
  );
};
export default VendorsReferrals;