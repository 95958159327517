import React, { useState } from 'react';


interface AccordionItem {
  heading: string;
  content: string[];
}

interface AccordionProps {
  items: AccordionItem[];
}

const Accordion: React.FC<AccordionProps> = ({ items }) => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const onTitleClick = (index: number) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const renderContent = (content: AccordionItem['content']) => {
    return (
      <ul className="accordion-content">
        {content.map((item, idx) => (
          <li key={idx}>{item}</li>
        ))}
      </ul>
    );
  };

  const renderItems = () => {
    return items.map((item, index) => {
      const isActive = index === activeIndex;
      const icon = isActive ? '-' : '+';

      return (
        <div key={index} className={`accordion-item ${isActive ? 'active' : ''}`}>
          <div className="accordion-title" onClick={() => onTitleClick(index)}>
            <div className="accordion-title-first">
              <h3>{item.heading}</h3>
            </div>
            <div className="accordion-title-second">
              <span className="accordion-icon">{icon}</span>
            </div>
          </div>
          {isActive && renderContent(item.content)}
        </div>
      );
    });
  };

  return <div className="accordion">{renderItems()}</div>;
};

export default Accordion;
