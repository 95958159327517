// Accordion.js
import React, { useState } from 'react';
import './accordian.css'

interface AccordionItem {
  image: string;
  title: string;
  content: Array<{
    image: string;
    title: string;
    link: string;
  }>;
}

interface AccordionProps {
  items: AccordionItem[];
}

const Accordion: React.FC<AccordionProps> = ({ items }) => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const onTitleClick = (index: number) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const renderContent = (content: AccordionItem['content']) => {
    return (
      <div className="accordion-content">
        {content.map((div, idx) => (
          <div key={idx}>
            {/* Render your content here */}
            <a href={div.link}>
              <img src={div.image} alt="Content Image" />
              <h4>{div.title}</h4>
            </a>
          </div>
        ))}
      </div>
    );
  };

  const renderItems = () => {
    return items.map((item, index) => {
      const isActive = index === activeIndex;
      const icon = isActive ? '-' : '+';

      return (
        <div key={index} className={`accordion-item ${isActive ? 'active' : ''}`}>
          <div className="accordion-title" onClick={() => onTitleClick(index)}>
            <div className='accordion-title-first'>
            <img src={item.image} alt="Accordion Item" />
            <h3>{item.title}</h3>
            </div>
            <div className='accordion-title-second'>
            <span className="accordion-icon">{icon}</span>
            </div> 
          </div>
          {isActive && renderContent(item.content)}
        </div>
      );
    });
  };

  return <div className="accordion">{renderItems()}</div>;
};

export default Accordion;
