import React, { useState, useEffect } from 'react';
import './tabs.css';

interface TabContentProps {
  tabName: string;
  svg: React.ReactNode;
  mainHeading: string;
  subHeading: string;
  imageSrc: string;
}

function TabContent({ svg, mainHeading, subHeading }: Pick<TabContentProps, 'svg' | 'mainHeading' | 'subHeading'>) {
  return (
    <div className="tabs-wrapper">
      <div className="tabs-icons">{svg}</div>
      <div className="tabs-headings">
        <h3 className="tab-main-heading montserrat">{mainHeading}</h3>
        <h5 className="tab-sub-heading montserrat">{subHeading}</h5>
      </div>
    </div>
  );
}

function Tabs({ tabData }: { tabData: TabContentProps[] }) {
  const [activeTab, setActiveTab] = useState<string | null>(tabData.length > 0 ? tabData[0].tabName : null);

  const openTab = (tabName: string | null = null) => {
    setActiveTab(tabName);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      const currentIndex = tabData.findIndex((tab) => tab.tabName === activeTab);
      const nextIndex = (currentIndex + 1) % tabData.length;
      openTab(tabData[nextIndex].tabName);
    }, 3000);

    return () => clearInterval(intervalId);
  }, [activeTab, tabData]);

  return (
    <div className="tabs-wrapper">
      <div className="tab">
        {tabData.map((tab, index) => (
          <button
            key={index}
            className={`tablinks ${activeTab === tab.tabName ? 'active' : ''}`}
            onClick={() => openTab(tab.tabName)}
          >
            <TabContent {...tab} />
          </button>
        ))}
      </div>

      {tabData.map((tab, index) => (
        <div key={index} id={tab.tabName} className="tabcontent" style={{ display: activeTab === tab.tabName ? 'flex' : 'none' }}>
          <img src={tab.imageSrc} alt={`${tab.tabName} Image`} />
        </div>
      ))}
    </div>
  );
}

export default Tabs;
