import React, { useState, useEffect } from 'react';
import ProgramCourses from './course';
import Programs from './data/program.json';
import './Courses.css';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

interface Program {
  id: number;
  name: string;
}

const Courses: React.FC = () => {
  const [selectedProgram, setSelectedProgram] = useState<Program | null>(null);
  const [isMobileView, setIsMobileView] = useState<boolean>(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleProgramClick = (program: Program) => {
    setSelectedProgram(program);
  };

  const handleBackClick = () => {
    setSelectedProgram(null);
  };

  return (
    <div className='main-courses-container'>
      <div className="courses-container">
        {isMobileView && selectedProgram ? (
          <div className="content active">
             <ArrowBackIcon style={{ marginRight: '10px' }} /><button className="back-button mb-4" onClick={handleBackClick}>Back</button>
            <ProgramCourses program={selectedProgram} />
          </div>
        ) : (
          <>
            <div className={`sidebar ${isMobileView && selectedProgram ? 'hidden' : ''}`}>
              <div className="program-list">
                <h1 className='program-list-heading'>Programs</h1>
                {Programs.map(program => (
                  <div
                    key={program.id}
                    className={`program-card ${selectedProgram?.id === program.id ? 'active' : ''}`}
                    onClick={() => handleProgramClick(program)}
                  >
                    <h2>{program.name}</h2>
                  </div>
                ))}
              </div>
            </div>
            {!isMobileView && (
              <div className="content">
                {selectedProgram ? <ProgramCourses program={selectedProgram} /> : <p>Select a program to see courses</p>}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Courses;
