import React, { useEffect, useState } from "react";
import Eventbaneerslider from "components/Sections/event/event-main-card/eventmainslider";
import Eventposterslider from "components/Sections/event/eventposterslider/eventposterslider";
import Eventshorcardslider from "components/Sections/event/eventshortcard/eventshortcardslider";
import Moreevent from "components/Sections/event/moreeventcard/moreevent";
import JoinNetwork from "components/Sections/mobileappdownload/mobileappdownload";
import TestimonialSlider from "components/Sections/testimonal-comp/TestimonialSlider";
import OfferService from "components/Sections/serviceoffer/serviceoffer";
import offerservice1 from "./images/serviceoffer1.png";
import offerservice2 from "./images/serviceoffer2.png";
import offerservice3 from "./images/serviceoffer3.png";
import offerservice4 from "./images/serviceoffer4.png";
import backgroundfooter from "./images/Rectangle-4.webp";
import qrtera from "./images/mobqr.png";
import "./event.css";
import persondefault from "./images/person-default.png"
import { getTopUpcomingEventsApi } from "services/eventsService";
import SearchEventsByCityFilter from "components/Sections/event/search-events";

export type CmeEventsData = {
  eventTitle: string;
  eventDate: string;
  eventEndDate: string;
  eventMode: string;
  registrationStartDate: string;
  registrationEndDate: string;
  location: string;
  credits: number;
  organizer: string;
  thumbnail: string;
  description: string;
  id: number;
};

const Events: React.FC = () => {
  const [cmeEventsDetails, setCmeEventsDetails] = useState<CmeEventsData[]>([]);
  const eventData = [
    {
      imgUrls: "https://i.ytimg.com/vi/eILUmCJhl64/hq720.jpg",
      heading: "Growing The Global Technology Industry",
      description:
        "Discover a world where healthcare collaboration meets cutting-edge world where healthcare collaboration",
      eventUrls: "#",
    },
    {
      imgUrls: "https://i.ytimg.com/vi/SqcY0GlETPk/hq720.jpg",
      heading: "Growing The Global Technology Industry",
      description:
        "Discover a world where healthcare collaboration meets cutting-edge world where healthcare collaboration",
      eventUrls: "#",
    },
    {
      imgUrls: "https://i.ytimg.com/vi/LoYbN6qoQHA/hq720.jpg",
      heading: "Growing The Global Technology Industry",
      description:
        "Discover a world where healthcare collaboration meets cutting-edge world where healthcare collaboration",
      eventUrls: "#",
    },
    {
      imgUrls: "https://i.ytimg.com/vi/FqByVCp0hWk/hq720.jpg",
      heading: "Growing The Global Technology Industry",
      description:
        "Discover a world where healthcare collaboration meets cutting-edge world where healthcare collaboration",
      eventUrls: "#",
    },

    // Add more event data as needed
  ];

  const offerServiceDatalist = [
    {
      image: offerservice1,
      title: "Setup & Smooth Sail",
      subtitle:
        "Simplifying the process of setting up and maintaining healthcare infrastructure for a hassle-free experience",
    },
    {
      image: offerservice2,
      title: "Theater of Excellence",
      subtitle:
        "Bringing expertise to the forefront in setting up and maintaining OTs, Transplant Units, and ICUs",
    },
    {
      image: offerservice3,
      title: "Accreditation Made Easy",
      subtitle:
        "Guiding through the accreditation journey with NABH/NABL Accreditation Services for seamless compliance",
    },
    {
      image: offerservice4,
      title: "Safety & Compliance Partner",
      subtitle:
        "Comprehensive services for Gas Pipeline Setup, Fire & Safety, and Sewage Treatment Plant, ensuring a safe and compliant healthcare space",
    },

    // Add more feature service data objects as needed
  ];
  const customTestimonialData = [

    {
      id: 1,
      name: "Dr. Madhuri ",
      heading: "“",
      description: " I had the incredible opportunity to attend a physician event where I connected with influential doctors and gained invaluable insights. Tera Connects not only led me to this enriching experience but also played a key role in expanding my professional network. Their commitment to connecting medical professionals with opportunities goes beyond placements—it's about fostering growth and learning. Grateful for the doors Tera Connects opened for me in the medical community. ",
      rating: 5,
      imageSrc: persondefault,
      organisation: "General Physician",
    },

  ];


  const sliderData = [
    {
      eventposter:
        "https://entertainmentpark.com.au/wp-content/uploads/2022/10/gallery-5.jpg",
      eventtitle: "Lorem Ipsum",
      eventtext: "Brand Go1",
    },
    {
      eventposter:
        "https://entertainmentpark.com.au/wp-content/uploads/2022/10/card-3.jpg",
      eventtitle: "Lorem Ipsum",
      eventtext: "Brand Go2",
    },
    {
      eventposter:
        "https://entertainmentpark.com.au/wp-content/uploads/2022/10/card-2.jpg",
      eventtitle: "Lorem Ipsum",
      eventtext: "Brand Go1",
    },
    {
      eventposter:
        "https://entertainmentpark.com.au/wp-content/uploads/2022/10/card-1.jpg",
      eventtitle: "Lorem Ipsum",
      eventtext: "Brand Go2",
    },
    {
      eventposter:
        "https://entertainmentpark.com.au/wp-content/uploads/2022/10/gallery-5.jpg",
      eventtitle: "Lorem Ipsum",
      eventtext: "Brand Go1",
    },
    {
      eventposter:
        "https://entertainmentpark.com.au/wp-content/uploads/2022/10/card-3.jpg",
      eventtitle: "Lorem Ipsum",
      eventtext: "Brand Go2",
    },
    {
      eventposter:
        "https://entertainmentpark.com.au/wp-content/uploads/2022/10/card-2.jpg",
      eventtitle: "Lorem Ipsum",
      eventtext: "Brand Go1",
    },
    {
      eventposter:
        "https://entertainmentpark.com.au/wp-content/uploads/2022/10/card-1.jpg",
      eventtitle: "Lorem Ipsum",
      eventtext: "Brand Go2",
    },
    // Add more slider data as needed
  ];
  useEffect(() => {
    getTopUpcomingEventsApi({
      orderBy: "EventDate",
      orderByDirection: "DESC",
      pageNumber: 1,
      pageSize: 10,
    }).then((response) => {
      setCmeEventsDetails(response.data);
    });
  }, []);
  return (
    <div className="event-page">
      <div className="event-banner">
        <div className="container">
          <Eventbaneerslider perView={1} cmeEventsDetails={cmeEventsDetails} />
        </div>
      </div>
      <div className="tabsteraconnects background-white">
        <div className="container">
          {/* <h2 className="servicepages-headings montserrat font-medium lg:mb-20 mb-10">
            {" "}
            Featured<span className="font-extrabold"> Conferences</span>
          </h2>
          <div className="event-four-card-text-side">
            <h2 className="font-sky-blue montserrat">Search Events</h2>
          </div>
          <SearchEventsByCityFilter setCmeEventsDetails={setCmeEventsDetails} /> */}
          <div className="event-short-card-slider">
            <Eventshorcardslider
              perView={4}
              cmeEventsDetails={cmeEventsDetails}
            />
          </div>
        </div>
      </div>

      {/* <div className="event-poster-auto-slider">
        <Eventposterslider sliderData={sliderData} />
      </div> */}

      {/* <div className="tabsteraconnects background-white">
        <div className="container">
          <h2 className="servicepages-headings montserrat font-medium lg:mb-20 mb-10">
            {" "}
            Featured<span className="font-extrabold"> Conferences</span>
          </h2>
          <div className="event-short-card-slider">
            <Eventshorcardslider
              perView={4}
              cmeEventsDetails={cmeEventsDetails}
            />
          </div>
        </div>
      </div> */}

      <div className="event-four-card">
        <div className="container">
          <div className="event-four-card-inner">
            <div className="event-four-card-text-side">
              <h2 className="font-sky-blue montserrat">4 Easy Steps</h2>
              <h5 className="montserrat">
                Discover a world where healthcare collaboration meets
                cutting-edge technology. At Tera Connects, we redefine{" "}
              </h5>
              <a className="montserrat four-card-event-btn" href="#">
                Book Now
              </a>
            </div>
            <div className="event-four-card-cards-side offer-service featureservice-display-wrap">
              {/* Map over the list of feature service data and render FeatureService for each */}
              {offerServiceDatalist.map((data, index) => (
                <OfferService key={index} data={data} />
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="tabsteraconnects background-white">
        <div className="container">
          <h2 className="servicepages-headings montserrat font-medium lg:mb-20 mb-10">
            {" "}
            Learn About <span className="font-extrabold"> The Event</span>
          </h2>
          <div className="more-event-section">
            {eventData.map((event, index) => (
              <Moreevent key={index} {...event} />
            ))}
          </div>
        </div>
      </div>
      {/* <div className="tabsteraconnects background-white">
        <div className="container">
          <h2 className="servicepages-headings montserrat font-medium lg:mb-20 mb-10">
            {" "}
            Featured<span className="font-extrabold"> Conferences</span>
          </h2>
          <div className="event-short-card-slider">
            <Eventshorcardslider
              perView={4}
              cmeEventsDetails={cmeEventsDetails}
            />
          </div>
        </div>
      </div> */}

      <div className="home-page-contact-us">
        <div className="home-page-last-wrapper">
          <div className="flex flex-col justify-center items-start container">
            <h2 className="font-sky-blue bolds title-custom montserrat text-start mb-5">
              Let’s Start Our Journey
            </h2>
            <h4 className="font-white regular title-custom montserrat mb-5">
              Discover how we can meet your healthcare needs
            </h4>
            <a href="#download-app-now" className="common-btn-custom blue-btn">
              Install App
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="44"
                  height="45"
                  viewBox="0 0 44 45"
                  fill="none"
                >
                  <rect width="44" height="45" rx="22" fill="none" />
                  <path
                    d="M22.5 28.25L15.3125 21.0625L17.325 18.9781L21.0625 22.7156V11H23.9375V22.7156L27.675 18.9781L29.6875 21.0625L22.5 28.25ZM11 34V26.8125H13.875V31.125H31.125V26.8125H34V34H11Z"
                    fill="white"
                  />
                </svg>
              </span>
            </a>
          </div>
          <div className="hame-last-image">
            <img src={backgroundfooter} />
          </div>
        </div>
      </div>
      <div className="appdownload-main" id="download-app-now">
          <JoinNetwork
          heading="Join Our Network"
          subheading="Discover the Power of Connection with Tera Connects - Where Your Medical Needs Find Their Perfect Match."
          googlePlayUrl="https://play.google.com/store/apps/details?id=com.teraplatforms.jobseeker"
          appStoreUrl="https://apps.apple.com/in/app/tera-recruit-job-search/id1659265164"
          imageUrl={qrtera}
        />
      </div>
 <div className="container background-white">
 <TestimonialSlider perView={1} testimonialData={customTestimonialData}/>
      </div>
    </div>
  );
};

export default Events;
