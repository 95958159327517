import CryptoJS from "crypto-js";
import _ from "lodash";

const encryptObject = (object) => {
  if (_.isNil(object)) return object;
  const key = getKey();
  if (_.isNil(key) || _.isEmpty(key)) {
    return;
  }
  const encryptedObject = CryptoJS.AES.encrypt(
    JSON.stringify(object),
    key
  ).toString();
  return encryptedObject;
};

const decryptObject = (encryptedObject) => {
  if (_.isNil(encryptedObject)) return encryptedObject;
  const key = getKey();
  if (_.isNil(key) || _.isEmpty(key)) {
    return;
  }
  const decryptedObjectBytes = CryptoJS.AES.decrypt(encryptedObject, key);
  const objBytes = decryptedObjectBytes.toString(CryptoJS.enc.Utf8);
  const decryptedObject = JSON.parse(objBytes);
  return decryptedObject;
};

const getKey = () => localStorage.getItem("corp_k");

export { encryptObject, decryptObject };
