import React from 'react';

interface CounterProps {
  imageSrc: string;
  counterValue: number;
  counterDescription: string;
}

const Counter: React.FC<CounterProps> = ({
  imageSrc,
  counterValue,
  counterDescription,
}) => {
  return (
    <div className="counter-display-section cards-home background-white ">

      
        <h5 className="montserrat">
        {counterValue.toLocaleString()}<span>+</span>
      </h5>


    <ul className='counter-display-bottom'>
      <h6 className="  montserrat">{counterDescription}</h6>
      <img className="custom-counter-img" src={imageSrc} alt="Counter" />
    </ul>
    
     
    </div>
  );
};

export default Counter;

