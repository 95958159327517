import axios from "axios";
import { decryptObject } from "./storageHelper";

const API_BASE_URL = process.env.REACT_APP_API_ENDPOINT;

class API {
  client = axios.create({
    baseURL: API_BASE_URL,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
  async get(url, params = {}) {
    return await this.client.get(url, {
      params,
    });
  }

  async postForm(url, data = {}) {
    return await this.client.post(url, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
  
  async post(url, data = {}) {
    return await this.client.post(url, data, {});
  }
  getAccessToken = () => {
    return decryptObject(localStorage.getItem("corp_token"));
  };
}

const instance = new API();

export default instance;
