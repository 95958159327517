import ButtonPrimary from "components/Button/ButtonPrimary";
import Input from "components/Input/Input";
import Label from "components/Label/Label";
import React, { useState, useEffect } from "react";
import Layout from "../../layout";
import LayoutDashboard from "../myaccountlayout";

import { useUser } from "components/UserContext";
import { getMyProfileDetailsApi } from "services/profileService";
interface ProfileDetails {
  partnerId: number;
  name: string;
  address: string;
  postalCode: string;
  gst: string;
  pan: string;
  contactEmail: string;
  contactNumber: number;
  webSite: string;
  referralCode: string;
  cityID: number;
  city: string;
  stateId: number;
  state: string;
  geoLocation: {
    x: number;
    y: number;
  };
  latitude: number;
  longitude: number;
}

const PersonalDetails = () => {
  const { userData } = useUser();
  const [profileDetails, setProfileDetails] = useState<ProfileDetails>({
    partnerId: 0,
    name: "",
    address: "",
    postalCode: "",
    gst: "",
    pan: "",
    contactEmail: "",
    contactNumber: 0,
    webSite: "",
    referralCode: "",
    cityID: 0,
    city: "",
    stateId: 0,
    state: "",
    geoLocation: {
      x: 0,
      y: 0,
    },
    latitude: 0,
    longitude: 0,
  });

  useEffect(() => {
    // getMyProfileDetailsApi(userData.getUser.parnerId).then((response) => {
    // getMyProfileDetailsApi(10)
    getMyProfileDetailsApi(userData.getUser.parnerId)
    .then((response) => {
        const profileResponseData = response.data;
        setProfileDetails(profileResponseData);
      })
      .catch((err) => {
        console.log(err, "Err");
      });
  }, []);

  // const handleUpdateProfile = async (e: React.FormEvent) => {
  //   e.preventDefault();
  //   try {
  //     console.log("Profile updated successfully!");
  //   } catch (error) {
  //     console.error("Error updating profile:", error);
  //   }
  // };

  return (
    <Layout>
      <LayoutDashboard>
        <div className="rounded-xl md:border md:border-neutral-100 dark:border-neutral-800 md:p-6">
          <form
            className="grid md:grid-cols-2 gap-6"
           
          >
      <label className="block">
              <Label>Full name</Label>
              <Input
                placeholder="Example Doe"
                type="text"
                className="mt-1"
                value={profileDetails.name}
                readOnly
                // onChange={(e) =>
                //   setProfileDetails({
                //     ...profileDetails,
                //     name: e.target.value,
                //   })
                // }
              />
            </label>
            <label className="block">
              <Label>Contact Number</Label>
              <Input
                placeholder=""
                prefix="+91"
                type="number"
                className="mt-1"
                value={profileDetails.contactNumber}
                readOnly
                // onChange={(e) =>
                //   setProfileDetails({
                //     ...profileDetails,
                //     contactNumber: Number(e.target.value),
                //   })
                // }
              />
            </label>

            <label className="block md:col-span-1">
              <Label> Contact Email</Label>
              <Input
                type="email"
                placeholder="example@example.com"
                className="mt-1"
                value={profileDetails.contactEmail}
                readOnly
                // onChange={(e) =>
                //   setProfileDetails({
                //     ...profileDetails,
                //     contactEmail: e.target.value,
                //   })
                // }
              />
            </label>
            <label className="block">
              <Label>Postal Code</Label>
              <Input
                placeholder=""
                type="text"
                className="mt-1"
                value={profileDetails.postalCode}
                readOnly
                // onChange={(e) =>
                //   setProfileDetails({
                //     ...profileDetails,
                //     postalCode: e.target.value,
                //   })
                // }
              />
            </label>
            <label className="block md:col-span-2">
              <Label>Address</Label>
              <Input
                placeholder=""
                type="text"
                className="mt-1"
                value={profileDetails.address}
                readOnly
                // onChange={(e) =>
                //   setProfileDetails({
                //     ...profileDetails,
                //     address: e.target.value,
                //   })
                // }
              />
            </label>

            <label className="block">
              <Label>State</Label>
              <Input
                placeholder=""
                type="text"
                className="mt-1"
                value={profileDetails.state}
                readOnly
                // onChange={(e) =>
                //   setProfileDetails({
                //     ...profileDetails,
                //     state: e.target.value,
                //   })
                // }
              />
            </label>
            <label className="block">
              <Label>City</Label>
              <Input
                placeholder=""
                type="text"
                className="mt-1"
                value={profileDetails.city}
                readOnly
                // onChange={(e) =>
                //   setProfileDetails({
                //     ...profileDetails,
                //     city: e.target.value,
                //   })
                // }
              />
            </label>
            <label className="block">
              <Label>Web Site</Label>
              <Input
                placeholder=""
                type="text"
                className="mt-1"
                value={profileDetails.webSite}
                readOnly
                // onChange={(e) =>
                //   setProfileDetails({
                //     ...profileDetails,
                //     webSite: e.target.value,
                //   })
                // }
              />
            </label>
            <label className="block">
              <Label>Referral Code</Label>
              <Input
                placeholder=""
                type="text"
                className="mt-1"
                value={profileDetails.referralCode}
                readOnly
                // onChange={(e) =>
                //   setProfileDetails({
                //     ...profileDetails,
                //     referralCode: e.target.value,
                //   })
                // }
              />
            </label>
            <label className="block">
              <Label>PAN</Label>
              <Input
                placeholder=""
                type="text"
                className="mt-1"
                value={profileDetails.pan}
                readOnly
                // onChange={(e) =>
                //   setProfileDetails({
                //     ...profileDetails,
                //     pan: e.target.value,
                //   })
                // }
              />
            </label>
            <label className="block">
              <Label>GSTIN</Label>
              <Input
                placeholder=""
                type="text"
                className="mt-1"
                value={profileDetails.gst}
                readOnly
                // onChange={(e) =>
                //   setProfileDetails({
                //     ...profileDetails,
                //     gst: e.target.value,
                //   })
                // }
              />
            </label>

            {/* <ButtonPrimary className="md:col-span-2" type="submit">
              Update profile
            </ButtonPrimary> */}
          </form>
        </div>
      </LayoutDashboard>
    </Layout>
  );
};

export default PersonalDetails;
