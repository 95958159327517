import React, { useState, useEffect } from 'react';
import './pricingtab.css';

interface TabContentProps {
  tabName: string;
  buttonContent: string;
  content: React.ReactNode;
}

function TabContent({ tabName, content, isActive }: { tabName: string; content: React.ReactNode; isActive: boolean }) {
  return (
    <div className={`tabcontent pricingtab-${tabName} ${isActive ? 'active' : ''}`} style={{ display: isActive ? 'flex' : 'none' }}>
      {content}
    </div>
  );
}

function Tabs({ tabData }: { tabData: TabContentProps[] }) {
  const [activeTab, setActiveTab] = useState<string | null>(tabData.length > 0 ? tabData[0].tabName : null);

  const openTab = (tabName: string) => {
    setActiveTab(tabName);
  };

  useEffect(() => {
    if (tabData.length > 0) {
      setActiveTab(tabData[0].tabName);
    }
  }, [tabData]);

  return (
    <div className="tabs-wrapper pricing-table ">
      <div className="button-wrapper container">
        <div>
          <h2 className="servicepages-headings montserrat font-medium montserrat">
          Access to<span className="font-extrabold"> Medical Brilliance</span>
          </h2>
        </div>
        <div className="buttons-container">
          {tabData.map((tab, index) => (
            <button
              key={index}
              className={` montserrattablinks ${activeTab === tab.tabName ? 'active montserrat' : 'montserrat'}`}
              onClick={() => openTab(tab.tabName)}
            >
              {tab.buttonContent}
            </button>
          ))}
        </div>
      </div>

      {tabData.map((tab, index) => (
        <TabContent key={index} tabName={tab.tabName} content={tab.content} isActive={activeTab === tab.tabName} />
      ))}
    </div>
  );
}

export default Tabs;
