import { useEffect, useState } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { Step } from "./contactform";
import "./locationform.css";
import { getOrganizationTypeApi } from "services/lookupService";
import ClientImg from "./images/Client-Other-svg.svg"

interface OrgTypeProps {
  activeStep: any;
  setActiveStep: any;
  steps: Step[];
  userData: any;
  onUserDataChange: (data: any) => void;
}
interface KeyValue {
  value: number;
  label: string;
}
const schema = yup.object().shape({
  organizationTypeID: yup.string(),
});

const OrgTypeForm: React.FC<OrgTypeProps> = ({
  activeStep,
  setActiveStep,
  steps,
  userData,
  onUserDataChange,
}) => {
  const [image, setImage] = useState<string>("");
  const [organizationTypes, setOrganizationTypes] = useState<KeyValue[]>([]);
  const [selectedOrgType, setSelectedOrgType] = useState(null);
  const [error, setError] = useState("");

  const methods = useForm({
    mode: "onChange",
    defaultValues: {},
    resolver: yupResolver(schema),
  });

  const { handleSubmit } = methods;

  useEffect(() => {
    getOrganizationTypeApi()
      .then((response) => {
        const data = response.data;
        setOrganizationTypes(data);
      })
      .catch((error) => {
        console.error("Error fetching organization types:", error);
      });
  }, []);
  const handleOrgTypeClick = (value: any) => {
    setSelectedOrgType(value);
  };
  const handleOrgTypeDetails = () => {
    if (!selectedOrgType) {
      setError("Please select any option.");
      return;
    }
    let formData = {
      userTypeID: userData.userTypeID,
      organizationTypeID: selectedOrgType,
    };
    onUserDataChange(formData);
    setActiveStep(activeStep + 1);
  };
  return (
    <FormProvider {...methods}>
      <>
        <div className="flex org-type-details">
          <div className="w-2/3">
            <div className="mt-0">
              <h1
                style={{
                  color: "white",
                  fontSize: "30px",
                  paddingTop: "18px",
                  paddingBottom: "18px",
                }}
              >
                Help us to know what kind of your Organization is?{" "}
              </h1>
              <div className="grid gap-3 md:grid-cols-3 mt-6">
                {organizationTypes.map((orgType) => (
                  <button
                    key={orgType.value}
                    className={`border rounded py-2 px-1 ${
                      selectedOrgType === orgType.value
                        ? "bg-blue-500 text-white"
                        : "bg-white"
                    }`}
                    onClick={() => handleOrgTypeClick(orgType.value)}
                  >
                    {orgType.label}
                  </button>
                ))}
              </div>
              <div className="mt-3">
                {error && <p style={{ color: "#f44336" }}>{error}</p>}
              </div>
            </div>
          </div>
          <div className="w-1/3 p-16 org-type-img">
            <img src={ClientImg} alt="Your Image" />
          </div>
        </div>
        <div className="button-container">
          {activeStep !== 0 && activeStep !== steps.length - 1 && (
            <button
              onClick={() => setActiveStep(activeStep - 1)}
              className="button flex gap-2"
            >
              <svg
                className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 10"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M13 5H1m0 0l4-4m-4 4l4 4"
                />
              </svg>
              Previous
            </button>
          )}
          {activeStep !== steps.length - 1 && (
            <button
              onClick={() => {
                handleSubmit(handleOrgTypeDetails)();
              }}
              className="button"
            >
              Next
              <svg
                className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 10"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M1 5h12m0 0L9 1m4 4L9 9"
                />
              </svg>
            </button>
          )}
        </div>
      </>
    </FormProvider>
  );
};
export default OrgTypeForm;
