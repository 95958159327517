import React from "react";
import {Helmet} from "react-helmet";
import Eventposterslider from "components/Sections/event/eventposterslider/eventposterslider"
import OfferService from "components/Sections/serviceoffer/serviceoffer";
import Tabs from 'components/Sections/pricingpagtab/pricingtab';
import TeraOpeningslider from "components/Sections/Teracarreir/openings/openingsslider";


import bannerimage from './images/bannerimage.png'
import offerservice1 from './images/serviceoffer1.png'
import offerservice2 from './images/serviceoffer2.png'
import offerservice3 from './images/serviceoffer3.png'
import offerservice4 from './images/serviceoffer4.png'
import "./carrier.css"



const Carrier: React.FC = () => {




  const offerServiceDatalist = [
    {
      image: offerservice1,
      title: 'Innovation at Every Turn',
      subtitle: "Be part of a team that's always pushing boundaries and embracing the future.",
    },
    {
      image: offerservice2,
      title: 'Balanced Life, Standardized Hours',
      subtitle: 'Achieve the ideal work-life balance with our commitment to standardized work hours.',
    },
    {
      image: offerservice3,
      title: 'Rewarding Excellence',
      subtitle: 'Your skills deserve recognition—enjoy competitive pay that mirrors your dedication and the industry standards.',
    },
    {
      image: offerservice4,
      title: 'Transparent Communication',
      subtitle: 'Experience a workplace built on trust and open communication—know your path and thrive.',
    },
  
    // Add more feature service data objects as needed
  ];



   
      
  return (
    <div className="event-page">
<Helmet>
        <title>Join Tera Connects - Empowering Healthcare Services</title>
        
        <meta name="description" content=" Discover rewarding career opportunities at Tera Connects. Join our dynamic team and take part in revolutionizing the healthcare industry." />
      </Helmet>
   
<div className='Jobs-page-banner carrier-banner'>
      <div className='container'>
      <div className='banner-content-job'>
        <h2 className='montserrat'> Discover Thrilling 
<b> <br/>Career Opportunities! </b></h2>
        <h5 className='montserrat'>Discover your next adventure in medical innovation and growth with Tera Connects career!
</h5>
        {/* <a className='montserrat banner-btn' href="">We’re Hiring</a> */}
      </div>
      <div className='banner-image-job'>
        <img src={bannerimage} alt="" />
      </div>
    </div>
    </div>
        
   

   <div className='teraservicesolution tabsteraconnects page-jobs'>
      <div className='container tera-own-opening'>
        <h2 className='servicepages-headings montserrat font-medium  mb-10 lg:mb-20'>Featured <span className='font-extrabold'> Openings</span></h2>
        <TeraOpeningslider perView={3} />
   
      </div>
    </div>


    

   
        <div className='tabsteraconnects background-white'>
            <div className="container">
                <h2 className='servicepages-headings montserrat font-medium mb-20'> Why Join  <span className='font-extrabold'> Our Team?</span></h2>
          
                <div className="offer-service featureservice-display-wrap">
                          {/* Map over the list of feature service data and render FeatureService for each */}
                         {offerServiceDatalist.map((data, index) => (
                         <OfferService  key={index} data={data} />
                         ))}
                 </div>
            </div>
        </div>
 


     
{/*      
        <div className="event-poster-auto-slider carrier-page-event-slider">
        <Eventposterslider sliderData={sliderData}  />
        </div>
 */}


    
    </div>
  );
};

export default Carrier;
