import React, { useEffect, useRef } from 'react';
import { useLocation,useNavigate } from 'react-router-dom';
import { Helmet } from "react-helmet";
import FeatureService from "components/Sections/featureservice/featureservice";
import OfferService from "components/Sections/serviceoffer/serviceoffer";
import Whoweare from "components/Sections/who-we-are/whoweare";
import TestimonialSlider from "components/Sections/testimonal-comp/TestimonialSlider";

import featureservice1 from "./images/featureservice1.png";
import featureservice2 from "./images/featureservice2.png";
import featureservice3 from "./images/featureservice3.png";
import featureservice4 from "./images/featureservice4.png";

import offerservice1 from "./images/serviceoffer1.png";
import offerservice2 from "./images/serviceoffer2.png";
import offerservice3 from "./images/serviceoffer3.png";
import offerservice4 from "./images/serviceoffer4.png";
import offerservice5 from "./images/serviceoffer5.png";
import offerservice6 from "./images/serviceoffer6.png";
import offerservice7 from "./images/serviceoffer7.png";
import offerservice8 from "./images/serviceoffer8.png";

import backgroundfooter from "./images/Rectangle-4.webp";
import bannerimage from "./images/clinnicbannerimage.webp";

import JoinNetwork from "components/Sections/mobileappdownload/mobileappdownload";
import qrteraclinicians from "./images/teraclinician.png";

import k1 from "./images/k1.png";
import k2 from "./images/k2.png";
import k3 from "./images/k3.png";
import k4 from "./images/k4.png";
import k5 from "./images/k5.png";
import k6 from "./images/k6.png";
import k7 from "./images/k7.png";
import k8 from "./images/k8.png";
import k9 from "./images/k9.png";
import k10 from "./images/k10.png";
import k11 from "./images/k11.png";
import k12 from "./images/bm.png";
import X1 from "./images/xx1.png"

import "./clinic.css";
import { Link } from "react-router-dom";

const featureServiceDataList = [
  {
    image: featureservice1,
    title: "Courses, Fellowships, and CME Updates",
    subtitle:
      "Stay updated, attend specialized programs and events, and elevate your skills and knowledge for continuous professional growth.",
    badgeContent: "Education",
  },
  {
    image: featureservice3,
    title: "Job Discovery",
    subtitle:
      "Effortlessly find opportunities that match your skills and preferences. ",
    badgeContent: "Career",
  },
  {
    image: featureservice2,
    title: "Lifetime Portfolio",
    subtitle:
      "Build and manage a comprehensive professional portfolio to capitalize on job and freelancing opportunities.",

    badgeContent: "Professional Journey",
  },
  {
    image: featureservice4,
    title: "Medical Community",
    subtitle:
      " Connect with a thriving community for collaboration, networking, and shared insights.",
    badgeContent: "Social",
  },
  // Add more feature service data objects as needed
];

const offerServiceDatalist = [
  {
    image: offerservice1,
    title: "Effortless Job Search",
    subtitle:
      "Streamline your journey with personalized preferences and uncover opportunities that align with your skills.",
  },
  {
    image: offerservice2,
    title: "Freelance and Nearby Jobs",
    subtitle:
      "Access various opportunities that suit your expertise and location.",
  },
  {
    image: offerservice7,
    title: "Personalized Promotions",
    subtitle:
      "Showcase your skills and achievements for increased recognition.",
  },

  {
    image: offerservice8,
    title: "Medical News and Journals",
    subtitle:
      "Stay updated on the latest medical advancements through curated content.",
  },

  {
    image: offerservice4,
    title: "CME Updates",
    subtitle: "Stay informed about Continuing Medical Education events.",
  },
  {
    image: offerservice3,
    title: "Networking Hub",
    subtitle:
      "Network with a diverse community of medical professionals, including pharma staff, physiotherapists, nurses, and more. ",
  },

  // Add more feature service data objects as needed
];
const WhoweareDatalist = [
  {
    image: X1,
    title: "Allopathy Doctors",
  },
  {
    image: k3,
    title: "Physiotherapists",
  },
  {
    image: k10,
    title: "Nurses",
  },
  {
    image: k11,
    title: "Technicians",
  },
  {
    image: k8,
    title: "Admin Staff",
  },
  {
    image: k4,
    title: "AYUSH Doctors",
  },
  {
    image: k7,
    title: "Medical Teaching Staff",
  },
  {
    image: k9,
    title: "Dental Surgeons",
  },

  {
    image: k1,
    title: "Pharma Staff",
  },

  {
    image: k5,
    title: "Healthcare Students",
  },
  {
    image: k2,
    title: "Other Medical Staff",
  },
  {
  image: k12,
  title: "Biomedical Engineers",
}
];

const PageHomeDemo6: React.FC = () => {

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const hash = location.hash;

    const scrollToSection = () => {
      if (hash) {
        const targetElement = document.querySelector(hash) as HTMLElement;
        if (targetElement) {
          // Calculate the offset to position the top of the section at 20% of the viewport height
          const offset = window.innerHeight * -1.1;
          
          // Calculate the target position
          const targetPosition = targetElement.getBoundingClientRect().top + window.scrollY;
          
          // Scroll to the section with the offset
          window.scrollTo({
            top: targetPosition - offset,
            behavior: 'smooth',
          });
        }
      }
    };

    // Scroll to the section when the component mounts
    scrollToSection();

    // Add a listener for hash changes
    const handleHashChange = () => {
      scrollToSection();
    };

    window.addEventListener('hashchange', handleHashChange);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('hashchange', handleHashChange);
    };
  }, [location]);

  const handleInstallButtonClick = () => {
    // Handle scrolling when the Install App button is clicked
    const hash = "#download-app-now"; // Replace with the ID of your target section
    navigate(hash);
  };



  return (
    <div className="nc-PageHomeDemo6 relative [ nc-section-rounded-md ]">
      <Helmet>
        <title>Perfect Platform For Professionals To Empower their Career </title>

        <meta
          name="description"
          content="Join the community of 100+ Professionals and know the latest news, trends, job listing, freelance opportunity, course, and more to improve your career."
        />
      </Helmet>
      <div className=" mx-auto home-hero-section for-clinic-page">
        <div className="container flex head-home-sec gap-extra">
          <div className="head-home-sec-centre gap">
            <h3 className="font-white regular title-custom text-center title-heading-banner montserrat ">
              Elevate Your <br />{" "}
              <span className="font-sky-blue bolders banner-grandient">
                Professional Journey
              </span>
            </h3>
            <h5 className="font-white  text-center regular title-custom montserrat">
             MediProHub is a platform crafted exclusively for professionals, transforming
              how you navigate your career.
            </h5>
            <Link to="/contact-us">
              <a href="/contact-us/" className="common-btn-custom">
                Get Started
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="26"
                    height="27"
                    viewBox="0 0 26 27"
                    fill="none"
                  >
                    <path
                      d="M8.99945 7.14938L16.8203 14.4116L8.99946 21.6738"
                      stroke="#fff"
                      stroke-width="2"
                    />
                  </svg>
                </span>
              </a>
            </Link>
          </div>
          <div className="head-home-sec-centre">
            <img src={bannerimage} alt="Service" className="" />
          </div>
        </div>
      </div>

      <div className="tabsteraconnects background-white">
        <div className="container">
          <h2 className="servicepages-headings montserrat font-medium mb-5 lg:mb-20 mb-10">
            {" "}
            Featured<span className="font-extrabold"> Services</span>
          </h2>
          <div className="featureservice-display-wrap">
            {/* Map over the list of feature service data and render FeatureService for each */}
            {featureServiceDataList.map((data, index) => (
              <FeatureService key={index} data={data} />
            ))}
          </div>
        </div>
      </div>

      <div className="who-we-are teraservicesolution tabsteraconnects">
        <div className="container">
          <h2 className="servicepages-headings montserrat font-medium mb-5">
            Who are our <span className="font-extrabold"> Professionals?</span>
          </h2>
          <h5 className="font-white regular title-custom montserrat  lg:mb-20 mb-10">
            <span className="font-sky-blue">
              <b>MediProHub</b>
            </span>{" "}
            caters to a diverse range of professionals, including
          </h5>
          <div className="whoweare-wrraper featureservice-display-wrap">
            {/* Map over the list of feature service data and render FeatureService for each */}
            {WhoweareDatalist.map((data, index) => (
              <Whoweare key={index} data={data} />
            ))}
          </div>
        </div>
      </div>

      <div className="tabsteraconnects background-white">
        <div className="container">
        <h2 className="servicepages-headings montserrat font-medium mb-5">
            {" "}
            Why Choose <span className="font-extrabold"> MediProHub</span>
          </h2>
          <h5 className="font-black regular title-custom montserrat lg:mb-20 mb-10">
            MediProHub addresses the pressing issues faced by professionals
            through a range of tailored services.
          </h5>
          <div className="offer-service partner featureservice-display-wrap">
            {/* Map over the list of feature service data and render FeatureService for each */}
            {offerServiceDatalist.map((data, index) => (
              <OfferService key={index} data={data} />
            ))}
          </div>
        </div>
      </div>

      <div className="lets-start-journey">
  <div className="container">
    <div className="start-journey-container">
      <div className="journey-start-text-section">
      <h2 className="font-sky-blue bolds title-custom montserrat text-start mb-5">
              Let’s Start Our Journey
            </h2>
            <h4 className="font-white regular title-custom montserrat mb-5">
            Discover how we can help you find the perfect job for your skills and interests.
            </h4>
            <a href="#download-app-now" className="common-btn-custom blue-btn">
              Install App
            </a>

      </div>
      <div className="journey-start-image-section">
      <img src={backgroundfooter} />
      </div>

    </div>

  </div>

</div>

      {/* <div className="container background-white">
        <div className="sec-padding">
          <h2 className="servicepages-headings montserrat font-medium lg:mb-10 mb-10">
            {" "}
            <span className="font-extrabold">Testimonials</span>
          </h2>
          <TestimonialSlider perView={1} />
        </div>
      </div> */}

      <div className="background-white" id="download-app-now">
        <JoinNetwork
          heading="Join Our Network"
          subheading="Join MediProHub to stay updated on new trends, network with medical industry leaders, and find job opportunities."
          googlePlayUrl="https://play.google.com/store/apps/details?id=com.teraplatforms.jobseeker"
          appStoreUrl="https://apps.apple.com/in/app/tera-recruit-job-search/id1659265164"
          imageUrl={qrteraclinicians}
        />
      </div>
    </div>
  );
};

export default PageHomeDemo6;
