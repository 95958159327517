import "./fillformtoapply.css";
import React, { useState, useEffect } from "react";
import Select from "react-select";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import PhoneInputWithCountrySelect, {
  parsePhoneNumber,
} from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import "../contactform/contactform.css";
import { applyJobApi } from "services/jobService";
import { getCitiesByStateApi, getStatesApi } from "services/lookupService";
import ReCAPTCHA from "react-google-recaptcha";

interface KeyValue {
  value: string;
  label: string;
}
const schema = yup.object().shape({
  firstName: yup
    .string()
    .trim()
    .nullable()
    .required("Please provide First Name")
    .test(
      "firstName-length",
      "The first name must be between 1 and 100 characters",
      function (value) {
        if (!value) {
          return true;
        }
        const length = value.trim().replace(/<[^>]+>/g, "")?.length;
        const isWithinUpperLimit = length <= 100;
        const isWithinLowerLimit = length >= 1;
        return isWithinLowerLimit && isWithinUpperLimit;
      }
    ),
  middleName: yup
    .string()
    .trim()
    .nullable()
    // .required("Please provide middle name")
    .test(
      "middleName-length",
      "The middle name must be between 1 and 100 characters",
      function (value) {
        if (!value) {
          return true;
        }
        const length = value.trim().replace(/<[^>]+>/g, "")?.length;
        const isWithinUpperLimit = length <= 100;
        const isWithinLowerLimit = length >= 1;
        return isWithinLowerLimit && isWithinUpperLimit;
      }
    ),
  lastName: yup
    .string()
    .trim()
    .nullable()
    .required("Please provide last name")
    .test(
      "lastName-length",
      "The last name must be between 1 and 100 characters",
      function (value) {
        if (!value) {
          return true;
        }
        const length = value.trim().replace(/<[^>]+>/g, "")?.length;
        const isWithinUpperLimit = length <= 100;
        const isWithinLowerLimit = length >= 1;
        return isWithinLowerLimit && isWithinUpperLimit;
      }
    ),

  mobileNumber: yup
    .string()
    .nullable()
    .required("Enter phone number")
    .test("is-valid-phone", "Enter valid phone number", function (value) {
      const onlyNumber = parsePhoneNumber(
        value.toString()
      )?.nationalNumber.toString();
      return /^[6-9]\d{9}$/.test(onlyNumber ?? "");
    }),

  emailID: yup
    .string()
    .trim()
    .nullable()
    .required("You must enter a email")
    .email("Enter valid email")
    .matches(
      /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
      "Invalid email format"
    )
    .test(
      "emailID-length",
      "The email must be between 5 and 150 characters",
      function (value) {
        if (!value) {
          throw new yup.ValidationError(
            "You must enter contact email",
            "contactEMail"
          );
        }
        const length = value.trim().length;
        return length >= 5 && length <= 150;
      }
    ),
  gender: yup.object().nullable().required("Please select gender"),
  resumePath: yup.string().nullable(),
  city: yup.object().nullable().required("You must select city"),
  state: yup.object().nullable().required("You must select state"),
});
interface FillFormProps {
  jobTitle: string;
  jobId: string;
  healthCareCategoryID: string;
}
const FillFormToApply: React.FC<FillFormProps> = ({
  jobTitle,
  jobId,
  healthCareCategoryID,
}) => {
  const [resume, setResume] = useState<string | Blob>("");
  const [cities, setCities] = useState<KeyValue[]>([]);
  const [states, setStates] = useState<KeyValue[]>([]);
  const [resumeName, setResumeName] = useState<string>("");
  const [error, setError] = useState("");

  
  const showCaptcha = window.location.hostname !== "localhost";
  const siteKey: string = process.env.REACT_APP_MAP_KEY ?? "";
  const [captchaVerified, setCaptchaVerified] = useState(
    window.location.hostname === "localhost"
  );

  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "all",
    resolver: yupResolver(schema),
  });
  const genderOptions = [
    { value: "F", label: "Female" },
    { value: "M", label: "Male" },
    { value: "O", label: "Other" },
  ];

  useEffect(() => {
    getStatesApi()
      .then((response) => {
        const data = response.data;
        setStates(data);
      })
      .catch((error) => {
        console.error("Error fetching states:", error);
      });
  }, []);
  const handleCaptchaChange = (value: any) => {
    setCaptchaVerified(() => true);
  };

  const onSubmit = (data: any) => {
    const payloadData = new FormData();
    if (!resume) {
      setError("Please upload resume.");
      return;
    }
    payloadData.append("resume", resume);
    payloadData.append("firstName", data.firstName);
    payloadData.append("middleName", data.middleName);
    payloadData.append("lastName", data.lastName);
    payloadData.append("emailID", data.emailID);
    payloadData.append("gender", data.gender.value);
    // payloadData.append("applicantFieldOfWorkID", healthCareCategoryID || "0");
    payloadData.append(
      "mobileNumber",
      parsePhoneNumber(data.mobileNumber!.toString())!.nationalNumber.toString()
    );
    payloadData.append("jobId", jobId);
    payloadData.append("CityID", data.city.value);
    applyJobApi(payloadData)
      .then((response) => {
        console.log(response, "response");
        if (response.status === 201) {
          Swal.fire({
            title: "Already Applied !",
            html: `You have already applied for the job <b>${jobTitle}</b>. For details please login to MediProHub App`,
            icon: "info",
          });
        } else {
          Swal.fire({
            title: "Success!",
            text: "Your request is submitted",
            icon: "success",
          }).then((result: any) => {
            navigate("/");
          });
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "Info!",
          text: "Your request is not submitted, Try after sometime",
          icon: "error",
        });
      });
  };
  const handleStateChange = (selectedOption: any) => {
    const selectedStateId = parseInt(selectedOption?.value, 10);

    if (selectedStateId) {
      getCitiesByStateApi(selectedStateId)
        .then((response) => {
          setCities(response.data);
        })
        .catch((error) => {
          console.error("Error fetching cities:", error);
        });
    }
  };
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setError("")
    if (e.target.files && e.target.files.length > 0) {
      const f = e.target.files[0];
      let filesize = Math.round(f?.size / 1024);
      if (
        f &&
        f.type !== "application/pdf" &&
        f.type !==
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document" &&
        f.type !== "application/msword"
      ) {
        alert("Invalid file, select a valid PDF or Document");
        return;
      } else if (filesize > 3 * 1024) {
        alert("File size is more than 3 MB, upload a smaller file");
        return;
      } else {
        setResume(f);
        setResumeName(f.name);
      }
    }
  };
  return (
    <div className="form-wrapper widget-container">
      <div className="external-job p-3">
        <div id="extra-content">
          <div className="job_summary_style">
            <span>Apply For</span>
            <h2 className="job-title">
              <a href="">{jobTitle}</a>
            </h2>
          </div>
          <div className="resume-heading">Upload Your Resume</div>
        </div>
        <div className="options-block resume-group">
          <div
            className="cloud-options"
            role="group"
            aria-labelledby="cloud-options-sr-text"
          >
            <div className="resume_info">
              <span id="cloud-options-sr-text" className="sr-only">
                Upload options
              </span>
              <div>Upload your resume and get noticed by recruiters</div>
            </div>
            <div className="drives">
              <div className="cloud-options-group" atm-id="cloud-options-group">
                <ul atm-id="cloudoptions-list"></ul>
              </div>
              <div className="apply-or-line">
                <span className="apply-or-box italic">
                  File format ( pdf,doc,docx with file size up to 3 mb)
                </span>
              </div>
              <div className="resume-upload-wrapper p-3">
                <input
                  type="file"
                  id="fileInput"
                  autoComplete="off"
                  style={{ display: "none" }}
                  onChange={(e) => handleFileChange(e)}
                />
                <button
                  type="button"
                  className="upload-resume-btn btn primary-button"
                  onClick={() => document.getElementById("fileInput")?.click()}
                >
                  Upload
                </button>
                {<p className="font-medium">{resumeName}</p>}
              </div>
            </div>
            <div className="mt-1">
              {error && <p style={{ color: "#f44336" }}>{error}</p>}
                </div>
          </div>
        </div>
        <div className="form-page">
          <form onSubmit={handleSubmit(onSubmit)} className="w-full">
            <div className="grid md:grid-cols-4 gap-2 p-3">
              <div className="w-full col-span-2 flex flex-col">
                <label className="text-xl inline-flex">First Name</label>
                <Controller
                  name="firstName"
                  control={control}
                  render={({ field }) => (
                    <>
                      <input
                        {...field}
                        type="text"
                        placeholder="Enter First Name *"
                        className="montserrat rounded-3xl capitalize"
                        required
                      />
                      <p className="error">
                        {errors.firstName && errors.firstName?.message}
                      </p>
                    </>
                  )}
                />
              </div>
              <div className="w-full col-span-2  flex flex-col">
                <label className="text-xl inline-flex">Middle Name</label>
                <Controller
                  name="middleName"
                  control={control}
                  render={({ field }) => (
                    <>
                      <input
                        {...field}
                        type="text"
                        placeholder="Enter Middle Name"
                        className="montserrat rounded-3xl capitalize"
                        value={field?.value || ""}
                        //  required
                      />
                      {/* <p className="error">
                        {errors.middleName && errors.middleName?.message}
                      </p> */}
                    </>
                  )}
                />
              </div>
              <div className="w-full col-span-2  flex flex-col">
                <label className="text-xl inline-flex">Last Name</label>
                <Controller
                  name="lastName"
                  control={control}
                  render={({ field }) => (
                    <>
                      <input
                        {...field}
                        type="text"
                        placeholder="Enter Last Name *"
                        className="rounded-3xl capitalize"
                        required
                      />
                      <p className="error">
                        {errors.lastName && errors.lastName?.message}
                      </p>
                    </>
                  )}
                />
              </div>
              <div className="w-full col-span-2 flex flex-col">
                <label className="text-xl inline-flex">Contact Number</label>
                <Controller
                  name="mobileNumber"
                  control={control}
                  render={({ field }) => (
                    <>
                      <PhoneInputWithCountrySelect
                        {...field}
                        international
                        defaultCountry="IN"
                        countryCallingCodeEditable={false}
                        countries={["IN"]}
                      />
                      <p className="error">
                        {errors.mobileNumber && errors.mobileNumber?.message}
                      </p>
                    </>
                  )}
                />
              </div>
              <div className="w-full col-span-2  flex flex-col">
                <label className="text-xl inline-flex">Email Id</label>
                <Controller
                  name="emailID"
                  control={control}
                  render={({ field }) => (
                    <>
                      <input
                        {...field}
                        type="email"
                        placeholder="Enter Email *"
                        className="montserrat rounded-3xl"
                        required
                      />
                      {/* <p style={{ color: "#f44336" }}> */}
                      <p className="error">
                        {errors.emailID && errors.emailID?.message}
                      </p>
                    </>
                  )}
                />
              </div>
              <div className="col-span-2  flex flex-col">
                <label className="text-xl inline-flex">Gender</label>
                <Controller
                  name="gender"
                  control={control}
                  render={({ field }) => (
                    <>
                      <Select
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            borderRadius: "22px",
                            // width: "360px",
                            height: "44px",
                          }),
                        }}
                        classNames={{
                          control: () => "br-22",
                        }}
                        {...field}
                        options={genderOptions}
                        placeholder="Select Gender *"
                        onChange={(selectedOption) => {
                          field.onChange(selectedOption);
                        }}
                        onBlur={() => field.onBlur()}
                        value={
                          genderOptions &&
                          genderOptions.find(
                            (option) => option.label === field.value
                          )
                        }
                        required
                      />

                      <p className="error">{errors.gender?.message}</p>
                    </>
                  )}
                />
              </div>
              <div className="w-full flex col-span-2 flex-col">
                <label className="text-xl inline-flex">State</label>
                <Controller
                  name="state"
                  control={control}
                  render={({ field }) => (
                    <>
                      <Select
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            borderRadius: "22px",
                            // width: "360px",
                            height: "44px",
                          }),
                        }}
                        classNames={{
                          control: () => "br-22",
                        }}
                        {...field}
                        options={states}
                        placeholder="Select State *"
                        onChange={(selectedOption) => {
                          field.onChange(selectedOption);
                          handleStateChange(selectedOption);
                        }}
                        onBlur={() => field.onBlur()}
                        value={
                          states &&
                          states.find((option) => option.value === field.value)
                        }
                        required
                      />
                      <p className="error">{errors.state?.message}</p>
                    </>
                  )}
                />
              </div>
              <div className="w-full flex col-span-2 flex-col">
                <label className="text-xl inline-flex">City</label>
                <Controller
                  name="city"
                  control={control}
                  render={({ field }) => (
                    <>
                      <Select
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            borderRadius: "22px",
                            // width: "360px",
                            height: "44px",
                          }),
                        }}
                        classNames={{
                          control: () => "br-22",
                        }}
                        {...field}
                        options={cities}
                        placeholder="Select City *"
                        onChange={(selectedOption) =>
                          field.onChange(selectedOption)
                        }
                        onBlur={() => field.onBlur()}
                        value={
                          cities &&
                          cities.find((option) => option.value === field.value)
                        }
                        required
                      />
                      <p className="error">{errors.city?.message}</p>
                    </>
                  )}
                />
              </div>
            </div>

            <div className="md:flex md:flex-row space-x-4 flex flex-col space-y-4 justify-center items-center">
              <div className="w-full inside-full sm:flex  md:flex gap-8 md:flex-row justify-between items-center btn-div">
                <div className="w-full">
                  {showCaptcha && (
                    <ReCAPTCHA
                      sitekey={siteKey}
                      onChange={handleCaptchaChange}
                      onExpired={() => setCaptchaVerified(() => false)}
                    />
                  )}
                </div>
                <div className="btn-div mt-3">
                  <button
                    disabled={captchaVerified === false}
                    className="btn-div-submit items-center"
                  >
                    <span>Submit</span>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default FillFormToApply;
