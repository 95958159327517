import React, { useState, useEffect } from "react";
import "./pricerange.css"; // Make sure to import your CSS file
import { useNavigate } from "react-router-dom";
interface ExperienceRangeProps {
  selectedExperienceValue: number;
  handleFilterJobsBasedOnExperience: (
    selectedCategoryId: any,
    updatedSelectedCheckboxes: any,
    experienceRange: any,
    salaryRange: any,
    selectedCompaniesCheckboxes: any
  ) => void;
  selectedCheckboxesValues: any;
  selectedCategoryIdValue: any;
  selectedSalaryRange: any;
  selectedCompanyCheckboxesValues: any;
}

const ExperienceRangeSlider: React.FC<ExperienceRangeProps> = ({
  handleFilterJobsBasedOnExperience,
  selectedExperienceValue,
  selectedCategoryIdValue,
  selectedCheckboxesValues,
  selectedSalaryRange,
  selectedCompanyCheckboxesValues,
}) => {
  const [minValue, setMinValue] = useState<number>(0);
  const [maxValue, setMaxValue] = useState<number>(50);
  const navigate = useNavigate();
  const [selectedValueOnMouseUp, setSelectedValueOnMouseUp] =
    useState<boolean>(false);

  useEffect(() => {
    // setMinValue(0);
    setMaxValue(selectedExperienceValue ? selectedExperienceValue : 50);
  }, [selectedExperienceValue]);

  const validateRange = () => {
    const minPrice = parseInt(minInputRef.current?.value || "0");
    const maxPrice = parseInt(maxInputRef.current?.value || "0");

    if (minPrice > maxPrice) {
      setMinValue(maxPrice);
      setMaxValue(minPrice);
    } else {
      setMinValue(minPrice);
      setMaxValue(maxPrice);
    }
  };

  const minInputRef = React.createRef<HTMLInputElement>();
  const maxInputRef = React.createRef<HTMLInputElement>();
  const rangeFillRef = React.createRef<HTMLDivElement>();

  useEffect(() => {
    const minPercentage = (minValue / 50) * 100;
    const maxPercentage = (maxValue / 50) * 100;

    rangeFillRef.current!.style.left = minPercentage + "%";
    rangeFillRef.current!.style.width = maxPercentage - minPercentage + "%";
  }, [minValue, maxValue]);

  const handleFilterJobsOnExperience = () => {
    setSelectedValueOnMouseUp(true);
    navigate(
      `/jobs/jobs-listing/?selectedCategory=${selectedCategoryIdValue}&selectedExperience=${maxValue}&selectedSalaryRange=${
        selectedSalaryRange?.max
      }&selectedStates=${
        selectedCheckboxesValues || []
      }&selectedOrganizations=${selectedCompanyCheckboxesValues || []}`
    );
    handleFilterJobsBasedOnExperience(
      selectedCategoryIdValue,
      selectedCheckboxesValues,
      { min: minValue, max: maxValue },
      selectedSalaryRange,
      selectedCompanyCheckboxesValues
    );
  };
  return (
    <div
      className="card"
      // title={
      //   selectedExperienceValue
      //     ? `Selected Experience: ${
      //         selectedValueOnMouseUp ? maxValue : selectedExperienceValue
      //       } Years`
      //     : ""
      // }
    >
      <div className="price-content">
        <div>
          <label>Min</label>
          <p id="min-value">{minValue} Years</p>
        </div>

        <div>
          <label>Max</label>
          <p id="max-value">{maxValue} Years</p>
        </div>
      </div>

      <div className="range-slider">
        <div className="range-fill" ref={rangeFillRef}></div>
        <input
          type="range"
          className="min-experience"
          value={minValue}
          min={0}
          max={50}
          step={1}
          ref={minInputRef}
          onChange={validateRange}
          onMouseUp={handleFilterJobsOnExperience}
        />
        <input
          type="range"
          className="max-experience"
          value={maxValue}
          min={0}
          max={50}
          step={1}
          ref={maxInputRef}
          onChange={validateRange}
          onMouseUp={handleFilterJobsOnExperience}
        />
      </div>
    </div>
  );
};

export default ExperienceRangeSlider;
