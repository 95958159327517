import { FC, Fragment, useEffect, useRef, useState } from "react";
import JoinNetworkForm from "components/Sections/mobileappdownload/jobapplyform";
import JoinNetwork from "components/Sections/mobileappdownload/mobileappdownload";

import qrtera from "./images/mobqr.png";
import { Link, useLocation } from "react-router-dom";
import "./jobpost.css";
import { getJobDetailsApi, searchJobsApi } from "services/jobService";
import { NumericFormat } from "react-number-format";
import { Job } from "../../page";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { ArrowSmallLeftIcon } from "@heroicons/react/24/outline";
import { Dialog, Transition } from "app/headlessui";
import LikeSaveBtns from "components/LikeSaveBtns";
import ButtonPrimary from "components/Button/ButtonPrimary";
import qrteraclinicians from "../../../For-Clinicians/images/teraclinician.png";
import defaultOrgImg from "./images/Default-Org-svg.svg";
import DOMPurify from "dompurify";
interface Props {
  onClose?: () => void;
  isShowModal: boolean;
}
interface JobInformation {
  branchId: number;
  branchName: string;
  cityId: number;
  cityName: string;
  clientId: number;
  clientName: string;
  contractDurationInDays: number;
  createdDate: string;
  department: number;
  departmentName: string;
  description: string;
  duties: string;
  dutyTypeId: number;
  dutyTypeName: string;
  ecr: string;
  employmentType: string;
  employmentTypeID: number;
  experienceMaximum: number;
  experienceMinimum: number;
  expiryDate: string;
  genderPreference: string;
  healthCareCategory: string;
  healthCareCategoryID: number;
  healthCareRole: string;
  id: number;
  isAccommodationProvided: boolean;
  isFoodProvided: boolean;
  isHealthInsuranceProvided: boolean;
  isPFAndESIProvided: boolean;
  isSpecialAllowancesProvided: boolean;
  isTempPosition: boolean;
  isTeraPayroll: boolean;
  isTransportationProvided: boolean;
  isWithRecruitment: boolean;
  jobCode: string;
  jobStatusID: number;
  noOfApplicants: number;
  noOfofferedApplicants: number;
  noOfPendingApplicants: number;
  noOfRejectedApplicants: number;
  noOfScheduledgApplicants: number;
  noOfSelectedApplicants: number;
  numberOfPositions: number;
  orgBranchId: number;
  orgBranchName: string;
  roleDepartmentID: number;
  salaryMaximum: number;
  salaryMinimum: number;
  salaryType: null;
  salaryTypeId: number;
  serviceEndDate: null;
  serviceStartDate: null;
  shiftEndTime: string;
  shiftStartTime: string;
  shiftType: string;
  shiftTypeID: number;
  skills: string;
  stateId: number;
  stateName: string;
  status: string;
  title: string;
  updatedDate: string;
  experienceRangeFrom: string;
  experienceRangeTo: string;
  salaryRangeFrom: string;
  salaryRangeTo: string;
  logoPath: string;
}
const JobPost = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const jobId = queryParams.get("jobId");
  const healthCareCategoryID = queryParams.get("healthCareCategoryID");
  const selectedValuesForSimilarJobsString = queryParams.get(
    "selectedValuesForSimilarJobs"
  );
  const selectedSimilarJobs = queryParams.get("selectedSimilarJobs");
  const selectedValuesForSimilarJobs = selectedValuesForSimilarJobsString
    ? JSON.parse(decodeURIComponent(selectedValuesForSimilarJobsString))
    : null;
  const selectedSimilarJobsValues = selectedSimilarJobs;
  const [jobInformation, setJobInformation] = useState<JobInformation | null>(
    null
  );
  const [similarJobs, setSimilarJobs] = useState<JobInformation[]>([]);
  const [postedAgo, setPostedAgo] = useState<string>();
  const [loading, setLoading] = useState(true);
  const [isShowModal, setIsShowModal] = useState(false);
  const handleClose = () => {
    setIsShowModal(false);
  };
  const getAMPM = (timeString: any) => {
    if (!timeString) {
      return ""; // Handle the case when timeString is undefined
    }
    const [hours, minutes, seconds] = timeString?.split(":");
    const hour = parseInt(hours, 10);

    if (hour >= 12) {
      return "PM";
    } else {
      return "AM";
    }
  };
  const formatTime = (timeString: any) => {
    if (!timeString) {
      return ""; // Handle the case when timeString is undefined
    }
    const [hours, minutes, seconds] = timeString.split(":");
    const hour = parseInt(hours, 10);
    const minute = parseInt(minutes, 10);
    const ampm = getAMPM(timeString);

    const formattedHour = hour % 12 === 0 ? 12 : hour % 12;
    const formattedMinute = minute < 10 ? `0${minute}` : minute;

    return `${formattedHour}:${formattedMinute} ${ampm}`;
  };
  useEffect(() => {
    getJobDetailsApi({
      // id: location.state?.jobId,
      id: jobId,
    })
      .then((response) => {
        setJobInformation(response?.data);
        const currentDate = new Date();
        const jobDate = new Date(response?.data?.createdDate);
        const timeDifference = currentDate.getTime() - jobDate.getTime();
        const daysDifference = Math.floor(
          timeDifference / (1000 * 60 * 60 * 24)
        );
        let postedAgo;
        if (daysDifference === 0) {
          postedAgo = "Today";
        } else if (daysDifference === 1) {
          postedAgo = "1 day ago";
        } else {
          postedAgo = `${daysDifference} days ago`;
        }
        setPostedAgo(postedAgo);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err, "Err");
      });
    if (
      healthCareCategoryID ||
      selectedValuesForSimilarJobs ||
      selectedSimilarJobsValues
    ) {
      searchJobsApi({
        departmentId: 0,
        dutyTypeId: 0,
        employmentTypeId: 0,
        healthCareCategoryId: healthCareCategoryID || 0,
        pageNumber: 1,
        pageSize: 10,
        roleDepartmentId: 0,
        salaryType: 0,
        shiftType: 0,
        statusId: 0,
        branchIds: selectedValuesForSimilarJobs?.selectedCompanyCheckboxes || [
          0,
        ],
        // stateIds: [0],
        stateIds: selectedValuesForSimilarJobs?.selectedCheckboxes || [],
        cityIds: [0],
        salaryRange: {
          min: selectedValuesForSimilarJobs?.selectedSalaryRange?.min || 0,
          max: selectedValuesForSimilarJobs?.selectedSalaryRange?.max || 0,
        },
        experienceRange: {
          min: selectedValuesForSimilarJobs?.selectedExperience?.min || 0,
          max: selectedValuesForSimilarJobs?.selectedExperience?.max || 0,
        },
      })
        .then((response) => {
          setSimilarJobs(response.data.data);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err, "Err");
        });
    }
  }, [jobId, healthCareCategoryID]);
  const startTimeFormatted = formatTime(jobInformation?.shiftStartTime);
  const endTimeFormatted = formatTime(jobInformation?.shiftEndTime);
  if (loading) {
    return (
      <div className="job-post-wrapper">
        <div className="jobdetail-side">
          <div className="joblistings-detail">
            <div className="jobs-prodiver-detail">
              <div className="job-info-main">
                <h4 className="job-name">
                  <Skeleton width={630} height={30} />
                </h4>
                <h5 className="job-provider-name">
                  <Skeleton width={630} height={30} />
                </h5>
              </div>
            </div>
            <div className="jobs-requirment">
              <div className="uppersection">
                <p className="job-detail-minor required-exprence">
                  <Skeleton width={100} height={15} />
                </p>

                <p className="job-detail-minor salary-offer">
                  <Skeleton width={100} height={15} />
                </p>

                <p className="job-detail-minor location">
                  <Skeleton width={150} height={15} />
                </p>
              </div>

              {/* Employment Type Section */}
              <div className="lowersections">
                <p className="job-detail-minor detail-other">
                  <Skeleton width={100} height={15} />
                </p>
              </div>
            </div>
            <div className="job-apply-section">
              {/* Viewed Section */}
              <div className="job-provider-info post-page">
                <p className="job-detail-minor job-viwed">
                  <Skeleton width={80} height={15} />
                </p>

                {/* Posted Ago Section */}
                <span className="job-detail-minor posted-ago">
                  <Skeleton width={120} height={15} />
                </span>
              </div>

              {/* Buttons Section */}
              <div className="job-btns-selection">
                <a className="savenowbtn apply-save-btn" href="">
                  <Skeleton width={60} height={25} />
                </a>
                <a className="applynowbtn apply-save-btn" href="">
                  <Skeleton width={60} height={25} />
                </a>
              </div>
            </div>
          </div>
          <div className="joblistings-detail job-description">
            <div className="product-specification">
              <h2 className="product-specs-title-for-product-single for-list-prod montserrat">
                <Skeleton width={300} height={30} />
              </h2>

              {/* Description Section */}
              <ul className="product-desc-list">
                <li className="specs-list-content montserrat">
                  <Skeleton count={5} />
                </li>
              </ul>

              {/* Specification Lists Section */}
              <ul className="product-specification-lists">
                <ul className="product-sepecs-list">
                  <li className="specs-list-head montserrat">
                    <Skeleton width={50} height={15} />
                  </li>
                  <li className="specs-list-content montserrat">
                    <Skeleton width={150} height={15} />
                  </li>
                </ul>

                {/* Repeat similar structure for other Specification Lists */}
              </ul>
            </div>
          </div>
          <div className="joblistings-detail about-company">
            <div className="product-specification">
              <h2 className="product-specs-title-for-product-single for-list-prod montserrat">
                <Skeleton width={300} height={30} />
              </h2>

              {/* Skills Section */}
              <ul className="product-desc-list">
                <li className="specs-list-content montserrat">
                  <Skeleton count={5} />
                </li>
              </ul>
            </div>
          </div>
          <div className="joblistings-detail about-company">
            <div className="product-specification">
              <h2 className="product-specs-title-for-product-single for-list-prod montserrat">
                <Skeleton width={300} height={30} />
              </h2>

              {/* Skills Section */}
              <ul className="product-desc-list">
                <li className="specs-list-content montserrat">
                  <Skeleton count={5} />
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="other-job-side similar-jobs">
          <div className="similiar-jobs-title">
            <h2 className="product-specs-title-for-product-single for-list-prod montserrat">
              <Skeleton width={150} height={20} />
            </h2>
          </div>
          {[...Array(similarJobs?.length)].map((_, index) => (
            <div className="similar-jobs-lists" key={index}>
              <div className="joblistings-detail">
                <div className="jobs-prodiver-detail">
                  <div className="job-info-main">
                    <h4 className="job-name">
                      <Skeleton width={200} />
                    </h4>
                    <h5 className="job-provider-name">
                      <Skeleton width={100} />
                    </h5>
                  </div>
                  <div className="job-provider-info">
                    <div className="our-solution-icons">
                      <Skeleton circle={true} height={50} width={50} />
                    </div>
                  </div>
                </div>
                <div className="jobs-requirment">
                  <div className="uppersection">
                    <p className="job-detail-minor required-exprence">
                      <Skeleton width={80} />
                    </p>
                    <p className="job-detail-minor salary-offer">
                      <Skeleton width={80} />
                    </p>
                    <p className="job-detail-minor location">
                      <Skeleton width={100} />
                    </p>
                  </div>
                  <div className="lowersections">
                    <p className="job-detail-minor detail-other">
                      <Skeleton width={120} />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
  return (
    <div className="page-job-post">
      <div className="container">
        <div className="job-post-wrapper">
          <div className="jobdetail-side">
            <div className="joblistings-detail">
              <div className="jobs-prodiver-detail">
                <div className="job-info-main">
                  <h4 className="job-name break-all autofocus">{jobInformation?.title}</h4>
                  <h5 className="job-provider-name">
                    {jobInformation?.clientName}
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="22"
                        height="21"
                        viewBox="0 0 22 21"
                        fill="none"
                      >
                        <path
                          d="M7.6 21L5.7 17.8L2.1 17L2.45 13.3L0 10.5L2.45 7.7L2.1 4L5.7 3.2L7.6 0L11 1.45L14.4 0L16.3 3.2L19.9 4L19.55 7.7L22 10.5L19.55 13.3L19.9 17L16.3 17.8L14.4 21L11 19.55L7.6 21ZM9.95 14.05L15.6 8.4L14.2 6.95L9.95 11.2L7.8 9.1L6.4 10.5L9.95 14.05Z"
                          fill="#00A0C0"
                        />
                      </svg>
                    </span>
                  </h5>
                </div>
                <div className="job-provider-info">
                  <div className="our-solution-icons">
                    {/* <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M3.75 21h16.5M4.5 3h15M5.25 3v18m13.5-18v18M9 6.75h1.5m-1.5 3h1.5m-1.5 3h1.5m3-6H15m-1.5 3H15m-1.5 3H15M9 21v-3.375c0-.621.504-1.125 1.125-1.125h3.75c.621 0 1.125.504 1.125 1.125V21"
                      />
                    </svg> */}
                    {jobInformation?.logoPath ? (
                      <img
                        src={jobInformation?.logoPath}
                        alt=""
                        className="w-4/5"
                      />
                    ) : (
                      <img
                        src={defaultOrgImg}
                        alt=""
                        className="w-4/5 default-org-img"
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="jobs-requirment">
                <div className="uppersection">
                  <p className="job-detail-minor required-exprence ">
                    {jobInformation?.experienceMinimum}-
                    {jobInformation?.experienceMaximum} years
                  </p>
                  <p className="job-detail-minor salary-offer">
                    {/* {
                      <NumericFormat
                        displayType="text"
                        value={jobInformation?.salaryMinimum}
                        thousandSeparator={true}
                        // prefix={"₹"}
                      />
                    } */}
                    {jobInformation?.salaryMinimum?.toLocaleString("en-IN")}-
                    {/* {
                      <NumericFormat
                        displayType="text"
                        value={jobInformation?.salaryMaximum}
                        thousandSeparator={true}
                        // prefix={"₹"}
                      />
                    } */}
                    {jobInformation?.salaryMaximum?.toLocaleString("en-IN")}
                  </p>
                  <p className="job-detail-minor location">
                    {jobInformation?.cityName},{jobInformation?.stateName}
                  </p>
                </div>
                <div className="lowersections">
                  {/* <p className="job-detail-minor detail-other ">
                    {jobInformation?.employmentType}{" "}
                  </p> */}
                  <p className="job-detail-minor detail-other ">
                    {jobInformation?.shiftType} <span>Shift</span> (
                    {startTimeFormatted} - {endTimeFormatted})
                  </p>
                </div>
              </div>
              <div className="job-apply-section">
                <div className="job-provider-info post-page">
                  {/* <p className="job-detail-minor job-viwed"></p> */}
                  <span className="job-detail-minor posted-ago">
                    {postedAgo}
                  </span>
                </div>
                <div className="job-btns-selection">
                  {/* <a className="savenowbtn apply-save-btn" href="">
                    Save
                  </a> */}
                  <ButtonPrimary
                    className="applynowbtn apply-save-btn"
                    onClick={() => setIsShowModal(true)}
                  >
                    Apply
                  </ButtonPrimary>
                </div>
              </div>
            </div>

            <div className="joblistings-detail job-description">
              <div className="product-specification">
                <h2 className="product-specs-title-for-product-single for-list-prod montserrat">
                  Job description
                </h2>
                <ul className="product-desc-list">
                  <li className="specs-list-content montserrat">
                    <div id="description-for-jobs"
                      className="text-justify"
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(
                          jobInformation?.description || "",
                          {
                            USE_PROFILES: { html: true },
                          }
                        ),
                      }}
                    />
                  </li>
                </ul>
                <ul className="product-specification-lists">
                  <ul className="product-sepecs-list">
                    <li className="specs-list-head montserrat role">Role: </li>
                    <li className="specs-list-content montserrat">
                      {jobInformation?.healthCareRole}
                    </li>
                  </ul>
                  <ul className="product-sepecs-list">
                    <li className="specs-list-head montserrat department">
                      Department:{" "}
                    </li>
                    <li className="specs-list-content montserrat">
                      {jobInformation?.departmentName}
                    </li>
                  </ul>
                  <ul className="product-sepecs-list">
                    <li className="specs-list-head montserrat  employment-type">
                      Employment Type:{" "}
                    </li>
                    <li className="specs-list-content montserrat">
                      {jobInformation?.employmentType}
                    </li>
                  </ul>
                </ul>
              </div>
            </div>
            {jobInformation?.skills && (
              <div className="joblistings-detail about-company">
                <div className="product-specification">
                  <h2 className="product-specs-title-for-product-single  for-list-prod montserrat">
                    Skills
                  </h2>
                  <ul className="product-desc-list">
                    <li className="specs-list-content montserrat">
                      <div id="skill"
                        className="text-justify"
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(jobInformation?.skills, {
                            USE_PROFILES: { html: true },
                          }),
                        }}
                      />
                    </li>
                  </ul>
                </div>
              </div>
            )}
            {jobInformation?.duties && (
              <div className="joblistings-detail about-company">
                <div className="product-specification">
                  <h2 className="product-specs-title-for-product-single  for-list-prod montserrat">
                    Duties
                  </h2>
                  <ul className="product-desc-list">
                    <li className="specs-list-content montserrat">
                      <div id="duty"
                        className="text-justify"
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(jobInformation?.duties, {
                            USE_PROFILES: { html: true },
                          }),
                        }}
                      />
                    </li>
                  </ul>
                </div>
              </div>
            )}
          </div>

          <div className="other-job-side similar-jobs">
            <div className="similiar-jobs-title">
              <h2 className="product-specs-title-for-product-single  for-list-prod montserrat">
                Similar Jobs
              </h2>
            </div>
            {similarJobs
              ?.filter((job) => job?.id !== Number(jobId))
              .slice(0, 5)
              .map((job, index) => (
                <Link
                  to={{
                    pathname: "/jobs/jobs-listing/jobpost",
                    search: `?jobId=${job?.id || 0}&healthCareCategoryID=${
                      job?.healthCareCategoryID || 0
                    }&selectedSimilarJobs=${null}`,
                  }}
                  state={{
                    jobId: jobId,
                  }}
                  key={index}
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                >
                  <div className="similar-jobs-lists">
                    <div className="joblistings-detail">
                      <div className="jobs-prodiver-detail">
                        <div className="job-info-main">
                          <h4 className="job-name break-all">{job?.title}</h4>

                          <h5 className="job-provider-name break-all">
                            {job?.clientName}
                          </h5>
                        </div>
                        <div className="job-provider-info">
                          <span className="">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="22"
                              height="21"
                              viewBox="0 0 22 21"
                              fill="none"
                            >
                              <path
                                d="M7.6 21L5.7 17.8L2.1 17L2.45 13.3L0 10.5L2.45 7.7L2.1 4L5.7 3.2L7.6 0L11 1.45L14.4 0L16.3 3.2L19.9 4L19.55 7.7L22 10.5L19.55 13.3L19.9 17L16.3 17.8L14.4 21L11 19.55L7.6 21ZM9.95 14.05L15.6 8.4L14.2 6.95L9.95 11.2L7.8 9.1L6.4 10.5L9.95 14.05Z"
                                fill="#00A0C0"
                              />
                            </svg>
                          </span>
                          <div className="our-solution-icons">
                            {job?.logoPath ? (
                              <img
                                src={job?.logoPath}
                                alt=""
                                className="w-4/5"
                              />
                            ) : (
                              <img
                                src={defaultOrgImg}
                                alt=""
                                className="w-4/5 default-org-img"
                              />
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="jobs-requirment">
                        <div className="uppersection">
                          <p className="job-detail-minor required-exprence ">
                            {job?.experienceRangeFrom}-{job?.experienceRangeTo}{" "}
                            years
                          </p>
                          <p className="job-detail-minor salary-offer">
                            {Number(job?.salaryRangeFrom)?.toLocaleString(
                              "en-IN"
                            )}
                            -
                            {Number(job?.salaryRangeTo)?.toLocaleString(
                              "en-IN"
                            )}
                          </p>
                          <p className="job-detail-minor location">
                            {job?.cityName},{job?.stateName}
                          </p>
                        </div>
                        <div className="lowersections">
                          <p className="job-detail-minor detail-other ">
                            {job?.departmentName}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              ))}
          </div>
        </div>
      </div>
      <div className="appdownload-main" id="download-app-now">
        <JoinNetwork
          heading="Join Our Network"
          subheading="Discover the Power of Connection with Tera Connects - Where Your Medical Needs Find Their Perfect Match."
          googlePlayUrl="https://play.google.com/store/apps/details?id=com.teraplatforms.jobseeker"
          appStoreUrl="https://apps.apple.com/in/app/tera-recruit-job-search/id1659265164"
          // imageUrl={qrtera}
          imageUrl={qrteraclinicians}
        />
      </div>
      <Transition appear show={isShowModal} as={Fragment}>
        <Dialog as="div" className="relative z-40" onClose={handleClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-white" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="sticky z-10 top-0 p-4 xl:px-10 flex items-center justify-between bg-white">
              <button
                className="focus:outline-none focus:ring-0 w-10 h-10 rounded-full flex items-center justify-center hover:bg-neutral-100"
                onClick={handleClose}
              >
                <ArrowSmallLeftIcon className="w-6 h-6" />
              </button>
              {/* <LikeSaveBtns /> */}
            </div>

            <div className="flex min-h-full items-center sm:p-4 pt-0 text-center">
              <JoinNetworkForm
                heading="Apply Job From App"
                subheading="Join the MediProHub app for professional growth, find job opportunities, and stay updated with new trends in the medical industry."
                googlePlayUrl="https://play.google.com/store/apps/details?id=com.teraplatforms.jobseeker"
                appStoreUrl="https://apps.apple.com/in/app/tera-recruit-job-search/id1659265164"
                // imageUrl={qrtera}
                imageUrl={qrteraclinicians}
                jobTitle={
                  jobInformation?.title !== undefined
                    ? jobInformation.title
                    : ""
                }
                jobId={
                  jobInformation?.id !== undefined ? jobInformation?.id : 0
                }
                healthCareCategoryID={
                  jobInformation?.healthCareCategoryID !== undefined
                    ? jobInformation?.healthCareCategoryID
                    : 0
                }
              />
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};

export default JobPost;
