// ImageList.tsx

import React, { useEffect, useState } from 'react';
import data from './searchbycity.json';

interface ImageData {
  id: number;
  imageUrl: string;
  linkUrl: string;
  title: string;
}

const ImageList: React.FC = () => {
  const [dataList, setDataList] = useState<ImageData[]>([]);

  useEffect(() => {
    // Set data from the imported JSON file
    setDataList(data);
  }, []);

  return (
    <div className='cityfilter'>
      {dataList.length === 0 ? (
        <p>Loading...</p>
      ) : (
        dataList.map((item) => (
          <a className='cityfiltercard' href={item.linkUrl} target="_blank" rel="noopener noreferrer" key={item.id}>
            <div  className='cityfilter-div'>
              <img src={item.imageUrl} alt="Dynamic Image" />
              <p>{item.title}</p>
            </div>
          </a>
        ))
      )}
    </div>
  );
};

export default ImageList;
