import { useState } from "react";
import Stepper from "react-stepper-horizontal";
import "./locationform.css";
import UserTypeForm from "./selectusertype";
import OrgTypeForm from "./organizationtype";
import OrgDetailsForm from "./orgdetailspage";
import SelectServicesForm from "./selectservices";
import AddMessageForm from "./addmessage";
export interface Step {
  title: string;
}
interface CustomStepperProps {
  steps: Step[];
  activeStep: number;
}
function CustomStepper(props: CustomStepperProps) {
  return (
    <Stepper
      {...props}
      activeColor="#ffd813"
      defaultColor="#eee"
      completeColor="#ffbd13"
      activeTitleColor="#fff"
      completeTitleColor="#eee"
      defaultTitleColor="#bbb"
      circleFontColor="#000"
      completeBarColor="#ffbd13"
    />
  );
}

function LocationForm() {
  const [activeStep, setActiveStep] = useState<number>(0);
  const [userData, setUserData] = useState<any>([]);
  const [orgTypeData, setOrgTypeData] = useState<any>([]);
  const [orgTypeDetails, setOrgTypeDetails] = useState<any>([]);
  const [servicesDetails, setServicesDetails] = useState<any>([]);

  let steps: Step[] = [];

  switch (Number(userData.userTypeID || userData?.orgTypeData?.userTypeID)) {
    case 5:
      steps = [
        { title: "User Type" },
        { title: "Org Type" },
        { title: "Org Info" },
        { title: "Select Services" },
        { title: "Add Message" },
      ];
      break;
    case 4:
      steps = [
        { title: "User Type" },
        { title: "Personal Information" },
        { title: "Add Message" },
      ];
      break;
    case 6:
    case 575:
      steps = [
        { title: "User Type" },
        { title: "Organization Information" },
        { title: "Add Message" },
      ];
      break;
    case 0:
    default:
      steps = [
        { title: "User Type" },
        { title: "Org Type" },
        { title: "Personal/Org Info" },
        { title: "Select Services" },
        { title: "Add Message" },
      ];
      break;
  }

  function getSectionComponent() {
    switch (activeStep) {
      case 0:
        return (
          <UserTypeForm
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            steps={steps}
            onUserDataChange={handleUserData}
          />
        );
      case 1:
        return (
          <OrgTypeForm
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            steps={steps}
            userData={userData}
            onUserDataChange={handleUserData}
          />
        );
      case 2:
        return (
          <OrgDetailsForm
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            steps={steps}
            orgTypeData={orgTypeData}
            userData={userData}
            onUserDataChange={handleUserData}
          />
        );
      case 3:
        return (
          <SelectServicesForm
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            steps={steps}
            orgTypeDetails={orgTypeDetails}
            onUserDataChange={handleUserData}
          />
        );
      case 4:
        return (
          <AddMessageForm
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            steps={steps}
            userData={userData}
            servicesDetails={servicesDetails}
            onUserDataChange={handleUserData}
          />
        );
      default:
        return null;
    }
  }
  const handleUserData = (data: any) => {
    setUserData(data);
    setOrgTypeData(data);
    setOrgTypeDetails(data);
    setServicesDetails(data);
  };
  return (
    <div className="contact-us-container">
      <CustomStepper steps={steps} activeStep={activeStep} />
      <div className="content-container">{getSectionComponent()}</div>
    </div>
  );
}

export default LocationForm;
