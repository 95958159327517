import React, { useState, useEffect } from "react";

const medicalcontentwriter = () => {
    return (
        <div className="page-job-post content-writer">
            <div className="container">
                <div className="job-post-wrapper">
                    <div className="jobdetail-side">
                        <div className="joblistings-detail">
                            <div className="jobs-prodiver-detail">
                                <div className="job-info-main">
                                    <h4 className="job-name">Medical Content Writer</h4>
                                    <h5 className="job-provider-name"></h5>
                                </div>
                                <div className="job-provider-info"></div>
                            </div>
                            <div className="jobs-requirment">
                                <div className="uppersection">
                                    <p className="job-detail-minor required-exprence ">
                                        0-3 years
                                    </p>
                                    <p className="job-detail-minor salary-offer">Not Disclosed</p>
                                    <p className="job-detail-minor location">On-Site, Hyderabad, Telangana</p>
                                </div>
                                <div className="lowersections">
                                    <p className="job-detail-minor detail-other ">
                                        Education :Bachelor's degree, or related field.
                                    </p>
                                    {/* <p className="job-detail-minor detail-other">
                                        Key Skills : Flutter, Dart, Android, iOS
                                    </p> */}
                                </div>
                            </div>
                            <div className="job-apply-section">
                                <div className="job-provider-info post-page">
                                    {/* <p className="job-detail-minor openings"> 1 Opening</p>
                                    <span className="job-detail-minor posted-ago">
                                        4/12/ 2023
                                    </span> */}
                                </div>
                                <div className="job-btns-selection">
                                    <a
                                        className="applynowbtn apply-save-btn"
                                        href="https://forms.gle/ABCdji6SSDwBeuYT6"
                                    >
                                        Apply
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className="career-joblisting-detail job-description">
                            <div className="product-specification">
                                <h2 className="product-specs-title-for-product-single for-list-prod montserrat">
                                    Job Description
                                </h2>
                                <ul className="product-desc-list tera-job-description">
                                    We are seeking a talented and experienced Medical Content Writer to join our team in creating engaging and informative content for our healthcare platform. The ideal candidate will have a strong background in the medical field, exceptional writing skills, and a passion for delivering accurate and compelling healthcare content to our audience.
                                </ul>

                            </div>
                        </div>

                        <div className="career-joblisting-detail job-description">
                            <div className="product-specification">
                                <h2 className="product-specs-title-for-product-single for-list-prod montserrat">
                                    Responsibilities
                                </h2>
                                {/* <ul className="product-desc-list tera-job-description"> */}
                                <ul className="">
                                    <li className="specs-list-content montserrat">
                                        Research and write high-quality medical content for our website, blog posts, articles, social media, and other digital platforms.
                                    </li>
                                    <li className="specs-list-content montserrat">
                                        Generate ideas for new content topics based on industry trends, audience interests, and SEO strategies.
                                    </li>
                                    <li className="specs-list-content montserrat">
                                        Collaborate with medical professionals, researchers, and subject matter experts to ensure content accuracy and relevance.
                                    </li>
                                    <li className="specs-list-content montserrat">
                                        Translate complex medical information into clear and accessible language for a diverse audience.
                                    </li>
                                    <li className="specs-list-content montserrat">
                                        Conduct thorough research on medical topics, including diseases, treatments, procedures, and healthcare trends.
                                    </li>
                                    <li className="specs-list-content montserrat">
                                        Ensure all content adheres to industry standards, ethical guidelines, and regulatory requirements.
                                    </li>
                                    <li className="specs-list-content montserrat">
                                        Optimize content for SEO by incorporating relevant keywords, meta tags, and internal and external links.
                                    </li>
                                    <li className="specs-list-content montserrat">
                                        Stay up-to-date with advancements in the medical field, emerging healthcare technologies, and industry best practices.
                                    </li>
                                    <li className="specs-list-content montserrat">
                                        Edit and proofread content to ensure accuracy, clarity, and consistency.
                                    </li>
                                    <li className="specs-list-content montserrat">
                                        Collaborate with the marketing team to develop content strategies and campaigns to engage and educate our audience.
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="career-joblisting-detail job-description">
                            <div className="product-specification">
                                <h2 className="product-specs-title-for-product-single for-list-prod montserrat">
                                    Qualifications
                                </h2>
                                {/* <ul className="product-desc-list tera-job-description"> */}
                                <ul className="">
                                    <li className="specs-list-content montserrat">
                                        Bachelor's degree, or related field.
                                    </li>
                                    <li className="specs-list-content montserrat">
                                        0- 3 years of experience as a medical writer or healthcare content creator with a portfolio of published work.
                                    </li>
                                    <li className="specs-list-content montserrat">
                                        Strong understanding of medical terminology, anatomy, physiology, and healthcare concepts.
                                    </li>
                                    <li className="specs-list-content montserrat">
                                        Excellent writing and communication skills, with the ability to convey complex medical information in a clear and engaging manner.
                                    </li>
                                    <li className="specs-list-content montserrat">
                                        Proficiency in conducting medical research and synthesizing information from credible sources.
                                    </li>
                                    <li className="specs-list-content montserrat">
                                        Familiarity with SEO principles and content optimization techniques.
                                    </li>
                                    <li className="specs-list-content montserrat">
                                        Detail-oriented with a keen eye for accuracy and consistency.
                                    </li>
                                    <li className="specs-list-content montserrat">
                                        Ability to work independently and meet deadlines in a fast-paced environment.
                                    </li>
                                    <li className="specs-list-content montserrat">
                                        Experience with content management systems (CMS) and basic knowledge of HTML/CSS is a plus.
                                    </li>
                                    <li className="specs-list-content montserrat">
                                        Passion for healthcare, wellness, and helping others make informed decisions about their health.
                                    </li>

                                </ul>
                            </div>
                        </div>
                        <div className="career-joblisting-detail job-description">
                            <div className="product-specification">
                                <h2 className="product-specs-title-for-product-single for-list-prod montserrat">
                                    Preferred Qualifications
                                </h2>
                                {/* <ul className="product-desc-list tera-job-description"> */}
                                <ul className="">
                                    <li className="specs-list-content montserrat">
                                        Advanced degree (B.Pharma, D.Pharma) in a healthcare-related field.
                                    </li>
                                    <li className="specs-list-content montserrat">
                                        Experience working in a healthcare or medical research setting.
                                    </li>
                                    <li className="specs-list-content montserrat">
                                        Certification in medical writing or healthcare communications.  </li>
                                    <li className="specs-list-content montserrat">
                                        Knowledge of regulatory requirements and compliance standards for medical content.
                                    </li>
                                </ul>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default medicalcontentwriter;
