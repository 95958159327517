import React, { useEffect, useState } from 'react';
import { IoIosChatboxes, IoMdClose } from 'react-icons/io';
import './chatbot.css';
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, FormProvider, useForm } from "react-hook-form";
import PhoneInputWithCountrySelect, { parsePhoneNumber } from 'react-phone-number-input';
import { getCitiesByStateApi, getStatesApi } from "services/lookupService";
import Select from "react-select";
import { contactUsApi } from 'services/contactService';
import Swal from 'sweetalert2';
import Textarea from "components/Textarea/Textarea";


interface State {
    value: number;
    label: string;
}
interface City {
    value: number;
    label: string;
}
const schema = yup.object().shape({
    name: yup
        .string()
        .trim()
        .nullable()
        .required("Please enter name")
        .test(
            "org-length",
            "The name must be between 3 and 300 characters",
            function (value) {
                if (!value) {
                    throw new yup.ValidationError(
                        "You must enter organization name",
                        "name"
                    );
                }
                const length = value.trim().length;
                return length >= 3 && length <= 300;
            }
        ),
    phoneNumber: yup
        .string()
        .nullable()
        .required("Please enter phone number")
        .test("is-valid-phone", "Enter valid phone number", function (value) {
            const onlyNumber = parsePhoneNumber(
                value.toString()
            )?.nationalNumber.toString();
            return /^[6-9]\d{9}$/.test(onlyNumber ?? "");
        }),
    contactEmail: yup
        .string()
        .trim()
        .nullable()
        .required("Please enter a email")
        .email("Enter valid email")
        .matches(
            /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
            "Invalid email format"
        )
        .test(
            "contactEmail-length",
            "The email must be between 5 and 150 characters",
            function (value) {
                if (!value) {
                    throw new yup.ValidationError(
                        "You must enter contact email",
                        "contactEmail"
                    );
                }
                const length = value.trim().length;
                return length >= 5 && length <= 150;
            }
        ),
    state:

        yup.object().nullable().required("Please select any state"),
    //   : yup.object().notRequired(),
    notes: yup
        .string()
        .trim()
        .nullable()
        .required("You must enter a notes")
        .test(
            "notes-length",
            "The notes must be between 50 and 1000 characters",
            function (value) {
                if (!value) {
                    return true;
                }
                const length = value.trim().replace(/<[^>]+>/g, "")?.length;
                const isWithinUpperLimit = length <= 500;
                const isWithinLowerLimit = length >= 50;
                return isWithinLowerLimit && isWithinUpperLimit;
            }
        ),
    city:
        yup.object().nullable().required("Please select any city")
    //   : yup.object().notRequired(),
});
const Chatbot: React.FC = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [activeTab, setActiveTab] = useState('chat');
    const [isVisible, setIsVisible] = useState(false);
    const [states, setStates] = useState<State[]>([]);
    const [cities, setCities] = useState<City[]>([]);
    const [selectedStateId, setSelectedStateId] = useState<string | null>(null);
    const [selectedCityId, setSelectedCityId] = useState<string | null>(null);





    const methods = useForm({
        mode: "onChange",
        defaultValues: {},
        resolver: yupResolver(schema),
    });
    const { control, formState, getValues, handleSubmit, setValue } = methods;
    const { errors } = formState;

    const handleScroll = () => {
        const scrollY = window.scrollY || window.pageYOffset;

        // Set visibility based on scroll position
        setIsVisible(scrollY > 100);
    };

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    const handleTabChange = (tab: string) => {
        setActiveTab(tab);
    };

    const handleClose = () => {
        setIsOpen(false);
    }

    const openWhatsappChat = (phoneNumber: Number) => {
        // Replace the phone number with the desired WhatsApp number
        const whatsappNumber = String(phoneNumber);

        // Create the WhatsApp chat link
        const whatsappLink = `https://wa.me/${whatsappNumber}`;

        // Open the WhatsApp chat link
        window.open(whatsappLink, '_blank');
    };


    useEffect(() => {
        if (isOpen) {
            setIsVisible(false);
        }
    }, [isOpen])
    useEffect(() => {
        getStatesApi()
            .then((response) => {
                setStates(response.data);
            })
            .catch((error) => {
                console.error("Error fetching states:", error);
            });
    }, []);
    const handleStateChange = (selectedOption: any) => {
        const selectedStateId = parseInt(selectedOption?.value, 10);
        setSelectedStateId(selectedStateId.toString())
        if (selectedStateId) {
            getCitiesByStateApi(selectedStateId)
                .then((response) => {
                    setCities(response.data);
                })
                .catch((error) => {
                    console.error("Error fetching cities:", error);
                });
        }
    };
    const SubmitContactForm = () => {
        console.log(getValues(), "values")
        let values = getValues();
        console.log(values.state, "state")
        console.log(selectedCityId, "selectedCityId")
        console.log(selectedStateId, "selectedStateId")
        let formData = {
            organizationTypeID: 0,
            name: values.name,
            contactPhone: parsePhoneNumber(
                values.phoneNumber!.toString()
            )!.nationalNumber.toString(),
            contactEmail: values.contactEmail,
            stateID: Number(selectedStateId) || 0,
            cityID: Number(selectedCityId) || 0,
            seekingServices: "",
            userTypeID: "4",
            notes: values?.notes || ''
        };
        console.log(formData, "formData")
        // return;
        contactUsApi(formData)
            .then(() => {
                handleClose();
                Swal.fire({
                    title: "Success!",
                    text: "Your request is submitted",
                    icon: "success",
                }).then(() => {
                    // navigate("/");
                    // handleClose();
                });
            })
            .catch(() => {
                Swal.fire({
                    title: "Error!",
                    text: "Your request is not submitted, Try after sometime",
                    icon: "error",
                });
            });

    }
    return (
        <FormProvider {...methods}>
            <div>
                <button className={`chatbot-button ${isVisible ? 'visible' : ''}`} onClick={handleToggle}>
                    <IoIosChatboxes className="chat-icon" />
                </button>

                {isOpen && (
                    <div className="chatbot-container">
                        <button className="close-button" onClick={handleClose}>
                            <IoMdClose />
                        </button>
                        <div className="tab-buttons">
                            <div
                                className={`tab-button ${activeTab === 'chat' ? 'active' : ''}`}
                                onClick={() => handleTabChange('chat')}
                            >
                                Chat
                            </div>
                            <div
                                className={`tab-button ${activeTab === 'whatsapp' ? 'active' : ''}`}
                                onClick={() => handleTabChange('whatsapp')}
                            >WhatsApp
                            </div>
                        </div>
                        {
                            activeTab === 'chat' && (
                                <>
                                    <div className="tab-content chat-tab">
                                        <div
                                            className="inline-flex"
                                            style={{ color: "black" }}
                                        >
                                            Name
                                        </div>
                                        <div className="w-full pt-1">
                                            <Controller
                                                name="name"
                                                control={control}
                                                render={({ field }) => (
                                                    <>
                                                        <input
                                                            {...field}
                                                            style={{
                                                                borderRadius: "1.35rem",
                                                            }}
                                                            type="text"
                                                            autoFocus
                                                            placeholder="Enter Name"
                                                            className="w-full"
                                                            required
                                                        />
                                                        <p className="error">
                                                            {errors.name && errors.name?.message}
                                                        </p>
                                                    </>
                                                )}
                                            />
                                        </div>
                                        <div
                                            className="inline-flex"
                                            style={{ color: "black", paddingTop: "6px" }}
                                        >
                                            State
                                        </div>
                                        <div className="w-full pt-1">
                                            <Controller
                                                name="state"
                                                control={control}
                                                render={({ field }) => (
                                                    <>
                                                        <Select
                                                            classNames={{
                                                                control: () => "br-22",
                                                            }}
                                                            {...field}
                                                            options={states}
                                                            placeholder='Select State'
                                                            onChange={(selectedOption) => {
                                                                field.onChange(selectedOption);
                                                                handleStateChange(selectedOption);
                                                            }}
                                                            onBlur={() => field.onBlur()}
                                                            value={
                                                                states &&
                                                                states.find((option) => option.value === field.value)
                                                            }
                                                            required
                                                        />
                                                        <p className="error">{errors.state?.message}</p>
                                                    </>
                                                )}
                                            />
                                        </div>
                                        <div
                                            className="inline-flex"
                                            style={{ color: "black", paddingTop: "6px" }}
                                        >
                                            City
                                        </div>
                                        <div className="w-full pt-1">
                                            <Controller
                                                name="city"
                                                control={control}
                                                render={({ field }) => (
                                                    <>
                                                        <Select
                                                            classNames={{
                                                                control: () => "br-22",
                                                            }}
                                                            {...field}
                                                            options={cities}
                                                            placeholder='Select City'
                                                            onChange={(selectedOption) => {
                                                                field.onChange(selectedOption)
                                                                setSelectedCityId(String(selectedOption?.value))
                                                            }
                                                            }
                                                            onBlur={() => field.onBlur()}
                                                            value={
                                                                cities &&
                                                                cities.find((option) => option.value === field.value)
                                                            }
                                                            required
                                                        />
                                                        <p className="error">{errors.city?.message}</p>
                                                    </>
                                                )}
                                            />
                                        </div>
                                        <div
                                            className="inline-flex border-0 contact-label"
                                            style={{ color: "black", paddingTop: "6px" }}
                                        >
                                            Contact Phone
                                        </div>
                                        <div className="w-full pt-1 contact-phone">
                                            <Controller
                                                name="phoneNumber"
                                                control={control}
                                                render={({ field }) => (
                                                    <>
                                                        <PhoneInputWithCountrySelect
                                                            {...field}
                                                            international
                                                            className=""
                                                            defaultCountry="IN"
                                                            countryCallingCodeEditable={false}
                                                            countries={["IN"]}
                                                        />
                                                        <p className="error">
                                                            {errors.phoneNumber && errors.phoneNumber?.message}
                                                        </p>
                                                    </>
                                                )}
                                            />
                                        </div>
                                        <div
                                            className="inline-flex border-0"
                                            style={{ color: "black", paddingTop: "6px" }}
                                        >
                                            Contact Email
                                        </div>
                                        <div className="w-full pt-1">
                                            <Controller
                                                name="contactEmail"
                                                control={control}
                                                render={({ field }) => (
                                                    <>
                                                        <input
                                                            {...field}
                                                            type="email"
                                                            placeholder="Enter Email"
                                                            className="w-full"
                                                            style={{
                                                                borderRadius: "1.35rem",
                                                            }}
                                                            required
                                                        />
                                                        <p className="error">
                                                            {errors.contactEmail && errors.contactEmail?.message}
                                                        </p>
                                                    </>
                                                )}
                                            />
                                        </div>
                                        <div
                                            className="inline-flex"
                                            style={{ color: "black", paddingTop: "6px" }}
                                        >
                                            Notes
                                        </div>
                                        <div className="w-full pt-1">
                                            <Controller
                                                name="notes"
                                                control={control}
                                                render={({ field }) => (
                                                    <>
                                                        <Textarea
                                                            className="mt-1"
                                                            {...field}
                                                            autoFocus
                                                            placeholder="Enter Your Message"
                                                            required
                                                            rows={3}
                                                        />
                                                        <p className="error">
                                                            {errors.notes && errors.notes.message}
                                                        </p>
                                                    </>
                                                )}
                                            />

                                        </div>
                                    </div>

                                    <button className="submit-button"
                                        onClick={() => {
                                            handleSubmit(SubmitContactForm)();
                                            { }
                                        }}
                                    >
                                        Send
                                    </button>
                                </>
                            )
                        }
                        {
                            activeTab === 'whatsapp' && (
                                <div className="tab-content whatsapp-tab">
                                    <div className="sidebar">
                                        <div className="sidebar-content">
                                            <div className="contact-box">
                                                <div className="contact-info">
                                                    <div className="text-info">
                                                        <div className="app-name">For Jobs Platform</div>
                                                        <div className='app-name-2'>Chat with us</div>
                                                    </div>
                                                    <div className={`whatsapp-chat-button`} onClick={() => openWhatsappChat(917416665595)}>
                                                        <i className="fab fa-whatsapp"></i>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="contact-box">
                                                <div className="contact-info">
                                                    <div className="text-info">
                                                        <div className="app-name">For Services</div>
                                                        <div className='app-name-2'>Chat with us</div>
                                                    </div>
                                                    <div className={`whatsapp-chat-button`} onClick={() => openWhatsappChat(917416665595)}>
                                                        <i className="fab fa-whatsapp"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="contact-box">
                                                <div className="contact-info">
                                                    <div className="text-info">
                                                        <div className="app-name">For Support</div>
                                                        <div className='app-name-2'>Chat with us</div>
                                                    </div>
                                                    <div className={`whatsapp-chat-button`} onClick={() => openWhatsappChat(917416665484)}>
                                                        <i className="fab fa-whatsapp"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </div >
                )}
            </div >
        </FormProvider >
    );
};

export default Chatbot;
