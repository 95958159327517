import React, { useState } from "react";
import { Link } from "react-router-dom";
import ForgotPasswordForm from "./forgotpassword_form";
import { parsePhoneNumber } from "react-phone-number-input";

import { forgotPasswordApi } from "services/loginService";
const PageForgotPass: React.FC = () => {
  const [otpSent, setOtpSent] = useState(false);

  const handleForgotPassword = async (
    phone: string,
    email: string,
    marketingCode: string
  ) => {
    if (!otpSent) {
      const phoneValue = parsePhoneNumber(phone!.toString())?.nationalNumber;
      forgotPasswordApi(phoneValue)
        .then((response) => {
          if (response.data && response.status === 200) {
            setOtpSent(true);
          }
        })
        .catch((error) => {
          console.error("API Error:", error);
        });
    }
  };

  return (
    <div className="login-signp">
      <div className="login-signup-iner">
        <div className="container">
          <div className="login-singup-inner-wrapper">
            <h3 className="montserrat">Marketing Partner Forgot Password</h3>
            <ForgotPasswordForm
              onSubmit={handleForgotPassword}
              otpSent={otpSent}
            />
            <div className="sign-up-login-redirection">
              <p>
                I have an account? <Link to="/login"> Sign in</Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageForgotPass;
