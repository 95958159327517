import API from "./api";
import { Lookup_API_URLS } from "./constants";
export function getHealthCareCategoriesApi() {
  return API.get(Lookup_API_URLS.getHealthCareCategoriesUrl);
}
export function getStatesApi() {
  return API.get(Lookup_API_URLS.getStatesUrl);
}
export function getOrganizationTypeApi() {
  return API.get(Lookup_API_URLS.getOrganizationTypeUrl);
}
export function getAllTeraServicesApi() {
  return API.get(Lookup_API_URLS.getAllTeraServicesUrl);
}
export function getCountriesApi() {
  return API.get(Lookup_API_URLS.getCountriesUrl);
}
export function getStatesByCountryApi(selectedCountryId) {
  return API.get(
    `${Lookup_API_URLS.getStatesByCountryUrl}/${selectedCountryId}`
  );
}
export function getCitiesByStateApi(selectedStateId) {
  return API.get(
    `${Lookup_API_URLS.getCitiesByStateUrl}?stateId=${selectedStateId}`
  );
}
export function getUserTypeApi() {
  return API.get(Lookup_API_URLS.getUserTypeUrl);
}