// CategoryFilter.jsx
import React, { useState, useEffect } from "react";
import { getHealthCareCategoriesApi } from "services/lookupService";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
interface Category {
  id: number;
  description: string;
}
interface FilterBasedOnHealthCareCategoryProps {
  handleFilterJobsBasedOnHealthCareCategory: (
    selectedCategoryId: any,
    updatedSelectedCheckboxes: any,
    experienceRange: any,
    salaryRange: any,
    selectedCompaniesCheckboxes: any
  ) => void;
  selectedCategoryValue: number;
  selectedCheckboxesValues: any;
  selectedExperienceRange: any;
  selectedSalaryRange: any;
  selectedCompanyCheckboxesValues: any;
  selectedCategoryValueFromFilter: any;
}

const FilterBasedOnHealthCareCategory: React.FC<
  FilterBasedOnHealthCareCategoryProps
> = ({
  handleFilterJobsBasedOnHealthCareCategory,
  selectedCategoryValue,
  selectedCategoryValueFromFilter,
  selectedCheckboxesValues,
  selectedExperienceRange,
  selectedSalaryRange,
  selectedCompanyCheckboxesValues,
}) => {
  const [categories, setCategories] = useState<Category[]>([]);
  const [showAllCategories, setShowAllCategories] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<number | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    getHealthCareCategoriesApi().then((response) => {
      setCategories(response?.data);
      const defaultCategory = response?.data.find(
        (category: any) =>
          category.id ===
          (selectedCategoryValue || selectedCategoryValueFromFilter)
      );
      if (defaultCategory) {
        setSelectedCategory(defaultCategory.id);
        handleFilterJobsBasedOnHealthCareCategory(
          defaultCategory.id,
          selectedCheckboxesValues,
          selectedExperienceRange || { min: 0, max: 0 },
          selectedSalaryRange || { min: 0, max: 0 },
          selectedCompanyCheckboxesValues
        );
      }
    });
  }, [selectedCategoryValue]);

  const handleShowMoreClick = () => {
    setShowAllCategories(true);
  };
  const handleCategoryClick = (categoryId: number) => {
    if (selectedCategory === categoryId) {
      setSelectedCategory(0);
      navigate(
        `/jobs/jobs-listing/?selectedCategory=${0}&selectedExperience=${
          selectedExperienceRange?.max || 50
        }&selectedSalaryRange=${selectedSalaryRange?.max}&selectedStates=${
          selectedCheckboxesValues || []
        }&selectedOrganizations=${selectedCompanyCheckboxesValues || []}`
      );
      handleFilterJobsBasedOnHealthCareCategory(
        0,
        selectedCheckboxesValues,
        selectedExperienceRange || { min: 0, max: 0 },
        selectedSalaryRange || { min: 0, max: 0 },
        selectedCompanyCheckboxesValues
      );
    } else {
      setSelectedCategory(categoryId);
      navigate(
        `/jobs/jobs-listing/?selectedCategory=${categoryId}&selectedExperience=${
          selectedExperienceRange?.max || 50
        }&selectedSalaryRange=${selectedSalaryRange?.max}&selectedStates=${
          selectedCheckboxesValues || []
        }&selectedOrganizations=${selectedCompanyCheckboxesValues || []}`
      );
      handleFilterJobsBasedOnHealthCareCategory(
        categoryId,
        selectedCheckboxesValues,
        selectedExperienceRange || { min: 0, max: 0 },
        selectedSalaryRange || { min: 0, max: 0 },
        selectedCompanyCheckboxesValues
      );
    }
  };
  const displayedCategories = showAllCategories
    ? categories
    : categories.slice(0, 4);

  return (
    <div className="category-list">
      {displayedCategories?.map((category) => (
        <a
          className={`categorieswithimages ${
            selectedCategory === category.id
              ? "accordionHover rounded-3xl"
              : "categorieswithimages rounded-3xl"
          }`}
          key={category.id}
          onClick={() => handleCategoryClick(category.id)}
        >
          <div
            key={category.id}
            className={classNames("flex", "gap-3", {
              "cursor-pointer": true,
            })}
          >
            <h3>{category.description} </h3>
          </div>
        </a>
      ))}
      {!showAllCategories && (
        <div className="text-right ml-12">
          <span
            onClick={handleShowMoreClick}
            className="inline-block cursor-pointer text-base font-semibold text-blue-500"
          >
            View More...
          </span>
        </div>
      )}
    </div>
  );
};

export default FilterBasedOnHealthCareCategory;
