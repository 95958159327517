import React from "react";
import { Doughnut } from "react-chartjs-2";
export interface EarningSummary {
  paid: number;
  paidPercentage: number;
  unPaid: number;
  unPainPercentage: number;
}
interface PieChartProps {
  earningSummary: EarningSummary;
}

const PieChartComponent: React.FC<PieChartProps> = ({ earningSummary }) => {
  const total = Number(earningSummary.paid + earningSummary.unPaid);
  const data = {
    labels: ["Paid", "UnPaid"],
    datasets: [
      {
        label: "Earnings",
        data: [earningSummary.paid, earningSummary.unPaid],
        backgroundColor: ["#61DBFB", "purple"],
        borderColor: ["#61DBFB", "black"],
        circumference: 180,
        rotation: 270,
      },
    ],
  };

  const options = {
    aspectRatio: 2,
  };
  const progressText = (
    <>
      <h1 className="line-clamp-2 text-lg font-medium  dark:text-neutral-300">
        Progress
      </h1>{" "}
      {earningSummary.paid}/{total}
    </>
  );

  return (
    <div>
      <Doughnut data={data} options={options} />
      <div style={{ textAlign: "center", padding: "15px" }}>
        <h2>{progressText}</h2>
        <div className="flex justify-around gap-3 pt-10">
          <p>Paid: {earningSummary.paidPercentage.toFixed(2)}%</p>
          <p>Unpaid: {earningSummary.unPainPercentage.toFixed(2)}%</p>
        </div>
      </div>
    </div>
  );
};

export default PieChartComponent;
