import React, { useState, useEffect } from "react";

const marketingpartner = () => {
  return (
    <div className="page-job-post content-writer">
      <div className="container">
        <div className="job-post-wrapper">
          <div className="jobdetail-side">
            <div className="joblistings-detail">
              <div className="jobs-prodiver-detail">
                <div className="job-info-main">
                  <h4 className="job-name"> Marketing Field Executive</h4>
                  <h5 className="job-provider-name"></h5>
                </div>
                <div className="job-provider-info"></div>
              </div>
              <div className="jobs-requirment">
                <div className="uppersection">
                  <p className="job-detail-minor required-exprence ">
                    0-3 years
                  </p>
                  <p className="job-detail-minor salary-offer">Not Disclosed</p>
                  <p className="job-detail-minor location">Remote</p>
                </div>
                <div className="lowersections">
                  <p className="job-detail-minor detail-other ">
                    Education : BSc or MBA in Marketing, Sales & Marketing /
                    Business Administration, or a Relevant Discipline
                  </p>
                </div>
              </div>
              <div className="job-apply-section">
                <div className="job-provider-info post-page">
                  <p className="job-detail-minor openings"> 1 Opening</p>
                  <span className="job-detail-minor posted-ago">
                    4/12/ 2023
                  </span>
                </div>
                <div className="job-btns-selection">
                  <a
                    className="applynowbtn apply-save-btn"
                    href="mailto:careers@teraconnects.com"
                  >
                    Apply
                  </a>
                </div>
              </div>
            </div>

            <div className="career-joblisting-detail job-description">
              <div className="product-specification">
                <h2 className="product-specs-title-for-product-single for-list-prod montserrat">
                  Responsibilities
                </h2>
                {/* <ul className="product-desc-list tera-job-description"> */}
                <ul className="">

                  <li className="specs-list-content montserrat">
                    Conceive and develop efficient and intuitive marketing
                    strategies.
                  </li>
                  <li className="specs-list-content montserrat">
                    Actively participate in brand campaigns and promote the
                    product in events to clients and customers.
                  </li>
                  <li className="specs-list-content montserrat">
                    Organize and oversee advertising and communication campaigns
                    (social media, TV, etc.), exhibitions, and promotional
                    events.
                  </li>
                  <li className="specs-list-content montserrat">
                    Conduct market research and analysis to evaluate trends,
                    brand awareness, and competition.
                  </li>
                  <li className="specs-list-content montserrat">
                    Initiate and control surveys to assess customer requirements
                    and dedication.
                  </li>
                  <li className="specs-list-content montserrat">
                    Write copy for diverse marketing distributions (brochures,
                    press releases, website material, etc.).
                  </li>
                  <li className="specs-list-content montserrat">
                    Maintain relationships with media vendors and publishers to
                    ensure collaboration in promotional activities.
                  </li>
                  <li className="specs-list-content montserrat">
                    Monitor the progress of campaigns using various metrics and
                    submit reports of performance.
                  </li>
                  <li className="specs-list-content montserrat">
                    Collaborate with managers in preparing budgets and
                    monitoring expenses.
                  </li>
                </ul>
              </div>
            </div>

            <div className="career-joblisting-detail job-description">
              <div className="product-specification">
                <h2 className="product-specs-title-for-product-single for-list-prod montserrat">
                  Requirements and skills
                </h2>
                {/* <ul className="product-desc-list tera-job-description"> */}
                <ul className="">

                  <li className="specs-list-content montserrat">
                    Proven experience as a marketing executive or similar role
                  </li>
                  <li className="specs-list-content montserrat">
                    Good understanding of market research techniques, data
                    analysis, and statistical methods
                  </li>
                  <li className="specs-list-content montserrat">
                    Thorough knowledge of strategic planning principles and
                    marketing best practices
                  </li>
                  <li className="specs-list-content montserrat">
                    Proficient in MS Office and marketing software (e.g., CRM)
                  </li>
                  <li className="specs-list-content montserrat">
                    Familiarity with social media and web analytics (e.g.,
                    WebTrends)
                  </li>
                  <li className="specs-list-content montserrat">
                    Excellent communication and people skills
                  </li>
                  <li className="specs-list-content montserrat">
                    Strong organizational and time-management abilities
                  </li>
                  <li className="specs-list-content montserrat">
                    Creativity and commercial awareness
                  </li>
                  <li className="specs-list-content montserrat">
                    BSc or MBA in Marketing, Sales & Marketing / business
                    administration, or a relevant discipline
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default marketingpartner;
