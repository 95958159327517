import React from "react";
import { Helmet } from "react-helmet";
import "./cancellation.css";

function cancellationpolicy() {
  return (
    <div className="container tnC-page">
      <Helmet>
        <title>Cancellation Policy - Tera Connects </title>

        <meta
          name="description"
          content="Our Privacy Policy explains how we collect, use, and protect your personal information. Please review it carefully to understand our practices."
        />
      </Helmet>
      <div className="p-6 bg-gray-100 rounded-lg ">
        <div className="home-hero-section privacy-page cancel-policy">
          <div className="container lg:flex head-home-sec gap-extra">
            <div className="lg:w-6/12  head-home-sec-centre">
              <h3 className="font-white regular title-custom title-heading-banner montserrat ">
                {" "}
                <b>Cancellation Policy</b>
              </h3>
            </div>
            <div className="lg:w-6/12 head-home-sec-centre"></div>
          </div>
        </div>
        <div className="pvcpolcy">
          <h4 className="font-semibold">1. Overview</h4>

          <p className="">
            At Tera, we understand that sometimes unforeseen circumstances can
            arise that may require the cancellation of scheduled services. We
            strive to accommodate our clients in every way possible while
            maintaining the high-quality standards we are known for. Therefore,
            we have created the following cancellation policy:
          </p>
          <p className="">
            Cancellation by the Client: If you need to cancel a scheduled
            service, please notify us as soon as possible. If you cancel more
            than 48 hours before the scheduled service, we will reschedule the
            service at no additional charge. If you cancel less than 48 hours
            before the scheduled service, we will charge a cancellation fee of
            50% of the total cost of the service.
          </p>
          <p className="">
            Cancellation by Applicant/Client: If you need to cancel your
            registration, you can utilize the delete account option, and then
            you must file a support ticket for withdrawal and exit for whatever
            amount in your wallet the firm would pay, not for premium costs.
          </p>
          <p className="">
            Cancellation by Tera Connects: In the unlikely event that we need to
            cancel a scheduled service, we will notify you as soon as possible
            and offer you the option of rescheduling the service at no
            additional cost or receiving a full refund.
          </p>
        <h4 className="mt-10 font-semibold">Last Updated</h4>
          <p>This Terms and Conditions was last updated on 01 April 2023.</p>
        </div>
      </div>
    </div>
  );
}

export default cancellationpolicy;
