import React from 'react';
import './countrylist.css';
import { count } from 'console';
import countries from '../data/countriesData.json';
import { Link, useLocation, useParams } from "react-router-dom";
import PageHomeDemo6 from 'app/(home)/contact-us/page';

interface Country {
    id: number;
    name: string;
    mapImage: string;
    flagImage: string;
    redirectTo: string;
}
interface CountryData {
    id: number;
    name: string;
    image: string;
    description: string;
    requirements: {
        Designation: string;
        MinimumQualification: string[];
        SalaryRange: string;
    }[];
}

interface CountrylistProps {
    country: Country;
}

const Countrylist: React.FC<CountrylistProps> = () => {

    const location = useLocation();
    const country = location.state as Country;

    console.log("countrylist:", country)
    console.log(country.id);
    const countryData = countries.find(x => x.id === country.id);
    console.log("data", countryData)
    return (
        <div className="country-container">
            <div className="">
                <div className="section about-section">
                    <div className="info-container">
                        <h2 className="font-bold text-3xl">Healthcare Jobs in {country.name}</h2>
                        <img src={countryData?.image} alt={countryData?.name} className="section-image" />
                        <div dangerouslySetInnerHTML={{ __html: countryData?.description || '' }} />
                        {/* <div className="clear-float"></div> */}
                    </div>

                    {/* <div className="image-container">
                        <img src={countryData?.image} alt={countryData?.name} className="section-image" />
                    </div> */}
                </div>

                <div className="second-section">
                    <div className='flex grid grid-cols-2'>
                        <div className='grid-cols-1 mr-10'>
                            <h2 className="section-title text-left font-bold">Requirements</h2>
                            <div className="section flex-1">
                                <div className="card card-style rounded-2xl">
                                    <ul className="qualification-list">
                                        <div dangerouslySetInnerHTML={{ __html: countryData?.benefits || '' }} />
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className='grid-cols-1'>
                            <h2 className="section-title text-left font-bold">Benefits</h2>
                            <div className="section">
                                <div className="card card-style rounded-2xl">
                                    <ul className="qualification-list">
                                        <div dangerouslySetInnerHTML={{ __html: countryData?.eligibility || '' }} />
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="nurse-salary-benefits-container">
                        <h2 className="section-title font-bold">Basic Salary</h2>
                        <table className="salary-benefits-table">
                            <thead>
                                <tr>
                                    <th>Designation</th>
                                    <th>Salary</th>
                                    <th>Minimum Requirements</th>
                                </tr>
                            </thead>
                            <tbody>
                                {countryData?.requirements.map((requirement, index) => (
                                    <tr key={index}>
                                        <td>{requirement.Designation}</td>
                                        <td>{requirement['SalaryRange(Annual)']}</td>
                                        <td>{requirement.MinimumQualification}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="contact-us-section">
                    {/* <h2>Contact Us</h2> */}
                    <p>If you have any questions or need assistance, feel free to reach out to us. We are here to help!</p>
                    <Link  className="contact-us-button" to="/contact-us">Contact Us</Link>
                </div>
            </div>
        </div>
    );
}

export default Countrylist;
