import React, { useEffect, useState } from "react";
import Eventshorcardslider from "components/Sections/event/eventshortcard/eventshortcardslider";
import JoinNetwork from "components/Sections/mobileappdownload/mobileappdownload";
import TestimonialSlider from "components/Sections/testimonal-comp/TestimonialSlider";
import backgroundfooter from "./images/Rectangle-4.webp";
import qrtera from "./images/mobqr.png";
import "./eventview.css";
import { useLocation } from "react-router-dom";
import { getEventDetailsApi } from "services/eventsService";
import { NumericFormat } from "react-number-format";
import { format, isAfter, isBefore, parseISO } from "date-fns";
import clsx from "clsx";
import Loading from "components/Sections/Loading/loading";

interface EventInformation {
  eventId: number;
  eventTitle: string;
  eventDate: string;
  organisingMemberName: string;
  organisingMemberProfession: string;
  thumbnail: string;
  broucherUrl: string;
  registrationFee: number;
  description: string;
  address: string;
  eventType: string;
  eventMode: string;
  registrationStartDate: string;
  registrationEndDate: string;
  eventEndDate: string;
  featuredEvents: [];
  departments: [];
}
const EventsView: React.FC = () => {
  const [eventInformation, setEventInformation] =
    useState<EventInformation | null>(null);

  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  const location = useLocation();
  useEffect(() => {
    getEventDetailsApi({
      id: location?.state.eventId,
    }).then((response) => {
      setEventInformation(response?.data);
      setIsLoaded(true);
    });
  }, [location?.state.eventId]);
  const formatEventDate = (dateTimeString: string) => {
    const eventDate = new Date(dateTimeString);

    const formattedDate = eventDate.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });

    return formattedDate;
  };
  const formatEventsDateTime = (dateTimeString: string) => {
    const eventDate = new Date(dateTimeString);
    const formattedTime = eventDate.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
    });
    return formattedTime;
  };

  if (!isLoaded) {
    return <Loading />;
  }
  return (
    <div className="event-page background-white">
      <div className="event-view-bannner container">
        <div className="event-view-inner">
          <div className="event-poster-img-main">
            {eventInformation?.thumbnail && (
              <img
                src={eventInformation?.thumbnail}
                style={{
                  overflow: "hidden",
                  width: "100%",
                  height: "420px",
                  overflowY: "hidden",
                  margin: "auto",
                  display: "block",
                  OObjectFit: "cover",
                  objectFit: "cover",
                }}
                alt=""
              ></img>
            )}
          </div>
          <div className="event-details-main-desc">
            <div className="orgainser-agenda">
              {/* <div className="org-logo-event">
                <img src={eventInformation?.thumbnail} alt="logo"></img>
              </div> */}
              <div className="org-agenda-event">
                <h1 className="">{eventInformation?.eventTitle} </h1>
              </div>
            </div>
            <span className="event-mode">
              {eventInformation?.eventType}-{eventInformation?.eventMode}
            </span>
            <div className="event-venue-date-time">
              <ul>
                <li className="montserrat">
                  {formatEventDate(eventInformation!.eventDate)}
                </li>
                {/* <li className="montserrat">
                  {formatEventsDateTime(eventInformation!.eventDate)}
                </li> */}
                <li className="montserrat">{eventInformation?.address}</li>
              </ul>
            </div>
            <div className="event-organiser-payment">
              <div className="event-organiser-details">
                {/* <div className="event-organiser-img">
                  <img src={eventInformation?.thumbnail} alt="Logo" />
                </div> */}
                <div className="event-organiser-names">
                  <h3 className="montserrat">
                    {eventInformation?.organisingMemberName}
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="23"
                        viewBox="0 0 24 23"
                        fill="none"
                      >
                        <path
                          d="M8.29091 22.9042L6.21818 19.4133L2.29091 18.5406L2.67273 14.5042L0 11.4497L2.67273 8.39512L2.29091 4.35875L6.21818 3.48603L8.29091 -0.00488281L12 1.57694L15.7091 -0.00488281L17.7818 3.48603L21.7091 4.35875L21.3273 8.39512L24 11.4497L21.3273 14.5042L21.7091 18.5406L17.7818 19.4133L15.7091 22.9042L12 21.3224L8.29091 22.9042ZM10.8545 15.3224L17.0182 9.15875L15.4909 7.57693L10.8545 12.2133L8.50909 9.92239L6.98182 11.4497L10.8545 15.3224Z"
                          fill="#00A0C0"
                        />
                      </svg>
                    </span>
                  </h3>
                  <h6 className="montserrat">
                    {eventInformation?.organisingMemberProfession}
                  </h6>
                </div>
              </div>
              <div className="booking-payment-details">
                {eventInformation?.broucherUrl && (
                  <a
                    className="cursor-pointer text-base float-right font-semibold text-blue-600"
                    href={eventInformation?.broucherUrl}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Brochure
                  </a>
                )}

                <h6 className="montserrat">Registration Fee</h6>
                <p className="montserrat">
                  {" "}
                  <NumericFormat
                    displayType="text"
                    value={eventInformation?.registrationFee}
                    thousandSeparator={true}
                    prefix={"₹"}
                  />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="joblistings-detail job-description">
          <div className="product-specification">
            <h2 className="product-specs-title-for-product-single for-list-prod montserrat">
              OVERVIEW
            </h2>
            <ul className="product-desc-list">
              <li className="specs-list-content montserrat">
                {eventInformation?.description}
              </li>
            </ul>
          </div>

          <div className="product-specification">
            <div className="timeline-slider">
              <h2 className="product-specs-title-for-product-single for-list-prod montserrat">
                KEY DATES
              </h2>
              <div>
                <ul className="timeline" id="timeline">
                  <li
                    className={clsx(
                      "li",
                      isAfter(
                        new Date(),
                        parseISO(eventInformation!.registrationStartDate)
                      ) && "complete"
                    )}
                  >
                    <div className="status"></div>
                    <div className="timestamp">
                      <span className="title">
                        {formatEventDate(
                          eventInformation!.registrationStartDate
                        )}
                      </span>
                      <span className="subtitle">Registration Open</span>
                    </div>
                  </li>
                  <li
                    className={clsx(
                      "li",
                      isAfter(
                        new Date(),
                        parseISO(eventInformation!.registrationEndDate)
                      ) && "complete"
                    )}
                  >
                    <div className="status"></div>
                    <div className="timestamp">
                      <span className="title">
                        {formatEventDate(eventInformation!.registrationEndDate)}
                      </span>
                      <span className="subtitle">Registration Ends</span>
                    </div>
                  </li>
                  <li
                    className={clsx(
                      "li",
                      isAfter(
                        new Date(),
                        parseISO(eventInformation!.eventDate)
                      ) && "complete"
                    )}
                  >
                    <div className="status"></div>
                    <div className="timestamp">
                      <span className="title">
                        {formatEventDate(eventInformation!.eventDate)}
                      </span>
                      <span className="subtitle">Event Start Date</span>
                    </div>
                  </li>
                  <li
                    className={clsx(
                      "li",
                      isAfter(
                        new Date(),
                        parseISO(eventInformation!.eventEndDate)
                      ) && "complete"
                    )}
                  >
                    <div className="status"></div>
                    <div className="timestamp">
                      <span className="title">
                        {formatEventDate(eventInformation!.eventEndDate)}
                      </span>
                      <span className="subtitle">Event End Date</span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="product-specification">
            <div className="timeline-slider">
              <h2 className="product-specs-title-for-product-single for-list-prod montserrat">
                TARGET AUDIENCE
              </h2>
              <div>
                <ul className="list-circle">
                  {eventInformation?.departments.map((item: any) => (
                    <li key={item.id} className="specs-list-content montserrat">
                      {item.name}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          {/* <div className="product-specification">
            <h2 className="product-specs-title-for-product-single for-list-prod montserrat">
              OUR SPEAKER
            </h2>
            <div className="speaker-card-holder">
              <div className="speaker-card">
                <img src="https://assets.gqindia.com/photos/645e034efc79052643f24e8e/16:9/w_1920,c_limit/Ratan-Tata.jpg" />
                <h3 className="montserrat">Arthur Mandelin Ii</h3>
                <p className="montserrat">MD, PhD, FACR, RhMSUS, RMSK</p>
                <h6 className="montserrat">Internal Medicine</h6>
              </div>
              <div className="speaker-card">
                <img src="https://assets.gqindia.com/photos/645e034efc79052643f24e8e/16:9/w_1920,c_limit/Ratan-Tata.jpg" />
                <h3 className="montserrat">Arthur Mandelin Ii</h3>
                <p className="montserrat">MD, PhD, FACR, RhMSUS, RMSK</p>
                <h6 className="montserrat">Internal Medicine</h6>
              </div>
              <div className="speaker-card">
                <img src="https://assets.gqindia.com/photos/645e034efc79052643f24e8e/16:9/w_1920,c_limit/Ratan-Tata.jpg" />
                <h3 className="montserrat">Arthur Mandelin Ii</h3>
                <p className="montserrat">MD, PhD, FACR, RhMSUS, RMSK</p>
                <h6 className="montserrat">Internal Medicine</h6>
              </div>
              <div className="speaker-card">
                <img src="https://assets.gqindia.com/photos/645e034efc79052643f24e8e/16:9/w_1920,c_limit/Ratan-Tata.jpg" />
                <h3 className="montserrat">Arthur Mandelin Ii</h3>
                <p className="montserrat">MD, PhD, FACR, RhMSUS, RMSK</p>
                <h6 className="montserrat">Internal Medicine</h6>
              </div>
            </div>
          </div> */}
        </div>
      </div>

      <div className="tabsteraconnects background-white">
        <div className="container">
          <h2 className="servicepages-headings montserrat font-medium lg:mb-20 mb-10">
            {" "}
            Featured<span className="font-extrabold"> Conferences</span>
          </h2>
          <div className="event-short-card-slider">
            <Eventshorcardslider
              perView={4}
              cmeEventsDetails={eventInformation?.featuredEvents ?? []}
            />
          </div>
        </div>
      </div>

      <div className="home-page-contact-us">
        <div className="home-page-last-wrapper">
          <div className="flex flex-col justify-center items-start container">
            <h2 className="font-sky-blue bolds title-custom montserrat text-start mb-5">
              Let’s Start Our Journey
            </h2>
            <h4 className="font-white regular title-custom montserrat mb-5">
              Discover how we can meet your healthcare needs
            </h4>
            <a href="#download-app-now" className="common-btn-custom blue-btn">
              Install App
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="44"
                  height="45"
                  viewBox="0 0 44 45"
                  fill="none"
                >
                  <rect width="44" height="45" rx="22" fill="none" />
                  <path
                    d="M22.5 28.25L15.3125 21.0625L17.325 18.9781L21.0625 22.7156V11H23.9375V22.7156L27.675 18.9781L29.6875 21.0625L22.5 28.25ZM11 34V26.8125H13.875V31.125H31.125V26.8125H34V34H11Z"
                    fill="white"
                  />
                </svg>
              </span>
            </a>
          </div>
          <div className="hame-last-image">
            <img src={backgroundfooter} />
          </div>
        </div>
      </div>

      <div className="appdownload-main" id="download-app-now">
        <JoinNetwork
          heading="Join Our Network"
          subheading="Discover the Power of Connection with Tera Connects - Where Your Medical Needs Find Their Perfect Match."
          googlePlayUrl="https://play.google.com/store/apps/details?id=com.teraplatforms.jobseeker"
          appStoreUrl="https://apps.apple.com/in/app/tera-recruit-job-search/id1659265164"
          imageUrl={qrtera}
        />
      </div>

      <div className="container background-white">
        <TestimonialSlider perView={1} />
      </div>
    </div>
  );
};

export default EventsView;
