import React, { useRef } from 'react';

interface PricingCardProps {
  price: string;
  label: string;
  labeltwo: string;
  features: string[];
  buttonUrl: string;
  crownData?: string;
  recommendedBadgeData?: string;
  scrollTarget?: string; // New prop for the scroll target
  scrollToSection?: () => void; // Callback to scroll to the section within tabs
}

const PricingCard: React.FC<PricingCardProps> = ({
  price,
  label,
  features,
  buttonUrl,
  labeltwo,
  crownData,
  recommendedBadgeData,
  scrollTarget = 'download-app-now',
  scrollToSection,
}) => {
  const targetRef = useRef<HTMLDivElement>(null);

  const handleButtonClick = () => {
    if (scrollToSection) {
      scrollToSection(); // Invoke the callback to scroll to the section within tabs
    } else if (targetRef.current) {
      targetRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="free-lableing-pricig">
      {/* Conditionally render the "crown" div if crownData is provided */}
      {crownData && (
        <div className="pricing-dynamic">
          <div className="crown">
            <p>
              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="27" viewBox="0 0 32 27" fill="none">
                <path d="M4.33748 20.0754L0.976562 2.05251L10.2191 10.2447L16.1007 0.414062L21.9823 10.2447L31.2248 2.05251L27.8639 20.0754H4.33748ZM27.8639 24.9908C27.8639 25.9738 27.1917 26.6292 26.1834 26.6292H6.01794C5.00966 26.6292 4.33748 25.9738 4.33748 24.9908V23.3523H27.8639V24.9908Z" fill="#F1B40A"/>
              </svg>
            </p>
          </div>
          {recommendedBadgeData && (
            <div className="recomended-badge">
              <p>{recommendedBadgeData}</p>
            </div>
          )}
        </div>
      )}

   

      <div className="box-free-pricing">
        <div className="group">
          <div className="text-wrapper-price montserrat">
            {price}
            <span className="div montserrat">/ {label}</span>
          </div>
          <div className="text-wrapper-below-price montserrat">{labeltwo}</div>
        </div>
      </div>

      <div className="label-pricing-card">
        <ul className="list-pricing-table">
          {features.map((feature, index) => (
            <li className='montserrat' key={index}>{feature}</li>
          ))}
        </ul>
      </div>

      <div className="button-pricing">
        <a href={buttonUrl} className="common-btn-custom blue-btn montserrat" onClick={handleButtonClick}>
          Get Now
        </a>
      </div>
    </div>
  );
};

export default PricingCard;
