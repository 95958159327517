import React, { useState, useEffect, useRef } from "react";
import Breadcrumb from "./breadCrumb";
import { Hidden } from "@material-ui/core";
import { FaTimes } from 'react-icons/fa';
import { MdMenuBook } from 'react-icons/md';
import { CgMenuGridR } from "react-icons/cg";
import ClinicianData from './data/clinician.json';
import { sub } from "date-fns";
import { useSet, useWindowSize } from "react-use";

const Clinician: React.FC = () => {
  const [activeSection, setActiveSection] = useState<string | null>(null);
  const [currentSectionIndex, setCurrentSectionIndex] = useState<number>(0);
  const [hoveredSection, setHoveredSection] = useState<string | null>(null);
  const manualSectionRefs = useRef<{ [key: string]: React.RefObject<HTMLDivElement> }>({});
  const [showSidebar, setShowSidebar] = useState(false);
  const [showContent, setShowContent] = useState(true);
  const [subSection, setSubSection] = useState<string | null>(null);
  const [currentSubsectionIndex, setCurrentSubsectionIndex] = useState<number>(0);
  const sidebarRef = useRef<HTMLDivElement>(null);
  const windowWidth = useWindowSize();
  const isMobile = windowWidth.width <= 768; 
  console.log(localStorage);
  const handleMenuButtonClick = () => {
    setShowContent(!showContent);
    setShowSidebar(!showSidebar);
  };

  const scrollToSection = (id: string) => {
    setTimeout(() => {
      const element = document.getElementById(id);
      if (element) {
        window.scrollTo(0, 0);
      }
    }, 100); // Adjust the delay as needed
  };

  const handleSectionClick = (sectionId: string, subsectionId: string) => {
    const section = ClinicianData.find(section => section.id === sectionId);
    if (section) {
      const subsectionIndex1 = section.subsections.findIndex(subsection => subsection.id === subsectionId);
      const sectionId = parseInt(section.id, 10);
      if (window.innerWidth <= 767) {
        setShowSidebar(false);
        setShowContent(!showContent);
      }
      if (subsectionIndex1 !== -1) {
        setCurrentSectionIndex(sectionId);
        setCurrentSubsectionIndex(subsectionIndex1);
        scrollToSection(subsectionId);
        setSubSection(subsectionId);
        setActiveSection(subsectionId);
        localStorage.setItem('activeSection', sectionId.toString());
        localStorage.setItem('activeSubsection', subsectionId);
      }
    }
  };

  const handleSectionHover = (id: string) => {
    setHoveredSection(id);
  };

  const handlePrevPage = () => {
    const newIndex = Math.max(currentSectionIndex - 1, 0);
    const newSection = ClinicianData[newIndex];
    setCurrentSectionIndex(newIndex);
    const firstSubsection = newSection.subsections[0].id;
    setCurrentSubsectionIndex(0);
    setActiveSection(firstSubsection);
    setSubSection(firstSubsection);
    localStorage.setItem('activeSection', newIndex.toString());
    localStorage.setItem('activeSubsection', firstSubsection);
    scrollToSection(firstSubsection);
  };
  const handleNextPage = () => {
    const newIndex = Math.min(currentSectionIndex + 1, ClinicianData.length - 1);
    const newSection = ClinicianData[newIndex];
    setCurrentSectionIndex(newIndex);
    const firstSubsection = newSection.subsections[0].id;
    setCurrentSubsectionIndex(0);
    setActiveSection(firstSubsection);
    setSubSection(firstSubsection);
    localStorage.setItem('activeSection', newIndex.toString());
    localStorage.setItem('activeSubsection', firstSubsection);
    scrollToSection(firstSubsection);
  };

  const handlePrevSubsection = () => {
    if (currentSubsectionIndex === 0 && currentSectionIndex > 0) {
      const prevSectionIndex = currentSectionIndex - 1;
      const prevSection = ClinicianData[prevSectionIndex];
      const lastSubsectionIndex = prevSection.subsections.length - 1;
      const lastSubsection = prevSection.subsections[lastSubsectionIndex];
      setCurrentSectionIndex(prevSectionIndex);
      setCurrentSubsectionIndex(lastSubsectionIndex);
      setActiveSection(lastSubsection.id);
      setSubSection(lastSubsection.id);
      localStorage.setItem('activeSection', prevSectionIndex.toString());
      localStorage.setItem('activeSubsection', lastSubsection.id);
      scrollToSection(lastSubsection.id);
    } else if (currentSubsectionIndex > 0) {
      const newSubsectionIndex = currentSubsectionIndex - 1;
      setCurrentSubsectionIndex(newSubsectionIndex);
      const newSubsection = ClinicianData[currentSectionIndex].subsections[newSubsectionIndex];
      setActiveSection(newSubsection.id);
      setSubSection(newSubsection.id);
      localStorage.setItem('activeSubsection', newSubsection.id);
      scrollToSection(newSubsection.id);
    }
  };

  const handleNextSubsection = () => {
    const currentSection = ClinicianData[currentSectionIndex];
    if (currentSubsectionIndex === currentSection.subsections.length - 1 && currentSectionIndex < ClinicianData.length - 1) {
      const nextSectionIndex = currentSectionIndex + 1;
      const nextSection = ClinicianData[nextSectionIndex];
      const firstSubsection = nextSection.subsections[0];
      setCurrentSectionIndex(nextSectionIndex);
      setCurrentSubsectionIndex(0);
      setActiveSection(firstSubsection.id);
      setSubSection(firstSubsection.id);
      localStorage.setItem('activeSection', nextSectionIndex.toString());
      localStorage.setItem('activeSubsection', firstSubsection.id);
      scrollToSection(firstSubsection.id);
    } else if (currentSubsectionIndex < currentSection.subsections.length - 1) {
      const newSubsectionIndex = currentSubsectionIndex + 1;
      setCurrentSubsectionIndex(newSubsectionIndex);
      const newSubsection = ClinicianData[currentSectionIndex].subsections[newSubsectionIndex];
      setActiveSection(newSubsection.id);
      setSubSection(newSubsection.id);
      localStorage.setItem('activeSubsection', newSubsection.id);
      scrollToSection(newSubsection.id);
    }
  };

  const handleCloseSidebar = () => {
    setShowContent(true);
    setShowSidebar(false);
  };

  useEffect(() => {
    localStorage.removeItem("activeSection")
    localStorage.removeItem("activeSubSection")
  }, [])

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const clickedElement = event.target as HTMLElement;

      if (
        sidebarRef.current &&
        !sidebarRef.current.contains(clickedElement) &&
        !clickedElement.classList.contains('contenttab') &&
        !clickedElement.closest('.contenttab')
      ) {
        setShowContent(true);
        setShowSidebar(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // useEffect(() => {
  //   const storedActiveSection = localStorage.getItem('activeSection');
  //   setActiveSection(storedActiveSection || (ClinicianData[0].subsections)[0].id);
  // }, []);




  useEffect(() => {
    const storedActiveSection = localStorage.getItem('activeSection');
    const storedActiveSubsection = localStorage.getItem('activeSubsection');
    if (storedActiveSection && storedActiveSubsection) {
      const sectionIndex = parseInt(storedActiveSection, 10);
      const subsectionIndex = ClinicianData[sectionIndex].subsections.findIndex(sub => sub.id === storedActiveSubsection);
      if (subsectionIndex !== -1) {
        setCurrentSectionIndex(sectionIndex);
        setCurrentSubsectionIndex(subsectionIndex);
        setActiveSection(storedActiveSection || (ClinicianData[0].subsections)[0].id);
        setActiveSection(storedActiveSubsection);
        setSubSection(storedActiveSubsection);
        scrollToSection(storedActiveSubsection);
      }
    } else {
      const firstSection = ClinicianData[0];
      const firstSubsection = firstSection.subsections[0];
      setCurrentSectionIndex(0);
      setCurrentSubsectionIndex(0);
      setActiveSection(firstSubsection.id);
      setSubSection(firstSubsection.id);
      scrollToSection(firstSubsection.id);
    }
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      for (const sectionId in manualSectionRefs.current) {
        const sectionRef = manualSectionRefs.current[sectionId];
        if (sectionRef.current && isElementInViewport(sectionRef.current)) {
          setActiveSection(sectionId);
          break;
        }
      }
    };
    document.addEventListener('scroll', handleScroll);

    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="background-white">
      <div className={`menu-button-wrapper top-40px bg-white ${showSidebar ? 'hidden' : ''}`}>
        <button className="menu-button" onClick={handleMenuButtonClick}>
          <CgMenuGridR />
        </button>
      </div>

      <div className="md:flex">
        <div ref={sidebarRef} className={`sticky top-0 left-0 h-screen w-64 overflow-y-scroll overflow-x-hidden py-12 pl-0.5 pr-8 xl:w-72 xl:pr-16 bg-gray-100 ${showSidebar ? 'block' : 'hidden'} md:block`}>
          {showSidebar && (
            <div className="menu-close-button p-0 m-0 flex justify-end" onClick={handleCloseSidebar}>
              <FaTimes />
            </div>
          )}
          <div className="ml-12">
            {ClinicianData.map((section, index) => (
              <div key={index}>
                <h3 className="header font-semibold">{section.title}</h3>
                {section ?
                  <ul>
                    {section.subsections.map(subsection => (
                      <li
                        key={subsection.id}
                        className={`sidebar-item ${activeSection === subsection.id ? 'active' : ''}`}
                        onClick={() => {
                          handleSectionClick(section.id, subsection.id);
                        }}
                        onMouseEnter={() => setHoveredSection(subsection.id)}
                        onMouseLeave={() => setHoveredSection(null)}
                      >
                        <span className={`line ${activeSection === subsection.id ? 'active' : ''}`}></span>
                        <button style={{ color: activeSection === subsection.id ? 'blue' : 'black' }}>{subsection.title}</button>
                        {activeSection === subsection.id && <span className="dot"></span>}
                        {hoveredSection === subsection.id && <span className="dot" style={{ backgroundColor: 'grey' }}></span>}
                      </li>
                    ))}
                  </ul>
                  : null
                }
              </div>
            ))}
          </div>
        </div>

        <div className={`w-full md:w-3/4 p-10 mr-4 sticky flex-1 ${!showContent ? 'hidden' : ''} contenttab`}>
          <div className="background-white">
            <div className="breadcrumbtab">
              <Breadcrumb ClinicianData={ClinicianData} currentSectionIndex={currentSectionIndex} setCurrentSectionIndex={setCurrentSectionIndex} currentPage="clinicians" currentSubSection={currentSubsectionIndex} />
            </div>
            <div className="content ml-4">
              <div key={currentSectionIndex} id={`section${currentSectionIndex + 1}`} ref={manualSectionRefs.current[`section${currentSectionIndex + 1}`]}>
                {ClinicianData[currentSectionIndex].subsections.map((subsection, index) => (
                  <div key={subsection.id} id={subsection.id} style={{ display: activeSection === subsection.id ? 'block' : 'none' }} className="insidecontent">
                    <h1 className='mt-4 text-2xl font-semibold' style={{ textAlign: "left" }}>{subsection.title}</h1>
                    {/* {subsection.videoLink && (
                      <div className='mb-4'>
                        <h3 className='mb-2'>Video Title</h3>
                        <iframe width="300" height="215" src={subsection?.videoLink} title="YouTube video" allowFullScreen></iframe>
                      </div>
                    )} */}
                    <p className='mt-2'>
                      <div dangerouslySetInnerHTML={{ __html: ClinicianData[currentSectionIndex].content }} />
                    </p>
                    <p className=''>
                      <div dangerouslySetInnerHTML={{ __html: subsection.content }} />
                    </p>
                  </div>
                ))}
              </div>
            </div>
            {/* <div className="buttons w-full pt-8 flex justify-between">
              {(currentSubsectionIndex !== 0 || currentSectionIndex !== 0) && (
                <button className="btn-1 absolute left-10 pl-4 bottom-10" onClick={handlePrevSubsection}>
                  <span className="mr-1">&lt;</span>
                  <span className="navigation_button" style={{ fontWeight: "500" }}>{currentSubsectionIndex === 0 ? ClinicianData[currentSectionIndex - 1].subsections[ClinicianData[currentSectionIndex - 1].subsections.length - 1].title : ClinicianData[currentSectionIndex].subsections[currentSubsectionIndex - 1].title}</span>
                </button>
              )}
              {(currentSubsectionIndex !== ClinicianData[currentSectionIndex].subsections.length - 1 || currentSectionIndex !== ClinicianData.length - 1) && (
                <button className="btn-2 absolute right-10 pr-8 bottom-10" onClick={handleNextSubsection}>
                  <span className="navigation_button" style={{ fontWeight: "500" }}>{currentSubsectionIndex === ClinicianData[currentSectionIndex].subsections.length - 1 ? ClinicianData[currentSectionIndex + 1].subsections[0].title : ClinicianData[currentSectionIndex].subsections[currentSubsectionIndex + 1].title}</span>
                  <span className="ml-1">&gt;</span>
                </button>
              )}
            </div> */}
             <div className="buttons w-full pt-8 flex justify-between">
      {(currentSubsectionIndex !== 0 || currentSectionIndex !== 0) && (
        <button
          className="btn-1 absolute left-10 pl-4 bottom-10"
          onClick={handlePrevSubsection}
        >
          <span className="mr-1">&lt;</span>
          <span className="navigation_button" style={{ fontWeight: '500' }}>
            {isMobile
              ? 'Back'
              : currentSubsectionIndex === 0
              ? ClinicianData[currentSectionIndex - 1].subsections[ClinicianData[currentSectionIndex - 1].subsections.length - 1].title
              : ClinicianData[currentSectionIndex].subsections[currentSubsectionIndex - 1].title}
          </span>
        </button>
      )}
      {(currentSubsectionIndex !== ClinicianData[currentSectionIndex].subsections.length - 1 || currentSectionIndex !== ClinicianData.length - 1) && (
        <button
          className="btn-2 absolute right-10 pr-8 bottom-10"
          onClick={handleNextSubsection}
        >
          <span className="navigation_button" style={{ fontWeight: '500' }}>
            {isMobile
              ? 'Next'
              : currentSubsectionIndex === ClinicianData[currentSectionIndex].subsections.length - 1
              ? ClinicianData[currentSectionIndex + 1].subsections[0].title
              : ClinicianData[currentSectionIndex].subsections[currentSubsectionIndex + 1].title}
          </span>
          <span className="ml-1">&gt;</span>
        </button>
      )}
    </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Clinician;

function isElementInViewport(el: HTMLElement) {
  const rect = el.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}
