import React, { useState, useEffect } from "react";
import Filter from "components/Sections/Filtercomp/filter";
import Pricerangeslider from "components/Sections/productlist/filter/pricerange/pricerange";
import Checkbox from "components/Sections/productlist/filter/checkboxfilter/checkbox";
import Checkboxbranddata from "components/Sections/productlist/filter/checkboxfilter/checkboxbrand.json";
import Checkboxtypedata from "components/Sections/productlist/filter/checkboxfilter/checkboxtype.json";
import Checkboxcapacitydata from "components/Sections/productlist/filter/checkboxfilter/checkboxcapacity.json";
import ProductList, {
  Product,
} from "components/Sections/productlist/productcard";
import productsData from "components/Sections/productlist/productlist.json";
import Pagination from "components/Sections/productlist/filter/pagination/pagination";
import Categorylist from "components/Sections/productlist/filter/Categoryfilter/Categoryfilter";
import Acoordianforcomponent from "components/Sections/Accordianforfilter/Accordianforfilter";
import Categoryfilterslider from "components/Sections/productlist/filter/Categoryfilter/categoryslider";

import "./shop.css";
import { getListings } from "services/buyAndSellService";
const Shop = () => {
  const [latestProducts, setLatestProducts] = useState<any>({
    data: [],
    totalCount: 0,
  });
  const itemsPerPage = 24;
  const [currentPage, setCurrentPage] = useState<number>(1);

  const totalProducts = latestProducts.totalCount;
  const totalPages = Math.ceil(totalProducts / itemsPerPage);

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  // const indexOfLastProduct = currentPage * itemsPerPage;
  // const currentProducts = latestProducts.data;
  useEffect(() => {
    getListings({
      orderBy: "UpdatedDate",
      orderByDirection: "DESC",
      pageNumber: 1,
      pageSize: 24,
      searchText: "",
      condition: [0],
      listingType: [0],
      brand: [0],
      subcategory: [0],
      category: [0],
      priceRange: {
        min: 0,
        max: 0,
      },
    }).then((response) => {
      setLatestProducts(response.data);
    });
  }, []);
  return (
    <div className="page-shop">
      <div className="container">
        <div className="filter-section">  
          {/* <Filter> */}
            {/* <Acoordianforcomponent title="Related Categories">
              <Categorylist />
            </Acoordianforcomponent> */}

            {/* <Acoordianforcomponent title="Price">
              <Pricerangeslider />
            </Acoordianforcomponent> */}

            {/* <Acoordianforcomponent title="Type">
              <Checkbox data={Checkboxtypedata} />
            </Acoordianforcomponent>

            <Acoordianforcomponent title="Brand">
              <Checkbox data={Checkboxbranddata} />
            </Acoordianforcomponent>

            <Acoordianforcomponent title="Capacity">
              <Checkbox data={Checkboxcapacitydata} />
            </Acoordianforcomponent> */}
          {/* </Filter> */}
        </div>

        <div className="shop-content-section">
          <div className="shop-right-inner-container">
            <div className="inner-category-list">
              <div className="prod-listing-four">
                <h1 className="montserrat buysellheading">Medical Equipment</h1>
              </div>

              <Categoryfilterslider />
            </div>

            <div className="inner-productlist">
              <div className="prod-listing-four">
                <h1 className="montserrat buysellheading">Resuscitator</h1>
              </div>
              <ProductList products={latestProducts?.data} />
            </div>

            <div className="inner-pagination">
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Shop;
