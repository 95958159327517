// Filter.tsx

import React, { useState, ReactNode } from 'react';
import './Filter.css';

interface FilterProps {
  children: ReactNode;
}

const Filter: React.FC<FilterProps> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleFilter = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={`filter-container ${isOpen ? 'open' : ''}`}>
      <button onClick={toggleFilter}><span className='filter-svg'><svg width="100%" height="100%" viewBox=' 0 0 100 100' xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"><g data-name="Layer 2"><path d="M39,53.2a8.17,8.17,0,0,0-5.51-4.59V28a2,2,0,1,0-4,0V48.6a9.27,9.27,0,0,0-1.4.48,8.24,8.24,0,0,0,1.4,15.5V72a2,2,0,1,0,4,0V64.59a8.18,8.18,0,0,0,5.72-5.08A8.17,8.17,0,0,0,39,53.2Zm-3.53,4.9a4.25,4.25,0,1,1-5.73-5.37,4.14,4.14,0,0,1,1.74-.38,4.26,4.26,0,0,1,3.88,2.5A4.21,4.21,0,0,1,35.49,58.1Z"/><path d="M76,53.2a8.17,8.17,0,0,0-5.51-4.59V28a2,2,0,0,0-4,0V48.6a9.27,9.27,0,0,0-1.4.48,8.24,8.24,0,0,0,1.4,15.5V72a2,2,0,0,0,4,0V64.59a8.18,8.18,0,0,0,5.72-5.08A8.17,8.17,0,0,0,76,53.2Zm-3.53,4.9a4.25,4.25,0,1,1-5.73-5.37,4.17,4.17,0,0,1,1.74-.38,4.26,4.26,0,0,1,3.88,2.5A4.21,4.21,0,0,1,72.47,58.1Z"/><path d="M52.9,36c-.3-.11-.6-.2-.91-.28V28a2,2,0,0,0-4,0v7.75a8.26,8.26,0,0,0-5.51,11.4,8.2,8.2,0,0,0,4.6,4.32c.3.11.61.2.91.28V72a2,2,0,0,0,4,0V51.74a7.71,7.71,0,0,0,1.4-.48A8.24,8.24,0,0,0,52.9,36ZM54,45.25a4.25,4.25,0,0,1-7.85.25,4.25,4.25,0,0,1,3.87-6,4.32,4.32,0,0,1,1.5.27A4.22,4.22,0,0,1,53.86,42h0A4.21,4.21,0,0,1,54,45.25Z"/></g></svg></span>
                   <span className='fiter-text'>Filter</span></button>
      <div className="filter-content">
        {children}
      </div>
    </div>
  );
};

export default Filter;
