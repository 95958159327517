// src/components/ProductList.js

import React from 'react';
import './productcard.css'
export type Product = {
    id: number;
    thumbnailUrl: string;
    title: string;
    price: number;
    moq: number;
    pickUpAddress: string;
    brand: string;
    url: string;
  };
  

type ProductListProps = {
  products: Product[];
};

const ProductList: React.FC<ProductListProps> = ({ products }) => {
  return (
    <div className='product-list-main'>
      {products.map(product => (
        <div key={product.id} className="product-item">
        <div className='prod-image-div'>
          <img className='product-image-list' src={product.thumbnailUrl} alt={product.title} />
        </div>
        <div className='prod-desc-div'>
          <h3 className='product-title montserrat'>{product.title}</h3>
          <p className='price-list montserrat'> Price: ₹ {product.price?.toFixed(2)}</p>
          <p className='moq-list montserrat'>MOQ- {product.moq}</p>
          <div className='locationandbrand'>
          <p className='locationinlocation'>
            <span className='svg-location'>
            <svg xmlns="http://www.w3.org/2000/svg" width="8" height="13" viewBox="0 0 8 13" fill="none">
                <path d="M3.84487 6.21787C3.48068 6.21787 3.13141 6.05873 2.8739 5.77546C2.61638 5.49219 2.4717 5.10799 2.4717 4.70739C2.4717 4.30678 2.61638 3.92258 2.8739 3.63931C3.13141 3.35604 3.48068 3.1969 3.84487 3.1969C4.20906 3.1969 4.55833 3.35604 4.81585 3.63931C5.07337 3.92258 5.21804 4.30678 5.21804 4.70739C5.21804 4.90575 5.18252 5.10216 5.11351 5.28542C5.04451 5.46869 4.94336 5.6352 4.81585 5.77546C4.68834 5.91572 4.53696 6.02698 4.37036 6.10289C4.20376 6.1788 4.0252 6.21787 3.84487 6.21787ZM3.84487 0.478027C2.82515 0.478027 1.84719 0.923619 1.12614 1.71678C0.405084 2.50994 0 3.58569 0 4.70739C0 7.87941 3.84487 12.5619 3.84487 12.5619C3.84487 12.5619 7.68974 7.87941 7.68974 4.70739C7.68974 3.58569 7.28466 2.50994 6.56361 1.71678C5.84255 0.923619 4.8646 0.478027 3.84487 0.478027Z" fill="#1188A8"/>
            </svg>
            </span>
            <span className='location-span'>{product.pickUpAddress}</span>
          </p>
          <p className='brandinlocation montserrat'>{product.brand}</p>
          </div>

          </div>
          {/* Use an anchor tag with the dynamic href attribute from the JSON data */}
          <div className='prod-send-inquiry-div'>
          <a href={product.url} className="enquiry-link" target="_blank" rel="noopener noreferrer">
           <span className='send-inquiry-button montserrat'>send inquiry</span>
           <span className='send-inquiry-svg'>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path d="M15 1L10.1 15L7.3 8.7L1 5.9L15 1Z" stroke="#E3ECFF" stroke-width="1.47368" stroke-linejoin="round"/>
                    <path d="M15.0008 1L7.30078 8.7" stroke="#E3ECFF" stroke-width="1.47368" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            </span>
          </a>
        </div>

        </div>
      ))}
    </div>
  );
};

export default ProductList;
