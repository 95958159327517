import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, FormProvider, useForm } from "react-hook-form";
import ClientImage from "../../../images/hero-right-2.png";
import { Step } from "./contactform";
import "./locationform.css";
import "./contactform.css";
import Label from "components/Label/Label";
import Textarea from "components/Textarea/Textarea";
import { contactUsApi } from "services/contactService";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { useState } from "react";
import ClientImg from './images/Client-Other-svg.svg'
import ClinicianImg from "./images/Clinician-Other-svg.svg"
import PartnerImg from "./images/Partner-Other-svg.svg"
import RecruitmentImg from "./images/RA-Other-svg.svg"

interface PersonalDetailsProps {
  activeStep: any;
  setActiveStep: any;
  steps: Step[];
  servicesDetails: any;
  userData: any;
  onUserDataChange: (data: any) => void;
}

const schema = yup.object().shape({
  notes: yup
    .string()
    .trim()
    .nullable()
    .required("You must enter a notes")
    .test(
      "notes-length",
      "The notes must be between 50 and 1000 characters",
      function (value) {
        if (!value) {
          return true;
        }
        const length = value.trim().replace(/<[^>]+>/g, "")?.length;
        const isWithinUpperLimit = length <= 500;
        const isWithinLowerLimit = length >= 50;
        return isWithinLowerLimit && isWithinUpperLimit;
      }
    ),
});

const AddMessageForm: React.FC<PersonalDetailsProps> = ({
  activeStep,
  setActiveStep,
  steps,
  userData,
  servicesDetails,
}) => {
  const navigate = useNavigate();
  const [agreed, setAgreed] = useState(false);
  const showCaptcha = window.location.hostname !== "localhost";
  const siteKey: string = process.env.REACT_APP_MAP_KEY ?? "";
  const [captchaVerified, setCaptchaVerified] = useState(
    window.location.hostname === "localhost"
  );
  const methods = useForm({
    mode: "onChange",
    defaultValues: {},
    resolver: yupResolver(schema),
  });

  const { control, formState, getValues, handleSubmit } = methods;
  const { errors } = formState;

  const handleMessageAndSubmit = () => {
    let values = getValues();
    if (!agreed) {
      alert("Please agree to the terms and conditions.");
      return;
    }
    const payload = {
      organizationTypeID: servicesDetails.orgTypeData?.organizationTypeID || 0,
      name: servicesDetails.name,
      cityID: servicesDetails.cityID?.value || 0,
      stateID: servicesDetails.stateID?.value || 0,
      seekingServices: servicesDetails.seekingServices || "",
      notes: values.notes,
      contactPhone: servicesDetails.contactPhone,
      contactEmail: servicesDetails.contactEmail,
      userTypeID: servicesDetails.orgTypeData?.userTypeID,
    };

    contactUsApi(payload)
      .then(() => {
        Swal.fire({
          title: "Success!",
          text: "Your request is submitted",
          icon: "success",
        }).then(() => {
          navigate("/");
        });
      })
      .catch(() => {
        Swal.fire({
          title: "Error!",
          text: "Your request is not submitted, Try after sometime",
          icon: "error",
        });
      });
  };
  const handleAgreementChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAgreed(e.target.checked);
  };
  const handleCaptchaChange = (value: any) => {
    setCaptchaVerified(() => true);
  };

  return (
    <FormProvider {...methods}>
      <>
        <div className="flex gap-9 add-msg-wrap">
          <div className="w-2/3">
            <div className="mt-0">
              <h1
                style={{
                  color: "white",
                  fontSize: "30px",
                  paddingTop: "18px",
                  paddingBottom: "18px",
                }}
              >
                Your message{" "}
              </h1>

              <label className="block">
                {/* <Label>Message</Label> */}
                <Controller
                  name="notes"
                  control={control}
                  render={({ field }) => (
                    <>
                      <Textarea
                        className="mt-1"
                        {...field}
                        autoFocus
                        placeholder="Enter Your Message"
                        required
                        rows={9}
                      />
                      <p className="error">
                        {errors.notes && errors.notes.message}
                      </p>
                    </>
                  )}
                />
              </label>
            </div>
          </div>
          <div className="w-1/3 images-for-add-message">
            {/* <img src={ClientImg} alt="Your Image" /> */}
            {Number(userData.orgTypeData?.userTypeID) === 4 && <img src={ClinicianImg} alt="" className=""/>}
            {Number(userData.orgTypeData?.userTypeID) === 5 && <img src={ClientImg} alt="" />}
            {Number(userData.orgTypeData?.userTypeID) === 6 && <img src={PartnerImg} alt="" />}
            {Number(userData.orgTypeData?.userTypeID) === 575 && <img src={RecruitmentImg} alt="" />}
          </div>
        </div>
        <div className="w-full captcha-verify m-6">
          <label className="">
            <input
              type="checkbox"
              checked={agreed}
              onChange={handleAgreementChange}
              className="tickbox"
              required
            />
            <span className="text-white ml-3">I agree to the </span>
            <a className="tnc-aaply" href="/terms-&-conditions">
              Terms & Conditions
            </a>{" "}
            <span className="text-white">of Tera Connects.</span>
          </label>
        </div>
        <div className="w-full flex inside-full ml-5">
          {showCaptcha && (
            <ReCAPTCHA
              sitekey={siteKey}
              onChange={handleCaptchaChange}
              onExpired={() => setCaptchaVerified(() => false)}
            />
          )}
        </div>

        <div className="button-container">
          {Number(userData.orgTypeData.userTypeID) !== 5
            ? activeStep !== 0 && (
                <button
                  onClick={() => setActiveStep(activeStep - 2)}
                  className="button flex gap-2"
                >
                  <svg
                    className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 10"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M13 5H1m0 0l4-4m-4 4l4 4"
                    />{" "}
                  </svg>
                  Previous
                </button>
              )
            : activeStep !== 0 && (
                <button
                  onClick={() => setActiveStep(activeStep - 1)}
                  className="button flex gap-2"
                >
                  <svg
                    className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 10"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M13 5H1m0 0l4-4m-4 4l4 4"
                    />
                  </svg>
                  Previous
                </button>
              )}
          {/* {activeStep === steps.length - 1 && ( */}
            <button
              disabled={captchaVerified === false}
              onClick={() => {
                handleSubmit(handleMessageAndSubmit)();
              }}
              className="button"
            >
              Submit
            </button>
          {/* )} */}
        </div>
      </>
    </FormProvider>
  );
};
export default AddMessageForm;
