import "./License.css";
import licenseData from "../../data/jsons/__licenses.json";

const Licenses = () => {
  return (
    <div className="container lg:flex head-home-sec gap-extra">
      <div className="nurse-salary-benefits-container">
        <h2 className="section-title font-bold px-3">
          LICENSES REQUIRED FOR HOSPITAL
        </h2>
        <table className="salary-benefits-table px-3 text-sm">
          <thead>
            <tr>
              <th>Sr_No.</th>
              <th>Licenses</th>
              <th>Issuing auth</th>
              <th>Remark</th>
            </tr>
          </thead>
          <tbody className="align-top">
            {licenseData?.map((requirement, index) => (
              <tr key={index}>
                <td>{requirement["Sr_No."]}</td>
                <td>{requirement.Licenses}</td>
                <td>{requirement["Issuing auth"]}</td>
                <td>{requirement.Remark}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Licenses;
