import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
interface CheckboxItem {
  value: string;
  label: string;
}
interface handleFilterJobsBasesOnStatesProps {
  handleFilterJobsBasedOnCompanies: (
    selectedCategoryId: any,
    selectedCategoryIds: string[],
    selectedCheckboxesValuesOfStates: any,
    experienceRange: any,
    salaryRange: any
  ) => void;
  selectedCategoryIdValue: any;
  experienceRange: any;
  selectedSalaryRange: any;
  selectedCheckboxesValuesOfStates: any;
  data: CheckboxItem[];
  selectedBranchIdFromTopCompaniesFilter: any;
}

const CheckboxCompaniesFilter: React.FC<handleFilterJobsBasesOnStatesProps> = ({
  handleFilterJobsBasedOnCompanies,
  selectedCategoryIdValue,
  selectedCheckboxesValuesOfStates,
  experienceRange,
  selectedSalaryRange,
  data,
  selectedBranchIdFromTopCompaniesFilter,
}) => {
  const [showAll, setShowAll] = useState(false);
  const [selectedCompaniesCheckboxes, setSelectedCompaniesCheckboxes] =
    useState<string[]>([]);
  const navigate = useNavigate();
  const visibleOptions = showAll ? data : data.slice(0, 4);
  React.useEffect(() => {
    if (selectedBranchIdFromTopCompaniesFilter) {
      setSelectedCompaniesCheckboxes([selectedBranchIdFromTopCompaniesFilter]);
    }
  }, [selectedBranchIdFromTopCompaniesFilter]);

  const handleCheckboxChange = (id: string) => {
    const updatedSelectedCheckboxes = [...selectedCompaniesCheckboxes];

    if (updatedSelectedCheckboxes.includes(id)) {
      updatedSelectedCheckboxes.splice(
        updatedSelectedCheckboxes.indexOf(id),
        1
      );
    } else {
      updatedSelectedCheckboxes.push(id);
    }
    setSelectedCompaniesCheckboxes(updatedSelectedCheckboxes);
    navigate(
      `/jobs/jobs-listing/?selectedCategory=${selectedCategoryIdValue}&selectedExperience=${
        experienceRange?.max || 50
      }&selectedSalaryRange=${selectedSalaryRange?.max}&selectedStates=${
        selectedCheckboxesValuesOfStates || []
      }&selectedOrganizations=${updatedSelectedCheckboxes || []}`
    );
    handleFilterJobsBasedOnCompanies(
      selectedCategoryIdValue,
      selectedCheckboxesValuesOfStates,
      experienceRange || { min: 0, max: 0 },
      selectedSalaryRange || { min: 0, max: 0 },
      updatedSelectedCheckboxes
    );
  };

  const handleShowMoreToggle = () => {
    setShowAll(!showAll);
  };

  return (
    <div className="checkbox-filter">
      {visibleOptions.map((item) => (
        <React.Fragment key={item.value}>
          <div className="checkbox-items">
            <input
              type="checkbox"
              id={item.value}
              className="filter-checkbox h-18 w-18  border-neutral-700 rounded-md"
              style={{ borderRadius: "4px" }}
              checked={selectedCompaniesCheckboxes.includes(item.value)}
              onChange={() => handleCheckboxChange(item.value)}
            />
            <label htmlFor={item.value} className="checkbox-label">
              {item.label}
            </label>
          </div>
        </React.Fragment>
      ))}

      {!showAll && data?.length > 4 && (
        <div className="text-right ml-12">
          <span
            onClick={handleShowMoreToggle}
            className="inline-block cursor-pointer text-base font-semibold text-blue-500"
          >
            View More...
          </span>
        </div>
      )}
    </div>
  );
};

export default CheckboxCompaniesFilter;
