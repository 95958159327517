// import { CustomLink } from "data/types";
// import React, { FC } from "react";
// import twFocusClass from "utils/twFocusClass";
// import Link from "components/Link";

// const DEMO_PAGINATION: CustomLink[] = [
//   {
//     label: "1",
//     href: "/",
//   },
//   {
//     label: "2",
//     href: "/",
//   },
//   {
//     label: "3",
//     href: "/",
//   },
//   {
//     label: "4",
//     href: "/",
//   },
// ];

// export interface PaginationProps {
//   className?: string;
//   onPageChange: (pageNumber: number) => void;
// }

// const Pagination: FC<PaginationProps> = ({ className = "", onPageChange }) => {
//   const renderItem = (pag: CustomLink, index: number) => {
//     if (index === 0) {
//       // RETURN ACTIVE PAGINATION
//       return (
//         <span
//           key={index}
//           className={`inline-flex w-11 h-11 items-center justify-center rounded-full bg-primary-6000 text-white ${twFocusClass()}`}
//         >
//           {pag.label}
//         </span>
//       );
//     }
//     // RETURN UNACTIVE PAGINATION
//     return (
//       <Link
//         key={index}
//         className={`inline-flex w-11 h-11 items-center justify-center rounded-full bg-white hover:bg-neutral-100 border border-neutral-200 text-neutral-6000 dark:text-neutral-400 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:border-neutral-700 ${twFocusClass()}`}
//         // href={pag.href}
//         onClick={() => onPageChange(Number(pag.label))} href={""}
//       >
//         {pag.label}
//       </Link>
//     //   <button
//     //     key={index}
//     //     className={`inline-flex w-11 h-11 items-center justify-center rounded-full bg-white hover:bg-neutral-100 border border-neutral-200 text-neutral-6000 dark:text-neutral-400 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:border-neutral-700 ${twFocusClass()}`}
//     //     onClick={() => onPageChange(Number(pag.label))}
//     //   >
//     //     {pag.label}
//     //   </button>
//     );
//   };

//   return (
//     <nav
//       className={`nc-Pagination inline-flex space-x-1 text-base font-medium ${className}`}
//     >
//       {DEMO_PAGINATION.map(renderItem)}
//     </nav>
//   );
// };

// export default Pagination;




import React from 'react';
import './pagination.css';

interface PaginationProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (newPage: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({ currentPage, totalPages, onPageChange }) => {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (

    <div className='pagination-btn'>
      {totalPages > 0 ?
        <>
          <button className='prev-next prev' onClick={() => { onPageChange(currentPage - 1); scrollToTop(); }} disabled={currentPage === 1}>
            Previous
          </button>
          {/* Render page numbers */}
          {Array.from({ length: totalPages }, (_, index) => (
            <button className="numer-btn" key={index + 1} onClick={() => { onPageChange(index + 1); scrollToTop(); }} disabled={currentPage === index + 1}>
              {index + 1}
            </button>
          ))}
          <button className="prev-next next" onClick={() => { onPageChange(currentPage + 1); scrollToTop(); }} disabled={currentPage === totalPages}>
            Next
          </button>
        </>
        : ''}
    </div>
  );
};

export default Pagination;

