import React, { useState, useEffect, useRef } from "react";
import { FaTimes } from 'react-icons/fa';
import { CgMenuGridR } from "react-icons/cg";
import "./offering.css";
import { useLocation, useParams } from "react-router-dom";
import Content from "../data/content.json";
import ProgramData from "../data/program.json";
import clsx from "clsx";
import Breadcrumb from "../breadcrumb";
import { FaChevronDown } from 'react-icons/fa';
import { number } from "yup";
import { useWindowSize } from "react-use";

interface Courses {
  programId: number;
  courseId: number;
  courseName: string;
  duration: string;
}

interface ContentFields {
  contentCourseId: number;
  contentId: number;
  contentTitle: string;
  sections: Section[];
}

interface SectionWithSubsectionProps {
  section: Section;
  activeCourseSection: string | null;
  handleSectionClick1: (id1: number, id: number) => void;
}

interface Section {
  sectionId: number;
  sectiontitle: string;
  sectionContent: string;
  subsections: Subsection[];
}

interface Subsection {
  subsectionId: number;
  subsectiontitle: string;
  subsectionContent: string;
}

const CourseDetail: React.FC = () => {
  const [activeCourseSection, setactiveCourseSection] = useState<string | null>(null);
  const [courseCurrentSectionIndex, setCourseCurrentSectionIndex] = useState<number>(1002);
  const [scrollbarWidth, setScrollbarWidth] = useState<number>(0);
  const [hoveredSection, setHoveredSection] = useState<string | null>(null);
  const sectionRefs = useRef<{ [key: string]: React.RefObject<HTMLDivElement> }>({});
  const [showSidebar, setShowSidebar] = useState(false);
  const [courseInfo, setCourseInfo] = useState<Courses[]>([]);
  const [showContent, setShowContent] = useState(true);
  const sidebarRef = useRef<HTMLDivElement>(null);
  const [showSubsections, setShowSubsections] = React.useState<boolean>(false);
  const [currentSubsectionIndex, setCurrentSubsectionIndex] = useState<number>(0);
  const windowWidth = useWindowSize();
  const isMobile = windowWidth.width <= 768; 
  const location = useLocation();
  const course = location.state;
  console.log("course:", course)
  const programData = ProgramData.find(program => program.id === course.course.programId);
  const programName = programData ? programData.name : "";
  console.log("programdata", programData)
  const contentData: ContentFields[] = (Content as ContentFields[]).filter((x: ContentFields) => x.contentCourseId === course.course.courseId);
  const contentDatalen = contentData[0].sections.length;
  const currSection = contentData[0].sections.filter((x: Section) => x.sectionId === courseCurrentSectionIndex);
  console.log("contentdata:", contentData);
  
  const lastSubSection = contentData[0].sections[contentDatalen-1].subsections ? contentData[0].sections[contentDatalen-1].subsections[contentData[0].sections[contentDatalen-1].subsections.length - 1] : 100;
  // console.log("currsection:", currSection)
  const [isOpen, setIsOpen] = useState<{ [key: number]: boolean }>({});
  
  console.log(localStorage)
  // const toggleSubsections = () => {
  //   setIsOpen(!isOpen);
  // };

  useEffect(() => {
    localStorage.removeItem("activeCourseSection")
    localStorage.removeItem("activeCourseSubSection")
  }, [])

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const clickedElement = event.target as HTMLElement;
      if (
        sidebarRef.current &&
        !sidebarRef.current.contains(clickedElement) &&
        !clickedElement.classList.contains('contenttab') &&
        !clickedElement.closest('.contenttab')
      ){
        setShowContent(true);
        setShowSidebar(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    console.log("in use effect")
    const storedactiveCourseSection = localStorage.getItem('activeCourseSection');
    setactiveCourseSection(storedactiveCourseSection || contentData[0].sections[0].sectionId.toString());
    console.log("setactiveCourseSection", storedactiveCourseSection)
    setCourseCurrentSectionIndex(contentData[0].sections[0].sectionId);
    console.log("courseCurrentSectionIndex", courseCurrentSectionIndex);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      for (const sectionId in sectionRefs.current) {
        const sectionRef = sectionRefs.current[sectionId];
        if (sectionRef.current && isElementInViewport(sectionRef.current)) {
          setactiveCourseSection(sectionId);
          break;
        }
      }
    };
    document.addEventListener('scroll', handleScroll);

    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(()=>{
    console.log("in new useeffect : ",courseCurrentSectionIndex)
    const has_subSections = contentData[0].sections[courseCurrentSectionIndex-1002]?.subsections?.length > 0 ;
    if(has_subSections){
      console.log()
      setIsOpen(prevState => ({
        ...prevState,
        [courseCurrentSectionIndex-1002]: true,
      }));
    }
    console.log("has_subsection",has_subSections);
  },[courseCurrentSectionIndex,currentSubsectionIndex]);

  // console.log("prevsectioncontent: ",contentData[0].sections[courseCurrentSectionIndex - 1002 -1])
  const handleMenuButtonClick = () => {
    setShowContent(!showContent);
    setShowSidebar(!showSidebar);
  };

  const scrollToSection = (id: string) => {
    console.log("Scroll to section:")
    setTimeout(() => {
      console.log(document);
      const element = document.getElementById(id);
      console.log("element is :", element);
      if (element) {
        window.scrollTo(0, 0);
      }
      else if (courseCurrentSectionIndex) {
        console.log("courseCurrentSectionIndex", courseCurrentSectionIndex)
        window.scrollTo(0, 0);
      }
    }, 100);
  };

  const handleSectionClick = (sectionId: number, subSectionId: number) => {
    if (window.innerWidth <= 767) {
      setShowSidebar(false);
      setShowContent(!showContent);
    }
    const section = contentData[0].sections.find(section => section.sectionId === sectionId);
    console.log("section in  :", section);
    if (section) {
      setCurrentSubsectionIndex(subSectionId);
      setCourseCurrentSectionIndex(sectionId);
      scrollToSection(subSectionId.toString());
      setactiveCourseSection(subSectionId.toString());
    }
  }

  const handleSectionClick1 = (sectionId: number, subSectionId: number) => {
    const section = contentData[0].sections.find(x => x.sectionId === sectionId);
    console.log("section out  :", section);
    if (window.innerWidth <= 767) {
      setShowSidebar(false);
      setShowContent(!showContent);
    }
    if (section) {
      setCourseCurrentSectionIndex(sectionId);
      setCurrentSubsectionIndex(0);
      scrollToSection(sectionId.toString());
      setactiveCourseSection(sectionId.toString());
      console.log("curr:", courseCurrentSectionIndex, "active:", activeCourseSection)
    }
  }

  const handleSectionHover = (id: string) => {
    setHoveredSection(id);
  };


  const handlePrevSubsection = () => {
    console.log("secidx", currentSubsectionIndex, " ", "subidx", courseCurrentSectionIndex)
    if (currentSubsectionIndex === 0 && courseCurrentSectionIndex - 1002 > 0) {
      const prevSectionIndex = courseCurrentSectionIndex - 1;
      console.log("prevsecidx", prevSectionIndex)
      const prevSection = contentData[0].sections[prevSectionIndex - 1002];
      console.log("prevSection:", prevSection);
      const lastSubsectionIndex = prevSection.subsections?.length - 1;
      console.log("lastsubsecidx:", lastSubsectionIndex)
      if (!Number.isNaN(lastSubsectionIndex)) {
        const lastSubsection = prevSection.subsections[lastSubsectionIndex];
        setCourseCurrentSectionIndex(prevSectionIndex);
        setCurrentSubsectionIndex(lastSubsectionIndex);
        setactiveCourseSection(lastSubsection.subsectionId.toString());
        localStorage.setItem('activeCourseSection', prevSectionIndex.toString());
        localStorage.setItem('activeCourseSubsection', lastSubsection.subsectionId.toString());
        scrollToSection(lastSubsection.subsectionId.toString());
      }
      else {
        setCourseCurrentSectionIndex(prevSection.sectionId);
        setactiveCourseSection(prevSection.sectionId.toString());
        localStorage.setItem('activeCourseSection', prevSection.sectionId.toString());
        localStorage.setItem('activeCourseSubsection', prevSection.sectionId.toString());
        scrollToSection(prevSection.sectionId.toString());
      }
    }
    else if (currentSubsectionIndex > 0) {
      const newSubsectionIndex = currentSubsectionIndex - 1;
      setCurrentSubsectionIndex(newSubsectionIndex);
      console.log("cursecidx", courseCurrentSectionIndex, "newsubidx", newSubsectionIndex);
      console.log("currsubsecidx:", currentSubsectionIndex);
      const newSubsection = contentData[0].sections[courseCurrentSectionIndex - 1002].subsections[newSubsectionIndex];
      setactiveCourseSection(newSubsection.subsectionId.toString());
      localStorage.setItem('activeCourseSubsection', newSubsection.subsectionId.toString());
      scrollToSection(newSubsection.subsectionId.toString());
    }
  };

  const handleNextSubsection = () => {
    console.log("subidx", currentSubsectionIndex, " ", "secidx", courseCurrentSectionIndex)
    const currentSection = contentData[0].sections[courseCurrentSectionIndex - 1002];
    console.log("currsec:", currentSection);
    if ((currentSection?.subsections === undefined || (currentSubsectionIndex == currentSection?.subsections.length - 1)) && courseCurrentSectionIndex - 1002 < contentData[0].sections.length - 1) {
      const nextSectionIndex = courseCurrentSectionIndex + 1;
      const nextSection = contentData[0].sections[nextSectionIndex - 1002];
      console.log("nextsection: ", nextSection);
      const nextSubsectionIndex = nextSection.subsections?.length - 1;
      console.log("lastsubsecidx:", nextSubsectionIndex)
      if (!Number.isNaN(nextSubsectionIndex)) {
        console.log("not empty")
        const nextSubsection = nextSection.subsections[0];
        setCourseCurrentSectionIndex(nextSectionIndex);
        setCurrentSubsectionIndex(0);
        setactiveCourseSection(nextSubsection.subsectionId.toString());
        localStorage.setItem('activeCourseSection', nextSection.sectionId.toString());
        localStorage.setItem('activeCourseSubsection', nextSubsection.subsectionId.toString());
        scrollToSection(nextSubsection.subsectionId.toString());
      }
      else {
        console.log("empty")
        setCourseCurrentSectionIndex(nextSection.sectionId);
        setactiveCourseSection(nextSection.sectionId.toString());
        setCurrentSubsectionIndex(0);
        localStorage.setItem('activeCourseSection', nextSection.sectionId.toString());
        localStorage.setItem('activeCourseSubsection', nextSection.sectionId.toString());
        scrollToSection(nextSection.sectionId.toString());
      }
      console.log("ccsidx", nextSectionIndex);
      console.log("setCurrentSubsectionIndex :", currentSubsectionIndex)
    }
    else if (currentSubsectionIndex === undefined) {
      setCurrentSubsectionIndex(currentSubsectionIndex);
    }
    else if ((currentSection.subsections != undefined && currentSubsectionIndex < currentSection.subsections.length - 1)) {
      // to navigate in btwn subsections
      const newSubsectionIndex = currentSubsectionIndex + 1;
      setCurrentSubsectionIndex(newSubsectionIndex);
      setCourseCurrentSectionIndex(courseCurrentSectionIndex);
      const newSubsection = contentData[0].sections[courseCurrentSectionIndex - 1002].subsections[newSubsectionIndex];
      localStorage.setItem('activeCourseSubsection', newSubsection.subsectionId.toString());
      scrollToSection(newSubsection.subsectionId.toString());
      setactiveCourseSection(newSubsection.subsectionId.toString());
    }
  };

  const handleCloseSidebar = () => {
    setShowContent(true);
    setShowSidebar(false);
  };
  console.log("coursecurrentsectionidx:(last)", courseCurrentSectionIndex)
  console.log("last currsubsectidx:", currentSubsectionIndex)
  return (
    <div className="background-white">
      <div className={`menu-button-wrapper top-40px bg-white ${showSidebar ? 'hidden' : ''}`}>
        <button className="menu-button" onClick={handleMenuButtonClick}>
          <CgMenuGridR />
        </button>
      </div>

      <div className="md:flex">
        <div ref={sidebarRef} className={`sticky top-0 left-0 h-screen w-64 overflow-y-scroll overflow-x-hidden py-12 pl-0.5 pr-8 xl:w-72 xl:pr-16 bg-gray-100 ${showSidebar ? 'block' : 'hidden'} md:block`}>
          {showSidebar && (
            <div className="menu-close-button p-0 m-0 flex justify-end" onClick={handleCloseSidebar}>
              <FaTimes />
            </div>
          )}
          <div className="ml-12">
            {contentData[0].sections?.map((section: Section,index) => (
              <div key={section.sectionId}>
                {section.subsections && section.subsections.length > 0 ? (
                  <>
                    <button>
                      <div
                        className={`sidebar-item ${activeCourseSection === section.sectionId.toString() ? 'active' : ''} flex`}
                        onClick={() => {
                          setIsOpen(prevState => ({
                            ...prevState,
                            [index]: !prevState[index],
                          }));
                        }}
                      >
                        <h3 className="header font-semibold">{section.sectiontitle}</h3>
                        <FaChevronDown className="arrow-icon" />
                      </div>
                    </button>
                    {isOpen[index] && (
                      <ul className="subsection_dropdown">
                        {section.subsections.map((subsection: Subsection) => (
                          <li
                            key={subsection.subsectionId}
                            className={`sidebar-item ${activeCourseSection === (subsection?.subsectionId?.toString() || '') ? 'active' : ''}`}
                            onClick={() => handleSectionClick(section.sectionId, subsection.subsectionId)}
                            onMouseEnter={() => setHoveredSection(subsection?.subsectionId?.toString() || '')}
                            onMouseLeave={() => setHoveredSection(null)}
                          >
                            <span className={`line ${activeCourseSection === (subsection?.subsectionId?.toString() || '') ? 'active' : ''}`}></span>
                            <button style={{ color: activeCourseSection === (subsection?.subsectionId?.toString() || '') ? 'blue' : 'black' }}>
                              {subsection?.subsectiontitle}
                            </button>
                            {activeCourseSection === (subsection?.subsectionId?.toString() || '') && <span className="dot"></span>}
                            {hoveredSection === (subsection?.subsectionId?.toString() || '') && <span className="dot" style={{ backgroundColor: 'grey' }}></span>}
                          </li>
                        ))}
                      </ul>
                    )}
                  </>
                ) : (
                  <button>
                    <div
                      className={`sidebar-item ${activeCourseSection === section.sectionId.toString() ? 'active' : ''}`}
                      onClick={() => handleSectionClick1(section.sectionId, -1)} // Using -1 to indicate no subsection
                    >
                      <h3 className="header font-semibold" style={{ color: activeCourseSection === (section?.sectionId?.toString() || '') ? 'blue' : 'black' }}>{section.sectiontitle}</h3>
                    </div>
                  </button>
                )}
              </div>

            ))}

          </div>
        </div>

        <div className={`w-full md:w-3/4 p-10 mr-4 sticky flex-1 ${!showContent ? 'hidden' : ''} contenttab`}>
          <div className="background-white">
            <div className="breadcrumbtab">
              <Breadcrumb
                SectionData={currSection}
                courseCurrentSectionIndex={courseCurrentSectionIndex}
                setCourseCurrentSectionIndex={setCourseCurrentSectionIndex}
                currentSubSection={courseCurrentSectionIndex}
                programName={programName}
                courseName={contentData[0].contentTitle}
                sectionId={contentData[0].contentId}
              />
            </div>
            <div className="content ml-4">
              <div key={courseCurrentSectionIndex} id={`section${courseCurrentSectionIndex}`} ref={sectionRefs.current[`section${courseCurrentSectionIndex}`]}>
                {currSection[0]?.subsections?.length > 0 ? (
                  currSection[0]?.subsections?.map((subsection: Subsection) => (
                    <div key={subsection.subsectionId} id={subsection?.subsectionId.toString() || ''} style={{ display: activeCourseSection === (subsection?.subsectionId?.toString() || '') ? 'block' : 'none' }}>
                      <h1 className='mt-4 text-2xl font-semibold' style={{ textAlign: "left" }}>{subsection?.subsectiontitle || ''}</h1>
                      <p className='mt-4'>
                        <div className="section_content" dangerouslySetInnerHTML={{ __html: currSection[0]?.sectionContent || '' }} />
                      </p>
                      <p className='mt-4'>
                        <div className="section_content" dangerouslySetInnerHTML={{ __html: subsection?.subsectionContent || '' }} />
                      </p>
                    </div>
                  ))
                ) : (
                  <div>
                    <h1 className='mt-4 text-2xl font-semibold' style={{ textAlign: "left" }}>{currSection[0]?.sectiontitle || ''}</h1>
                    <p className='mt-4'>
                      <div dangerouslySetInnerHTML={{ __html: currSection[0]?.sectionContent || '' }} />
                    </p>
                  </div>
                )}
              </div>
            </div>
            <div className="buttons w-full pt-8 flex justify-between">
              {(currentSubsectionIndex !== 0 || courseCurrentSectionIndex !== 1002) && (
                <button className="btn-1 absolute left-10 pl-4 bottom-10" onClick={handlePrevSubsection}>
                  <span className="mr-1">&lt;</span>
                  {isMobile ? "Back"
                  : (currentSubsectionIndex !== 0 || contentData[0].sections[courseCurrentSectionIndex - 1002 - 1].subsections != undefined) ?
                    <span className="navigation_button" style={{ fontWeight: "500" }}>{currentSubsectionIndex === 0 ? contentData[0].sections[courseCurrentSectionIndex - 1002 - 1].subsections[contentData[0].sections[courseCurrentSectionIndex - 1 - 1002].subsections?.length - 1]?.subsectiontitle : contentData[0].sections[courseCurrentSectionIndex - 1002].subsections[currentSubsectionIndex - 1].subsectiontitle}</span>
                    :
                    <span className="navigation_button" style={{ fontWeight: "500" }}>{contentData[0].sections[courseCurrentSectionIndex - 1002 - 1].sectiontitle}</span>
                  }
                </button>
              )}
              {(courseCurrentSectionIndex - 1002 !== (contentDatalen-1) || ((lastSubSection !== 100 && currentSubsectionIndex !== lastSubSection.subsectionId))) && (
                <button className="btn-2 absolute right-10 pr-8 bottom-10" onClick={handleNextSubsection}>
                  {isMobile ? "Next":
                  (((contentData[0].sections[courseCurrentSectionIndex - 1002].subsections != undefined) && (currentSubsectionIndex != contentData[0].sections[courseCurrentSectionIndex - 1002].subsections.length - 1)) || (contentData[0].sections[courseCurrentSectionIndex - 1002 + 1].subsections != undefined)) ?
                    <span className="navigation_button" style={{ fontWeight: "500" }}>{((contentData[0].sections[courseCurrentSectionIndex - 1002].subsections == undefined) || currentSubsectionIndex === (contentData[0].sections[courseCurrentSectionIndex - 1002].subsections?.length - 1)) ? contentData[0].sections[courseCurrentSectionIndex - 1002 + 1].subsections[0].subsectiontitle : contentData[0].sections[courseCurrentSectionIndex - 1002].subsections[currentSubsectionIndex + 1].subsectiontitle}</span>
                    :
                    <span className="navigation_button" style={{ fontWeight: "500" }}>{contentData[0].sections[courseCurrentSectionIndex - 1002 + 1].sectiontitle}</span>
                  }
                  <span className="ml-1">&gt;</span>
                </button>
              )}
            </div>
          </div>


        </div>
      </div>
    </div >
  );
};

export default CourseDetail;

function isElementInViewport(el: HTMLElement) {
  const rect = el.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}