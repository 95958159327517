// FeatureService.js
import React from 'react';
import './featureservice.css';

interface FeatureServiceProps {
  data?: {
    image: string;
    title: string;
    subtitle: string;
    badgeContent?: string;
  };
}

const FeatureService: React.FC<FeatureServiceProps> = ({ data }) => {
  return (
    <div className="feature-services-wrap">
      <div className="feature-service-img">
        <img src={data?.image} alt="Feature Service" />
      </div>
      <div className="feature-service-desc">
        {data?.badgeContent && data?.badgeContent !== 'black' && (
          <div className="desc-badge">
            <p>{data.badgeContent}</p>
          </div>
        )}
        <h3>{data?.title}</h3>
        <h5>{data?.subtitle}</h5>
      </div>
    </div>
  );
};

export default FeatureService;
