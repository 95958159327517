// ImageList.tsx

import React, { useEffect, useState } from 'react';
import data from './advirtisebrand.json';

interface ImageData {
  id: number;
  imageUrl: string;
  linkUrl: string;
}

const ImageComponent: React.FC<{ imageUrl: string; linkUrl: string }> = ({ imageUrl, linkUrl }) => (
  <a className='big-brand-logo' href={linkUrl} target="_blank" rel="noopener noreferrer">
    <img src={imageUrl} alt="Dynamic Image" />
  </a>
);

const ImageList: React.FC = () => {
  const [dataList, setDataList] = useState<ImageData[]>([]);

  useEffect(() => {
    // Set data from the imported JSON file
    setDataList(data);
  }, []);

  return (
    <div className='big-brands'>
      {dataList.length === 0 ? (
        <p>Loading...</p>
      ) : (
        dataList.map((item) => (
          <ImageComponent
            key={item.id}
            imageUrl={item.imageUrl}
            linkUrl={item.linkUrl}
          />
        ))
      )}
    </div>
  );
};

export default ImageList;
