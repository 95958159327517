import React, { FC, useState } from "react";
import Heading from "components/Heading/Heading";
import Nav from "components/Nav/Nav";
import NavItem from "components/NavItem/NavItem";
import InReviewTab from "./tabs/InReview";
import ActiveTab from "./tabs/Active";
import InActiveTab from "./tabs/InActive";
import Header2 from "components/Header/Header2";
export interface ClientHeaderFilterProps {
  tabs?: string[];
  heading: string;
}

const ClientHeaderFilter: FC<ClientHeaderFilterProps> = ({
  tabs = ["InReview", "Active", "InActive"],
  heading = "🎈 Clients",
}) => {
  const [tabActive, setTabActive] = useState<string>(tabs[0]);

  const getContentForTab = (tab: string): React.ReactNode => {
    switch (tab) {
      case "InReview":
        return <InReviewTab/>
      case "Active":
        return <ActiveTab/>;
      case "InActive":
        return <InActiveTab/>;
      default:
        return null;
    }
  };

  const handleClickTab = (item: string) => {
    if (item===tabActive) {
      return;
    }
  else{
    setTabActive(item);
}
  };

  return (
    <div className="flex flex-col mb-8 relative">
      <div className="pt-6 ml-3"><Heading>{heading}</Heading></div>
     
      <div className="flex justify-between">
        <Nav
          className="sm:space-x-2"
          containerClassName="relative flex w-full overflow-x-auto text-sm md:text-base"
        >
          {tabs.map((item, index) => (
            <NavItem
              key={index}
              isActive={tabActive === item}
              onClick={()=>handleClickTab(item)}
            >
              {item}
            </NavItem>
          ))}
        </Nav>
      </div>
      <div>{getContentForTab(tabActive)}</div>
    </div>
  );
};

export default ClientHeaderFilter;
