import { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";

import { useUser } from "components/UserContext";
import {
  Chart,
  LinearScale,
  CategoryScale,
  BarElement,
  Legend,
  Title,
  Tooltip,
  plugins,
  ChartOptions,
} from "chart.js";
import { getMyClientSummaryApi } from "services/dashboardService";
Chart.register(
  LinearScale,
  CategoryScale,
  BarElement,
  Legend,
  Title,
  Tooltip,
  plugins
);

interface clientsWidgetData {
  year: string;
  month: string;
  verified: number;
  inReview: number;
}

// const clientsWidgetData = [
//   {
//     "year": 2023,
//     "month": "Nov",
//     "verified": 60,
//     "inReview": 2
//   },
//   {
//     "year": 2023,
//     "month": "Dec",
//     "verified": 5,
//     "inReview": 20
//   },
//   {
//     "year": 2023,
//     "month": "Jan",
//     "verified": 5,
//     "inReview": 25
//   }
// ];

const options: ChartOptions<"bar"> = {
  plugins: {
    legend: {
      display: true,
      position: "top" as const,
      labels: {
        usePointStyle: true,
        boxWidth: 5,
      },
    },
    title: {
      display: true,
      text: "Clients Tracker",
    },
  },
};

const ClientsSummaryWidget: React.FC = () => {
  const [clientsWidgetData, setClientsWidgetData] = useState<
    clientsWidgetData[]
  >([]);
  const userData:any = useUser();
  useEffect(() => {
    getMyClientSummaryApi({
      //  partnerId: 10,
      partnerId: userData?.userData.getUser?.parnerId,
      // dateTimeFrom: null,
      // dateTimeTo: null,
    })
      .then((response) => {
        const clientsSummaryData = response.data;
        setClientsWidgetData(clientsSummaryData);
      })
      .catch((err) => {
        console.log(err, "Err");
      });
  }, []);
  const generateChartData = () => {
    const datasets = [
      {
        label: "In Review",
        data: clientsWidgetData.map((dataPoint) => dataPoint.inReview),
        backgroundColor: "#F4A460",
      },
      {
        label: "Verified",
        data: clientsWidgetData.map((dataPoint) => dataPoint.verified),
        backgroundColor: "#6A5ACD",
      },
    ];

    return {
      labels: clientsWidgetData.map(
        (dataPoint) => `${dataPoint.year} ${dataPoint.month}`
      ),
      datasets,
    };
  };

  const modifiedData = generateChartData();

  return (
    <div>
      <h1 className="line-clamp-2 text-lg font-semibold  dark:text-neutral-300">
        Clients
      </h1>
      <Bar options={options} data={modifiedData} />
    </div>
  );
};

export default ClientsSummaryWidget;
