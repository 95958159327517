import React from 'react';
import './cap.css';
import { FaUsers, FaLightbulb, FaStar, FaSchool, FaNetworkWired, FaTshirt, FaMedal, FaArrowRight, FaTrophy } from 'react-icons/fa';

const CampusAmbassador: React.FC = () => {
  return (
    <div className="App">
      <header className="App-header">
        <h1>Campus Ambassador Program</h1>
      </header>
      <div className="cap-section">
        <h2>About the Program <FaUsers /></h2>
        <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTTBVfOlV-5QLYv8qdkx9VYLWyO2nrS_k9O0Q&s" className="section-image" />
        <p className='about-program'>
          Our Campus Ambassador Program aims to connect with enthusiastic and motivated students across various campuses. Ambassadors act as a bridge between our company and their universities, helping spread awareness about our products, services, and initiatives among students and faculty members.
         </p>
         <p className='about-program'>
           They play a crucial role in engaging with potential users by organizing events, participating in campus activities, and fostering meaningful relationships within the university community. Ambassadors also provide valuable feedback, helping us tailor our offerings to better meet the needs and preferences of students.
        </p>
      </div>
      <div className="cap-section">
        <h2>What We Offer <FaMedal /></h2>
        <div className="offer-grid">
          <div className="offer-item bg-gray-100">
            <FaNetworkWired />
            <div className="offer-content">
              <p>Certificates and Recommendations</p>
              {/* <img src="https://source.unsplash.com/random/200x200?networking" alt="Networking" /> */}
            </div>
          </div>
          <div className="offer-item bg-gray-100">
            <FaTshirt />
            <div className="offer-content">
              <p>Incentives and Rewards</p>
              {/* <img src="https://source.unsplash.com/random/200x200?swag" alt="Swag" /> */}
            </div>
          </div>
          <div className="offer-item bg-gray-100">
            <FaStar />
            <div className="offer-content">
              <p>Skill Development</p>
              {/* <img src="https://source.unsplash.com/random/200x200?premium" alt="Premium Features" /> */}
            </div>
          </div>
          <div className="offer-item bg-gray-100">
            <FaSchool />
            <div className="offer-content">
              <p>Networking Opportunities</p>
              {/* <img src="https://source.unsplash.com/random/200x200?mentorship" alt="Mentorship" /> */}
            </div>
          </div>
        </div>
        <a href="https://apply-to-job.com" target="_blank" rel="noopener noreferrer">
          <button className="apply-button">Apply Now <FaArrowRight /></button>
        </a>
      </div>
      <div className="cap-section benefits-section">
        <h2>What You Will Do <FaLightbulb /></h2>
        <div className="benefit-boxes">
          <div className="benefit-box bg-gray-100">
            <h3><FaUsers /> Network and Collaborate</h3>
            <p> Work closely with university clubs, societies, and faculty to create collaborative opportunities. </p>
          </div>
          <div className="benefit-box">
            <h3><FaLightbulb /> Brainstorm</h3>
            <p>Ideate on real business problems and create solutions for growth, Gain valuable skills and knowledge.</p>
          </div>
          <div className="benefit-box">
            <h3><FaTrophy /> Promote Our Brand</h3>
            <p>Organize events, workshops, and seminars to introduce our products and services to your peers.</p>
          </div>
          <div className="benefit-box">
            <h3><FaArrowRight /> Gather Feedback</h3>
            <p>Collect valuable insights from your fellow students about their experiences and expectations.</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CampusAmbassador;
