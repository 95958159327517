import React from "react";
import MySlider from "components/MySlider";
import "./eventmaincard.css";
import { CmeEventsData } from "app/(home)/event/page";

interface CategorySliderProps {
  perView: number;
  cmeEventsDetails: CmeEventsData[];
}
const CategorySlider: React.FC<CategorySliderProps> = ({
  cmeEventsDetails,
}) => {
  const formatEventDate = (dateTimeString: string) => {
    const eventDate = new Date(dateTimeString);

    const formattedDate = eventDate.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });

    return formattedDate;
  };
  const formatEventsDateTime = (dateTimeString: string) => {
    const eventDate = new Date(dateTimeString);

    // Use toLocaleTimeString to extract only the time part
    const formattedTime = eventDate.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
    });

    return formattedTime;
  };

  // Render each item in the slider
  const renderSliderItem = (cmeEventsDetails: any) => (
    // Customize the rendering of each item in the slider
    <div className="event-slider-content">
      <div className="event-details">
        <div className="evets-titles">
          <h3 className="montserrat font-sky-blue">
            {cmeEventsDetails.eventTitle}
          </h3>
        </div>
        <div className="evets-datas">
          <div className="time-book">
            <div className="times">
              <p className="event-date montserrat">
                {formatEventDate(cmeEventsDetails.eventDate)}
              </p>
              <p className="event-time montserrat">
                {formatEventsDateTime(cmeEventsDetails.eventDate)}{" "}
              </p>
              <p className="location">{cmeEventsDetails.location} </p>
              {/* <p className="">{cmeEventsDetails.organizer}</p> */}
            </div>
          </div>
          <div></div>
          <div className="events-desc content">
            <p className="montserrat">{cmeEventsDetails.description}</p>
          </div>
        </div>
      </div>
      <div className="event-banner-img">
        {cmeEventsDetails?.thumbnail&&(
        <img src={cmeEventsDetails?.thumbnail} alt="" />
        )}
      </div>
    </div>
  );

  return (
    <div className="jobbyhospitalsliderslider">
      <MySlider
        className="category-slider"
        data={cmeEventsDetails}
        renderItem={renderSliderItem}
        itemPerRow={1} // Adjust the number of items per row as needed
        itemPerRowBreakpoints={{
          320: 1,
          500: 1,
          800: 1,
          1024: 1,
          1280: 1,
        }}
      />
    </div>
  );
};

export default CategorySlider;
