
import React, { useState, useEffect } from "react";

const marketingexecutive = () => {
    return (
        <div className="page-job-post content-writer">
            <div className="container">
                <div className="job-post-wrapper">
                    <div className="jobdetail-side">
                        <div className="joblistings-detail">
                            <div className="jobs-prodiver-detail">
                                <div className="job-info-main">
                                    <h4 className="job-name">Marketing Executive</h4>
                                    <h5 className="job-provider-name"></h5>
                                </div>
                                <div className="job-provider-info"></div>
                            </div>
                            <div className="jobs-requirment">
                                <div className="uppersection">
                                    <p className="job-detail-minor required-exprence ">
                                        Fresher / Experienced
                                    </p>
                                    <p className="job-detail-minor salary-offer">Basic + Allowance + Bonus</p>
                                    <p className="job-detail-minor location">On-Site, Hyderabad, Telangana</p>
                                </div>
                                <div className="lowersections">
                                    <p className="job-detail-minor detail-other ">
                                        Education : A bachelor's degree in Marketing, Business Administration, or related field.
                                    </p>
                                    {/* <p className="job-detail-minor detail-other">
                                        Key Skills : Flutter, Dart, Android, iOS
                                    </p> */}
                                </div>
                            </div>
                            <div className="job-apply-section">
                                <div className="job-provider-info post-page">
                                    {/* <p className="job-detail-minor openings"> 1 Opening</p>
                                    <span className="job-detail-minor posted-ago">
                                        4/12/ 2023
                                    </span> */}
                                </div>
                                <div className="job-btns-selection">
                                    <a
                                        className="applynowbtn apply-save-btn"
                                        href="https://www.naukri.com/job-listings-Field-Executive-TERA-CONNECTS-HEALTHCARE-PRIVATE-LIMITED-India-0-to-5-years-040524003314"
                                    >
                                        Apply
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className="career-joblisting-detail job-description">
                            <div className="product-specification">
                                <h2 className="product-specs-title-for-product-single for-list-prod montserrat">
                                    Job Description
                                </h2>
                                <ul className="product-desc-list tera-job-description">
                                    We are seeking a dynamic and results-driven Field Marketing Executive to join our team. As a Field Marketing Executive , you will play a crucial role in expanding our brand presence and driving customer engagement through various marketing initiatives in the field. Your responsibilities will include executing marketing campaigns, conducting market research, building and maintaining relationships with key stakeholders, and analyzing campaign performance to optimize results.
                                </ul>

                            </div>
                        </div>

                        <div className="career-joblisting-detail job-description">
                            <div className="product-specification">
                                <h2 className="product-specs-title-for-product-single for-list-prod montserrat">
                                    Responsibilities
                                </h2>
                                {/* <ul className="product-desc-list tera-job-description"> */}
                                <ul className="">
                                    <li className="specs-list-content montserrat">
                                        Develop and execute field marketing plans to drive brand awareness and customer engagement in target markets.
                                    </li>
                                    <li className="specs-list-content montserrat">
                                        Collaborate with the marketing team to create compelling marketing materials and promotions tailored to local market needs.    </li>
                                    <li className="specs-list-content montserrat">
                                        Conduct market research to identify opportunities and consumer preferences in different regions.
                                    </li>
                                    <li className="specs-list-content montserrat">
                                        Build and maintain strong relationships with local businesses, organizations, and community influencers to support marketing initiatives.
                                    </li>
                                    <li className="specs-list-content montserrat">
                                        Coordinate and participate in on-site events, trade shows, and promotional activities to showcase our products/services.
                                    </li>
                                    <li className="specs-list-content montserrat">
                                        Collect and analyze data on consumer demographics, preferences, and buying habits to inform marketing strategies.
                                    </li>
                                    <li className="specs-list-content montserrat">
                                        Track and report on the performance of field marketing campaigns, providing insights and recommendations for improvement.
                                    </li>
                                    <li className="specs-list-content montserrat">
                                        Stay up-to-date on industry trends, competitor activities, and best practices in field marketing.
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="career-joblisting-detail job-description">
                            <div className="product-specification">
                                <h2 className="product-specs-title-for-product-single for-list-prod montserrat">
                                    Qualifications
                                </h2>
                                {/* <ul className="product-desc-list tera-job-description"> */}
                                <ul className="">
                                    <li className="specs-list-content montserrat">
                                        A bachelor's degree in Marketing, Business Administration, or related field.
                                    </li>
                                    <li className="specs-list-content montserrat">
                                        0-5 years of experience in field marketing, brand promotion, or similar role.
                                    </li>
                                    <li className="specs-list-content montserrat">
                                        Strong communication and interpersonal skills, with the ability to build rapport with diverse audiences.
                                    </li>
                                    <li className="specs-list-content montserrat">
                                        Excellent organizational and time management abilities, with a keen attention to detail.
                                    </li>
                                    <li className="specs-list-content montserrat">
                                        creative thinking and problem-solving skills, with a results-oriented mindset.
                                    </li>
                                    <li className="specs-list-content montserrat">
                                        Ability to work independently and as part of a team in a fast-paced environment.
                                    </li>
                                    <li className="specs-list-content montserrat">
                                        Flexibility to travel frequently and work flexible hours, including weekends and evenings as needed.
                                    </li>
                                    <li className="specs-list-content montserrat">
                                        Proficiency in Microsoft Office Suite and familiarity with marketing analytics tools is a plus.
                                    </li>
                                    <li className="specs-list-content montserrat">
                                        Knowledge in  healthcare or medical industry background is added advantageF
                                    </li>

                                </ul>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default marketingexecutive;
