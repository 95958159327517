import React, { useState, useEffect } from 'react';
import CategoryFilter from './Categoryfilter';
import MySlider from 'components/MySlider';
import categoriesData from './categoryfilter.json';

const CategorySlider: React.FC = () => {
  const [sliderData, setSliderData] = useState<any[]>([]);

  useEffect(() => {
    // Assuming categoriesData is an array, you might need to adjust the structure
    setSliderData(categoriesData);
  }, []);

  // Render each item in the slider
  const renderSliderItem = (category: any, index: number) => (
    // Customize the rendering of each item in the slider
    <a href={category.url} key={category.id} className='categorieswithimages'>
      <div className='image-div'>
        <img src={category.image} alt={category.name} />
      </div>
      <h3>
        {category.name}{' '}
     
      </h3>
    </a>
  );

  return (
    <div className='categoryslider'>
    
      <MySlider
        className="category-slider"
        data={sliderData}
        renderItem={renderSliderItem}
        itemPerRow={4} // Adjust the number of items per row as needed
        itemPerRowBreakpoints={{
          660: 2,
          768: 3,
          1025: 3,
          1280: 4,
        }}
      />
    </div>
  );
};

export default CategorySlider;
