// FeatureService.js
import React from 'react';
import './whoweare.css'

interface WhowewreProps {
  data: {
    image: string;
    title: string;
  };
}

const whoweare: React.FC<WhowewreProps> = ({ data }) => {
  return (
    <div className=" whoweare feature-services-wrap">
      <div className=" whoweare-img feature-service-img">
        <img src={data.image} alt="Feature Service" />
      </div>
      <div className="whoweare-desc feature-service-desc">
        <h3>{data.title}</h3>
      </div>
    </div>
  );
};

export default whoweare;
