import React from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import python from "./images/python.jpg"
import Courses from './data/courses.json';


interface CoursesValues {
  programId: number,
  courseId: number,
  courseName: string,
  duration: string,
  image : string
}

interface Program {
  id: number;
  name: string;
}

export interface ProgramCoursesProps {
  program: Program;
}

const ProgramCourses: React.FC<ProgramCoursesProps> = ({ program }) => {
  const navigate = useNavigate();

  const handleCourseClick = (course: CoursesValues) => {
    console.log("course at button click :",course)
    navigate(`/training/${program.name.split(" ").join("")}/${course.courseName.split(" ").join("")}`,{ state: {course } });
  };

  const courses = Courses.filter(x => x.programId === program.id)
  console.log("Orig courses",courses)
  return (
    <div className="program-courses-container">
      <h1>{program.name} Courses</h1>
      <div className="course-list">
        {courses ? (
         courses.map((course: CoursesValues) => (
            <div className='course-card grid grid-cols-3'>
              <div key={course.courseId} className="col-span-2" onClick={() => handleCourseClick(course) }>
              {/* <div key={course.courseId} className="col-span-2" onClick={() => navigate(`/training/${program.name.split(" ").join("")}/${course.courseName.split(" ").join("-")}`)}> */}
                <h2>{course.courseName}</h2>
                <p className='duration'>{course.duration}</p>
                <button onClick={() => handleCourseClick(course) }>Syllabus</button>
              </div>
              <div className='col-span-1 md:block hidden'>
                <img src = {course.image}></img>
              </div>
            </div>
          ))
        ) : (
          <p>No courses available</p>
        )}
      </div>
    </div>
  );
};

export default ProgramCourses;
