import React, { useState, useEffect } from "react";
import { Job } from "app/(home)/jobs/page";
import { getStatesApi } from "services/lookupService";
import { useNavigate } from "react-router-dom";

interface State {
  value: number;
  label: string;
}
interface SearchFormProps {
  setJobsData: React.Dispatch<React.SetStateAction<Job[]>>;
}
const SearchJobsByCityFilter: React.FC<SearchFormProps> = ({ setJobsData }) => {
  const [states, setStates] = useState<State[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    getStatesApi()
      .then((response) => {
        setStates(response.data);
      })
      .catch((error) => {
        console.error("Error fetching countries:", error);
      });
  }, []);

  const handleSearch = (selectedState: any) => {
    navigate(`/jobs/jobs-listing/?selectedStateId=${selectedState}`);
  };

  return (
    <div className="flex flex-wrap justify-center items-center">
      <div className="grid grid-cols-1 md:grid-cols-6 gap-4">
        {states.map((state, index) => (
          <div
            key={index}
            className="p-1.5 bg-gray-600 text-white cursor-pointer rounded-md hover:bg-sky-500 text-center  line-clamp-1 hover:line-clamp-none"
            onClick={() => handleSearch(state.value)}
          >
            {state.label}
          </div>
        ))}
      </div>
    </div>
  );
};
export default SearchJobsByCityFilter;
