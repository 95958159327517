import PropTypes from 'prop-types';
import { useState } from 'react';
import clsx from 'clsx';
import useTimeout from './useTimeout';
import"./loading.css";

function Loading(props: { delay: any; }) {
    const [showLoading, setShowLoading] = useState(!props.delay);

    useTimeout(() => {
        setShowLoading(true);
    }, props.delay);

    return (
        <div
            className={clsx(
                'flex flex-1 flex-col items-center justify-center p-24',
                !showLoading && 'hidden'
            )}
        >
            <div
                id="spinner"
            >
                <div className="bounce1" />
                <div className="bounce2" />
                <div className="bounce3" />
            </div>
        </div>
    );
}

Loading.propTypes = {
    delay: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
};

Loading.defaultProps = {
    delay: false,
};

export default Loading;
