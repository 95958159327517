import React, { useState, useEffect } from "react";
import MySlider from "components/MySlider";
import { Link } from "react-router-dom";
import { HiringCompanies, Job } from "app/(home)/jobs/page";
import defaultOrgImg from "../../images/Default-Org-svg.svg";
import "./jobscard.css";
export interface JobCardListSliderProps {
  perView: number;
  topHiringCompanies: HiringCompanies[];
}
const JobCardListSlider: React.FC<JobCardListSliderProps> = ({
  topHiringCompanies,
}) => {
  const currentDate = new Date();
  const renderSliderItem = (sliderData: any) => (
    <Link
      to={{
        pathname: "/jobs/jobs-listing/",
        search: `branchId=${sliderData?.branchId}`,
      }}
    >
      <div className="our-solution-card cards-home job-short-card">
        <div className="our-solution-icons">
          <div className="company-icos-on-job-card">
            {sliderData.logoPath ? (
              <img src={sliderData.logoPath} alt="" className="w-1/2" />
            ) : (
              <img
                src={defaultOrgImg}
                alt=""
                className="w-1/2 default-org-img"
              />
            )}
          </div>
        </div>
        <h3 className="our-solution-heading montserrat overflow-ellipsis line-clamp-1 capitalize">
          {sliderData.branchName.split(",")[0]}
        </h3>
        <h4 className="text-left our-solution-heading montserrat overflow-ellipsis line-clamp-1 capitalize mb-0">
          {sliderData.branchName.split(",")[1]}
        </h4>
        <h5 className="our-solution-sub-heading montserrat">
          {sliderData.orgType}
        </h5>
        <h5 className="our-solution-sub-heading montserrat">
          {sliderData.city},{sliderData.state}
        </h5>
      </div>
    </Link>
  );
  return (
    <div className="jobbyhospitalsliderslider">
      <MySlider
        className="category-slider"
        data={topHiringCompanies}
        renderItem={renderSliderItem}
        itemPerRow={4}
        itemPerRowBreakpoints={{
          320: 1,
          500: 1,
          800: 2,
          1024: 3,
          1280: 4,
        }}
      />
    </div>
  );
};

export default JobCardListSlider;
