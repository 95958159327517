import React, { useState, useEffect } from "react";

const fullstackdeveloper = () => {
  return (
    <div className="page-job-post content-writer">
      <div className="container">
        <div className="job-post-wrapper">
          <div className="jobdetail-side">
            <div className="joblistings-detail">
              <div className="jobs-prodiver-detail">
                <div className="job-info-main">
                  <h4 className="job-name">Technical Lead</h4>
                  <h5 className="job-provider-name"></h5>
                </div>
                <div className="job-provider-info"></div>
              </div>
              <div className="jobs-requirment">
                <div className="uppersection">
                  <p className="job-detail-minor required-exprence ">
                  4-10 years
                  </p>
                  <p className="job-detail-minor salary-offer">Not Disclosed</p>
                  <p className="job-detail-minor location">Hyderabad</p>
                </div>
                <div className="lowersections">
                  <p className="job-detail-minor detail-other ">
                  Education :  B.Tech or M.Tech, MCA,  IT/Computer related stream
 | Key Skills : React JS, Tailwind CSS, Nodejs, Material UI, .Net Core WebAPI, Azure/ AWS, MySQL, PostgreSQL 
                  </p>
                </div>
              </div>
              <div className="job-apply-section">
                <div className="job-provider-info post-page">
                  <p className="job-detail-minor openings"> 1 Opening</p>
                  <span className="job-detail-minor posted-ago">
                    4/12/ 2023
                  </span>
                </div>
                <div className="job-btns-selection">
                
                  <a className="applynowbtn apply-save-btn" href="mailto:careers@teraconnects.com">
                    Apply
                  </a>
                </div>
              </div>
            </div>

            <div className="career-joblisting-detail job-description">
              <div className="product-specification">
                <h2 className="product-specs-title-for-product-single for-list-prod montserrat">
                Job Responsibilities
                </h2>
                {/* <ul className="product-desc-list tera-job-description"> */}

      <ul className="">
    <li className="specs-list-content montserrat">Use SOLID development principles to deliver quality web applications</li>
    <li className="specs-list-content montserrat">Meeting with Product owners/Technical Managers/Lead to understand application domain, user stories</li>
    <li className="specs-list-content montserrat">Develop .NET applications</li>
    <li className="specs-list-content montserrat">Analyzing requirements</li>
    <li className="specs-list-content montserrat">Writing scalable code, clean testable code</li>
    <li className="specs-list-content montserrat">Testing and debugging .NET applications</li>
    <li className="specs-list-content montserrat">Review and refactor code</li>
    <li className="specs-list-content montserrat">Providing support for Junior Developers</li>
    <li className="specs-list-content montserrat">Develop technical specifications and Architecture</li>
</ul>


          
              </div>
            </div>
         

            <div className="career-joblisting-detail job-description">
              <div className="product-specification">
                <h2 className="product-specs-title-for-product-single for-list-prod montserrat">
                Required Technical Skills
                </h2>
                {/* <ul className="product-desc-list tera-job-description"> */}
                <ul className="">


    <li className="specs-list-content montserrat">Minimum of 4 years of experience</li>
    <li className="specs-list-content montserrat">Strong Frontend technical skills in React Js, Tailwind CSS, Nodejs, Material UI</li>
    <li className="specs-list-content montserrat">Strong Backend technical skills in C#, .NET Core WebAPI</li>
    <li className="specs-list-content montserrat">Must have experience on entity framework, ADO.NET, Dapper</li>
    <li className="specs-list-content montserrat">Must have experience in Cloud technologies like Azure, Digital Ocean or AWS</li>
    <li className="specs-list-content montserrat">Must have experience and work experience in Scrum process/Agile process</li>
    <li className="specs-list-content montserrat">Must have experience on Microservices architecture</li>
    <li className="specs-list-content montserrat">Work experience with Visual Studio .NET editors</li>
    <li className="specs-list-content montserrat">Good Experience in any database PostgreSQL, MySQL, MongoDB, Redis</li>
    <li className="specs-list-content montserrat">Application performance tuning and optimization</li>
</ul>



          
              </div>
            </div>

       
          </div>

        
        </div>
      </div>
    
    </div>
  );
};

export default fullstackdeveloper;
