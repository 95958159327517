import React from 'react';
import './oursolution.css'
interface CardProps {
  svg: React.ReactNode;
  mainHeading: string;
  subHeading: string;
}

function Card({ svg, mainHeading, subHeading }: CardProps) {
  return (
    <div className="our-solution-card cards-home">
      <div className="our-solution-icons">
        {svg}
      </div>
      <h3 className="our-solution-heading montserrat">{mainHeading}</h3>
      <h5 className="our-solution-sub-heading montserrat">{subHeading}</h5>
    </div>
  );
}

interface CardData {
  svg: React.ReactNode;
  mainHeading: string;
  subHeading: string;
}

interface CardListProps {
  cardData: CardData[];
}

function CardList({ cardData }: CardListProps) {
  return (
    <div className="card-list">
      {cardData.map((data, index) => (
        <Card key={index} {...data} />
      ))}
    </div>
  );
}

export default CardList;
