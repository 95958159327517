import React from "react";
const LogoSvg = () => {
  return (
    // <svg
    //   width="250"
    //   height="41"
    //   viewBox="0 0 250 41"
    //   fill="none"
    //   xmlns="http://www.w3.org/2000/svg"
    // >
    //   <g filter="url(#filter0_d_2900_1963)">
    //     <path
    //       d="M69.0795 2.2869L66.5136 6.37759H61.95V18H57.9935V6.37759H52L54.5658 2.2869H69.0795Z"
    //       fill="#1188A8"
    //     />
    //     <path
    //       d="M85.121 13.9355L82.5747 17.9781H70.2744V2.289H85.0818L82.516 6.37969H74.2505V8.2566H84.3767L82.1047 11.8179H74.2505V13.9595H85.121V13.9355Z"
    //       fill="#1188A8"
    //     />
    //     <path
    //       d="M104.61 17.9778H99.3215L96.4814 13.8149H90.4096V17.9778H86.4531V9.94083H97.5783C98.7926 9.94083 99.7524 9.26704 99.7524 8.1842C99.7524 6.95699 98.9297 6.35543 97.5783 6.35543H86.4531L89.0385 2.26474H97.2453C100.144 2.26474 103.807 3.3957 103.807 7.87141C103.807 10.3018 102.573 12.323 100.457 12.8524C100.888 13.2615 101.378 13.8149 102.259 14.9218L104.61 17.9778Z"
    //       fill="#1188A8"
    //     />
    //     <path
    //       d="M111.427 17.9778L113.817 13.9352H117.029C117.793 13.9352 118.694 13.9352 119.301 13.9833C118.948 13.4299 118.42 12.4674 118.008 11.6974L115.717 7.53449L109.723 17.9537H105.042L113.229 3.75658C113.758 2.84219 114.541 2 115.795 2C116.97 2 117.754 2.77 118.322 3.75658L126.313 17.9537H111.427V17.9778Z"
    //       fill="#1188A8"
    //     />
    //     <path
    //       d="M143.351 13.7657L141.252 17.7322H135.958C132.307 17.7322 129.6 14.3451 129.6 9.84371C129.6 5.29777 132.307 2.26715 135.958 2.26715H143.381L141.282 6.27825H135.988C134.224 6.27825 132.885 7.83816 132.885 10.0666C132.885 12.2504 134.193 13.7657 135.988 13.7657H143.351Z"
    //       fill="#F1B207"
    //     />
    //     <path
    //       d="M159.595 9.97773C159.595 15.5042 157.009 18 151.867 18C146.726 18 144.14 15.5042 144.14 9.97773C144.14 4.45128 146.726 2 151.867 2C157.009 2 159.595 4.45128 159.595 9.97773ZM156.339 9.97773C156.339 6.63512 155.153 6.01117 151.867 6.01117C148.581 6.01117 147.395 6.63512 147.395 9.97773C147.395 13.3203 148.581 13.9443 151.867 13.9443C155.153 13.9443 156.339 13.3203 156.339 9.97773Z"
    //       fill="#F1B207"
    //     />
    //     <path
    //       d="M170.67 17.1532L164.189 8.64067V17.7325H161.208V4.89697C161.208 2.84683 162.06 2 163.003 2C163.398 2 163.794 2.13372 164.311 2.8468L170.791 11.3593V2.26747H173.773V15.103C173.773 17.1532 172.921 18 171.978 18C171.552 18 171.156 17.8663 170.67 17.1532Z"
    //       fill="#F1B207"
    //     />
    //     <path
    //       d="M185.091 17.1532L178.61 8.64067V17.7325H175.629V4.89697C175.629 2.84683 176.481 2 177.424 2C177.819 2 178.215 2.13372 178.732 2.8468L185.212 11.3593V2.26747H188.194V15.103C188.194 17.1532 187.342 18 186.399 18C186.003 18 185.608 17.8663 185.091 17.1532Z"
    //       fill="#F1B207"
    //     />
    //     <path
    //       d="M202.403 13.7657L200.304 17.7322H190.143V2.26715H202.373L200.243 6.27825H193.398V8.1501H201.764L199.878 11.671H193.398V13.7657H202.403Z"
    //       fill="#F1B207"
    //     />
    //     <path
    //       d="M216.975 13.7657L214.876 17.7322H209.582C205.931 17.7322 203.224 14.3451 203.224 9.84371C203.224 5.29777 205.931 2.26715 209.582 2.26715H217.005L214.906 6.27825H209.612C207.848 6.27825 206.509 7.83816 206.509 10.0666C206.509 12.2504 207.818 13.7657 209.612 13.7657H216.975Z"
    //       fill="#F1B207"
    //     />
    //     <path
    //       d="M231.244 2.26715L229.115 6.27825H225.342V17.7322H222.087V6.27825H217.128L219.258 2.26715H231.244Z"
    //       fill="#F1B207"
    //     />
    //     <path
    //       d="M242.044 8.4175C244.63 8.4175 245.999 9.84367 245.999 12.6515C245.999 15.8158 244.691 17.7322 241.466 17.7322H231.64L233.739 13.7657H241.74C242.501 13.7657 242.866 13.4537 242.866 12.6515C242.866 11.8492 242.531 11.5373 241.74 11.5373H235.99C233.283 11.5373 232.035 9.75453 232.035 7.08045C232.035 4.18352 233.374 2.26715 236.568 2.26715H245.756L243.657 6.27825H236.325C235.564 6.27825 235.199 6.54569 235.199 7.34791C235.199 8.15014 235.564 8.4175 236.325 8.4175H242.044Z"
    //       fill="#F1B207"
    //     />
    //   </g>
    //   <g filter="url(#filter1_d_2900_1963)">
    //     <path
    //       d="M31.3246 9.1322H25.3916V14.7237H31.3246V9.1322Z"
    //       fill="#F1B207"
    //     />
    //     <path
    //       d="M23.5951 9.1322V29.2225C23.5951 32.2718 20.9717 34.7443 17.7361 34.7443H17.6621V14.7237H17.6823C17.6722 14.7237 17.6621 14.7142 17.6621 14.7047V12.8028C17.6621 10.7742 19.4043 9.1322 21.5569 9.1322H23.5951Z"
    //       fill="#F1B207"
    //     />
    //     <path
    //       d="M44.9862 9.14805V10.6917C44.9862 12.917 43.0724 14.7237 40.7079 14.7237H33.1201V9.1322H44.966C44.9761 9.1322 44.9862 9.13854 44.9862 9.14805Z"
    //       fill="#F1B207"
    //     />
    //     <path
    //       d="M4.02018 2.00006H27.4294C29.5819 2.00006 31.3242 3.64202 31.3242 5.6707V7.57259C31.3242 7.5821 31.3141 7.59161 31.304 7.59161H31.3242V27.6153H31.2502C28.0146 27.6153 25.3911 25.1429 25.3911 22.0935V7.59478H8.27823C5.91377 7.59478 4 5.79115 4 3.56278V2.02225C4 2.0064 4.01009 2.00006 4.02018 2.00006Z"
    //       fill="#1188A8"
    //     />
    //   </g>
    //   <g className="logo-text" filter="url(#filter2_d_2900_1963)">
    //     <path
    //       d="M85.5135 32.1537C84.8443 32.1537 84.2655 31.994 83.7771 31.6747C83.2888 31.3463 82.9135 30.9039 82.6512 30.3474C82.3889 29.7909 82.2578 29.1751 82.2578 28.5C82.2578 27.8158 82.4025 27.2 82.6919 26.6526C82.9813 26.0961 83.3973 25.6582 83.9399 25.3389C84.4825 25.0105 85.1337 24.8463 85.8933 24.8463C86.6259 24.8463 87.2544 25.006 87.7789 25.3253C88.3034 25.6446 88.7059 26.0779 88.9862 26.6253C89.2756 27.1726 89.4203 27.7839 89.4203 28.4589V31.9895H88.1587V30.5663H88.1316C87.996 30.8309 87.8106 31.0863 87.5754 31.3326C87.3493 31.5698 87.0645 31.766 86.7208 31.9211C86.3772 32.0761 85.9747 32.1537 85.5135 32.1537ZM85.8391 31.0726C86.2912 31.0726 86.6892 30.9632 87.0328 30.7442C87.3855 30.5161 87.6568 30.206 87.8467 29.8137C88.0457 29.4123 88.1452 28.9653 88.1452 28.4726C88.1452 27.9891 88.0502 27.5558 87.8603 27.1726C87.6794 26.7895 87.4172 26.4884 87.0735 26.2695C86.7299 26.0414 86.3274 25.9274 85.8662 25.9274C85.3778 25.9274 84.9573 26.0414 84.6046 26.2695C84.261 26.4975 83.9942 26.8077 83.8043 27.2C83.6234 27.5832 83.533 28.0211 83.533 28.5137C83.533 28.9881 83.6234 29.4214 83.8043 29.8137C83.9851 30.1968 84.2474 30.5025 84.5911 30.7305C84.9347 30.9586 85.3507 31.0726 85.8391 31.0726Z"
    //       fill="black"
    //     />
    //     <path
    //       d="M94.9975 31.9895V22H96.2455V25.9137C96.5259 25.5488 96.865 25.2796 97.2629 25.1063C97.6699 24.933 98.0904 24.8463 98.5245 24.8463C99.1395 24.8463 99.673 24.9786 100.125 25.2432C100.577 25.4986 100.93 25.8589 101.183 26.3242C101.437 26.7895 101.563 27.3323 101.563 27.9526V31.9895H100.315V27.9663C100.315 27.5467 100.22 27.1863 100.03 26.8853C99.8403 26.5751 99.5871 26.3379 99.2706 26.1737C98.9631 26.0095 98.633 25.9274 98.2803 25.9274C97.9276 25.9274 97.5975 26.0095 97.29 26.1737C96.9826 26.3288 96.7293 26.5568 96.5304 26.8579C96.3405 27.1589 96.2455 27.5193 96.2455 27.9389V31.9895H94.9975Z"
    //       fill="black"
    //     />
    //     <path
    //       d="M107.056 31.9895C106.296 31.9895 105.622 31.8481 105.034 31.5653C104.446 31.2733 103.985 30.8674 103.651 30.3474C103.325 29.8182 103.162 29.2025 103.162 28.5C103.162 27.8067 103.302 27.1863 103.583 26.6389C103.863 26.0825 104.252 25.6446 104.749 25.3253C105.247 25.006 105.826 24.8463 106.486 24.8463C107.2 24.8463 107.793 25.0014 108.263 25.3116C108.742 25.6218 109.099 26.0414 109.334 26.5705C109.57 27.0905 109.687 27.6744 109.687 28.3221C109.687 28.4225 109.683 28.5274 109.674 28.6368C109.674 28.7463 109.665 28.8421 109.646 28.9242H104.492C104.537 29.353 104.677 29.7179 104.912 30.0189C105.147 30.3109 105.446 30.5344 105.807 30.6895C106.178 30.8354 106.585 30.9084 107.028 30.9084H108.833V31.9895H107.056ZM104.478 28.0347H108.426C108.426 27.8614 108.408 27.6789 108.371 27.4874C108.335 27.2867 108.272 27.0951 108.181 26.9126C108.091 26.7211 107.969 26.5523 107.815 26.4063C107.67 26.2512 107.485 26.1281 107.259 26.0368C107.042 25.9456 106.784 25.9 106.486 25.9C106.169 25.9 105.889 25.9639 105.645 26.0916C105.41 26.2102 105.206 26.3744 105.034 26.5842C104.862 26.7849 104.731 27.013 104.641 27.2684C104.55 27.5239 104.496 27.7793 104.478 28.0347Z"
    //       fill="black"
    //     />
    //     <path
    //       d="M114.154 32.1537C113.485 32.1537 112.906 31.994 112.418 31.6747C111.93 31.3463 111.554 30.9039 111.292 30.3474C111.03 29.7909 110.899 29.1751 110.899 28.5C110.899 27.8158 111.043 27.2 111.333 26.6526C111.622 26.0961 112.038 25.6582 112.581 25.3389C113.123 25.0105 113.775 24.8463 114.534 24.8463C115.267 24.8463 115.895 25.006 116.42 25.3253C116.944 25.6446 117.347 26.0779 117.627 26.6253C117.917 27.1726 118.061 27.7839 118.061 28.4589V31.9895H116.8V30.5663H116.773C116.637 30.8309 116.452 31.0863 116.216 31.3326C115.99 31.5698 115.705 31.766 115.362 31.9211C115.018 32.0761 114.616 32.1537 114.154 32.1537ZM114.48 31.0726C114.932 31.0726 115.33 30.9632 115.674 30.7442C116.026 30.5161 116.298 30.206 116.488 29.8137C116.687 29.4123 116.786 28.9653 116.786 28.4726C116.786 27.9891 116.691 27.5558 116.501 27.1726C116.32 26.7895 116.058 26.4884 115.714 26.2695C115.371 26.0414 114.968 25.9274 114.507 25.9274C114.019 25.9274 113.598 26.0414 113.246 26.2695C112.902 26.4975 112.635 26.8077 112.445 27.2C112.264 27.5832 112.174 28.0211 112.174 28.5137C112.174 28.9881 112.264 29.4214 112.445 29.8137C112.626 30.1968 112.888 30.5025 113.232 30.7305C113.576 30.9586 113.992 31.0726 114.48 31.0726Z"
    //       fill="black"
    //     />
    //     <path d="M120.048 31.9895V22H121.296V31.9895H120.048Z" fill="black" />
    //     <path
    //       d="M125.641 31.9895C125.026 31.9895 124.542 31.8116 124.19 31.4558C123.846 31.1 123.674 30.6165 123.674 30.0053V23.2589H124.922V29.9232C124.922 30.2242 125.004 30.466 125.166 30.6484C125.338 30.8218 125.573 30.9084 125.872 30.9084H127.269V31.9895H125.641ZM122.48 26.0916V25.0105H127.296V26.0916H122.48Z"
    //       fill="black"
    //     />
    //     <path
    //       d="M128.805 31.9895V22H130.053V25.9137C130.333 25.5488 130.672 25.2796 131.07 25.1063C131.477 24.933 131.898 24.8463 132.332 24.8463C132.947 24.8463 133.48 24.9786 133.933 25.2432C134.385 25.4986 134.738 25.8589 134.991 26.3242C135.244 26.7895 135.371 27.3323 135.371 27.9526V31.9895H134.123V27.9663C134.123 27.5467 134.028 27.1863 133.838 26.8853C133.648 26.5751 133.395 26.3379 133.078 26.1737C132.771 26.0095 132.44 25.9274 132.088 25.9274C131.735 25.9274 131.405 26.0095 131.097 26.1737C130.79 26.3288 130.537 26.5568 130.338 26.8579C130.148 27.1589 130.053 27.5193 130.053 27.9389V31.9895H128.805Z"
    //       fill="black"
    //     />
    //     <path
    //       d="M140.7 31.9895C139.986 31.9895 139.348 31.8344 138.787 31.5242C138.227 31.214 137.784 30.7989 137.458 30.2789C137.132 29.7498 136.97 29.1568 136.97 28.5C136.97 27.8432 137.132 27.2547 137.458 26.7347C137.784 26.2056 138.227 25.786 138.787 25.4758C139.348 25.1656 139.986 25.0105 140.7 25.0105H141.514V26.0916H140.659C140.198 26.0916 139.782 26.2011 139.411 26.42C139.05 26.6298 138.76 26.9172 138.543 27.2821C138.335 27.647 138.231 28.053 138.231 28.5C138.231 28.947 138.335 29.353 138.543 29.7179C138.76 30.0828 139.05 30.3747 139.411 30.5937C139.782 30.8035 140.198 30.9084 140.659 30.9084H141.514V31.9895H140.7Z"
    //       fill="black"
    //     />
    //     <path
    //       d="M145.816 32.1537C145.147 32.1537 144.568 31.994 144.079 31.6747C143.591 31.3463 143.216 30.9039 142.954 30.3474C142.691 29.7909 142.56 29.1751 142.56 28.5C142.56 27.8158 142.705 27.2 142.994 26.6526C143.284 26.0961 143.7 25.6582 144.242 25.3389C144.785 25.0105 145.436 24.8463 146.196 24.8463C146.928 24.8463 147.557 25.006 148.081 25.3253C148.606 25.6446 149.008 26.0779 149.289 26.6253C149.578 27.1726 149.723 27.7839 149.723 28.4589V31.9895H148.461V30.5663H148.434C148.298 30.8309 148.113 31.0863 147.878 31.3326C147.652 31.5698 147.367 31.766 147.023 31.9211C146.679 32.0761 146.277 32.1537 145.816 32.1537ZM146.141 31.0726C146.594 31.0726 146.991 30.9632 147.335 30.7442C147.688 30.5161 147.959 30.206 148.149 29.8137C148.348 29.4123 148.447 28.9653 148.447 28.4726C148.447 27.9891 148.353 27.5558 148.163 27.1726C147.982 26.7895 147.719 26.4884 147.376 26.2695C147.032 26.0414 146.63 25.9274 146.168 25.9274C145.68 25.9274 145.26 26.0414 144.907 26.2695C144.563 26.4975 144.296 26.8077 144.107 27.2C143.926 27.5832 143.835 28.0211 143.835 28.5137C143.835 28.9881 143.926 29.4214 144.107 29.8137C144.287 30.1968 144.55 30.5025 144.893 30.7305C145.237 30.9586 145.653 31.0726 146.141 31.0726Z"
    //       fill="black"
    //     />
    //     <path
    //       d="M151.669 31.9895V26.9947C151.669 26.3744 151.841 25.8909 152.185 25.5442C152.537 25.1884 153.021 25.0105 153.636 25.0105H155.06V26.0916H153.867C153.568 26.0916 153.333 26.1782 153.161 26.3516C152.998 26.5249 152.917 26.7667 152.917 27.0768V31.9895H151.669Z"
    //       fill="black"
    //     />
    //     <path
    //       d="M159.727 31.9895C158.968 31.9895 158.294 31.8481 157.706 31.5653C157.118 31.2733 156.657 30.8674 156.322 30.3474C155.997 29.8182 155.834 29.2025 155.834 28.5C155.834 27.8067 155.974 27.1863 156.255 26.6389C156.535 26.0825 156.924 25.6446 157.421 25.3253C157.919 25.006 158.497 24.8463 159.158 24.8463C159.872 24.8463 160.464 25.0014 160.935 25.3116C161.414 25.6218 161.771 26.0414 162.006 26.5705C162.241 27.0905 162.359 27.6744 162.359 28.3221C162.359 28.4225 162.354 28.5274 162.345 28.6368C162.345 28.7463 162.336 28.8421 162.318 28.9242H157.163C157.209 29.353 157.349 29.7179 157.584 30.0189C157.819 30.3109 158.118 30.5344 158.479 30.6895C158.85 30.8354 159.257 30.9084 159.7 30.9084H161.504V31.9895H159.727ZM157.15 28.0347H161.097C161.097 27.8614 161.079 27.6789 161.043 27.4874C161.007 27.2867 160.944 27.0951 160.853 26.9126C160.763 26.7211 160.641 26.5523 160.487 26.4063C160.342 26.2512 160.157 26.1281 159.931 26.0368C159.714 25.9456 159.456 25.9 159.158 25.9C158.841 25.9 158.561 25.9639 158.316 26.0916C158.081 26.2102 157.878 26.3744 157.706 26.5842C157.534 26.7849 157.403 27.013 157.313 27.2684C157.222 27.5239 157.168 27.7793 157.15 28.0347Z"
    //       fill="black"
    //     />
    //     <path
    //       d="M171.04 24.8463C171.565 24.8463 172.044 24.933 172.478 25.1063C172.912 25.2705 173.288 25.5168 173.604 25.8453C173.921 26.1737 174.165 26.5705 174.337 27.0358C174.518 27.4919 174.608 28.0074 174.608 28.5821C174.608 29.3028 174.459 29.9323 174.16 30.4705C173.871 31.0088 173.473 31.4239 172.967 31.7158C172.46 32.0077 171.886 32.1537 171.244 32.1537C170.891 32.1537 170.552 32.0944 170.226 31.9758C169.91 31.8572 169.625 31.693 169.372 31.4832C169.128 31.2642 168.929 31.0179 168.775 30.7442H168.748V35H167.5V28.5411C167.5 27.7565 167.653 27.0905 167.961 26.5432C168.277 25.9958 168.703 25.5761 169.236 25.2842C169.77 24.9923 170.371 24.8463 171.04 24.8463ZM171.04 25.9274C170.624 25.9274 170.24 26.0323 169.887 26.2421C169.544 26.4519 169.268 26.7484 169.06 27.1316C168.861 27.5147 168.761 27.9663 168.761 28.4863C168.761 29.0154 168.861 29.4761 169.06 29.8684C169.268 30.2516 169.544 30.5481 169.887 30.7579C170.24 30.9677 170.629 31.0726 171.054 31.0726C171.488 31.0726 171.877 30.9632 172.22 30.7442C172.564 30.5253 172.835 30.2242 173.034 29.8411C173.242 29.4579 173.346 29.0109 173.346 28.5C173.346 27.9709 173.242 27.5147 173.034 27.1316C172.826 26.7484 172.546 26.4519 172.193 26.2421C171.85 26.0323 171.465 25.9274 171.04 25.9274Z"
    //       fill="black"
    //     />
    //     <path d="M176.204 31.9895V22H177.452V31.9895H176.204Z" fill="black" />
    //     <path
    //       d="M182.312 32.1537C181.643 32.1537 181.064 31.994 180.576 31.6747C180.088 31.3463 179.712 30.9039 179.45 30.3474C179.188 29.7909 179.057 29.1751 179.057 28.5C179.057 27.8158 179.201 27.2 179.491 26.6526C179.78 26.0961 180.196 25.6582 180.739 25.3389C181.281 25.0105 181.933 24.8463 182.692 24.8463C183.425 24.8463 184.053 25.006 184.578 25.3253C185.102 25.6446 185.505 26.0779 185.785 26.6253C186.075 27.1726 186.219 27.7839 186.219 28.4589V31.9895H184.958V30.5663H184.931C184.795 30.8309 184.61 31.0863 184.374 31.3326C184.148 31.5698 183.863 31.766 183.52 31.9211C183.176 32.0761 182.774 32.1537 182.312 32.1537ZM182.638 31.0726C183.09 31.0726 183.488 30.9632 183.832 30.7442C184.184 30.5161 184.456 30.206 184.646 29.8137C184.845 29.4123 184.944 28.9653 184.944 28.4726C184.944 27.9891 184.849 27.5558 184.659 27.1726C184.478 26.7895 184.216 26.4884 183.872 26.2695C183.529 26.0414 183.126 25.9274 182.665 25.9274C182.177 25.9274 181.756 26.0414 181.404 26.2695C181.06 26.4975 180.793 26.8077 180.603 27.2C180.422 27.5832 180.332 28.0211 180.332 28.5137C180.332 28.9881 180.422 29.4214 180.603 29.8137C180.784 30.1968 181.046 30.5025 181.39 30.7305C181.734 30.9586 182.15 31.0726 182.638 31.0726Z"
    //       fill="black"
    //     />
    //     <path
    //       d="M190.567 31.9895C189.952 31.9895 189.468 31.8116 189.115 31.4558C188.772 31.1 188.6 30.6165 188.6 30.0053V23.2589H189.848V29.9232C189.848 30.2242 189.929 30.466 190.092 30.6484C190.264 30.8218 190.499 30.9084 190.797 30.9084H192.195V31.9895H190.567ZM187.406 26.0916V25.0105H192.222V26.0916H187.406Z"
    //       fill="black"
    //     />
    //     <path
    //       d="M194.138 31.9895V26.0916H192.93V25.0105H194.138V23.9842C194.138 23.3639 194.309 22.8804 194.653 22.5337C195.006 22.1779 195.49 22 196.105 22H197.814V23.0811H196.349C196.041 23.0811 195.806 23.1677 195.643 23.3411C195.481 23.5144 195.399 23.7561 195.399 24.0663V25.0105H197.542V26.0916H195.399V31.9895H194.138Z"
    //       fill="black"
    //     />
    //     <path
    //       d="M201.623 32.1537C200.917 32.1537 200.293 31.994 199.751 31.6747C199.208 31.3463 198.779 30.9084 198.462 30.3611C198.146 29.8046 197.987 29.1842 197.987 28.5C197.987 27.8158 198.146 27.2 198.462 26.6526C198.779 26.0961 199.208 25.6582 199.751 25.3389C200.293 25.0105 200.917 24.8463 201.623 24.8463C202.328 24.8463 202.952 25.0105 203.495 25.3389C204.047 25.6582 204.476 26.0916 204.784 26.6389C205.1 27.1863 205.258 27.8067 205.258 28.5C205.258 29.1842 205.1 29.8046 204.784 30.3611C204.476 30.9084 204.047 31.3463 203.495 31.6747C202.952 31.994 202.328 32.1537 201.623 32.1537ZM201.623 31.0726C202.102 31.0726 202.518 30.9586 202.871 30.7305C203.224 30.5025 203.499 30.1968 203.698 29.8137C203.897 29.4214 203.997 28.9835 203.997 28.5C203.997 28.0165 203.897 27.5832 203.698 27.2C203.499 26.8077 203.224 26.4975 202.871 26.2695C202.518 26.0414 202.102 25.9274 201.623 25.9274C201.153 25.9274 200.737 26.0414 200.375 26.2695C200.022 26.4975 199.746 26.8077 199.547 27.2C199.348 27.5832 199.249 28.0165 199.249 28.5C199.249 28.9835 199.348 29.4214 199.547 29.8137C199.746 30.1968 200.022 30.5025 200.375 30.7305C200.737 30.9586 201.153 31.0726 201.623 31.0726Z"
    //       fill="black"
    //     />
    //     <path
    //       d="M206.818 31.9895V26.9947C206.818 26.3744 206.99 25.8909 207.334 25.5442C207.686 25.1884 208.17 25.0105 208.785 25.0105H210.209V26.0916H209.016C208.717 26.0916 208.482 26.1782 208.31 26.3516C208.147 26.5249 208.066 26.7667 208.066 27.0768V31.9895H206.818Z"
    //       fill="black"
    //     />
    //     <path
    //       d="M211.363 31.9895V27.7884C211.363 27.2502 211.485 26.7575 211.729 26.3105C211.973 25.8635 212.326 25.5077 212.787 25.2432C213.248 24.9786 213.805 24.8463 214.456 24.8463C214.772 24.8463 215.089 24.8919 215.405 24.9832C215.722 25.0653 216.016 25.2112 216.287 25.4211C216.558 25.6218 216.775 25.8954 216.938 26.2421H216.952C217.133 25.8954 217.359 25.6218 217.63 25.4211C217.901 25.2112 218.195 25.0653 218.512 24.9832C218.828 24.8919 219.149 24.8463 219.475 24.8463C220.117 24.8463 220.669 24.9786 221.13 25.2432C221.591 25.5077 221.944 25.8635 222.188 26.3105C222.432 26.7575 222.554 27.2502 222.554 27.7884V31.9895H221.306V27.7884C221.306 27.4418 221.225 27.127 221.062 26.8442C220.899 26.5614 220.678 26.3379 220.397 26.1737C220.126 26.0095 219.814 25.9274 219.461 25.9274C219.118 25.9274 218.801 26.014 218.512 26.1874C218.222 26.3516 217.992 26.5751 217.82 26.8579C217.657 27.1316 217.576 27.4418 217.576 27.7884V31.9895H216.328V27.7884C216.328 27.4418 216.242 27.1316 216.07 26.8579C215.907 26.5751 215.681 26.3516 215.392 26.1874C215.111 26.014 214.795 25.9274 214.442 25.9274C214.099 25.9274 213.787 26.0095 213.506 26.1737C213.235 26.3379 213.018 26.5614 212.855 26.8442C212.692 27.127 212.611 27.4418 212.611 27.7884V31.9895H211.363Z"
    //       fill="black"
    //     />
    //   </g>
    //   <defs>
    //     <filter
    //       id="filter0_d_2900_1963"
    //       x="48"
    //       y="0"
    //       width="201.999"
    //       height="24"
    //       filterUnits="userSpaceOnUse"
    //       color-interpolation-filters="sRGB"
    //     >
    //       <feFlood flood-opacity="0" result="BackgroundImageFix" />
    //       <feColorMatrix
    //         in="SourceAlpha"
    //         type="matrix"
    //         values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
    //         result="hardAlpha"
    //       />
    //       <feOffset dy="2" />
    //       <feGaussianBlur stdDeviation="2" />
    //       <feComposite in2="hardAlpha" operator="out" />
    //       <feColorMatrix
    //         type="matrix"
    //         values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
    //       />
    //       <feBlend
    //         mode="normal"
    //         in2="BackgroundImageFix"
    //         result="effect1_dropShadow_2900_1963"
    //       />
    //       <feBlend
    //         mode="normal"
    //         in="SourceGraphic"
    //         in2="effect1_dropShadow_2900_1963"
    //         result="shape"
    //       />
    //     </filter>
    //     <filter
    //       id="filter1_d_2900_1963"
    //       x="0"
    //       y="6.10352e-05"
    //       width="48.9863"
    //       height="40.7442"
    //       filterUnits="userSpaceOnUse"
    //       color-interpolation-filters="sRGB"
    //     >
    //       <feFlood flood-opacity="0" result="BackgroundImageFix" />
    //       <feColorMatrix
    //         in="SourceAlpha"
    //         type="matrix"
    //         values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
    //         result="hardAlpha"
    //       />
    //       <feOffset dy="2" />
    //       <feGaussianBlur stdDeviation="2" />
    //       <feComposite in2="hardAlpha" operator="out" />
    //       <feColorMatrix
    //         type="matrix"
    //         values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
    //       />
    //       <feBlend
    //         mode="normal"
    //         in2="BackgroundImageFix"
    //         result="effect1_dropShadow_2900_1963"
    //       />
    //       <feBlend
    //         mode="normal"
    //         in="SourceGraphic"
    //         in2="effect1_dropShadow_2900_1963"
    //         result="shape"
    //       />
    //     </filter>
    //     <filter
    //       id="filter2_d_2900_1963"
    //       x="78.2578"
    //       y="20"
    //       width="148.297"
    //       height="21"
    //       filterUnits="userSpaceOnUse"
    //       color-interpolation-filters="sRGB"
    //     >
    //       <feFlood flood-opacity="0" result="BackgroundImageFix" />
    //       <feColorMatrix
    //         in="SourceAlpha"
    //         type="matrix"
    //         values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
    //         result="hardAlpha"
    //       />
    //       <feOffset dy="2" />
    //       <feGaussianBlur stdDeviation="2" />
    //       <feComposite in2="hardAlpha" operator="out" />
    //       <feColorMatrix
    //         type="matrix"
    //         values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
    //       />
    //       <feBlend
    //         mode="normal"
    //         in2="BackgroundImageFix"
    //         result="effect1_dropShadow_2900_1963"
    //       />
    //       <feBlend
    //         mode="normal"
    //         in="SourceGraphic"
    //         in2="effect1_dropShadow_2900_1963"
    //         result="shape"
    //       />
    //     </filter>
    //   </defs>
    // </svg>
    <svg width="250" height="40" viewBox="0 0 250 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M63.8395 12.5385L61.3276 15.8188H56.8599V25.139H52.9866V15.8188H47.1191L49.631 12.5385H63.8395Z" fill="#1188A8"/>
<path d="M79.5442 21.8779L77.0515 25.1197H65.0098V12.5385H79.5058L76.994 15.8188H68.9022V17.3239H78.8156L76.5913 20.1798H68.9022V21.8972H79.5442V21.8779Z" fill="#1188A8"/>
<path d="M98.6245 25.1198H93.4474L90.6671 21.7815H84.7229V25.1198H80.8496V18.6748H91.7408C92.9297 18.6748 93.8692 18.1345 93.8692 17.2661C93.8692 16.282 93.0639 15.7996 91.7408 15.7996H80.8496L83.3807 12.5192H91.4149C94.2527 12.5192 97.8384 13.4262 97.8384 17.0153C97.8384 18.9642 96.6304 20.5851 94.5595 21.0096C94.9814 21.3377 95.4607 21.7815 96.3236 22.6691L98.6245 25.1198Z" fill="#1188A8"/>
<path d="M105.296 25.1205L107.635 21.8787H110.78C111.528 21.8787 112.41 21.8787 113.004 21.9173C112.659 21.4735 112.141 20.7016 111.739 20.0841L109.495 16.7459L103.628 25.1012H99.0449L107.06 13.7163C107.578 12.983 108.345 12.3077 109.572 12.3077C110.722 12.3077 111.489 12.9252 112.045 13.7163L119.869 25.1012H105.296V25.1205Z" fill="#1188A8"/>
<path d="M136.55 21.74L134.495 24.9209H129.313C125.739 24.9209 123.088 22.2047 123.088 18.595C123.088 14.9495 125.739 12.5192 129.313 12.5192H136.58L134.525 15.7358H129.342C127.615 15.7358 126.305 16.9867 126.305 18.7737C126.305 20.5249 127.585 21.74 129.342 21.74H136.55Z" fill="#F1B207"/>
<path d="M152.452 18.7051C152.452 23.1369 149.921 25.1383 144.887 25.1383C139.854 25.1383 137.322 23.1369 137.322 18.7051C137.322 14.2734 139.854 12.3077 144.887 12.3077C149.921 12.3077 152.452 14.2734 152.452 18.7051ZM149.265 18.7051C149.265 16.0246 148.104 15.5243 144.887 15.5243C141.671 15.5243 140.509 16.0246 140.509 18.7051C140.509 21.3856 141.671 21.886 144.887 21.886C148.104 21.886 149.265 21.3856 149.265 18.7051Z" fill="#F1B207"/>
<path d="M163.296 24.4592L156.952 17.6329V24.9238H154.033V14.6308C154.033 12.9868 154.867 12.3077 155.79 12.3077C156.178 12.3077 156.565 12.4149 157.071 12.9867L163.415 19.813V12.5222H166.334V22.8152C166.334 24.4592 165.5 25.1383 164.577 25.1383C164.16 25.1383 163.772 25.0311 163.296 24.4592Z" fill="#F1B207"/>
<path d="M177.411 24.4592L171.067 17.6329V24.9238H168.148V14.6308C168.148 12.9868 168.982 12.3077 169.906 12.3077C170.293 12.3077 170.68 12.4149 171.186 12.9867L177.53 19.813V12.5222H180.449V22.8152C180.449 24.4592 179.615 25.1383 178.692 25.1383C178.305 25.1383 177.917 25.0311 177.411 24.4592Z" fill="#F1B207"/>
<path d="M194.36 21.74L192.305 24.9209H182.357V12.5192H194.33L192.246 15.7358H185.544V17.2368H193.735L191.888 20.0603H185.544V21.74H194.36Z" fill="#F1B207"/>
<path d="M208.626 21.74L206.571 24.9209H201.389C197.815 24.9209 195.164 22.2047 195.164 18.595C195.164 14.9495 197.815 12.5192 201.389 12.5192H208.656L206.601 15.7358H201.419C199.691 15.7358 198.381 16.9867 198.381 18.7737C198.381 20.5249 199.661 21.74 201.419 21.74H208.626Z" fill="#F1B207"/>
<path d="M222.595 12.5192L220.51 15.7358H216.817V24.9209H213.63V15.7358H208.775L210.86 12.5192H222.595Z" fill="#F1B207"/>
<path d="M233.168 17.4513C235.7 17.4513 237.04 18.5949 237.04 20.8465C237.04 23.3841 235.76 24.9209 232.603 24.9209H222.982L225.037 21.74H232.871C233.615 21.74 233.973 21.4899 233.973 20.8465C233.973 20.2032 233.645 19.953 232.871 19.953H227.241C224.591 19.953 223.37 18.5235 223.37 16.3791C223.37 14.056 224.68 12.5192 227.807 12.5192H236.802L234.747 15.7358H227.569C226.825 15.7358 226.467 15.9502 226.467 16.5936C226.467 17.2369 226.825 17.4513 227.569 17.4513H233.168Z" fill="#F1B207"/>
<path d="M26.1783 18.337H20.4941V23.0664H26.1783V18.337Z" fill="#F1B207"/>
<path d="M18.774 18.337V35.3296C18.774 37.9088 16.2606 40 13.1607 40H13.0898V23.0664H13.1092C13.0995 23.0664 13.0898 23.0583 13.0898 23.0503V21.4417C13.0898 19.7258 14.759 18.337 16.8213 18.337H18.774Z" fill="#F1B207"/>
<path d="M39.2667 18.3504V19.6561C39.2667 21.5382 37.4332 23.0664 35.168 23.0664H27.8984V18.337H39.2474C39.2571 18.337 39.2667 18.3423 39.2667 18.3504Z" fill="#F1B207"/>
<path d="M0.019333 12.3077H22.4466C24.5088 12.3077 26.178 13.6965 26.178 15.4124V17.0211C26.178 17.0291 26.1683 17.0371 26.1587 17.0371H26.178V33.9734H26.1071C23.0072 33.9734 20.4938 31.8822 20.4938 29.303V17.0398H4.09877C1.83349 17.0398 0 15.5143 0 13.6295V12.3265C0 12.3131 0.00966612 12.3077 0.019333 12.3077Z" fill="#1188A8"/>
<path d="M76.4861 36.4885C75.8309 36.4885 75.2643 36.3605 74.7862 36.1044C74.3081 35.8411 73.9407 35.4863 73.684 35.04C73.4272 34.5937 73.2988 34.0999 73.2988 33.5586C73.2988 33.0099 73.4405 32.5161 73.7238 32.0771C74.0071 31.6309 74.4144 31.2797 74.9456 31.0237C75.4768 30.7603 76.1142 30.6286 76.8579 30.6286C77.575 30.6286 78.1904 30.7567 78.7039 31.0127C79.2174 31.2688 79.6113 31.6162 79.8858 32.0552C80.1691 32.4941 80.3107 32.9843 80.3107 33.5256V36.3568H79.0757V35.2156H79.0491C78.9163 35.4277 78.7348 35.6326 78.5046 35.8301C78.2833 36.0203 78.0044 36.1776 77.668 36.302C77.3316 36.4263 76.9376 36.4885 76.4861 36.4885ZM76.8048 35.6216C77.2475 35.6216 77.637 35.5338 77.9734 35.3582C78.3187 35.1753 78.5843 34.9266 78.7703 34.612C78.965 34.2901 79.0624 33.9317 79.0624 33.5366C79.0624 33.1489 78.9695 32.8014 78.7835 32.4941C78.6065 32.1869 78.3497 31.9455 78.0133 31.7699C77.6769 31.587 77.2829 31.4955 76.8313 31.4955C76.3533 31.4955 75.9416 31.587 75.5963 31.7699C75.2599 31.9528 74.9987 32.2015 74.8128 32.5161C74.6357 32.8233 74.5472 33.1745 74.5472 33.5695C74.5472 33.95 74.6357 34.2975 74.8128 34.612C74.9898 34.9193 75.2466 35.1644 75.583 35.3473C75.9194 35.5301 76.3267 35.6216 76.8048 35.6216Z" fill="black"/>
<path d="M85.7707 36.3568V28.3461H86.9924V31.4846C87.2669 31.1919 87.5989 30.9761 87.9884 30.8371C88.3868 30.6981 88.7985 30.6286 89.2235 30.6286C89.8255 30.6286 90.3479 30.7347 90.7906 30.9469C91.2332 31.1517 91.5785 31.4407 91.8264 31.8138C92.0743 32.1869 92.1982 32.6222 92.1982 33.1196V36.3568H90.9765V33.1306C90.9765 32.7941 90.8835 32.5051 90.6976 32.2637C90.5117 32.015 90.2638 31.8247 89.9539 31.6931C89.6529 31.5614 89.3297 31.4955 88.9845 31.4955C88.6392 31.4955 88.316 31.5614 88.015 31.6931C87.714 31.8174 87.4661 32.0003 87.2713 32.2417C87.0854 32.4832 86.9924 32.7721 86.9924 33.1087V36.3568H85.7707Z" fill="black"/>
<path d="M97.5752 36.3568C96.8315 36.3568 96.1719 36.2434 95.5964 36.0166C95.0209 35.7825 94.5694 35.457 94.2418 35.04C93.9231 34.6157 93.7637 34.1219 93.7637 33.5586C93.7637 33.0026 93.901 32.5051 94.1754 32.0662C94.4499 31.6199 94.8306 31.2688 95.3175 31.0127C95.8045 30.7567 96.3711 30.6286 97.0174 30.6286C97.7168 30.6286 98.2967 30.753 98.7571 31.0017C99.2263 31.2505 99.576 31.587 99.8062 32.0113C100.036 32.4283 100.152 32.8965 100.152 33.4159C100.152 33.4964 100.147 33.5805 100.138 33.6683C100.138 33.7561 100.129 33.8329 100.112 33.8987H95.0652C95.1095 34.2426 95.2467 34.5352 95.4769 34.7766C95.7071 35.0107 95.9992 35.19 96.3534 35.3143C96.7164 35.4314 97.1148 35.4899 97.5486 35.4899H99.3149V36.3568H97.5752ZM95.0519 33.1855H98.9164C98.9164 33.0465 98.8987 32.9002 98.8633 32.7465C98.8279 32.5856 98.7659 32.4319 98.6774 32.2856C98.5889 32.132 98.4694 31.9967 98.3188 31.8796C98.1772 31.7552 97.9957 31.6565 97.7744 31.5833C97.5619 31.5102 97.3095 31.4736 97.0174 31.4736C96.7075 31.4736 96.4331 31.5248 96.194 31.6272C95.9638 31.7223 95.7646 31.854 95.5964 32.0223C95.4282 32.1832 95.2998 32.3661 95.2113 32.5709C95.1228 32.7758 95.0696 32.9806 95.0519 33.1855Z" fill="black"/>
<path d="M104.525 36.4885C103.87 36.4885 103.303 36.3605 102.825 36.1044C102.347 35.8411 101.979 35.4863 101.723 35.04C101.466 34.5937 101.338 34.0999 101.338 33.5586C101.338 33.0099 101.479 32.5161 101.763 32.0771C102.046 31.6309 102.453 31.2797 102.984 31.0237C103.516 30.7603 104.153 30.6286 104.897 30.6286C105.614 30.6286 106.229 30.7567 106.743 31.0127C107.256 31.2688 107.65 31.6162 107.925 32.0552C108.208 32.4941 108.35 32.9843 108.35 33.5256V36.3568H107.114V35.2156H107.088C106.955 35.4277 106.774 35.6326 106.543 35.8301C106.322 36.0203 106.043 36.1776 105.707 36.302C105.37 36.4263 104.976 36.4885 104.525 36.4885ZM104.844 35.6216C105.286 35.6216 105.676 35.5338 106.012 35.3582C106.357 35.1753 106.623 34.9266 106.809 34.612C107.004 34.2901 107.101 33.9317 107.101 33.5366C107.101 33.1489 107.008 32.8014 106.822 32.4941C106.645 32.1869 106.388 31.9455 106.052 31.7699C105.716 31.587 105.322 31.4955 104.87 31.4955C104.392 31.4955 103.98 31.587 103.635 31.7699C103.299 31.9528 103.037 32.2015 102.852 32.5161C102.674 32.8233 102.586 33.1745 102.586 33.5695C102.586 33.95 102.674 34.2975 102.852 34.612C103.029 34.9193 103.285 35.1644 103.622 35.3473C103.958 35.5301 104.365 35.6216 104.844 35.6216Z" fill="black"/>
<path d="M110.295 36.3568V28.3461H111.517V36.3568H110.295Z" fill="black"/>
<path d="M115.77 36.3568C115.168 36.3568 114.694 36.2142 114.349 35.9289C114.013 35.6435 113.844 35.2558 113.844 34.7657V29.3557H115.066V34.6998C115.066 34.9412 115.146 35.1351 115.305 35.2814C115.473 35.4204 115.704 35.4899 115.996 35.4899H117.364V36.3568H115.77ZM112.676 31.6272V30.7603H117.39V31.6272H112.676Z" fill="black"/>
<path d="M118.867 36.3568V28.3461H120.089V31.4846C120.364 31.1919 120.696 30.9761 121.085 30.8371C121.483 30.6981 121.895 30.6286 122.32 30.6286C122.922 30.6286 123.445 30.7347 123.887 30.9469C124.33 31.1517 124.675 31.4407 124.923 31.8138C125.171 32.1869 125.295 32.6222 125.295 33.1196V36.3568H124.073V33.1306C124.073 32.7941 123.98 32.5051 123.794 32.2637C123.608 32.015 123.36 31.8247 123.051 31.6931C122.75 31.5614 122.426 31.4955 122.081 31.4955C121.736 31.4955 121.413 31.5614 121.112 31.6931C120.811 31.8174 120.563 32.0003 120.368 32.2417C120.182 32.4832 120.089 32.7721 120.089 33.1087V36.3568H118.867Z" fill="black"/>
<path d="M130.512 36.3568C129.813 36.3568 129.189 36.2325 128.64 35.9837C128.091 35.735 127.657 35.4021 127.338 34.9851C127.02 34.5608 126.86 34.0853 126.86 33.5586C126.86 33.0318 127.02 32.56 127.338 32.143C127.657 31.7187 128.091 31.3821 128.64 31.1334C129.189 30.8847 129.813 30.7603 130.512 30.7603H131.309V31.6272H130.473C130.021 31.6272 129.614 31.715 129.251 31.8906C128.897 32.0588 128.613 32.2893 128.401 32.5819C128.197 32.8745 128.095 33.2001 128.095 33.5586C128.095 33.917 128.197 34.2426 128.401 34.5352C128.613 34.8278 128.897 35.0619 129.251 35.2375C129.614 35.4058 130.021 35.4899 130.473 35.4899H131.309V36.3568H130.512Z" fill="black"/>
<path d="M135.52 36.4885C134.865 36.4885 134.299 36.3605 133.821 36.1044C133.343 35.8411 132.975 35.4863 132.718 35.04C132.462 34.5937 132.333 34.0999 132.333 33.5586C132.333 33.0099 132.475 32.5161 132.758 32.0771C133.042 31.6309 133.449 31.2797 133.98 31.0237C134.511 30.7603 135.149 30.6286 135.892 30.6286C136.609 30.6286 137.225 30.7567 137.738 31.0127C138.252 31.2688 138.646 31.6162 138.92 32.0552C139.204 32.4941 139.345 32.9843 139.345 33.5256V36.3568H138.11V35.2156H138.084C137.951 35.4277 137.769 35.6326 137.539 35.8301C137.318 36.0203 137.039 36.1776 136.702 36.302C136.366 36.4263 135.972 36.4885 135.52 36.4885ZM135.839 35.6216C136.282 35.6216 136.671 35.5338 137.008 35.3582C137.353 35.1753 137.619 34.9266 137.805 34.612C137.999 34.2901 138.097 33.9317 138.097 33.5366C138.097 33.1489 138.004 32.8014 137.818 32.4941C137.641 32.1869 137.384 31.9455 137.048 31.7699C136.711 31.587 136.317 31.4955 135.866 31.4955C135.388 31.4955 134.976 31.587 134.631 31.7699C134.294 31.9528 134.033 32.2015 133.847 32.5161C133.67 32.8233 133.582 33.1745 133.582 33.5695C133.582 33.95 133.67 34.2975 133.847 34.612C134.024 34.9193 134.281 35.1644 134.617 35.3473C134.954 35.5301 135.361 35.6216 135.839 35.6216Z" fill="black"/>
<path d="M141.251 36.3568V32.3515C141.251 31.854 141.419 31.4663 141.755 31.1883C142.101 30.903 142.574 30.7603 143.176 30.7603H144.571V31.6272H143.402C143.11 31.6272 142.88 31.6967 142.711 31.8357C142.552 31.9747 142.472 32.1686 142.472 32.4173V36.3568H141.251Z" fill="black"/>
<path d="M149.139 36.3568C148.396 36.3568 147.736 36.2434 147.161 36.0166C146.585 35.7825 146.134 35.457 145.806 35.04C145.487 34.6157 145.328 34.1219 145.328 33.5586C145.328 33.0026 145.465 32.5051 145.74 32.0662C146.014 31.6199 146.395 31.2688 146.882 31.0127C147.369 30.7567 147.935 30.6286 148.582 30.6286C149.281 30.6286 149.861 30.753 150.321 31.0017C150.791 31.2505 151.14 31.587 151.371 32.0113C151.601 32.4283 151.716 32.8965 151.716 33.4159C151.716 33.4964 151.711 33.5805 151.703 33.6683C151.703 33.7561 151.694 33.8329 151.676 33.8987H146.63C146.674 34.2426 146.811 34.5352 147.041 34.7766C147.271 35.0107 147.564 35.19 147.918 35.3143C148.281 35.4314 148.679 35.4899 149.113 35.4899H150.879V36.3568H149.139ZM146.616 33.1855H150.481C150.481 33.0465 150.463 32.9002 150.428 32.7465C150.392 32.5856 150.33 32.4319 150.242 32.2856C150.153 32.132 150.034 31.9967 149.883 31.8796C149.742 31.7552 149.56 31.6565 149.339 31.5833C149.126 31.5102 148.874 31.4736 148.582 31.4736C148.272 31.4736 147.997 31.5248 147.758 31.6272C147.528 31.7223 147.329 31.854 147.161 32.0223C146.993 32.1832 146.864 32.3661 146.776 32.5709C146.687 32.7758 146.634 32.9806 146.616 33.1855Z" fill="black"/>
<path d="M160.215 30.6286C160.728 30.6286 161.197 30.6981 161.622 30.8371C162.047 30.9688 162.415 31.1663 162.725 31.4297C163.034 31.6931 163.273 32.0113 163.442 32.3844C163.619 32.7502 163.707 33.1635 163.707 33.6244C163.707 34.2023 163.561 34.7071 163.269 35.1388C162.986 35.5704 162.596 35.9032 162.1 36.1374C161.605 36.3715 161.042 36.4885 160.414 36.4885C160.069 36.4885 159.737 36.441 159.418 36.3458C159.108 36.2507 158.829 36.1191 158.581 35.9508C158.342 35.7752 158.147 35.5777 157.997 35.3582H157.97V38.771H156.749V33.5915C156.749 32.9623 156.899 32.4283 157.2 31.9893C157.51 31.5504 157.926 31.2139 158.448 30.9798C158.971 30.7457 159.559 30.6286 160.215 30.6286ZM160.215 31.4955C159.807 31.4955 159.431 31.5797 159.086 31.7479C158.749 31.9162 158.479 32.154 158.276 32.4612C158.081 32.7685 157.984 33.1306 157.984 33.5476C157.984 33.9719 158.081 34.3413 158.276 34.6559C158.479 34.9632 158.749 35.2009 159.086 35.3692C159.431 35.5375 159.812 35.6216 160.228 35.6216C160.653 35.6216 161.034 35.5338 161.37 35.3582C161.706 35.1827 161.972 34.9412 162.167 34.634C162.37 34.3267 162.472 33.9682 162.472 33.5586C162.472 33.1343 162.37 32.7685 162.167 32.4612C161.963 32.154 161.689 31.9162 161.343 31.7479C161.007 31.5797 160.631 31.4955 160.215 31.4955Z" fill="black"/>
<path d="M165.27 36.3568V28.3461H166.492V36.3568H165.27Z" fill="black"/>
<path d="M171.25 36.4885C170.595 36.4885 170.028 36.3605 169.55 36.1044C169.072 35.8411 168.704 35.4863 168.448 35.04C168.191 34.5937 168.063 34.0999 168.063 33.5586C168.063 33.0099 168.204 32.5161 168.488 32.0771C168.771 31.6309 169.178 31.2797 169.709 31.0237C170.241 30.7603 170.878 30.6286 171.622 30.6286C172.339 30.6286 172.954 30.7567 173.468 31.0127C173.981 31.2688 174.375 31.6162 174.65 32.0552C174.933 32.4941 175.074 32.9843 175.074 33.5256V36.3568H173.839V35.2156H173.813C173.68 35.4277 173.499 35.6326 173.268 35.8301C173.047 36.0203 172.768 36.1776 172.432 36.302C172.095 36.4263 171.701 36.4885 171.25 36.4885ZM171.569 35.6216C172.011 35.6216 172.401 35.5338 172.737 35.3582C173.082 35.1753 173.348 34.9266 173.534 34.612C173.729 34.2901 173.826 33.9317 173.826 33.5366C173.826 33.1489 173.733 32.8014 173.547 32.4941C173.37 32.1869 173.113 31.9455 172.777 31.7699C172.441 31.587 172.047 31.4955 171.595 31.4955C171.117 31.4955 170.705 31.587 170.36 31.7699C170.024 31.9528 169.762 32.2015 169.577 32.5161C169.399 32.8233 169.311 33.1745 169.311 33.5695C169.311 33.95 169.399 34.2975 169.577 34.612C169.754 34.9193 170.01 35.1644 170.347 35.3473C170.683 35.5301 171.09 35.6216 171.569 35.6216Z" fill="black"/>
<path d="M179.331 36.3568C178.729 36.3568 178.255 36.2142 177.91 35.9289C177.573 35.6435 177.405 35.2558 177.405 34.7657V29.3557H178.627V34.6998C178.627 34.9412 178.706 35.1351 178.866 35.2814C179.034 35.4204 179.264 35.4899 179.556 35.4899H180.924V36.3568H179.331ZM176.236 31.6272V30.7603H180.951V31.6272H176.236Z" fill="black"/>
<path d="M182.826 36.3568V31.6272H181.644V30.7603H182.826V29.9373C182.826 29.4398 182.994 29.0521 183.331 28.7741C183.676 28.4888 184.15 28.3461 184.752 28.3461H186.425V29.213H184.991C184.69 29.213 184.46 29.2825 184.3 29.4215C184.141 29.5605 184.061 29.7544 184.061 30.0031V30.7603H186.16V31.6272H184.061V36.3568H182.826Z" fill="black"/>
<path d="M190.154 36.4885C189.464 36.4885 188.853 36.3605 188.322 36.1044C187.79 35.8411 187.37 35.4899 187.06 35.051C186.75 34.6047 186.595 34.1072 186.595 33.5586C186.595 33.0099 186.75 32.5161 187.06 32.0771C187.37 31.6309 187.79 31.2797 188.322 31.0237C188.853 30.7603 189.464 30.6286 190.154 30.6286C190.845 30.6286 191.456 30.7603 191.987 31.0237C192.527 31.2797 192.947 31.6272 193.248 32.0662C193.558 32.5051 193.713 33.0026 193.713 33.5586C193.713 34.1072 193.558 34.6047 193.248 35.051C192.947 35.4899 192.527 35.8411 191.987 36.1044C191.456 36.3605 190.845 36.4885 190.154 36.4885ZM190.154 35.6216C190.623 35.6216 191.031 35.5301 191.376 35.3473C191.721 35.1644 191.991 34.9193 192.186 34.612C192.381 34.2975 192.478 33.9463 192.478 33.5586C192.478 33.1708 192.381 32.8233 192.186 32.5161C191.991 32.2015 191.721 31.9528 191.376 31.7699C191.031 31.587 190.623 31.4955 190.154 31.4955C189.694 31.4955 189.287 31.587 188.932 31.7699C188.587 31.9528 188.317 32.2015 188.122 32.5161C187.928 32.8233 187.83 33.1708 187.83 33.5586C187.83 33.9463 187.928 34.2975 188.122 34.612C188.317 34.9193 188.587 35.1644 188.932 35.3473C189.287 35.5301 189.694 35.6216 190.154 35.6216Z" fill="black"/>
<path d="M195.24 36.3568V32.3515C195.24 31.854 195.408 31.4663 195.745 31.1883C196.09 30.903 196.564 30.7603 197.166 30.7603H198.56V31.6272H197.392C197.099 31.6272 196.869 31.6967 196.701 31.8357C196.542 31.9747 196.462 32.1686 196.462 32.4173V36.3568H195.24Z" fill="black"/>
<path d="M199.689 36.3568V32.9879C199.689 32.5563 199.809 32.1613 200.048 31.8028C200.287 31.4443 200.632 31.159 201.084 30.9469C201.535 30.7347 202.08 30.6286 202.717 30.6286C203.027 30.6286 203.337 30.6652 203.647 30.7384C203.957 30.8042 204.245 30.9213 204.51 31.0895C204.776 31.2505 204.988 31.4699 205.148 31.7479H205.161C205.338 31.4699 205.559 31.2505 205.825 31.0895C206.09 30.9213 206.378 30.8042 206.688 30.7384C206.998 30.6652 207.312 30.6286 207.631 30.6286C208.26 30.6286 208.8 30.7347 209.251 30.9469C209.703 31.159 210.048 31.4443 210.287 31.8028C210.526 32.1613 210.646 32.5563 210.646 32.9879V36.3568H209.424V32.9879C209.424 32.7099 209.344 32.4576 209.185 32.2308C209.025 32.004 208.809 31.8247 208.534 31.6931C208.268 31.5614 207.963 31.4955 207.618 31.4955C207.281 31.4955 206.971 31.565 206.688 31.704C206.405 31.8357 206.179 32.015 206.011 32.2417C205.851 32.4612 205.772 32.7099 205.772 32.9879V36.3568H204.55V32.9879C204.55 32.7099 204.466 32.4612 204.298 32.2417C204.138 32.015 203.917 31.8357 203.634 31.704C203.359 31.565 203.049 31.4955 202.704 31.4955C202.368 31.4955 202.062 31.5614 201.788 31.6931C201.522 31.8247 201.31 32.004 201.15 32.2308C200.991 32.4576 200.911 32.7099 200.911 32.9879V36.3568H199.689Z" fill="black"/>
<path d="M243.454 1.02563C242.738 1.02563 242.028 1.15828 241.367 1.416C240.705 1.67371 240.104 2.05145 239.597 2.52765C238.575 3.48937 238 4.79375 238 6.15383C238 7.51392 238.575 8.8183 239.597 9.78002C240.104 10.2562 240.705 10.634 241.367 10.8917C242.028 11.1494 242.738 11.282 243.454 11.282C244.9 11.282 246.287 10.7417 247.31 9.78002C248.333 8.8183 248.908 7.51392 248.908 6.15383C248.908 5.48039 248.766 4.81354 248.492 4.19136C248.218 3.56918 247.817 3.00385 247.31 2.52765C246.804 2.05145 246.202 1.67371 245.541 1.416C244.879 1.15828 244.17 1.02563 243.454 1.02563ZM243.454 10.2564C241.049 10.2564 239.091 8.41537 239.091 6.15383C239.091 3.8923 241.049 2.05127 243.454 2.05127C245.859 2.05127 247.817 3.8923 247.817 6.15383C247.817 8.41537 245.859 10.2564 243.454 10.2564ZM243.59 6.80512H242.805V8.46152H241.818V3.95384H243.596C244.157 3.95384 244.594 4.07179 244.904 4.30768C245.21 4.54871 245.363 4.87178 245.363 5.31281C245.363 5.6205 245.292 5.87691 245.15 6.08204C245.008 6.28717 244.795 6.45127 244.506 6.56922L245.543 8.41024V8.46152H244.485L243.59 6.80512ZM242.805 6.0564H243.601C243.846 6.0564 244.037 5.99486 244.174 5.87691C244.31 5.75896 244.375 5.59486 244.375 5.3846C244.375 5.17435 244.315 5.01025 244.185 4.88717C244.059 4.76409 243.863 4.70768 243.596 4.70768H242.805V6.0564Z" fill="black"/>
</svg>

  );
};

export default LogoSvg;
