import avatar1 from "data/avatars/1.jpg";
import avatar2 from "data/avatars/2.jpg";
import avatar3 from "data/avatars/3.jpg";
import avatar4 from "data/avatars/4.jpg";
import avatar6 from "data/avatars/6.jpg";
import avatar7 from "data/avatars/7.jpg";
import avatar8 from "data/avatars/8.jpg";
import avatar9 from "data/avatars/9.jpg";
import avatar11 from "data/avatars/11.jpg";
import avatar12 from "data/avatars/12.jpg";
import avatar13 from "data/avatars/13.jpg";
import avatar14 from "data/avatars/14.jpg";
import avatar15 from "data/avatars/15.jpg";

export const avatarImgs = [
  avatar1,
  avatar2,
  avatar3,
  avatar4,
  avatar6,
  avatar7,
  avatar8,
  avatar9,
  avatar11,
  avatar12,
  avatar13,
  avatar14,
  avatar15,
];

const personNames = [
  "Kailey Greer",
  "Karli Costa",
  "Camren Barnes",
  "Belinda Ritter",
  "Jameson Dickerson",
  "Giada Mann",
  "Evie Osborn",
  "Juliet Mcpherson",
  "Charlize Raymond",
  "Amaris Pittman",
  "Arnav Morris",
  "Malakai Casey",
  "Nevaeh Henry",
  "Mireya Roman",
  "Anthony Wyatt",
  "Mike Orr",
  "Azul Hull",
  "Derick Hubbard",
];

const tagNames = [
  "Life",
  "Travel",
  "Music",
  "Beauty",
  "Beach",
  "Hotdog",
  "Car",
  "Bike",
  "Wordpress",
  "Php",
  "Javascript",
  "Vue",
  "Reactjs",
  "Androind",
];

const featuredImgs = [
  "https://images.pexels.com/photos/1337753/pexels-photo-1337753.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
  "https://images.pexels.com/photos/761963/pexels-photo-761963.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
  "https://images.pexels.com/photos/374876/pexels-photo-374876.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
  "https://images.pexels.com/photos/3225531/pexels-photo-3225531.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
  "https://images.pexels.com/photos/1020016/pexels-photo-1020016.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
  "https://images.pexels.com/photos/1154638/pexels-photo-1154638.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
  "https://images.pexels.com/photos/2827374/pexels-photo-2827374.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
  "https://images.pexels.com/photos/1640777/pexels-photo-1640777.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
  "https://images.pexels.com/photos/842571/pexels-photo-842571.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
  "https://images.pexels.com/photos/315755/pexels-photo-315755.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
  "https://images.pexels.com/photos/1391487/pexels-photo-1391487.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
  "https://images.pexels.com/photos/704971/pexels-photo-704971.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
  "https://images.pexels.com/photos/248547/pexels-photo-248547.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
  "https://images.pexels.com/photos/209977/pexels-photo-209977.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
  "https://images.pexels.com/photos/848618/pexels-photo-848618.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
];

export const imgHigtQualitys = [
  "https://images.pexels.com/photos/4352244/pexels-photo-4352244.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  "https://images.pexels.com/photos/3023211/pexels-photo-3023211.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  "https://images.pexels.com/photos/4066850/pexels-photo-4066850.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  "https://images.pexels.com/photos/6168061/pexels-photo-6168061.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  "https://images.pexels.com/photos/5913170/pexels-photo-5913170.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  "https://images.pexels.com/photos/5913391/pexels-photo-5913391.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  "https://images.pexels.com/photos/4617820/pexels-photo-4617820.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  "https://images.pexels.com/photos/6136086/pexels-photo-6136086.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  "https://images.pexels.com/photos/4443477/pexels-photo-4443477.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  "https://images.pexels.com/photos/4273989/pexels-photo-4273989.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  "https://images.pexels.com/photos/4352244/pexels-photo-4352244.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  "https://images.pexels.com/photos/3023211/pexels-photo-3023211.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  "https://images.pexels.com/photos/4066850/pexels-photo-4066850.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  "https://images.pexels.com/photos/4806731/pexels-photo-4806731.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  "https://images.pexels.com/photos/5499131/pexels-photo-5499131.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
];

const aTitles = [
  "adipiscing bibendum est ultricies integer quis auctor elit sed vulputate",
  "in arcu cursus euismod quis viverra nibh cras pulvinar mattis",
  "natoque penatibus et magnis dis parturient montes nascetur ridiculus mus",
  "et leo duis ut diam quam nulla porttitor massa id",
  "turpis cursus in hac habitasse platea dictumst quisque sagittis purus",
  "ut faucibus pulvinar elementum integer enim neque volutpat ac tincidunt",
  "interdum velit euismod in pellentesque massa placerat duis ultricies lacus",
  "fringilla ut morbi tincidunt augue interdum velit euismod in pellentesque",
  "sagittis vitae et leo duis ut diam quam nulla porttitor",
  "in mollis nunc sed id semper risus in hendrerit gravida",
  "tellus integer feugiat scelerisque varius morbi enim nunc faucibus a",
  "eleifend mi in nulla posuere sollicitudin aliquam ultrices sagittis orci",
  "non sodales neque sodales ut etiam sit amet nisl purus",
];

function _getTitleRd() {
  return aTitles[Math.floor(Math.random() * aTitles.length)];
}
function _getPersonNameRd() {
  return personNames[Math.floor(Math.random() * personNames.length)];
}

function _getImgRd() {
  return featuredImgs[Math.floor(Math.random() * featuredImgs.length)];
}

function _getImgHightQualityRd() {
  return imgHigtQualitys[Math.floor(Math.random() * imgHigtQualitys.length)];
}

function _getTagNameRd() {
  return tagNames[Math.floor(Math.random() * tagNames.length)];
}
function _getAvatarRd() {
  return avatarImgs[Math.floor(Math.random() * avatarImgs.length)];
}

export {
  _getImgRd,
  _getTagNameRd,
  _getAvatarRd,
  _getImgHightQualityRd,
  _getTitleRd,
  _getPersonNameRd,
};
