import { NavItemType } from "components/Navigation/NavigationItem";
import { Route } from "routers/types";
import _, { drop, random } from "lodash";

const randomId = _.uniqueId;

export const MEGAMENU_TEMPLATES: NavItemType[] = [
  // home pages ---------
  {
    id: randomId(),
    href: "/#",
    name: "Home Page",
    children: [
      { id: randomId(), href: "/", name: "Home page 1" },
      { id: randomId(), href: "/blog", name: "Home page 2" },
      { id: randomId(), href: "/about-us", name: "About Us" },
      { id: randomId(), href: "/ops-maintenance", name: "Services" },
      { id: randomId(), href: "/contact-us", name: "Contact Us" },
      { id: randomId(), href: "/", name: "Header style 1", isNew: true },
      { id: randomId(), href: "/blog", name: "Header style 2", isNew: true },
    ],
  },

  // single pages ---------
  {
    id: randomId(),
    href: "/single/demo-slug" as Route,
    name: "Single Pages",
    children: [
      {
        id: randomId(),
        href: "/single/demo-slug" as Route,
        name: "Single page 1",
      },
      {
        id: randomId(),
        href: "/single-2/demo-slug" as Route,
        name: "Single page 2",
      },
      {
        id: randomId(),
        href: "/single-3/demo-slug" as Route,
        name: "Single page 3",
      },
      {
        id: randomId(),
        href: "/single-4/demo-slug" as Route,
        name: "Single page 4",
      },

      {
        id: randomId(),
        href: "/single-audio/demo-slug" as Route,
        name: "Single Audio",
      },
      {
        id: randomId(),
        href: "/single-video/demo-slug" as Route,
        name: "Single Video",
      },
      {
        id: randomId(),
        href: "/single-gallery/demo-slug" as Route,
        name: "Single Gallery",
        isNew: true,
      },
    ],
  },

  // archive pages ---------
  {
    id: randomId(),
    href: "/#",
    name: "Archive Pages",
    children: [
      {
        id: randomId(),
        href: "/archive/demo-slug" as Route,
        name: "Category page",
      },
      {
        id: randomId(),
        href: "/archive-2/demo-slug" as Route,
        name: "Category audio",
      },
      {
        id: randomId(),
        href: "/archive-3/demo-slug" as Route,
        name: "Category videos",
      },
      {
        id: randomId(),
        href: "/search",
        name: "Search page",
      },
      {
        id: randomId(),
        href: "/search-2",
        name: "Search page 2",
      },
      {
        id: randomId(),
        href: "/author/demo-slug" as Route,
        name: "Author page",
      },
    ],
  },

  // others pages ----------------
  {
    id: randomId(),
    href: "/#",
    name: "Other Pages",
    children: [
      { id: randomId(), href: "/dashboard", name: "Dashboard" },
      { id: randomId(), href: "/about", name: "About" },
      { id: randomId(), href: "/contact", name: "Contact us" },
      {
        id: randomId(),
        href: "/login",
        name: "Login",
      },
      {
        id: randomId(),
        href: "/signup",
        name: "Signup",
      },
      {
        id: randomId(),
        href: "/forgot-pass",
        name: "Forgot password",
      },
      {
        id: randomId(),
        href: "/subscription",
        name: "Subscription",
      },
    ],
  },
];

const OTHER_PAGE_CHILD: NavItemType[] = [
  // archive pages ----------------
  {
    id: randomId(),
    href: "/archive/demo-slug" as Route,
    name: "Category pages",
    type: "dropdown",
    children: [
      {
        id: randomId(),
        href: "/archive/demo-slug" as Route,
        name: "Category page 1",
      },
      {
        id: randomId(),
        href: "/archive-2/demo-slug" as Route,
        name: "Category page 2",
      },
      {
        id: randomId(),
        href: "/archive-3/demo-slug" as Route,
        name: "Category page 2",
      },
    ],
  },

  // single pages ----------------
  {
    id: randomId(),
    href: "/single/demo-slug" as Route,
    name: "Single pages",
    type: "dropdown",
    children: [
      {
        id: randomId(),
        href: "/single/demo-slug" as Route,
        name: "Single 1",
      },
      {
        id: randomId(),
        href: "/single-2/demo-slug" as Route,
        name: "Single 2",
      },
      {
        id: randomId(),
        href: "/single-3/demo-slug" as Route,
        name: "Single 3",
      },
      {
        id: randomId(),
        href: "/single-4/demo-slug" as Route,
        name: "Single 4",
      },
      {
        id: randomId(),
        href: "/single-5/demo-slug" as Route,
        name: "Single 5",
      },
      {
        id: randomId(),
        href: "/single-audio/demo-slug" as Route,
        name: "Single Audio",
      },
      {
        id: randomId(),
        href: "/single-video/demo-slug" as Route,
        name: "Single Video",
      },
      {
        id: randomId(),
        href: "/single-gallery/demo-slug" as Route,
        name: "Single Gallery",
      },
    ],
  },

  // seach pages ----------------
  {
    id: randomId(),
    href: "/search",
    name: "Search Page",
    type: "dropdown",
    children: [
      {
        id: randomId(),
        href: "/search",
        name: "Search page",
      },
      {
        id: randomId(),
        href: "/search-2",
        name: "Search page 2",
      },
    ],
  },

  // author pages ----------------
  {
    id: randomId(),
    href: "/author/demo-slug" as Route,
    name: "Author page",
  },

  // dashboard pages ----------------
  {
    id: randomId(),
    href: "/dashboard",
    name: "Dashboard Page",
    type: "dropdown",
    children: [
      {
        id: randomId(),
        href: "/dashboard/edit-profile",
        name: "Edit profile page",
      },
      {
        id: randomId(),
        href: "/dashboard/posts",
        name: "Posts page",
      },
      {
        id: randomId(),
        href: "/dashboard/submit-post",
        name: "Submit post page",
      },
      {
        id: randomId(),
        href: "/dashboard/subscription",
        name: "Subscription",
      },
      {
        id: randomId(),
        href: "/dashboard/billing-address",
        name: "Billing address",
      },
    ],
  },

  // about pages ----------------
  {
    id: randomId(),
    href: "/about",
    name: "Other Pages",
    type: "dropdown",
    children: [
      {
        id: randomId(),
        href: "/about",
        name: "About",
      },
      {
        id: randomId(),
        href: "/contact",
        name: "Contact us",
      },
      {
        id: randomId(),
        href: "/login",
        name: "Login",
      },
      {
        id: randomId(),
        href: "/signup",
        name: "Signup",
      },
      {
        id: randomId(),
        href: "/forgot-pass",
        name: "Forgot password",
      },
      {
        id: randomId(),
        href: "/subscription",
        name: "Subscription",
      },
    ],
  },
];

export const NAVIGATION_DEMO_2: NavItemType[] = [


  // Services pages ----------------
  {
    id: randomId(),
    href: "/ops-maintenance",
    name: "Services",
    type: "dropdown",
    children: [
      {
        id: randomId(),
        href: "/hiring-careers/staffing-and-hr" as Route,
        name: "Staffing & HR",
      },
      {
        id: randomId(),
        href: "/services/operational-services" as Route,
        name: "Infra & Ops service",
      },
      {
        id: randomId(),
        href: "/ops-maintenance/branding-and-marketing" as Route,
        name: "Branding & Promotions",
      },
      {
        id:randomId(),
        href :"/services/medical-center-setup" as Route,
        name:"Medical Center Setup",
      },
    ],
  },

  //
  // {
  //   id: randomId(),
  //   href: "/buy-sell" as Route,
  //   name: "Buy/Sell",
  //   type: "dropdown",
  //   children: [
  //     {
  //       id: randomId(),
  //       href: "/shop" as Route,
  //       name: "shop",
  //     },
  //     {
  //       id: randomId(),
  //       href: "/productpage" as Route,
  //       name: "Product Page",
  //     },
  //   ],
    
  // },
  {
    id:randomId(),
    href: "/training" as Route,
    name :"Training",
  },
  {
    id:randomId(),
    href :"/placements" as Route,
    name : "Placements",
  },
  {
    id: randomId(),
    href: "/hiring-careers/for-clients" as Route,
    name: "For Clients",
    type: "dropdown",
    // children: [
    //   {
    //     id: randomId(),
    //     href: "/pricing" as Route,
    //     name: "Pricing",
    //   },
    // ],
  },
  {
    id: randomId(),
    href: "/hiring-careers/for-professionals"  as Route,
    name: "For Clinicians",
    type: "dropdown",
    children: [
      {
        id: randomId(),
        href: "/hiring-careers/for-professionals" as Route,
        name: "Overview",
      },
      {
        id: randomId(),
        href: "/event" as Route,
        name: "Events",
      },
      {
        id: randomId(),
        href: "/jobs" as Route,
        name: "Jobs",
      },
    ],
  },
  {
    id: randomId(),
    href: "/ops-maintenance/vendors-suppliers"  as Route,
    name: "For Partners",
  },
  {
    id: randomId(),
    href: "/hiring-careers/for-agencies"  as Route,
    name: "For Agencies",
  },
  // {
  //   id: randomId(),
  //   href: "/For-Associations"  as Route,
  //   name: "For Associations",
  // },
];


//========desktop nav first section====//

export const NAVIGATION_desktop_1: NavItemType[] = [



  {
    id: randomId(),
    href: "/ops-maintenance",
    name: "Services",
    type: "dropdown",
    children: [
      {
        id: randomId(),
        href: "/hiring-careers/staffing-and-hr" as Route,
        name: "Staffing & HR",
      },
      {
        id: randomId(),
        href: "/services/operational-services" as Route,
        name: "Infra & Ops service",
      },
      {
        id: randomId(),
        href: "/ops-maintenance/branding-and-marketing" as Route,
        name: "Branding & Promotions",
      },
      {
        id:randomId(),
        href:"/services/medical-center-setup" as Route,
        name:"Medical Center Setup",
      },
    ],
  },

  //
  // {
  //   id: randomId(),
  //   href: "/buy-sell" as Route,
  //   name: "Buy & Sell",
  //   type: "dropdown",
  //   children: [
  //     {
  //       id: randomId(),
  //       href: "/shop" as Route,
  //       name: "shop",
  //     },
  //     {
  //       id: randomId(),
  //       href: "/productpage" as Route,
  //       name: "Product Page",
  //     },
  //   ],
    
  // },



];

//========desktop nav second section====//
export const NAVIGATION_desktop_2: NavItemType[] = [
  {
    id:randomId(),
    href: "/training" as Route,
    name :"Training",
  },
  {
    id:randomId(),
    href :"/placements" as Route,
    name : "Placements",
  },
  {
    id: randomId(),
    href: "/hiring-careers/for-clients" as Route,
    name: "For Clients",
  },
 
  {
    id: randomId(),
    href: "/hiring-careers/for-professionals"  as Route,
    name: "For Professionals",
    type: "dropdown",
    children: [
      {
        id: randomId(),
        href: "/hiring-careers/for-professionals" as Route,
        name: "Overview",
      },
      {
        id: randomId(),
        href: "/event" as Route,
        name: "Events",
      },
      {
        id: randomId(),
        href: "/jobs" as Route,
        name: "Jobs",
      },
    ],
    // type: "dropdown",
    // children: [
    //   {
    //     id: randomId(),
    //     href: "/for-clinicians" as Route,
    //     name: "Overview",
    //   },
      // {
      //   id: randomId(),
      //   href: "/event" as Route,
      //   name: "Events",
      // },
      // {
      //   id: randomId(),
      //   href: "/jobs" as Route,
      //   name: "Jobs",
      // },
    // ],
  },
  {
    id: randomId(),
    href: "/ops-maintenance/vendors-suppliers"  as Route,
    name: "For Partners",
  },
  {
    id: randomId(),
    href: "/hiring-careers/for-agencies"  as Route,
    name: "For Agencies",
  },
  
  // {
  //   id: randomId(),
  //   href: "/For-Associations"  as Route,
  //   name: "For Associations",
  // },
  
]

export const new_NAVIGATION_DESKTOP: NavItemType[] = [
  {
    id:randomId(),
    href: "/hiring-careers/staffing-and-hr" as Route,
    name:"Hiring & Careers",
    type:'dropdown',
    children:[
      {
        id:randomId(),
        href:"/hiring-careers/for-clients",
        name:"Clients"
      },
      {
        id:randomId(),
        href:"/hiring-careers/for-professionals",
        name:"Professionals",
        // type:'dropdown',
        // children:[
        //   {
        //     id:randomId(),
        //     href:"/training",
        //     name:"Trainings"
        //   },
        //   {
        //     id:randomId(),
        //     href:"/placements",
        //     name:"Placements"
        //   }
        // ]
      },
      {
        id:randomId(),
        href:"/hiring-careers/for-agencies",
        name:"Agency"
      }
    ]
  },
  {
    id:randomId(),
    href:"/services/medical-center-setup",
    name:"Medical Center Setup",
    // type:"dropdown",
    // children:[
    //   {
    //     id:randomId(),
    //     href:'/licenses-required',
    //     name:'Licenses Required '
    //   }
    // ]
  },
  {
    id:randomId(),
    href:"/ops-maintenance",
    name:"Operations & Maintenance",
    type:"dropdown",
    children:[
      {
        id:randomId(),
        href:'/ops-maintenance/vendors-suppliers',
        name:'Vendors & Suppliers'
      },
      {
        id:randomId(),
        href:'/ops-maintenance/branding-and-marketing',
        name:'Branding & Marketing'
      }
    ]
  },
  // {
  //   id:randomId(),
  //   href:"/for-partners",
  //   name:"For Partners"
  // },
  // {
  //   id:randomId(),
  //   href:"/Buy-sell",
  //   name:'E-shop'
  // }
]