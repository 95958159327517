import React, { useEffect, useRef } from 'react';
import { useLocation,useNavigate } from 'react-router-dom';
import { Helmet } from "react-helmet";
import FeatureService from "components/Sections/featureservice/featureservice";
import OfferService from "components/Sections/serviceoffer/serviceoffer";
import featureservice1 from "./images/featureservice1.png";
import featureservice2 from "./images/featureservice2.png";
import featureservice3 from "./images/featureservice3.png";
import featureservice4 from "./images/featureservice4.png";

import offerservice1 from "./images/serviceoffer1.png";
import offerservice2 from "./images/serviceoffer2.png";

import backgroundfooter from "./images/Rectangle-4.webp";
import bannerimage from "./images/associationsbanner.png";

import JoinNetwork from "components/Sections/mobileappdownload/mobileappdownload";
import qrteraclients from "./images/teraclients.png";

import agencycall from "./images/agency-call.png"

import "./agency.css";
import { Link } from "react-router-dom";
const featureServiceDataList = [
  {
    image: featureservice1,
    title: "Effortless Hiring with Advanced ATS",
    subtitle:
      " Tera Connects simplifies your hiring process with an advanced Applicant Tracking System (ATS), ensuring a seamless and efficient experience to find the right talent for your clients.",
  },
  {
    image: featureservice2,
    title: "Diverse Talent Pool Access",
    subtitle:
      "Access a broad talent pool, ranging from professionals like doctors and nurses to versatile manpower. Tera Connects provides comprehensive staffing solutions to meet all your diverse hiring needs.",
  },
  {
    image: featureservice3,
    title: "Tailored Solutions for Medical Recruitment ",
    subtitle:
      "Our platform is tailored for medical staff recruitment, offering specialized solutions to address the unique demands of the medical industry and ensuring you find the right candidates.",
  },

  {
    image: featureservice4,
    title: "Direct Interaction for Seamless Communication ",
    subtitle:
      "Forge direct connections with professionals looking to get hired. Tera Connects creates opportunities to deeply understand and fulfill your staffing requirements.",
  },
  // Add more feature service data objects as needed
];

const offerServiceDatalist = [
  {
    image: offerservice1,
    title: "Medical Recruitment Agencies",
    subtitle:
      "Are you a medical recruitment agency seeking the ideal platform to connect with top-tier medical professionals? Tera Connects is your gateway to a vast network of doctors, nurses, and technicians, ensuring your clients access the finest talent effortlessly.",
  },
  {
    image: offerservice2,
    title: "Manpower Providers in the Medical Industry",
    subtitle:
      "If you specialize in providing manpower for the medical industry, Tera Connects is your go-to platform. Whether it's professionals like doctors and nurses or manpower for security and technical roles, our platform connects you with best professionals.",
  },

  // Add more feature service data objects as needed
];

function Foragencies() {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const hash = location.hash;

    const scrollToSection = () => {
      if (hash) {
        const targetElement = document.querySelector(hash) as HTMLElement;
        if (targetElement) {
          // Calculate the offset to position the top of the section at 20% of the viewport height
          const offset = window.innerHeight * -1;
          
          // Calculate the target position
          const targetPosition = targetElement.getBoundingClientRect().top + window.scrollY;
          
          // Scroll to the section with the offset
          window.scrollTo({
            top: targetPosition - offset,
            behavior: 'smooth',
          });
        }
      }
    };

    // Scroll to the section when the component mounts
    scrollToSection();

    // Add a listener for hash changes
    const handleHashChange = () => {
      scrollToSection();
    };

    window.addEventListener('hashchange', handleHashChange);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('hashchange', handleHashChange);
    };
  }, [location]);

  const handleInstallButtonClick = () => {
    // Handle scrolling when the Install App button is clicked
    const hash = "#download-app-now"; // Replace with the ID of your target section
    navigate(hash);
  };

  return (
    <div className="nc-PageHomeDemo6 relative [ nc-section-rounded-md ]">
      <Helmet>
        <title>Revolutionize Your Agency's impact in Medical Industry </title>

        <meta
          name="description"
          content=" Our platform is dedicated to help you streamline, improve, and amplify your healthcare agency. Join our community and streamline your healthcare agency.
          "
        />
      </Helmet>
      <div className=" mx-auto home-hero-section for-clinic-page clients">
        <div className="container flex head-home-sec gap-extra">
          <div className="head-home-sec-centre gap">
            <h3 className="font-white regular title-custom text-center title-heading-banner montserrat ">
              <span className="font-sky-blue bolders banner-grandient">
              Revitalize 
              </span>{" "}
              Your Medical Agency{" "}
            </h3>
            <h5 className="font-white  text-center regular title-custom montserrat">
            Revolutionize your agency's impact in the medical industry with Tera Connects — an all-encompassing platform designed to streamline, elevate, and amplify agency operations.
            </h5>
            <Link to="/contact-us">
              <a href="/contact-us/" className="common-btn-custom">
                Get Started
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="26"
                    height="27"
                    viewBox="0 0 26 27"
                    fill="none"
                  >
                    <path
                      d="M8.99945 7.14938L16.8203 14.4116L8.99946 21.6738"
                      stroke="#fff"
                      stroke-width="2"
                    />
                  </svg>
                </span>
              </a>
            </Link>
          </div>
          <div className="head-home-sec-centre">
            <img src={bannerimage} alt="Service" className="" />
          </div>
        </div>
      </div>

      <div className="tabsteraconnects background-white">
        <div className="container">
          <h2 className="servicepages-headings montserrat font-medium lg:mb-20 mb-10">
            {" "}
            Empowering Diverse Range of 

            <span className="font-extrabold"> Medical Agencies</span>
          </h2>
          {/* <h5 className="font-black regular title-custom montserrat lg:mb-20 mb-10">Tera Connects addresses the challenges faced by medical organisations with an array of solutions</h5> */}
          <div className="offer-service featureservice-display-wrap association-offer-service">
            {/* Map over the list of feature service data and render FeatureService for each */}
            {offerServiceDatalist.map((data, index) => (
              <OfferService key={index} data={data} />
            ))}
          </div>
        </div>
      </div>

      <div className="tabsteraconnects background-white">
        <div className="container">
          <h2 className="servicepages-headings montserrat font-medium lg:mb-20 mb-10">
            {" "}
            Featured<span className="font-extrabold"> Services</span>
          </h2>
          <div className="featureservice-display-wrap for-agency-wrap">
            {/* Map over the list of feature service data and render FeatureService for each */}
            {featureServiceDataList.map((data, index) => (
              <FeatureService key={index} data={data} />
            ))}
          </div>
        </div>
      </div>

      <div className="lets-start-journey">
  <div className="container">
    <div className="start-journey-container">
      <div className="journey-start-text-section">
      <h2 className="font-sky-blue bolds title-custom montserrat text-start mb-5">
              Let’s Start Our Journey
            </h2>
            <h4 className="font-white regular title-custom montserrat mb-5">
            Discover how we can help you find qualified and experienced medical professionals.
            </h4>
            <a href="#download-app-now" className="common-btn-custom blue-btn">
              Install App
            </a>

      </div>
      <div className="journey-start-image-section">
      <img src={agencycall} />
      </div>

    </div>

  </div>

</div>
{/* 
      <div className="container background-white">
        <div className="sec-padding">
          <h2 className="servicepages-headings montserrat font-medium lg:mb-10 mb-10">
            {" "}
            Read <span className="font-extrabold">Testimonials</span>
          </h2>
          <TestimonialSlider perView={1} />
        </div>
      </div> */}

<div id="download-app-now">
                  <JoinNetwork
                    heading="Join Our Network"
                    subheading="Join Tera Connects for a transformative journey where your agency can thrive, connect with top-notch talent, and shape the future of medical staffing."
                    googlePlayUrl="https://play.google.com/store/apps/details?id=com.teraplatforms.teraconnects"
                    appStoreUrl="https://apps.apple.com/in/app/tera-connects/id6448921572"
                    imageUrl={qrteraclients}
                  />
                </div>
    </div>
  );
}

export default Foragencies;
