import React, { useState, useEffect } from "react";

const medicalrecruiter = () => {
    return (
        <div className="page-job-post content-writer">
            <div className="container">
                <div className="job-post-wrapper">
                    <div className="jobdetail-side">
                        <div className="joblistings-detail">
                            <div className="jobs-prodiver-detail">
                                <div className="job-info-main">
                                    <h4 className="job-name">Medical Recruiter</h4>
                                    <h5 className="job-provider-name"></h5>
                                </div>
                                <div className="job-provider-info"></div>
                            </div>
                            <div className="jobs-requirment">
                                <div className="uppersection">
                                    <p className="job-detail-minor required-exprence ">
                                        1-2 years
                                    </p>
                                    <p className="job-detail-minor salary-offer">Not Disclosed</p>
                                    <p className="job-detail-minor location">On-Site, Hyderabad, Telangana</p>
                                </div>
                                <div className="lowersections">
                                    <p className="job-detail-minor detail-other ">
                                        Education : Bachelor's degree in Human Resources, Business Administration, Healthcare Management, or related field.
                                    </p>
                                    {/* <p className="job-detail-minor detail-other">
                                        Key Skills : Flutter, Dart, Android, iOS
                                    </p> */}
                                </div>
                            </div>
                            <div className="job-apply-section">
                                <div className="job-provider-info post-page">
                                    {/* <p className="job-detail-minor openings"> 1 Opening</p>
                                    <span className="job-detail-minor posted-ago">
                                        4/12/ 2023
                                    </span> */}
                                </div>
                                <div className="job-btns-selection">
                                    <a
                                        className="applynowbtn apply-save-btn"
                                        href="https://www.naukri.com/job-listings-Medical-Recruiter-TERA-CONNECTS-HEALTHCARE-PRIVATE-LIMITED--2-to-6-years-100524006168"
                                    >
                                        Apply
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className="career-joblisting-detail job-description">
                            <div className="product-specification">
                                <h2 className="product-specs-title-for-product-single for-list-prod montserrat">
                                    Job Description
                                </h2>
                                <ul className="product-desc-list tera-job-description">
                                    We are seeking a dedicated and experienced Medical Recruiter to join our team.
                                    The Medical Recruiter will be responsible for sourcing, screening, and recruiting
                                    qualified healthcare professionals to fill various positions within our
                                    organization or for our clients. The ideal candidate will have a strong understanding of
                                    the healthcare industry, excellent communication skills, and a proven track record in talent acquisition.
                                </ul>

                            </div>
                        </div>

                        <div className="career-joblisting-detail job-description">
                            <div className="product-specification">
                                <h2 className="product-specs-title-for-product-single for-list-prod montserrat">
                                    Responsibilities
                                </h2>
                                {/* <ul className="product-desc-list tera-job-description"> */}
                                <ul className="">
                                    <li className="specs-list-content montserrat">
                                        Source and recruit healthcare professionals, including physicians, nurses, allied health professionals, and administrative staff, through various channels such as job boards, social media, networking events, and referrals.
                                    </li>
                                    <li className="specs-list-content montserrat">
                                        Screen resumes, conduct phone interviews, and schedule and facilitate in-person interviews to assess candidates' qualifications, skills, and fit for specific roles.
                                    </li>
                                    <li className="specs-list-content montserrat">
                                        Collaborate with hiring managers or clients to understand staffing needs, job requirements, and organizational culture to ensure successful placements.
                                    </li>
                                    <li className="specs-list-content montserrat">
                                        Build and maintain a network of qualified candidates and healthcare professionals to proactively fill future job openings.
                                    </li>
                                    <li className="specs-list-content montserrat">
                                        Develop and implement effective recruitment strategies and campaigns to attract top talent, including employer branding initiatives and recruitment events.
                                    </li>
                                    <li className="specs-list-content montserrat">
                                        Provide guidance and support to candidates throughout the recruitment process, including interview preparation, offer negotiation, and onboarding.
                                    </li>
                                    <li className="specs-list-content montserrat">
                                        Maintain accurate and up-to-date candidate records and documentation in the applicant tracking system (ATS) or recruitment database.
                                    </li>
                                    <li className="specs-list-content montserrat">
                                        Stay informed about industry trends, market conditions, and best practices in healthcare recruitment to continuously improve recruitment processes and strategies.

                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="career-joblisting-detail job-description">
                            <div className="product-specification">
                                <h2 className="product-specs-title-for-product-single for-list-prod montserrat">
                                    Requirements
                                </h2>
                                {/* <ul className="product-desc-list tera-job-description"> */}
                                <ul className="">
                                    <li className="specs-list-content montserrat">
                                        Bachelor's degree in Human Resources, Business Administration, Healthcare Management, or related field.
                                    </li>
                                    <li className="specs-list-content montserrat">
                                        Proven experience as a recruiter, preferably in the healthcare industry.
                                    </li>
                                    <li className="specs-list-content montserrat">
                                        Strong knowledge of healthcare terminology, regulations, and compliance requirements.
                                    </li>
                                    <li className="specs-list-content montserrat">
                                        Excellent communication and interpersonal skills, with the ability to build rapport with candidates, hiring managers, and clients.
                                    </li>
                                    <li className="specs-list-content montserrat">
                                        Ability to multitask and prioritize in a fast-paced environment while maintaining attention to detail and accuracy.
                                    </li>
                                    <li className="specs-list-content montserrat">
                                        Proficiency in applicant tracking systems (ATS), recruitment software, and Microsoft Office Suite.
                                    </li>
                                    <li className="specs-list-content montserrat">
                                        High level of professionalism, integrity, and confidentiality in handling sensitive information and interactions with candidates and clients.
                                    </li>
                                </ul>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default medicalrecruiter;
