import { useUser } from "components/UserContext";
import { Link } from "react-router-dom";
import AvatarDropdown1 from '../components/Header/AvatarDropdown';
import AvatarDropdown from "../components/Header/loginbuttondropdown";
import DownloadsPopover from "components/DownloadsPopover";

const TopHeader = () => {
    let { isAuthenticated } = useUser();
    let loginComponent = isAuthenticated ? <AvatarDropdown1 /> : <AvatarDropdown />
    return (
        <div className="flex container header-teraconnect-one justify-between mr-4 items-center text-slate-700">
          <div className="h-8">
          </div>
          <div className="flex gap-4 items-center">
            <DownloadsPopover />
            <Link to={'/jobs'} className="text-sm text-black-500 hover:text-blue-700">Medical Jobs</Link>
            <div className="text-sm text-black-500 hover:text-blue-700">{loginComponent}</div>
          </div>
        </div>
      );
}

export default TopHeader

  