import React from "react";
import { Helmet } from "react-helmet";
import Contactform from "components/Sections/contactform/contactform";

import "./contact-u.css";
import { Card, CardContent, Typography, CardActions, Button } from "@material-ui/core";
const PageHomeDemo6: React.FC = () => {
  return (
    <div className="nc-PageHomeDemo6 relative [ nc-section-rounded-md ]">
      <Helmet>
        <title>Contact Tera Connects - Your Healthcare Service Partner</title>
        <meta
          name="description"
          content="Reach out to Tera Connect for comprehensive healthcare services, including staffing, operational support, and branding solutions. "
        />
      </Helmet>

      <div className="service-pages-header  contact aboutus-pages-header">
        <div className="container flex items-center justify-center service-pages-header-inner">
          <div className="hero-inner">
            <h3 className="font-white regular text-center title-custom title-heading-banner montserrat ">
              Connect for <br />
              <span className="font-sky-blue bolders">Medical Industry</span>
              <br />
              Solutions
            </h3>
            <h5 className="font-white regular text-center title-custom montserrat">
              Whether you're a vendor seeking new opportunities or a client
              looking for reliable services, our platform is designed to
              streamline your connection process.
            </h5>
          </div>
        </div>
      </div>

      <div className="lg:py-20 py-10 md:py=16 sm:py-16 home-hero-section conat-us-background">
        <div className="container flex flex-col-reverse justify-center item-center lg:flex-row lg:space-x-4 space-y-10">
          <Contactform />
        </div>
      </div>

      <div className="contact-us-page-phones-section sec-padding background-white">
        <div className="contact-us-header">For more Information: </div>
        <div className="container">
          <div className="col-sm-6 col-md-3">
            <div className="contact-us-card p-4 text-center mt-3 undefined">
              <h3 className="contact-us-card-title">For Jobs Platform</h3>
              <p className="contact-us-card-description mt-3 mb-0">Recruiter dashboard support, inquires, Jobs listing support, feedback.</p>
              <a href="mailto:events@docthub.com" className="contact-us-card-link text-blue-500 link mt-3">hr@teraconnects.com</a>
              <a href="tel:+91 8320875943" className="mt-2 contact-us-card-link link">+91- 7416665595</a>
            </div>
          </div>
          <div className="col-sm-6 col-md-3">
            <div className="contact-us-card p-4 text-center mt-3 undefined">
              <h3 className="contact-us-card-title">For Services</h3>
              <p className="contact-us-card-description mt-3 mb-0">For Services like Medical Center Setup, Branding and promotion, Infra & Ops related queries and questions or partnering with Tera Connects.</p>
              <a href="mailto:jobs@docthub.com" className="contact-us-card-link text-blue-500 link mt-3">sales@teraconnects.com</a>
              <a href="tel:+91 9510403130" className="mt-2 contact-us-card-link link">+91- 7416665595</a>
            </div>
          </div>
          <div className="col-sm-6 col-md-3">
            <div className="contact-us-card p-4 text-center mt-3 undefined">
              <h3 className="contact-us-card-title">For Support</h3>
              <p className="contact-us-card-description  mt-3 mb-0">General queries, Business inquires, partnership opportunities, etc.</p>
              <a href="mailto:courses@docthub.com" className="contact-us-card-link text-blue-500 link mt-3">contactus@teraconnects.com</a>
              <a href="tel:+91 6351041743" className="mt-2 contact-us-card-link link">+91- 7416665484</a>
            </div>
          </div>
        </div>
      </div>

      <div className=" mx-auto background-white-shadow">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3805.9330589318647!2d78.34717907492094!3d17.46291780062533!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb93e6db746a71%3A0x36d6a8a2cf3b027!2sTera%20Connects%20Healthcare%20Private%20Limited!5e0!3m2!1sen!2sin!4v1697016888725!5m2!1sen!2sin"
          width="100%"
          height="500px"
          loading="lazy"
        ></iframe>
      </div>
    </div>
  );
};

export default PageHomeDemo6;
