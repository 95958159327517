import React, { useState, useEffect } from "react";

const businessdevelopmentassociate = () => {
    return (
        <div className="page-job-post content-writer">
            <div className="container">
                <div className="job-post-wrapper">
                    <div className="jobdetail-side">
                        <div className="joblistings-detail">
                            <div className="jobs-prodiver-detail">
                                <div className="job-info-main">
                                    <h4 className="job-name">Business Development Associate</h4>
                                    <h5 className="job-provider-name"></h5>
                                </div>
                                <div className="job-provider-info"></div>
                            </div>
                            <div className="jobs-requirment">
                                <div className="uppersection">
                                    <p className="job-detail-minor required-exprence ">
                                        0-3 years
                                    </p>
                                    <p className="job-detail-minor salary-offer">Not Disclosed</p>
                                    <p className="job-detail-minor location">On-Site, Hyderabad, Telangana</p>
                                </div>
                                <div className="lowersections">
                                    <p className="job-detail-minor detail-other ">
                                        Education : Bachelor's degree in Business Administration, Marketing, Economics, or a related field.
                                    </p>
                                    {/* <p className="job-detail-minor detail-other">
                                        Key Skills : Flutter, Dart, Android, iOS
                                    </p> */}
                                </div>
                            </div>
                            <div className="job-apply-section">
                                <div className="job-provider-info post-page">
                                    {/* <p className="job-detail-minor openings"> 1 Opening</p>
                                    <span className="job-detail-minor posted-ago">
                                        4/12/ 2023
                                    </span> */}
                                </div>
                                <div className="job-btns-selection">
                                    <a
                                        className="applynowbtn apply-save-btn"
                                        href="https://www.naukri.com/job-listings-business-development-executive-tera-connects-hea[…]00424005206?src=jobsearchDesk&sid=17136066946634263&xp=1&px=1"
                                    >
                                        Apply
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className="career-joblisting-detail job-description">
                            <div className="product-specification">
                                <h2 className="product-specs-title-for-product-single for-list-prod montserrat">
                                    Job Description
                                </h2>
                                <ul className="product-desc-list tera-job-description">
                                    We are seeking a highly motivated and detail-oriented Business Development Associate to join our team. The ideal candidate will be responsible for conducting market research, analyzing industry trends, identifying business opportunities, and supporting the development and implementation of strategic business plans.
                                </ul>

                            </div>
                        </div>

                        <div className="career-joblisting-detail job-description">
                            <div className="product-specification">
                                <h2 className="product-specs-title-for-product-single for-list-prod montserrat">
                                    Responsibilities
                                </h2>
                                {/* <ul className="product-desc-list tera-job-description"> */}
                                <ul className="">
                                    <li className="specs-list-content montserrat">
                                        Conduct market research to identify potential customers, market trends, and the competitive landscape.
                                    </li>
                                    <li className="specs-list-content montserrat">
                                        Analyze data and prepare reports on market trends, customer needs, and competitor activities.
                                    </li>
                                    <li className="specs-list-content montserrat">
                                        Assist in the development of marketing strategies and campaigns to target key customer segments.
                                    </li>
                                    <li className="specs-list-content montserrat">
                                        Identify new business opportunities and partnerships to drive revenue growth.
                                    </li>
                                    <li className="specs-list-content montserrat">
                                        Support the development and implementation of strategic business plans.
                                    </li>
                                    <li className="specs-list-content montserrat">
                                        Collaborate with cross-functional teams, including sales, marketing, and product development, to achieve business objectives.
                                    </li>
                                    <li className="specs-list-content montserrat">
                                        Assist in the preparation of business proposals, presentations, and sales materials.
                                    </li>
                                    <li className="specs-list-content montserrat">
                                        Stay up-to-date with industry developments, market dynamics, and emerging trends.
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="career-joblisting-detail job-description">
                            <div className="product-specification">
                                <h2 className="product-specs-title-for-product-single for-list-prod montserrat">
                                    Qualifications
                                </h2>
                                {/* <ul className="product-desc-list tera-job-description"> */}
                                <ul className="">
                                    <li className="specs-list-content montserrat">
                                        Bachelor's degree in Business Administration, Marketing, Economics, or a related field.
                                    </li>
                                    <li className="specs-list-content montserrat">
                                        0-3 years of experience in market research, business development, or related fields.
                                    </li>
                                    <li className="specs-list-content montserrat">
                                        Strong analytical skills with the ability to interpret data and draw actionable insights.
                                    </li>
                                    <li className="specs-list-content montserrat">
                                        Excellent written and verbal communication skills.
                                    </li>
                                    <li className="specs-list-content montserrat">
                                        Proficiency in Microsoft Office Suite (Word, Excel, PowerPoint).
                                    </li>
                                    <li className="specs-list-content montserrat">
                                        Experience with market research tools and techniques.
                                    </li>
                                    <li className="specs-list-content montserrat">
                                        Ability to work independently and collaboratively in a fast-paced environment.
                                    </li>
                                    <li className="specs-list-content montserrat">
                                        Strong attention to detail and organizational skills.
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="career-joblisting-detail job-description">
                            <div className="product-specification">
                                <h2 className="product-specs-title-for-product-single for-list-prod montserrat">
                                    Preferred Qualifications
                                </h2>
                                {/* <ul className="product-desc-list tera-job-description"> */}
                                <ul className="">
                                    <li className="specs-list-content montserrat">
                                        Master's degree in Business Administration or related field.
                                    </li>
                                    <li className="specs-list-content montserrat">
                                        Experience in a relevant industry such as technology, healthcare, finance, or consumer goods.  </li>
                                    <li className="specs-list-content montserrat">
                                        Familiarity with CRM software and other sales tools.
                                    </li>
                                    <li className="specs-list-content montserrat">
                                        Previous experience in project management or consulting.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default businessdevelopmentassociate;
