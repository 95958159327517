import React from "react";
import Loginform, {
  LoginDetails,
} from "components/Sections/loginandsingup/login";
import "./login.css";
import { Link, useNavigate } from "react-router-dom";

import { useUser } from "components/UserContext";
import { setSession } from "services/authService";
import { loginApi } from "services/loginService";
import  { parsePhoneNumber } from "react-phone-number-input";
const LoginComponent: React.FC = () => {
  const { setUser } = useUser();

  const navigate = useNavigate();

  const handleLoginSubmit = (
    phone: string,
    password: string,
    loginDetails: LoginDetails
  ) => {
    loginApi({
      phoneNumber: parsePhoneNumber(phone.toString())?.nationalNumber,
      // phone,
      password,
      ...loginDetails,
    })
      .then((response) => {
        if (response.data && response.status === 200) {
          setUser(response.data);
          setSession(response.data?.token,response.data?.getUser?.contactNumber);
          navigate("/referrals/dashboard");
        }
      })
      .catch((error) => {
        console.error("API Error:", error);
      });
  };

  return (
    <div className="login-signp">
      <div className="login-signup-iner">
        <div className="container">
          <div className="login-singup-inner-wrapper">
            <h3 className="montserrat">Marketing Partner Sign In</h3>
            <Loginform onSubmit={handleLoginSubmit} />
            <div className="sign-up-login-redirection">
              {/* <p>
                Don’t have an account?<Link to="/signup"> Sign up</Link>
              </p> */}
              <Link to="/forgot-pass"> Forgot Password</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginComponent;
