// FeatureService.js
import React from 'react';
import './medicalcentersetup.css'

interface offerServiceProps {
  data: {
    image: string;
    title: string;
    subtitle: string;
  };
}

const offerService: React.FC<offerServiceProps> = ({ data }) => {
  return (
    <div className="offer-services feature-services-wrap">
      <div className="offer-services-img feature-service-img">
        <img className = "h-20 w-20" style = {{objectFit:"scale-down"}}src={data.image} alt="Feature Service" />
      </div>
      <div className="offer-services-desc feature-service-desc">
        <h3>{data.title}</h3>
        <h5>{data.subtitle}</h5>
      </div>
    </div>
  );
};

export default offerService;
