import React, { useState, useEffect } from "react";

const contentwriter = () => {
  return (
    <div className="page-job-post content-writer">
      <div className="container">
        <div className="job-post-wrapper">
          <div className="jobdetail-side">
            <div className="joblistings-detail">
              <div className="jobs-prodiver-detail">
                <div className="job-info-main">
                  <h4 className="job-name"> Content Writer</h4>
                  <h5 className="job-provider-name"></h5>
                </div>
                <div className="job-provider-info"></div>
              </div>
              <div className="jobs-requirment">
                <div className="uppersection">
                  <p className="job-detail-minor required-exprence ">
                    1-2 years
                  </p>
                  <p className="job-detail-minor salary-offer">Not Disclosed</p>
                  <p className="job-detail-minor location">Hyderabad</p>
                </div>
                <div className="lowersections">
                  <p className="job-detail-minor detail-other ">
                    Education : M.A English/BDS/BHMS/BUMS/M.Pharm/B.Pharm
                  </p>
                </div>
              </div>
              <div className="job-apply-section">
                <div className="job-provider-info post-page">
                  <p className="job-detail-minor openings"> 1 Opening</p>
                  <span className="job-detail-minor posted-ago">
                    4/12/ 2023
                  </span>
                </div>
                <div className="job-btns-selection">
                 
                  <a className="applynowbtn apply-save-btn" href="mailto:careers@teraconnects.com">
                    Apply
                  </a>
                </div>
              </div>
            </div>

            <div className="career-joblisting-detail job-description">
              <div className="product-specification">
                <h2 className="product-specs-title-for-product-single for-list-prod montserrat">
                  Job description
                </h2>
                {/* <ul className="product-desc-list tera-job-description"> */}
                <ul className="">
               <li className="specs-list-content montserrat">
                    {" "}
                    Experience in the content writing and should have knowledge
                    in the copywriting and SEO.
                  </li>
                  <li className="specs-list-content montserrat">
                    {" "}
                    Conducting in-depth research on industry-related topics in
                    order to develop copyrighted content.
                  </li>
                  <li className="specs-list-content montserrat">
                    {" "}
                    Developing content for blogs, articles, product
                    descriptions, social media, and the company website.
                  </li>
                  <li className="specs-list-content montserrat">
                    {" "}
                    Assisting the marketing team in developing content for
                    advertising campaigns.
                  </li>
                  <li className="specs-list-content montserrat">
                    {" "}
                    Conducting keyword research and using SEO best practices to
                    increase traffic to the company website.
                  </li>
                  <li className="specs-list-content montserrat">
                    {" "}
                    Creating compelling headlines and body copy that will
                    capture the attention of the target audience.
                  </li>
                  <li className="specs-list-content montserrat">
                    {" "}
                    Translate medical processes and strategies into creative
                    content execution.{" "}
                  </li>
                  <li className="specs-list-content montserrat">
                    {" "}
                    Responsible for all content-related aspects of each assigned
                    project from initiation to launch, including conception,
                    writing, revising, annotating per source references.
                  </li>
                  <li className="specs-list-content montserrat">
                    {" "}
                    Develop different types of medical education & communication
                    programs/deliverables such as Training manuals/ slides,
                    Review articles, Product monographs, CME slide decks,
                    Newsletters.
                  </li>
                  <li className="specs-list-content montserrat">
                    {" "}
                    Conduct content search, editing, storyboard creation for
                    multimedia deliverables such as interactive CDs, e-detailing
                    films, websites, animations.{" "}
                  </li>
                  <li className="specs-list-content montserrat">
                    {" "}
                    Develop and maintain necessary templates, formats, and
                    styles to ensure that documents generated meet industry,
                    regulatory, internal, and client requirements.{" "}
                  </li>
                  <li className="specs-list-content montserrat">
                    {" "}
                    Editing and polishing existing content to improve
                    readability.
                  </li>
                  <li className="specs-list-content montserrat">
                    {" "}
                    Coordination and delegation with content writers for
                    targets, compliance, quality and review.
                  </li>
                  <li className="specs-list-content montserrat">
                    {" "}
                    Must be able to interact effectively with internal teams to
                    ensure quality and smooth progression of projects.
                  </li>
                  <li className="specs-list-content montserrat">
                    {" "}
                    Simplification of complex medical content.
                  </li>
                  <li className="specs-list-content montserrat">
                    {" "}
                    Strong attention to detail and the ability to multi-task
                    projects and deliverables.
                  </li>
                  <li className="specs-list-content montserrat">
                    {" "}
                    Competent enough to handle multiple projects and liaise and
                    coordinate with departments.
                  </li>
                  <li className="specs-list-content montserrat">
                    {" "}
                    Should possess good command over English language and flair
                    for writing with an understanding of what works for internet
                    content writing.{" "}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="appdownload-main" id="download-app-now"></div>
    </div>
  );
};

export default contentwriter;
