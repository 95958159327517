import React from "react";
import JoinNetwork from "components/Sections/mobileappdownload/mobileappdownload";
import Jobsbyhopital from "components/Sections/joblist/joblist/joblistcard/jobs/jobscardsliderforhospital";

import Categoryfilterslider from 'components/Sections/joblist/filter/categoryfilter/jobcategoryslider';

import qrtera from './images/mobqr.png'


import "./client-profile.css"
const Clientprofile: React.FC = () => {
  
    
  return (
    <div className="event-page backgrount-white">
        
            <div className="about-client-to-card">
                <div className="poster-of-client">
                    <img src="https://assets-in.bmscdn.com/iedb/movies/images/mobile/listing/xxlarge/animal-et00311762-1672646524.jpg"></img>
                </div>
                <div className="details-of-client container">
                    <div className="client-inner-details">
                        <div className="details-start">
                            <div className="client-profile-logo">
                                <div className="main-logo-client">
                                    <img src="https://teraconnects.com/images/client/clients-4.png"></img>
                                </div>
                            </div>
                            <div className="client-profile-write-details">
                                <h5 className="comapny-name montserrat">
                                Global Hospital & Healthcare
                                    <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="21" viewBox="0 0 22 21" fill="none">
                                                    <path
                                                            d="M7.6 21L5.7 17.8L2.1 17L2.45 13.3L0 10.5L2.45 7.7L2.1 4L5.7 3.2L7.6 0L11 1.45L14.4 0L16.3 3.2L19.9 4L19.55 7.7L22 10.5L19.55 13.3L19.9 17L16.3 17.8L14.4 21L11 19.55L7.6 21ZM9.95 14.05L15.6 8.4L14.2 6.95L9.95 11.2L7.8 9.1L6.4 10.5L9.95 14.05Z"
                                                                fill="#00A0C0"  />
                                                  
                                        </svg>
                                    </span>
                                </h5>

                                <h3 className="company-speciality montserrat">
                                    Multi-speciality Clinic
                                </h3>

                                <h6 className="company-details-tagline montserrat">
                                t is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout
                                </h6>

                                <h6 className="compnay-address montserrat">
                                2nd Floor, Chamberrs Business Center At KEYS Hotel, Mumbai Pune Road, Finolex Chowk, Morwadi, Landmark: Near Bank of Maharashtra & Above Tanishq, Pune
                                </h6>

                            </div>

                        </div>
                        <div className="client-btn-follow-call">
                            <button className="montserrat follow-us-btn">Follow</button>
                            <a className="montserrat callus-btn"> Call Us</a>
                            
                            </div>

                    </div>
                </div>
            </div>


            <div className='container'>
                <div className='job-post-wrapper'>
                    <div className='jobdetail-side'>


                    <div className="client-profile-page-left-container about-organisation">
                            <h2 className="comapny-page-heading montserrat">About Organisation</h2>
                            <ul className="companypage">
                                <li className="about-compant lists-on-about-company montserrat">Meditech (India) is counted amidst the renowned in manufacturing, supplying and exporting of high quality health-care and surgical equipments. The company offers a comprehensive range of operation theater equipment, OT tables, universal operating tables, universal operating lights, orthopedic attachments, neuro attachment, hospital furniture and suction machines. Meditech (India) is an ISO 9001 & ISO 13485 Certified & CE MARK Company specifically focused on offering the superior quality products and timely services. Each of its surgical accessories for the operation theaters is designed ergonomically and certified by competent medical authorities. All its products are in compliance to various Indian as well as international quality standards. At Meditech (India) there is a team of experienced professionals from this industry who possess years of experience. The company's entire business operations are inclined to achieve total customer satisfaction. At the same time, it also strives to offer all these reliable surgical products at most competitive prices</li>
                            </ul>
                        </div>



                        <div className="client-profile-page-left-container hospitalpagejobs job-tab-content">
                        <h2 className="comapny-page-heading montserrat">Jobs</h2>
                        <Jobsbyhopital perView={3}/>
                        </div>


                        <div className="client-profile-page-left-container hospitalpagecategory">
                        <h2 className="comapny-page-heading montserrat">Jobs category</h2>
                        {/* <Categoryfilterslider /> */}
                        </div>


                        <div className="client-profile-page-left-container about-organisation">
                            <h2 className="comapny-page-heading montserrat">About Organisation</h2>
                            <ul className="companypage">
                                <li className="about-compant lists-on-about-company montserrat">Meditech (India) is counted amidst the renowned in manufacturing, supplying and exporting of high quality health-care and surgical equipments. The company offers a comprehensive range of operation theater equipment, OT tables, universal operating tables, universal operating lights, orthopedic attachments, neuro attachment, hospital furniture and suction machines. Meditech (India) is an ISO 9001 & ISO 13485 Certified & CE MARK Company specifically focused on offering the superior quality products and timely services. Each of its surgical accessories for the operation theaters is designed ergonomically and certified by competent medical authorities. All its products are in compliance to various Indian as well as international quality standards. At Meditech (India) there is a team of experienced professionals from this industry who possess years of experience. The company's entire business operations are inclined to achieve total customer satisfaction. At the same time, it also strives to offer all these reliable surgical products at most competitive prices</li>
                            </ul>
                        </div>

                    </div>
                    <div className='other-job-side similar-jobs'>
                        <div className='similar-jobs-lists'>
                            <div className="joblistings-detail">
                                <div className="jobs-prodiver-detail">
                                    <div className="job-info-main">
                                        <h4 className="job-name comapny-page-heading">Senior Nurse</h4>
                                        <p className="about-compant lists-on-about-company montserrat">
                                        Design layouts for color ads, brochures, collateral materials, digital/interactive projects.Manipulate photographs for basic comp work such as scanning, silhouetting and light re-touching.Create high-end cutting-edge design for complex projects and demo samples
                                        </p>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                        <div className='similar-jobs-lists'>
                            <div className="joblistings-detail">
                                <div className="jobs-prodiver-detail">
                                    <div className="job-info-main">
                                        <h4 className="job-name comapny-page-heading">Senior Nurse</h4>
                                        <p className="about-compant lists-on-about-company montserrat">
                                        Design layouts for color ads, brochures, collateral materials, digital/interactive projects.Manipulate photographs for basic comp work such as scanning, silhouetting and light re-touching.Create high-end cutting-edge design for complex projects and demo samples
                                        </p>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                        <div className='similar-jobs-lists'>
                            <div className="joblistings-detail">
                                <div className="jobs-prodiver-detail">
                                    <div className="job-info-main">
                                        <h4 className="job-name comapny-page-heading">Senior Nurse</h4>
                                        <p className="about-compant lists-on-about-company montserrat">
                                        Design layouts for color ads, brochures, collateral materials, digital/interactive projects.Manipulate photographs for basic comp work such as scanning, silhouetting and light re-touching.Create high-end cutting-edge design for complex projects and demo samples
                                        </p>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                        <div className='similar-jobs-lists'>
                            <div className="joblistings-detail">
                                <div className="jobs-prodiver-detail">
                                    <div className="job-info-main">
                                        <h4 className="job-name comapny-page-heading">Senior Nurse</h4>
                                        <p className="about-compant lists-on-about-company montserrat">
                                        Design layouts for color ads, brochures, collateral materials, digital/interactive projects.Manipulate photographs for basic comp work such as scanning, silhouetting and light re-touching.Create high-end cutting-edge design for complex projects and demo samples
                                        </p>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
             <div className="appdownload-main" id="download-app-now">
                <JoinNetwork
                    heading="Join Our Network"
                    subheading="Join Tera Connects for a transformative journey where your agency can thrive, connect with top-notch talent, and become a key player in shaping the future of medical staffing."
                    googlePlayUrl="https://play.google.com/store/apps/details?id=com.teraplatforms.jobseeker"
                    appStoreUrl="https://apps.apple.com/in/app/tera-recruit-job-search/id1659265164"
                    imageUrl= {qrtera}
                />
            </div>

        
    </div>
  );
};

export default Clientprofile;
