import React, { useState, useEffect } from "react";

const fluttermobiledeveloper = () => {
    return (
        <div className="page-job-post content-writer">
            <div className="container">
                <div className="job-post-wrapper">
                    <div className="jobdetail-side">
                        <div className="joblistings-detail">
                            <div className="jobs-prodiver-detail">
                                <div className="job-info-main">
                                    <h4 className="job-name">Flutter Mobile Developer</h4>
                                    <h5 className="job-provider-name"></h5>
                                </div>
                                <div className="job-provider-info"></div>
                            </div>
                            <div className="jobs-requirment">
                                <div className="uppersection">
                                    <p className="job-detail-minor required-exprence ">
                                        1-5 years
                                    </p>
                                    <p className="job-detail-minor salary-offer">Not Disclosed</p>
                                    <p className="job-detail-minor location">On-Site, Hyderabad, Telangana</p>
                                </div>
                                <div className="lowersections">
                                    <p className="job-detail-minor detail-other ">
                                        Education : Bachelor's degree in Computer Science, Engineering, or related field.
                                    </p>
                                    <p className="job-detail-minor detail-other">
                                        Key Skills : Flutter, Dart, Android, iOS
                                    </p>
                                </div>
                            </div>
                            <div className="job-apply-section">
                                <div className="job-provider-info post-page">
                                    {/* <p className="job-detail-minor openings"> 1 Opening</p>
                                    <span className="job-detail-minor posted-ago">
                                        4/12/ 2023
                                    </span> */}
                                </div>
                                <div className="job-btns-selection">
                                    <a
                                        className="applynowbtn apply-save-btn"
                                        href="mailto:careers@teraconnects.com"
                                    >
                                        Apply
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className="career-joblisting-detail job-description">
                            <div className="product-specification">
                                <h2 className="product-specs-title-for-product-single for-list-prod montserrat">
                                Job Description
                                </h2>
                                <ul className="product-desc-list tera-job-description">
                                We are seeking a talented Flutter Mobile Developer to join our team. 
                                The Flutter Mobile Developer will be responsible for developing and maintaining 
                                mobile applications using the Flutter framework. The ideal candidate will have a
                                strong understanding of Flutter development principles, 
                                excellent problem-solving skills, and a passion for creating high-quality mobile applications.
                                    </ul>
                               
                            </div>
                        </div>

                        <div className="career-joblisting-detail job-description">
                            <div className="product-specification">
                                <h2 className="product-specs-title-for-product-single for-list-prod montserrat">
                                    Responsibilities
                                </h2>
                                {/* <ul className="product-desc-list tera-job-description"> */}
                                <ul className="">
                                    <li className="specs-list-content montserrat">
                                        Develop mobile applications for Android and iOS platforms using the Flutter framework.
                                    </li>
                                    <li className="specs-list-content montserrat">
                                        Collaborate with cross-functional teams to define, design, and ship new features.
                                    </li>
                                    <li className="specs-list-content montserrat">
                                        Write clean, efficient, and maintainable code following best practices and coding standards.
                                    </li>
                                    <li className="specs-list-content montserrat">
                                        Perform code reviews, debug and fix issues, and optimize application performance.
                                    </li>
                                    <li className="specs-list-content montserrat">
                                        Implement user interfaces and user experiences based on design guidelines and wireframes.
                                    </li>
                                    <li className="specs-list-content montserrat">
                                        Stay updated on the latest trends and technologies in mobile development, including Flutter updates and new features.
                                    </li>
                                    <li className="specs-list-content montserrat">
                                        Work closely with designers, product managers, and QA engineers to ensure timely delivery of high-quality applications.
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="career-joblisting-detail job-description">
                            <div className="product-specification">
                                <h2 className="product-specs-title-for-product-single for-list-prod montserrat">
                                    Requirements
                                </h2>
                                {/* <ul className="product-desc-list tera-job-description"> */}
                                <ul className="">
                                    <li className="specs-list-content montserrat">
                                        Bachelor's degree in Computer Science, Engineering, or related field.
                                    </li>
                                    <li className="specs-list-content montserrat">
                                        1- 5 years of proven experience developing mobile applications using Flutter.
                                    </li>
                                    <li className="specs-list-content montserrat">
                                        Strong knowledge of Dart programming language, Flutter SDK, and Flutter architecture.
                                    </li>
                                    <li className="specs-list-content montserrat">
                                        Experience with mobile app development best practices, including responsive design and performance optimization.
                                    </li>
                                    <li className="specs-list-content montserrat">
                                        Familiarity with RESTful APIs, JSON, and asynchronous programming.
                                    </li>
                                    <li className="specs-list-content montserrat">
                                        A solid understanding of software development lifecycle and agile methodologies.
                                    </li>
                                    <li className="specs-list-content montserrat">
                                        Excellent problem-solving skills and attention to detail.
                                    </li>
                                    <li className="specs-list-content montserrat">
                                        Ability to work independently and as part of a team in a fast-paced environment.
                                    </li>
                                    <li className="specs-list-content montserrat">
                                        Good communication skills and the ability to collaborate effectively with cross-functional teams.
                                    </li>
                                </ul>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default fluttermobiledeveloper;
