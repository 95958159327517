import React, { useEffect, useState } from 'react';

const CopyrightYear: React.FC<{}> = () => {
  const [currentYear, setCurrentYear] = useState<number>(new Date().getFullYear());

  useEffect(() => {
    const intervalId = setInterval(() => {
      // Update the current year every 1 minute (or as needed)
      setCurrentYear(new Date().getFullYear());
    }, 60000); // Update every 1 minute

    // Clear the interval when the component unmounts
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  // Return the current year to be displayed
  return <>{currentYear}</>;
};

export default CopyrightYear;
