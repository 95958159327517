import React, { useEffect } from "react";

const ClientDeepLink = () => {
  
  useEffect(() => {
    const baseDomain = process.env.NODE_ENV === "production" ? "https://teraconnects.com" : "https://beta.teraconnects.com"
    const userAgent =
      navigator.userAgent || navigator.vendor || (window as any).opera;
    if (/android/i.test(userAgent)) {
      window.location.href =
        "https://play.google.com/store/apps/details?id=com.teraplatforms.teraconnects";
    } else if (
      /iPad|iPhone|iPod/.test(userAgent) &&
      !(window as any).MSStream
    ) {
      window.location.href =
        "https://apps.apple.com/in/app/tera-connects/id6448921572";
    } else {
      window.location.href =
        `${baseDomain}/hiring-careers/for-clients#download-app-now`;
    }
  }, []);
  return null;
};

export default ClientDeepLink;
