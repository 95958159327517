import { useEffect, useState } from "react";
import Layout from "../../layout";
import LayoutDashboard from "../referralslayout";

import ClientSummaryWidget from "./widgets/clientWidget";
import VendorSummaryWidget from "./widgets/vendorWidget";
import CandidatesSummaryWidget from "./widgets/candidatesWidget";

import Pagination from "components/Pagination/Pagination";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import PieChartComponent from "./widgets/earningsWidget";
import ReferralCoverageComponent from "./widgets/referralCoverageWidget";
import GoogleMapComponent from "./widgets/mapcomponent";
import { NumericFormat } from "react-number-format";
import { useUser } from "components/UserContext";
import { getMyDashBoardSummaryApi } from "services/dashboardService";
interface DashboardSummary {
  advertisementrReferralCount: number;
  candidaterReferralCount: number;
  vendorReferralCount: number;
  clientrReferralCount: number;
  totalEarnings: string;
  advertisementEarnings: string;
  candidateEarnings: string;
  vendorEarnings: string;
  clientEarnings: string;
  state: string;
  city: string;
}
interface EarningSummary {
  paid: number;
  paidPercentage: number;
  unPaid: number;
  unPainPercentage: number;
}
interface ReferralCoveragesummary {
  advertisementPercentage: number;
  candidatePercentage: number;
  clientPercentage: number;
  vendorPercentage: number;
}
interface LocationDataSummary {
  city: string;
  geoLocation: {
    x: string;
    y: string;
  };
  latitude: number;
  longitude: number;
}
ChartJS.register(ArcElement, Tooltip, Legend);

const ReferralsDashboard = () => {
  const [dashboardSummary, setDashboardSummary] = useState<DashboardSummary[]>(
    []
  );
  const [earningSummary, setEarningSummary] = useState<EarningSummary | null>(
    null
  );
  const [referralCoveragesummary, setReferralCoveragesummary] =
    useState<ReferralCoveragesummary | null>(null);
  const [locationData, setLocationData] = useState<LocationDataSummary[]>([]);
  const { userData } = useUser();
  useEffect(() => {
    getMyDashBoardSummaryApi(userData.getUser.parnerId)
      // getMyDashBoardSummaryApi(10)
      .then((response) => {
        const dashboardSummary = response.data;
        setEarningSummary(dashboardSummary.earningSummary);
        setReferralCoveragesummary(dashboardSummary.referralCoveragesummary);
        setLocationData(dashboardSummary.coveragebyLocations);
        const dashboardSummaryData = dashboardSummary.earningbyCities;
        setDashboardSummary(dashboardSummaryData);
      })
      .catch((err) => {
        console.log(err, "Err");
      });
  }, []);

  return (
    <Layout>
      <LayoutDashboard>
        <div className="rounded-xl md:border md:border-neutral-100 dark:border-neutral-800 md:p-4 shadow-md">
          <div className="grid grid-cols-3 gap-4">
            <div className="bg-white rounded-md p-3 shadow-md  col-span-1">
              <CandidatesSummaryWidget />
            </div>
            <div className="bg-white rounded-md p-4 shadow-md col-span-1">
              <ClientSummaryWidget />
            </div>
            <div className="bg-white rounded-md p-4 shadow-md  col-span-1">
              <VendorSummaryWidget />
            </div>

            <div className="bg-white rounded-md p-4 shadow-md col-span-1">
              <h1 className="line-clamp-2 text-lg font-semibold  dark:text-neutral-300">
                Earning Summary
              </h1>{" "}
              {earningSummary && (
                <PieChartComponent earningSummary={earningSummary} />
              )}
            </div>
            <div className="bg-white rounded-md p-4 shadow-md col-span-1">
              <h1 className="line-clamp-2 text-lg font-semibold  dark:text-neutral-300">
                Location
              </h1>

              <div>
                <GoogleMapComponent locationData={locationData} />
              </div>
            </div>
            <div className="bg-white rounded-md p-3 shadow-md col-span-1">
              <h1 className="line-clamp-2 text-lg font-semibold  dark:text-neutral-300">
                Coverage
              </h1>
              {referralCoveragesummary && (
                <ReferralCoverageComponent
                  referralCoveragesummary={referralCoveragesummary}
                />
              )}
            </div>
          </div>
          <div className="p-6">
            <h1 className="line-clamp-2 text-lg font-semibold  dark:text-neutral-300">
              Earning By City
            </h1>
          </div>

          <div className="flex flex-col space-y-9">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="py-3 align-middle inline-block min-w-full px-1 sm:px-6 lg:px-8">
                <div className="shadow dark:border dark:border-neutral-800 overflow-hidden sm:rounded-lg">
                  <table className="divide-y divide-neutral-200 dark:divide-neutral-800">
                    <thead className="bg-neutral-50 dark:bg-neutral-800">
                      <tr className="text-left text-xs font-medium text-neutral-500 dark:text-neutral-300 uppercase tracking-wider">
                        <th scope="col" className="px-6 py-3">
                          City
                        </th>
                        <th scope="col" className="px-6 py-3">
                          State
                        </th>
                        <th scope="col" className="px-6 py-3">
                          #Clients
                        </th>
                        <th scope="col" className="px-6 py-3">
                          #Vendors
                        </th>
                        <th scope="col" className="px-6 py-3">
                          #Candidates
                        </th>
                        <th scope="col" className="px-6 py-3">
                          #Ads
                        </th>
                        <th scope="col" className="px-6 py-3">
                          #Earnings
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white dark:bg-neutral-900 divide-y divide-neutral-200 dark:divide-neutral-800">
                      {dashboardSummary?.map((item, index) => (
                        <tr key={index}>
                          <td className="px-4 py-2">
                            <div className="ml-4 flex-grow">
                              <h2 className="inline-flex line-clamp-2 text-sm font-semibold  dark:text-neutral-300">
                                {item?.city}
                              </h2>
                            </div>
                          </td>
                          <td className="px-4 py-2">
                            <div className="ml-4 flex-grow">
                              <h2 className="inline-flex line-clamp-2 text-sm font-semibold  dark:text-neutral-300">
                                {item.state}
                              </h2>
                            </div>
                          </td>
                          <td className="px-4 py-2">
                            <div className="ml-4">
                              <h2 className="line-clamp-2 text-sm font-semibold dark:text-neutral-300">
                                {item.clientrReferralCount}
                              </h2>
                              <h2 className="line-clamp-2 text-sm font-semibold  dark:text-neutral-300">
                                <NumericFormat
                                  displayType="text"
                                  value={item?.clientEarnings}
                                  thousandSeparator={true}
                                  prefix={"₹"}
                                />
                              </h2>
                            </div>
                          </td>
                          <td className="px-4 py-2">
                            <div className="ml-4">
                              <h2 className="line-clamp-2 text-sm font-semibold dark:text-neutral-300">
                                {item.vendorReferralCount}
                              </h2>
                              <h2 className="inline-flex line-clamp-2 text-sm font-semibold  dark:text-neutral-300">
                                <NumericFormat
                                  displayType="text"
                                  value={item?.vendorEarnings}
                                  thousandSeparator={true}
                                  prefix={"₹"}
                                />
                              </h2>
                            </div>
                          </td>
                          <td className="px-4 py-2">
                            <div className="ml-4">
                              <h2 className="line-clamp-2 text-sm font-semibold dark:text-neutral-300">
                                {item.candidaterReferralCount}
                              </h2>
                              <h2 className="inline-flex line-clamp-2 text-sm font-semibold  dark:text-neutral-300">
                                <NumericFormat
                                  displayType="text"
                                  value={item?.candidateEarnings}
                                  thousandSeparator={true}
                                  prefix={"₹"}
                                />
                              </h2>
                            </div>
                          </td>
                          <td className="px-4 py-2">
                            <div className="ml-4">
                              <h2 className="line-clamp-2 text-sm font-semibold dark:text-neutral-300">
                                {item.advertisementrReferralCount}
                              </h2>
                              <h2 className="inline-flex line-clamp-2 text-sm font-semibold  dark:text-neutral-300">
                                <NumericFormat
                                  displayType="text"
                                  value={item?.advertisementEarnings}
                                  thousandSeparator={true}
                                  prefix={"₹"}
                                />
                              </h2>
                            </div>
                          </td>
                          <td className="px-4 py-2">
                            <div className="ml-4 flex-grow">
                              <h2 className="inline-flex line-clamp-2 text-sm font-semibold  dark:text-neutral-300">
                                <NumericFormat
                                  displayType="text"
                                  value={item?.totalEarnings}
                                  thousandSeparator={true}
                                  prefix={"₹"}
                                />
                              </h2>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            {/* <Pagination /> */}
          </div>
        </div>
      </LayoutDashboard>
    </Layout>
  );
};
export default ReferralsDashboard;
