import React, { useState, useEffect, useRef } from "react";
import Breadcrumb from "./breadCrumb";

interface Section {
  id: string;
  title: string;
  subsections: Subsection[];
  videoLink?: string; // Optional field for video link
  imageUrl?: string;
}

interface Subsection {
  id: string;
  title: string;
}



const Partners: React.FC = () => {
  return (<>Partners</>)
}

export default Partners;

function isElementInViewport(el: HTMLElement) {
  const rect = el.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}