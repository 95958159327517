import React, { useState } from 'react';

interface SignupProps {
  onSubmit: (firstName: string, lastName: string, email: string, password: string) => void;
}

const Signup: React.FC<SignupProps> = ({ onSubmit }) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const handleFirstNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFirstName(e.target.value);
  };

  const handleLastNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLastName(e.target.value);
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(firstName, lastName, email, password);
  };

  return (
    <form className='signup-form' onSubmit={handleSubmit}>
    <div className='name-emain-wrapper'>
      <div className="name-dept">
        <div>
          <label>First Name:</label>
          <input type="text" value={firstName} onChange={handleFirstNameChange} required />
        </div>
        <div>
          <label>Last Name:</label>
          <input type="text" value={lastName} onChange={handleLastNameChange} required />
        </div>
      </div>
      <div className='email-password-dept'>
        <div>
          <label>Email:</label>
          <input type="email" value={email} onChange={handleEmailChange} required />
        </div>
       
      </div>
      <div className="password-dep">
        <div>
          <label>Password:</label>
          <div className={`password-field ${showPassword ? 'show' : 'hide'}`}>
          <input
            type={showPassword ? 'text' : 'password'}
            value={password}
            onChange={handlePasswordChange}
            placeholder='Your Password'
            required
          />
          <span onClick={handleTogglePasswordVisibility} className="toggle-password">
            {showPassword ? 'Hide' : 'Show'}
          </span>
          </div>
        </div>

        <div>
          <label>Confirm Password:</label>
          <div className={`password-field ${showPassword ? 'show' : 'hide'}`}>
          <input
            type={showPassword ? 'text' : 'password'}
            value={password}
            onChange={handlePasswordChange}
            placeholder='Your Password'
            required
          />
          <span onClick={handleTogglePasswordVisibility} className="toggle-password">
            {showPassword ? 'Hide' : 'Show'}
          </span>
          </div>
        </div>
        </div>
      </div>
      <button type="submit">Create New Account</button>
    </form>
  );
};

export default Signup;
