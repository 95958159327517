import API from "./api";
import { Events_API_URLS } from "./constants";

export function searchEventsApi(args) {
  return API.post(Events_API_URLS.searchEventsUrl, args);
}
export function getTopUpcomingEventsApi(args) {
  return API.get(Events_API_URLS.getTopUpcomingEventsUrl,args);
}
export function getEventDetailsApi(args) {
  return API.post(Events_API_URLS.getEventDetailsUrl,args);
}
