import { useEffect } from "react";
import MyRoutes from "./routers";

function App() {
  useEffect(() => {
    const handleContextMenu = (e:Event) => {
      e.preventDefault();
    };
    if (window.location.hostname !== 'localhost') {
      document.addEventListener('contextmenu', handleContextMenu);
    }
    return () => {
      document.removeEventListener('contextmenu', handleContextMenu);
    };
  }, []);

  return (
    <div className="bg-[#f8f8f8] text-base dark:bg-neutral-900/95 text-neutral-900 dark:text-neutral-200 font-body">
      <MyRoutes />
    </div>
  );
}

export default App;
