import React from 'react';
import './pagination.css';

interface PaginationProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (newPage: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({ currentPage, totalPages, onPageChange }) => {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (

    <div className='pagination-btn'>
      {totalPages > 0 ?
        <>
          <button className='prev-next prev' onClick={() => { onPageChange(currentPage - 1); scrollToTop(); }} disabled={currentPage === 1}>
            Previous
          </button>
          {/* Render page numbers */}
          {Array.from({ length: totalPages }, (_, index) => (
            <button className="numer-btn" key={index + 1} onClick={() => { onPageChange(index + 1); scrollToTop(); }} disabled={currentPage === index + 1}>
              {index + 1}
            </button>
          ))}
          <button className="prev-next next" onClick={() => { onPageChange(currentPage + 1); scrollToTop(); }} disabled={currentPage === totalPages}>
            Next
          </button>
        </>
        : ''}
    </div>
  );
};

export default Pagination;
