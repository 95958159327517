import React from "react";


import "./clinicians-profile.css"
const Cliniciansprofile: React.FC = () => {

  return (
    <div className="clinicians-profile-page background-white">
        <div className="clinicians-profile-short container">
            <div className="clinicicians-poster">
            <img src="https://assets-in.bmscdn.com/iedb/movies/images/mobile/listing/xxlarge/animal-et00311762-1672646524.jpg"></img>
           
            </div>
            <div className="clinicicians-poster-data">
                <div className="clinicians-profile-pic">
                    <div className="clinicians-profic-inner-pic">
                        <img src="https://assets.gqindia.com/photos/645e034efc79052643f24e8e/16:9/w_1920,c_limit/Ratan-Tata.jpg"></img>
                     
                    </div>

                </div>
                <div className="clinicians-profile-data">
                    <div className="high-priority-data">
                        <div className="high-prior-ty-data-side">
                            <h4 className="clinicians-name montserrat">Suman Singh
                                <span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                    <path d="M11.0545 31.2051L8.2909 26.5505L3.05454 25.3869L3.56363 20.0051L0 15.9324L3.56363 11.8596L3.05454 6.47784L8.2909 5.31421L11.0545 0.659668L16 2.76876L20.9454 0.659668L23.7091 5.31421L28.9454 6.47784L28.4363 11.8596L31.9999 15.9324L28.4363 20.0051L28.9454 25.3869L23.7091 26.5505L20.9454 31.2051L16 29.096L11.0545 31.2051ZM14.4727 21.096L22.6909 12.8778L20.6545 10.7687L14.4727 16.9506L11.3454 13.896L9.30908 15.9324L14.4727 21.096Z" fill="#00A0C0"/>
                                </svg>
                                </span>
                            </h4>

                            <h6 className="client-designation montserrat">
                            Psychiatrist
                            </h6>

                        </div>
                        <div className="high-prior-logo">
                            <div className="current-company-logo">
                                <img src="https://teraconnects.com/images/client/clients-4.png"></img>
                            </div>
                        </div>
                    </div>


                    <div className="low-prioriti-data">
                        <div className="low-pri-ority-details">
                            <div className="adreess-client">
                                <p className="address montserrat">New Delhi, Delhi 110023</p>
                                <ul className="client-solical montserrat">
                                    <li>
                                       <a> facebook</a>
                                    </li>
                                    <li>
                                    <a> x</a>
                                    </li>
                                    <li>
                                    <a> linkdin</a>
                                    </li>
                                    <li>
                                    <a> instagram</a>
                                    </li>

                                </ul>

                            </div>

                        </div>
                        <div className="low-priority-buttons">
                        <button className="montserrat follow-us-btn">Follow</button>
                        <a className="montserrat callus-btn"> Contact</a>

                        </div>
                    </div>


                </div>
            </div>

        </div>
        <div className="container">
            <div className="clinicians-bio-data">
                <div className="clinician-about">
                    <h2 className="comapny-page-heading montserrat">About</h2>
                    <p className="about-compant lists-on-about-company montserrat">
                                        Design layouts for color ads, brochures, collateral materials, digital/interactive projects.Manipulate photographs for basic comp work such as scanning, silhouetting and light re-touching.Create high-end cutting-edge design for complex projects and demo samples
                    </p>
                </div>
                <div className="clinician-exprience">
                    <h2 className="comapny-page-heading montserrat">Experience</h2>
                    <div className="exprience-lists">
                        <div className="clinicians-expreience">
                            <div className="cliniciane-expreience-company">
                                 <img src="https://teraconnects.com/images/client/clients-4.png"></img>
                            </div>

                            <div className="expreice-cliniciane-details">
                                <h3 className="montserrat">Sr. Psychiatrist</h3>
                                <h5 className="montserrat">New Town Hospital</h5>
                                <h6 className="montserrat">Jan 2023 to Mar 2023</h6>

                            </div>
                        </div>
                        <div className="clinicians-expreience">
                            <div className="cliniciane-expreience-company">
                                 <img src="https://teraconnects.com/images/client/clients-4.png"></img>
                            </div>

                            <div className="expreice-cliniciane-details">
                                <h3 className="montserrat">Sr. Psychiatrist</h3>
                                <h5 className="montserrat">New Town Hospital</h5>
                                <h6 className="montserrat">Jan 2023 to Mar 2023</h6>

                            </div>
                        </div>
                        <div className="clinicians-expreience">
                            <div className="cliniciane-expreience-company">
                                 <img src="https://teraconnects.com/images/client/clients-4.png"></img>
                            </div>

                            <div className="expreice-cliniciane-details">
                                <h3 className="montserrat">Sr. Psychiatrist</h3>
                                <h5 className="montserrat">New Town Hospital</h5>
                                <h6 className="montserrat">Jan 2023 to Mar 2023</h6>

                            </div>
                        </div>
                        <div className="clinicians-expreience">
                            <div className="cliniciane-expreience-company">
                                 <img src="https://teraconnects.com/images/client/clients-4.png"></img>
                            </div>

                            <div className="expreice-cliniciane-details">
                                <h3 className="montserrat">Sr. Psychiatrist</h3>
                                <h5 className="montserrat">New Town Hospital</h5>
                                <h6 className="montserrat">Jan 2023 to Mar 2023</h6>

                            </div>
                        </div>

                    </div>
                </div>
                <div className="clinician-exprience">
                    <h2 className="comapny-page-heading montserrat">Education</h2>
                    <div className="exprience-lists">
                        <div className="clinicians-expreience">
                            <div className="cliniciane-expreience-company">
                                 <img src="https://teraconnects.com/images/client/clients-4.png"></img>
                            </div>

                            <div className="expreice-cliniciane-details">
                                <h3 className="montserrat">Sr. Psychiatrist</h3>
                                <h6 className="montserrat">Jan 2023 to Mar 2023</h6>

                            </div>
                        </div>
                        <div className="clinicians-expreience">
                            <div className="cliniciane-expreience-company">
                                 <img src="https://teraconnects.com/images/client/clients-4.png"></img>
                            </div>

                            <div className="expreice-cliniciane-details">
                                <h3 className="montserrat">Sr. Psychiatrist</h3>
                                <h6 className="montserrat">Jan 2023 to Mar 2023</h6>

                            </div>
                        
                     
                        </div>

                    </div>
                </div>
                <div className="clinician-other-details">
                    <h2 className="comapny-page-heading montserrat">About</h2>
                    <p className="about-compant lists-on-about-company montserrat">
                                        Design layouts for color ads, brochures, collateral materials, digital/interactive projects.Manipulate photographs for basic comp work such as scanning, silhouetting and light re-touching.Create high-end cutting-edge design for complex projects and demo samples
                    </p>
                </div>
        </div>
        </div>
    </div>
  );
};

export default Cliniciansprofile;
