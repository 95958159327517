import { useState, useEffect } from "react";
import { format } from "date-fns";
import { useUser } from "components/UserContext";
import { getInActiveMAVendorReferralsApi } from "services/referralService";
import Pagination from "../../pagenation/pagenation";

interface inActiveData {
  vendorName: string;
  // "isRecurring": false,
  id: number;
  city: string;
  state: string;
  phonenumber: number;
  status: string;
  onboardingDate: string;
  feedback: string;
  subscription: string;
}

function InActiveTab() {
  const [inActiveData, setInActiveData] = useState<inActiveData[]>([]);
  const { userData } = useUser();
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);

  const fetchInActiveData = (page: number) => {
    getInActiveMAVendorReferralsApi({
      partnerId: userData.getUser.parnerId,
      orderbyDirection: "ASC",
      pageNumber: page,
      pageSize: itemsPerPage,
      orderby: "Id",
    }).then((response) => {
      const inActiveResponseData = response.data;
      setInActiveData(inActiveResponseData.data);
      setTotalPages(Math.ceil(inActiveResponseData?.totalCount / itemsPerPage));
    });
  };
  useEffect(() => {
    fetchInActiveData(currentPage);
  }, [currentPage]);
  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className="flex flex-col space-y-9">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-6 align-middle inline-block min-w-full px-1 sm:px-6 lg:px-8">
          <div className="shadow dark:border dark:border-neutral-800 overflow-hidden sm:rounded-lg">
            <table className="divide-y divide-neutral-200 dark:divide-neutral-800">
              <thead className="bg-neutral-50 dark:bg-neutral-800">
                <tr className="text-left text-xs font-medium text-neutral-500 dark:text-neutral-300 uppercase tracking-wider">
                  <th scope="col" className="px-9 py-6">
                    Company
                  </th>

                  <th scope="col" className="px-9 py-6">
                    City
                  </th>
                  <th scope="col" className="px-9 py-6">
                    Due Date
                  </th>

                  <th scope="col" className="px-6 py-6">
                    Status
                  </th>
                  <th scope="col" className="px-6 py-6">
                    Feedback
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white dark:bg-neutral-900 divide-y divide-neutral-200 dark:divide-neutral-800">
                {inActiveData?.map((item) => (
                  <tr key={item.id}>
                    <td className="px-4 py-2">
                      <div className="ml-2 flex-grow">
                        <h2 className="inline-flex line-clamp-2 text-sm font-semibold  dark:text-neutral-300">
                          {item.vendorName}
                        </h2>
                      </div>
                    </td>

                    <td className="px-4 py-2">
                      <div className="ml-2 flex-grow">
                        <h2 className="inline-flex line-clamp-2 text-sm font-semibold  dark:text-neutral-300">
                          {item.city}
                        </h2>
                      </div>
                    </td>
                    <td className="px-4 py-2">
                      <div className="ml-3 flex-grow">
                        <h2 className="inline-flex line-clamp-2 text-sm font-semibold  dark:text-neutral-300">
                          {format(new Date(item.onboardingDate), "dd MMM yyyy")}
                        </h2>
                      </div>
                    </td>

                    <td className="px-4 py-2">
                      <div className="ml-3 flex-grow">
                        <h2 className="inline-flex line-clamp-2 text-sm font-semibold  dark:text-neutral-300">
                          {item.status}
                        </h2>
                      </div>
                    </td>
                    <td className="px-4 py-2">
                      <div className="ml-4 flex-grow">
                        <h2 className="inline-flex line-clamp-2 text-sm font-semibold  dark:text-neutral-300">
                          {item.feedback}
                        </h2>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />
    </div>
  );
}
export default InActiveTab;
