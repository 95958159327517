import API from "./api";
import { Dashboard_API_URLS } from "./constants";
export function getMyDashBoardSummaryApi(args) {
  return API.post(Dashboard_API_URLS.getMyDashBoardSummaryUrl, args);
}
export function getMyClinicianSummaryApi(args) {
  return API.post(Dashboard_API_URLS.getMyClinicianSummaryUrl, args);
}
export function getMyClientSummaryApi(args) {
    return API.post(Dashboard_API_URLS.getMyClientSummaryUrl, args);
  }
  export function getMyVendorSummaryApi(args) {
    return API.post(Dashboard_API_URLS.getMyVendorSummaryUrl, args);
  }
  