import React, { useState } from "react";
import MySlider from "components/MySlider";
import { CmeEventsData } from "app/(home)/event/page";
import "./eventshortcard.css";
import EventsView from "app/(home)/event/eventview/eventview";
import { Link } from "react-router-dom";
import { NumericFormat } from "react-number-format";

interface CategorySliderProps {
  perView: number;
  cmeEventsDetails: CmeEventsData[];
}

const CategorySlider: React.FC<CategorySliderProps> = ({
  cmeEventsDetails,
}) => {
  const formatEventDate = (dateTimeString: string) => {
    const eventDate = new Date(dateTimeString);

    const formattedDate = eventDate.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });

    return formattedDate;
  };
  const formatEventsDateTime = (dateTimeString: string) => {
    const eventDate = new Date(dateTimeString);

    const formattedTime = eventDate.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
    });

    return formattedTime;
  };

  const renderSliderItem = (cmeEventsDetails: any) => (
    <Link to="/event/eventview" state={{ eventId: cmeEventsDetails.id }}>
      <div className="event-slider-content">
        <div className="event-details">
          <div className="evets-titles">
          <h5 className="montserrat">
              {cmeEventsDetails.eventTitle}
            </h5>
            <h3 className="montserrat">
              By {cmeEventsDetails.organizer}
            </h3>
          </div>
          {/* <div className="events-desc">
            <p className="montserrat truncate">
              {cmeEventsDetails.description}
            </p>
          </div> */}
          <div className="evets-datas mt-3">
            <div className="time-book">
              <div className="times">
              <p  className="event-date montserrat">
                  {formatEventDate(cmeEventsDetails.eventDate)} -{" "}
                  {formatEventDate(cmeEventsDetails.eventEndDate)}
                </p>
              </div>
          
              <div className="book">
                    <Link to="/event/eventview" state={{ eventId: cmeEventsDetails.id }}>Book Now</Link>
              
              
                    <p>
                    <NumericFormat
                       displayType="text"
                        value={cmeEventsDetails.credits}
                       thousandSeparator={true}
                      prefix={"₹"}
                    />
                    </p>
                </div>
            </div>
            
            
          </div>
        </div>
        <div className="event-banner-img">
          {cmeEventsDetails?.thumbnail&&(
          <img src={cmeEventsDetails.thumbnail} alt="" />
        )}
        </div>
      </div>
    </Link>
  );

  return (
    <div className="jobbyhospitalsliderslider">
      <MySlider
        className="category-slider"
        data={cmeEventsDetails}
        renderItem={renderSliderItem}
        itemPerRow={4}
        itemPerRowBreakpoints={{
          500: 1,
          1024: 2,
          1280: 4,
        }}
      />
    </div>
  );
};

export default CategorySlider;
