import React, { useState, useEffect } from "react";
import Filter from "components/Sections/Filtercomp/filter";
import ExperienceRangeSlider from "components/Sections/productlist/filter/pricerange/experiencerange";
import CheckboxStatesFilter from "components/Sections/productlist/filter/checkboxfilter/checkbox";
import JobListing, {
  JobData,
} from "components/Sections/joblist/joblist/joblists/joblistingmaincard";
import Pagination from "components/Sections/productlist/filter/pagination/pagination";
import FilterBasedOnHealthCareCategory from "components/Sections/productlist/filter/Categoryfilter/Categoryfilter";
import Acoordianforcomponent from "components/Sections/Accordianforfilter/Accordianforfilter";

import "./jobs-listing.css";
import { useLocation, useParams } from "react-router-dom";
import { getTopJobOrganizationsApi, searchJobsApi } from "services/jobService";
import { getStatesApi } from "services/lookupService";
import PriceRangeSlider from "components/Sections/productlist/filter/pricerange/pricerange";
import CheckboxCompaniesFilter from "components/Sections/productlist/filter/checkboxfilter/companycheckbox";

const   JobListingComponent = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const selectedTextString = queryParams.get("searchName") ?? "";
  const selectedParamsCategory = parseInt(
    queryParams.get("selectedCategory") ?? "0",
    10
  );
  const selectedParamsExperience = parseInt(
    queryParams.get("selectedExperience") ?? "0",
    10
  );
  const selectedCategoryFromRelatedCategoryFilter = parseInt(
    queryParams.get("selectedCategory") ?? "0",
    10
  );
  const selectedSalaryRangeFromFilter = parseInt(
    queryParams.get("selectedSalaryRange") ?? "0",
    10
  );
  const selectedStateId = queryParams.get("selectedStateId");
  const branchIdFromOrgFilter = queryParams.get("branchId");

  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [jobsInfo, setJobsInfo] = useState<[]>([]);
  const [statesData, setStatesData] = useState<[]>([]);
  const [companiesData, setCompaniesData] = useState<[]>([]);

  const [selectedCategoryIdValue, setSelectedCategoryIdValue] =
    useState<number>();
  const [selectedCheckboxes, setSelectedCheckboxes] = useState<[]>([]);
  const [selectedCompanyCheckboxes, setSelectedCompanyCheckboxes] = useState<
    []
  >([]);

  const [selectedExperience, setSelectedExperience] = useState<[]>([]);
  const [selectedSalaryRange, setSelectedSalaryRange] = useState<[]>([]);
  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };
  useEffect(() => {
    searchJobsApi({
      searchText: selectedTextString,
      departmentId: 0,
      dutyTypeId: 0,
      employmentTypeId: 0,
      healthCareCategoryId: selectedParamsCategory || 0,
      // pageNumber: 1,
      // pageSize: 10,
      pageNumber: currentPage, // Update the page number based on the current page
      pageSize: itemsPerPage,
      roleDepartmentId: 0,
      salaryType: 0,
      shiftType: 0,
      statusId: 0,
      branchIds: [branchIdFromOrgFilter || 0],
      stateIds: [selectedStateId || 0],
      cityIds: [0],
      salaryRange: {
        min: 0,
        max: selectedSalaryRangeFromFilter || 0,
      },
      experienceRange: {
        min: 0,
        max: selectedParamsExperience || 0,
      },
    }).then((response) => {
      setJobsInfo(response.data.data);
      setTotalPages(Math.ceil(response.data.totalCount / itemsPerPage));
    });
    getStatesApi().then((res: any) => {
      setStatesData(res?.data);
    });
    getTopJobOrganizationsApi(true).then((res) => {
      const convertedBranches = res?.data.map((item: any) => ({
        value: item?.branchId,
        label: item?.branchName,
      }));
      setCompaniesData(convertedBranches);
    });
  }, [currentPage]);
  const JobsFilterBasedOnStatesAndCategories = (
    selectedCategoryId: any,
    updatedSelectedCheckboxes: any,
    experienceRange: any,
    salaryRange: any,
    selectedCompaniesCheckboxes: any
  ) => {
    const updatedCategoryIdValue =
      selectedCategoryId === 0 ? 0 : selectedCategoryId;
    setSelectedCategoryIdValue(updatedCategoryIdValue);
    setSelectedCheckboxes(updatedSelectedCheckboxes);
    setSelectedExperience(experienceRange);
    setSelectedSalaryRange(salaryRange);
    setSelectedCompanyCheckboxes(selectedCompaniesCheckboxes);
    searchJobsApi({
      departmentId: 0,
      dutyTypeId: 0,
      employmentTypeId: 0,
      healthCareCategoryId:
        selectedCategoryId || selectedCategoryId === 0
          ? updatedCategoryIdValue
          : selectedCategoryIdValue || 0,
      pageNumber: 1,
      pageSize: 10,
      roleDepartmentId: 0,
      salaryType: 0,
      shiftType: 0,
      statusId: 0,
      branchIds: selectedCompaniesCheckboxes || selectedCompanyCheckboxes || [],
      stateIds: updatedSelectedCheckboxes || selectedCheckboxes || [],
      cityIds: [0],
      salaryRange: {
        min: salaryRange?.min || 0,
        max: salaryRange?.max || 0,
      },
      experienceRange: {
        min: experienceRange?.min || 0,
        max: experienceRange?.max || selectedParamsExperience || 0,
      },
    })
      .then((response) => {
        setJobsInfo(response.data.data);
        setTotalPages(Math.ceil(response.data.totalCount / itemsPerPage));
      })
      .catch((err) => {
        console.log(err, "Err");
      });
  };
  return (
    <div className="page-shop">
      <div className="container">
        <div className="filter-section">
          <Filter>
            <Acoordianforcomponent title="Related Categories">
              <FilterBasedOnHealthCareCategory
                handleFilterJobsBasedOnHealthCareCategory={
                  JobsFilterBasedOnStatesAndCategories
                }
                selectedCategoryValue={selectedParamsCategory || 0}
                selectedCategoryValueFromFilter={
                  selectedCategoryFromRelatedCategoryFilter
                }
                selectedCheckboxesValues={selectedCheckboxes}
                selectedExperienceRange={selectedExperience}
                selectedSalaryRange={selectedSalaryRange}
                selectedCompanyCheckboxesValues={selectedCompanyCheckboxes}
              />
            </Acoordianforcomponent>
            <Acoordianforcomponent title="Experience">
              <ExperienceRangeSlider
                selectedCategoryIdValue={selectedCategoryIdValue}
                selectedCheckboxesValues={selectedCheckboxes}
                selectedExperienceValue={selectedParamsExperience || 0}
                selectedSalaryRange={selectedSalaryRange}
                selectedCompanyCheckboxesValues={selectedCompanyCheckboxes}
                handleFilterJobsBasedOnExperience={
                  JobsFilterBasedOnStatesAndCategories
                }
              />
            </Acoordianforcomponent>
            <Acoordianforcomponent title="Salary Range">
              <PriceRangeSlider
                selectedCategoryIdValue={selectedCategoryIdValue}
                selectedCheckboxesValues={selectedCheckboxes}
                selectedExperienceRange={selectedExperience}
                selectedCompanyCheckboxesValues={selectedCompanyCheckboxes}
                handleFilterJobsBasedOnSalaryRange={
                  JobsFilterBasedOnStatesAndCategories
                }
              />
            </Acoordianforcomponent>
            <Acoordianforcomponent title="Location">
              <CheckboxStatesFilter
                data={statesData}
                selectedCategoryIdValue={selectedCategoryIdValue}
                experienceRange={selectedExperience}
                selectedSalaryRange={selectedSalaryRange}
                selectedCompanyCheckboxesValues={selectedCompanyCheckboxes}
                handleFilterJobsBasedOnLocation={
                  JobsFilterBasedOnStatesAndCategories
                }
                selectedStateIdFromGetJobsByStatesFilter={selectedStateId}
              />
            </Acoordianforcomponent>
            <Acoordianforcomponent title="Organizations">
              <CheckboxCompaniesFilter
                data={companiesData}
                selectedCategoryIdValue={selectedCategoryIdValue}
                experienceRange={selectedExperience}
                selectedSalaryRange={selectedSalaryRange}
                selectedCheckboxesValuesOfStates={selectedCheckboxes}
                handleFilterJobsBasedOnCompanies={
                  JobsFilterBasedOnStatesAndCategories
                }
                selectedBranchIdFromTopCompaniesFilter={branchIdFromOrgFilter}
              />
            </Acoordianforcomponent>
          </Filter>
        </div>
        <div className="shop-content-section">
          <div className="shop-right-inner-container">
            <div className="inner-productlist">
              {(jobsInfo || [])?.map((job: JobData) => (
                <JobListing
                  key={job?.id}
                  jobData={job}
                  selectedValues={{
                    selectedCategoryIdValue,
                    selectedCheckboxes,
                    selectedExperience,
                    selectedSalaryRange,
                    selectedCompanyCheckboxes,
                  }}
                />
              ))}
              {jobsInfo?.length === 0 && (
                <span className="container justify-center capitalize">
                  No Jobs Found, Search with other filter
                </span>
              )}
              {jobsInfo?.length !== 0 && (
                <div className="inner-pagination">
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobListingComponent;
