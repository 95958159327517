import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);
export interface ReferralCoveragesummary {
  advertisementPercentage: number;
  candidatePercentage: number;
  clientPercentage: number;
  vendorPercentage: number;
}

interface PieChartProps {
  referralCoveragesummary: ReferralCoveragesummary;
}

const ReferralCoverageComponent: React.FC<PieChartProps> = ({
  referralCoveragesummary,
}) => {
  const {
    vendorPercentage,
    clientPercentage,
    candidatePercentage,
    advertisementPercentage,
  } = referralCoveragesummary;
  const data = {
    labels: ["Vendors", "Clients", "Candidates", "Ads"],
    datasets: [
      {
        label: "Coverage",
        data: [
          vendorPercentage,
          clientPercentage,
          candidatePercentage,
          advertisementPercentage,
        ],
        backgroundColor: ["#C71585", "#20B2AA", "#FF4500", "#ADFF2F"],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };
  return (
    <div className="">
      <Pie data={data} />
      <br />
    </div>
  );
};

export default ReferralCoverageComponent;
