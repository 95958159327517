import { useEffect, useState } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, FormProvider, useForm } from "react-hook-form";
import ClientImage from "../../../images/hero-right-2.png";
import { Step } from "./contactform";
import "./locationform.css";
import "./contactform.css";
import { getCitiesByStateApi, getStatesApi } from "services/lookupService";
import PhoneInputWithCountrySelect, {
  parsePhoneNumber,
} from "react-phone-number-input";
import "react-phone-number-input/style.css";
import "./locationform.css";
import Select from "react-select";
import PartnerImg from "./images/Partner-Other-svg.svg";
import ClinicianImg from "./images/Clinician-Other-svg.svg";
import RecruitmentImg from "./images/RA-Other-svg.svg";
import ClientImg from "./images/Client-Other-svg.svg";
interface OrgNameProps {
  activeStep: any;
  setActiveStep: any;
  steps: Step[];
  orgTypeData: any;
  userData: any;
  onUserDataChange: (data: any) => void;
}
interface State {
  value: number;
  label: string;
}
interface City {
  value: number;
  label: string;
}
const createSchema = (userTypeID: number) => {
  return yup.object().shape({
    name: yup
      .string()
      .trim()
      .nullable()
      .required("Please enter name")
      .test(
        "org-length",
        "The name must be between 3 and 300 characters",
        function (value) {
          if (!value) {
            throw new yup.ValidationError(
              "You must enter organization name",
              "name"
            );
          }
          const length = value.trim().length;
          return length >= 3 && length <= 300;
        }
      ),
    phoneNumber: yup
      .string()
      .nullable()
      .required("Please enter phone number")
      .test("is-valid-phone", "Enter valid phone number", function (value) {
        const onlyNumber = parsePhoneNumber(
          value.toString()
        )?.nationalNumber.toString();
        return /^[6-9]\d{9}$/.test(onlyNumber ?? "");
      }),
    contactEmail: yup
      .string()
      .trim()
      .nullable()
      .required("Please enter a email")
      .email("Enter valid email")
      .matches(
        /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
        "Invalid email format"
      )
      .test(
        "contactEmail-length",
        "The email must be between 5 and 150 characters",
        function (value) {
          if (!value) {
            throw new yup.ValidationError(
              "You must enter contact email",
              "contactEmail"
            );
          }
          const length = value.trim().length;
          return length >= 5 && length <= 150;
        }
      ),
    state:
      userTypeID !== 4
        ? yup.object().nullable().required("Please select any state")
        : yup.object().notRequired(),

    city:
      userTypeID !== 4
        ? yup.object().nullable().required("Please select any city")
        : yup.object().notRequired(),
  });
};
const OrgDetailsForm: React.FC<OrgNameProps> = ({
  activeStep,
  setActiveStep,
  steps,
  orgTypeData,
  onUserDataChange,
  userData,
}) => {
 
  const [image, setImage] = useState<string>("");
  const [states, setStates] = useState<State[]>([]);
  const [cities, setCities] = useState<City[]>([]);

  const methods = useForm({
    mode: "onChange",
    defaultValues: {},
    resolver: yupResolver(createSchema(userData.userTypeID)),
  });

  const { control, formState, getValues, handleSubmit, setValue } = methods;
  const { errors } = formState;

  useEffect(() => {
    getStatesApi()
      .then((response) => {
        setStates(response.data);
      })
      .catch((error) => {
        console.error("Error fetching states:", error);
      });
  }, []);
  function getUserTypeImage(userTypeID:any) {
    switch (Number(userTypeID)) {
      case 4:
        return <img src={ClinicianImg} alt="" className="" />;
      case 5:
        return <img src={ClientImg} alt="" />;
      case 6:
        return <img src={PartnerImg} alt="" />;
      case 575:
        return <img src={RecruitmentImg} alt="" />;
      default:
        return null;
    }
  }
  const handleStateChange = (selectedOption: any) => {
    const selectedStateId = parseInt(selectedOption?.value, 10);

    if (selectedStateId) {
      getCitiesByStateApi(selectedStateId)
        .then((response) => {
          setCities(response.data);
        })
        .catch((error) => {
          console.error("Error fetching cities:", error);
        });
    }
  };
  const handleOrgTypeMoreDetails = () => {
    let values = getValues();
    let formData = {
      name: values.name,
      contactPhone: parsePhoneNumber(
        values.phoneNumber!.toString()
      )!.nationalNumber.toString(),
      contactEmail: values.contactEmail,
      stateID: values.state,
      cityID: values.city,
      orgTypeData,
    };
    onUserDataChange(formData);
    if (Number(userData.userTypeID) !== 5) {
      setActiveStep(activeStep + 2);
    } else {
      setActiveStep(activeStep + 1);
    }
  };
  const userTypeImage = getUserTypeImage(
    userData?.userTypeID || userData?.orgTypeData.userTypeID
  );
  return (
    <FormProvider {...methods}>
      <>
        <div className="flex org-details gap-12">
          <div className="w-1/2">
            <div className="mt-0">
              <h1
                style={{
                  color: "white",
                  fontSize: "30px",
                  paddingTop: "18px",
                  paddingBottom: "18px",
                }}
              >
                {Number(userData.userTypeID) === 4
                  ? "Tell us more about yourself"
                  : "Tell us more about your Organization"}
              </h1>
              <div
                className="inline-flex"
                style={{ color: "white", paddingTop: "6px" }}
              >
                Name
              </div>
              <div className="w-full pt-3">
                <Controller
                  name="name"
                  control={control}
                  render={({ field }) => (
                    <>
                      <input
                        {...field}
                        style={{
                          borderRadius: "1.35rem",
                        }}
                        type="text"
                        autoFocus
                        placeholder="Enter Name"
                        className="w-full"
                        required
                      />
                      <p className="error">
                        {errors.name && errors.name?.message}
                      </p>
                    </>
                  )}
                />
              </div>
              <div
                className="inline-flex"
                style={{ color: "white", paddingTop: "6px" }}
              >
                State
              </div>
              <div className="w-full pt-3">
                <Controller
                  name="state"
                  control={control}
                  render={({ field }) => (
                    <>
                      <Select
                        classNames={{
                          control: () => "br-22",
                        }}
                        {...field}
                        options={states}
                        placeholder={`${
                          userData.userTypeID !== 4
                            ? "Select State"
                            : "Select State (Optional)"
                        }`}
                        onChange={(selectedOption) => {
                          field.onChange(selectedOption);
                          handleStateChange(selectedOption);
                        }}
                        onBlur={() => field.onBlur()}
                        value={
                          states &&
                          states.find((option) => option.value === field.value)
                        }
                        required
                      />
                      <p className="error">{errors.state?.message}</p>
                    </>
                  )}
                />
              </div>

              <div
                className="inline-flex"
                style={{ color: "white", paddingTop: "6px" }}
              >
                City
              </div>
              <div className="w-full pt-3">
                <Controller
                  name="city"
                  control={control}
                  render={({ field }) => (
                    <>
                      <Select
                        classNames={{
                          control: () => "br-22",
                        }}
                        {...field}
                        options={cities}
                        placeholder={`${
                          userData.userTypeID !== 4
                            ? "Select City"
                            : "Select City (Optional)"
                        }`}
                        onChange={(selectedOption) =>
                          field.onChange(selectedOption)
                        }
                        onBlur={() => field.onBlur()}
                        value={
                          cities &&
                          cities.find((option) => option.value === field.value)
                        }
                        required
                      />
                      <p className="error">{errors.city?.message}</p>
                    </>
                  )}
                />
              </div>

              <div
                className="inline-flex border-0 contact-label"
                style={{ color: "white", paddingTop: "6px" }}
              >
                Contact Phone
              </div>
              <div className="w-full pt-3 contact-phone">
                <Controller
                  name="phoneNumber"
                  control={control}
                  render={({ field }) => (
                    <>
                      <PhoneInputWithCountrySelect
                        {...field}
                        international
                        className=""
                        defaultCountry="IN"
                        countryCallingCodeEditable={false}
                        countries={["IN"]}
                      />
                      <p className="error">
                        {errors.phoneNumber && errors.phoneNumber?.message}
                      </p>
                    </>
                  )}
                />
              </div>
              <div
                className="inline-flex border-0"
                style={{ color: "white", paddingTop: "6px" }}
              >
                Contact Email
              </div>
              <div className="w-full pt-3">
                <Controller
                  name="contactEmail"
                  control={control}
                  render={({ field }) => (
                    <>
                      <input
                        {...field}
                        type="email"
                        placeholder="Enter Email"
                        className="w-full"
                        style={{
                          borderRadius: "1.35rem",
                        }}
                        required
                      />
                      <p className="error">
                        {errors.contactEmail && errors.contactEmail?.message}
                      </p>
                    </>
                  )}
                />
              </div>
            </div>
          </div>
          <div className="w-1/2 images-for-org-details p-9">
            {/* <img src={userTypeImage} alt="Your Image" /> */}
            {userTypeImage}
            {/* { <img src={userTypeImage} alt="" />}
            { <img src={userTypeImage} alt="" />}
            { <img src={userTypeImage} alt="" />} */}
          </div>
        </div>
        <div className="button-container">
          {Number(userData?.userTypeID || userData?.orgTypeData?.userTypeID) !==
            5 ||
          Number(
            orgTypeData?.orgTypeData?.userTypeID || orgTypeData?.userTypeID
          ) !== 5 ? (
            <button
              onClick={() => setActiveStep(activeStep - 2)}
              className="button flex gap-2"
            >
              {" "}
              <svg
                className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 10"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M13 5H1m0 0l4-4m-4 4l4 4"
                />{" "}
              </svg>
              Previous
            </button>
          ) : (
            activeStep !== 0 && (
              <button
                onClick={() => setActiveStep(activeStep - 1)}
                className="button flex gap-2"
              >
                <svg
                  className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 10"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M13 5H1m0 0l4-4m-4 4l4 4"
                  />
                </svg>
                Previous
              </button>
            )
          )}

          {/* {activeStep !== steps.length - 1 && ( */}
          <button
            onClick={() => {
              handleSubmit(handleOrgTypeMoreDetails)();
            }}
            className="button"
          >
            Next
            <svg
              className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 10"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M1 5h12m0 0L9 1m4 4L9 9"
              />
            </svg>
          </button>
          {/* )} */}
        </div>
      </>
    </FormProvider>
  );
};
export default OrgDetailsForm;
