import React from 'react';
import { Link } from "react-router-dom"; // Import the Link component
import './ourservices.css';


interface OurServiceProps {
  imageSrc: string;
  header: string;
  description: string;
  link: string; // Add a link prop
}




const OurService: React.FC<OurServiceProps> = ({
    imageSrc,
    header,
    description,
    link,
  }) => (
    <Link to={link}>   
     <a className="ourservices-wrap" href={link}>
      <div className="ourserviceimage-wrap">
        <img src={imageSrc} alt="Service" />
      </div>
      <div className="ourservicedetail-wrap background-white">
        <h2 className="header-services">{header}</h2>
        <p className="para-services">{description}</p>
        {/* <a className="para-button" href={link}>
          Get Now <span><svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
  <path d="M18 10.6006L11.8994 4.5L11.0392 5.36019L15.6635 9.99058L3.35846 9.99058L3.35846 11.2107L15.6635 11.2107L11.0392 15.8411L11.8994 16.7013L18 10.6006Z" fill="#F1B40A"/>
</svg></span>
        </a> */}
      </div>
    </a>
    </Link>
  );
  
  export default OurService;
  
  
  
  
  
  
  
