import React, { createContext, useContext, useEffect, useState } from "react";
import API from "../services/api";
import _ from "lodash";
import { clearSession, setSession } from "services/authService";
import { jwtDecode } from "jwt-decode";
import { signInWithToken } from "services/loginService";
interface UserContextProps {
  children: React.ReactNode;
}

const UserContext = createContext<
  | { isAuthenticated: boolean; userData: any; setUser: (data: any) => void }
  | undefined
>(undefined);

const UserProvider: React.FC<UserContextProps> = ({ children }) => {
  const [userData, setUserData] = useState<any>(null);
  const [isAuthenticated, setAuthenticated] = useState<boolean>(false);

  const setUser = (data: any) => {
    setUserData(data);
    setAuthenticated(data !== null);
  };

  useEffect(() => {
    const access_token = API.getAccessToken();
    if (!access_token) {
      return;
    }
    const decoded: any = jwtDecode(access_token);

    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
      setUser(null);
      clearSession();
      return;
    }

    if (_.isEmpty(userData)) {
      signInWithToken(access_token, decoded.contactnumber)
        .then((response: any) => {
          if (response.data && response.status === 200) {
            setUser(response.data);
            setSession(
              response.data?.token,
              response.data?.getUser?.contactNumber
            );
          }
        })
        .catch((error: any) => {
          setUser(null);
          clearSession();
        });
    } else {
      clearSession();
    }
  }, []);

  return (
    <UserContext.Provider value={{ isAuthenticated, userData, setUser }}>
      {children}
    </UserContext.Provider>
  );
};

const useUser = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("useUser must be used within a UserProvider");
  }
  return context;
};

export { UserProvider, useUser };
