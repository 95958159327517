import axios from "axios";
import { encryptObject } from "./storageHelper";
export const setSession = (access_token,contact) => {
  localStorage.setItem("corp_k", contact);
  localStorage.setItem("corp_token", encryptObject(access_token));
  axios.defaults.headers.common.Authorization = `Bearer ${access_token}`;
};
export const clearSession = () => {
  localStorage.clear();
  delete axios.defaults.headers.common.Authorization;
};
