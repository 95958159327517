// src/components/CategoryFilter/CategoryFilter.tsx
import React, { useState, useEffect } from "react";
import categoriesData from "../Categoryfilter/categoryfilter.json";

import "./searchcate.css";

interface Category {
  id: number;
  name: string;
  image: string;
}

const CategoryFilter: React.FC = () => {
  const [categories, setCategories] = useState<Category[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<number | null>(null);
  const [searchName, setSearchName] = useState<string>("");

  useEffect(() => {
    setCategories(categoriesData);
  }, []);

  useEffect(() => {
    // Fetch data from the JSON file
    fetch("/categories.json")
      .then((response) => response.json())
      .then((data) => setCategories(data))
      .catch((error) => console.error("Error fetching categories:", error));
  }, []);

  const handleSearch = () => {
    // Handle search logic here, using selectedCategory and searchName
    // console.log("Selected Category:", selectedCategory);
    // console.log("Search Name:", searchName);
  };

  return (
    <div>
      <div style={{ display: "none", flexWrap: "wrap" }}>
        {categories.map((category) => (
          <div
            key={category.id}
            style={{ margin: "10px", textAlign: "center", cursor: "pointer" }}
            onClick={() => setSelectedCategory(category.id)}
          >
            <img
              src={process.env.PUBLIC_URL + "/" + category.image}
              alt={category.name}
              style={{
                width: "100px",
                height: "100px",
                objectFit: "cover",
                borderRadius: "50%",
              }}
            />
            <h3>{category.name}</h3>
          </div>
        ))}
      </div>

      <div className="searchcategoryfilter">
        <select
          className="montserrat"
          id="category"
          value={selectedCategory || ""}
          onChange={(e) => setSelectedCategory(Number(e.target.value))}
        >
          <option value="" disabled>
            Select a category
          </option>
          {categories.map((category) => (
            <option key={category.id} value={category.id}>
              {category.name}
            </option>
          ))}
        </select>
        <div className="inputwithsearch">
          <input
            className="montserrat"
            type="text"
            placeholder="Search your product"
            id="name"
            value={searchName}
            onChange={(e) => setSearchName(e.target.value)}
          />

          <button className="montserrat" onClick={handleSearch}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="17"
              viewBox="0 0 16 17"
              fill="none"
            >
              <path
                d="M5.90234 0.855469C5.12598 0.85539 4.3572 1.00824 3.63992 1.30529C2.92263 1.60234 2.27088 2.03777 1.72189 2.58672C1.17289 3.13567 0.737396 3.78738 0.440278 4.50465C0.14316 5.22191 -0.0097656 5.99068 -0.00976559 6.76705C-0.00984473 7.54347 0.143022 8.3123 0.440105 9.02964C0.737188 9.74697 1.17267 10.3988 1.72167 10.9478C2.27068 11.4968 2.92245 11.9323 3.63978 12.2294C4.3571 12.5264 5.12592 12.6793 5.90234 12.6792C7.28201 12.6792 8.54901 12.1906 9.55515 11.399L10.0823 11.9261C9.97407 12.1365 9.93574 12.3758 9.97291 12.6095C10.0101 12.8431 10.1208 13.0587 10.289 13.2251L13.0567 16.0116C13.4863 16.4418 14.1894 16.4418 14.6196 16.0116L15.1467 15.4844C15.3531 15.2767 15.469 14.9958 15.469 14.703C15.469 14.4102 15.3531 14.1293 15.1467 13.9216L12.3603 11.1537C12.1928 10.9867 11.9758 10.8782 11.7417 10.8443C11.5075 10.8104 11.2687 10.853 11.0607 10.9658L10.5336 10.4386C11.2236 9.5678 11.6543 8.52017 11.7762 7.41582C11.8982 6.31148 11.7064 5.19512 11.223 4.19476C10.7395 3.1944 9.98395 2.35053 9.04289 1.75991C8.10183 1.16929 7.01338 0.855826 5.90234 0.855469ZM5.90234 1.94717C7.18063 1.94717 8.40657 2.45498 9.31046 3.35888C10.2143 4.26278 10.7221 5.48874 10.7221 6.76705C10.7221 8.04536 10.2143 9.27132 9.31046 10.1752C8.40657 11.0791 7.18063 11.5869 5.90234 11.5869C4.62404 11.5869 3.3981 11.0791 2.49421 10.1752C1.59032 9.27132 1.08252 8.04536 1.08252 6.76705C1.08252 5.48874 1.59032 4.26278 2.49421 3.35888C3.3981 2.45498 4.62404 1.94717 5.90234 1.94717Z"
                fill="white"
              />
            </svg>
            Search
          </button>
        </div>
      </div>
    </div>
  );
};

export default CategoryFilter;
