import API from "./api";
import { Jobs_API_URLS } from "./constants";

export function searchJobsApi(args) {
  return API.post(Jobs_API_URLS.searchJobsUrl, args);
}
export function getJobDetailsApi(args) {
  return API.post(Jobs_API_URLS.getJobDetailsUrl, args);
}
export function getTopJobOrganizationsApi(boolean,args) {
  return API.get(`${Jobs_API_URLS.getTopJobOrganizationsUrl}?includeAll=${boolean}`, args);
}
export function applyJobApi(body) {
  return API.postForm(Jobs_API_URLS.applyJobUrl, body);
}
