import React from "react";
import { Link } from "react-router-dom";

interface Section {
    id: string;
    title: string;
    subsections: Subsection[];
}

interface Subsection {
    id: string;
    title: string;
    content: string;
    videoLink?: string; // Optional field for video link
    imageUrl?: string;
}

interface BreadcrumbProps {
    ClinicianData: Section[];
    currentSectionIndex: number;
    setCurrentSectionIndex: (index: number) => void;
    currentPage: string;
    currentSubSection : number;
}


const Breadcrumb: React.FC<BreadcrumbProps> = ({ ClinicianData, currentSectionIndex, setCurrentSectionIndex, currentPage,currentSubSection }) => {
    const title =ClinicianData[currentSectionIndex].subsections[currentSubSection].title;
    const handleClick = (index: number) => {
        setCurrentSectionIndex(index);
    };
    return (
        <div className="ml-4 mb-4">
            {ClinicianData.slice(currentSectionIndex, currentSectionIndex + 1).map((section, index) => (
                <React.Fragment key={section.id}>
                    <Link to="/usermanual" className="text-gray-500 cursor-pointer">
                        User Manual {'> '}
                    </Link>
                    {/* {currentPage === 'clinicians' && (
                        <Link to="/clinician" className="text-gray-500 cursor-pointer">
                            Clinicians {'> '}
                        </Link>
                    )}
                    {currentPage === 'clients' && (
                        <Link to="/clients" className="text-gray-500 cursor-pointer">
                            Clients {'> '}
                        </Link>
                    )}*/
                    }
                    <span className="text-gray-500 cursor-pointer" onClick={() => handleClick(index)}>
                        {index !== 0 && ' > '}
                        {title}
                    </span>
                </React.Fragment>
            ))}
        </div>
    );
};

export default Breadcrumb;
