import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useNavigate } from "react-router-dom";
import { updatePasswordWithOTPApi } from "services/loginService";
import PhoneInputWithCountrySelect, {
  parsePhoneNumber,
} from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
interface ForgotPasswordProps {
  onSubmit: (phoneNumber: string, email: string, marketingCode: string) => void;
  otpSent: boolean;
}
const schema = yup.object().shape({
  phoneNumber: yup
    .string()
    .nullable()
    .required("Enter phone number")
    .test("is-valid-phone", "Enter valid phone number", function (value) {
      const onlyNumber = parsePhoneNumber(
        value.toString()
      )?.nationalNumber.toString();
      return /^[6-9]\d{9}$/.test(onlyNumber ?? "");
    }),
});
const ForgotPasswordForm: React.FC<ForgotPasswordProps> = ({
  onSubmit,
  otpSent,
}) => {
  const [email, setEmail] = useState<string>("");
  const [marketingCode, setMarketingCode] = useState<string>("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showOtp, setShowOtp] = useState(false);
  const [otpValue, setOtpValue] = useState("");
  const showCaptcha = window.location.hostname !== "localhost";
  const siteKey: string = process.env.REACT_APP_MAP_KEY ?? "";
  const [captchaVerified, setCaptchaVerified] = useState(
    window.location.hostname === "localhost"
  );
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };
  const handleMarketingCodeChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setMarketingCode(e.target.value);
  };
  const handleCaptchaChange = (value: any) => {
    setCaptchaVerified(() => true);
  };

  const handleOTPChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOtpValue(e.target.value);
  };
  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setConfirmPassword(e.target.value);
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const handleToggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  const handleToggleShowOtpVisibility = () => {
    setShowOtp(!showOtp);
  };
  const onSubmitVal = async (data: any) => {
    const phoneNumber = parsePhoneNumber(data.phoneNumber!.toString())?.nationalNumber.toString() ?? "";
    if (data.phoneNumber?.length) {
      onSubmit(data?.phoneNumber, email, marketingCode);
    }
    if (password !== confirmPassword) {
      alert("Password and Confirm Password Should be Same");
      return;
    }
    if (data?.phoneNumber && otpValue && password) {
      updatePasswordWithOTPApi(phoneNumber, otpValue, password)
        .then((response) => {
          if (response.data && response.status === 200) {
            navigate("/login");
          }
        })
        .catch((error) => {
          console.error("API Error:", error);
        });
    }
  };
  return (
    <form onSubmit={handleSubmit(onSubmitVal)}>
      {!otpSent && (
        <>
          <div className="">
            <label>Phone Number:</label>
            <Controller
              name="phoneNumber"
              control={control}
              render={({ field }) => (
                <>
                  <PhoneInputWithCountrySelect
                    {...field}
                    international
                    defaultCountry="IN"
                    countryCallingCodeEditable={false}
                    countries={["IN"]}
                  />
                  <p className="error">
                    {errors.phoneNumber && errors.phoneNumber?.message}
                  </p>
                </>
              )}
            />
          </div>
          {/* <div className="">
            <label>Email Id:</label>
            <input
              type="email"
              value={email}
              onChange={handleEmailChange}
              placeholder="Email Id"
              required
            />
          </div>
          <div className="">
            <label>Marketing Code:</label>
            <input
              type="text"
              value={marketingCode}
              onChange={handleMarketingCodeChange}
              placeholder="Marketing Code"
              required
            />
          </div> */}
          <div>
            {showCaptcha && (
              <ReCAPTCHA
                sitekey={siteKey}
                onChange={handleCaptchaChange}
                onExpired={() => setCaptchaVerified(() => false)}
              />
            )}
          </div>
        </>
      )}

      {otpSent ? (
        <>
          <div className="password-dept">
            <label>Enter Otp:</label>
            <div className={`password-field ${showPassword ? "show" : "hide"}`}>
              <input
                className="pass-word-field"
                type={showOtp ? "text" : "password"}
                value={otpValue}
                onChange={handleOTPChange}
                placeholder="Your Otp"
                required
              />
              <span
                onClick={handleToggleShowOtpVisibility}
                className="toggle-password"
              >
                {showOtp ? "Hide" : "Show"}
              </span>
            </div>
          </div>
          <div className="password-dept">
            <label>Password:</label>
            <div className={`password-field ${showPassword ? "show" : "hide"}`}>
              <input
                className="pass-word-field"
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={handlePasswordChange}
                placeholder="Your Password"
                required
              />
              <span
                onClick={handleTogglePasswordVisibility}
                className="toggle-password"
              >
                {showPassword ? "Hide" : "Show"}
              </span>
            </div>
          </div>
          <div>
            <label>Confirm Password:</label>
            <div
              className={`password-field ${showConfirmPassword ? "show" : "hide"
                }`}
            >
              <input
                type={showConfirmPassword ? "text" : "password"}
                value={confirmPassword}
                onChange={handleConfirmPasswordChange}
                placeholder="Confirm Password"
                required
              />
              <span
                onClick={handleToggleConfirmPasswordVisibility}
                className="toggle-password"
              >
                {showConfirmPassword ? "Hide" : "Show"}
              </span>
            </div>
          </div>
          <button type="submit">Update Password</button>
        </>
      ) : (
        <button type="submit" disabled={captchaVerified === false}>
          Send OTP
        </button>
      )}
    </form>
  );
};
export default ForgotPasswordForm;
