
import CountryCard from "./countryCard";
import bm from "./images/bm.png";
import { Link } from "react-router-dom";
import "./placements.css"
import Countrylist from "./countries/countrynew";
import { useNavigate } from "react-router-dom";
import JoinNetwork from "components/Sections/mobileappdownload/mobileappdownload";
import qrterapartner from "./images/terapartner.png";
import { count } from "console";
import australia from "./images/australia.png";
import japan from "./images/japan.png";
const countries = [
    {
        id: 1,
        name: "United Kingdom",
        mapImage: "https://dynamichealthstaff.com/public/front/home/images/flag/lond_map.png",
        flagImage: "https://upload.wikimedia.org/wikipedia/en/a/ae/Flag_of_the_United_Kingdom.svg",
        redirectTo: "/uk",
    },
    {
        id: 2,
        name: "Ireland",
        mapImage: "https://dynamichealthstaff.com/public/front/home/images/flag/ireland.png",
        flagImage: "https://upload.wikimedia.org/wikipedia/commons/4/45/Flag_of_Ireland.svg",
        redirectTo: "/ireland",
    },
    {
        id: 3,
        name: "Canada",
        mapImage: "https://dynamichealthstaff.com/public/front/home/images/flag/canda_map.png",
        flagImage: "https://upload.wikimedia.org/wikipedia/commons/d/d9/Flag_of_Canada_%28Pantone%29.svg",
        redirectTo: "/canada",
    },
    {
        id: 4,
        name: "Australia",
        mapImage: australia,
        flagImage: "https://upload.wikimedia.org/wikipedia/en/b/b9/Flag_of_Australia.svg",
        redirectTo: "/australia",
    },
    {
        id: 5,
        name: "Saudi Arabia",
        mapImage: "https://dynamichealthstaff.com/public/front/home/images/flag/saudi.png",
        flagImage: "https://upload.wikimedia.org/wikipedia/commons/0/0d/Flag_of_Saudi_Arabia.svg",
        redirectTo: "/saudi-arabia",
    },
    {
        id: 6,
        name: "Qatar",
        mapImage: "https://dynamichealthstaff.com/public/front/home/images/flag/qatar.png",
        flagImage: "https://upload.wikimedia.org/wikipedia/commons/6/65/Flag_of_Qatar.svg",
        redirectTo: "/qatar",
    },
    {
        id: 7,
        name: "UAE",
        mapImage: "https://dynamichealthstaff.com/public/front/home/images/flag/uae.png",
        flagImage: "https://upload.wikimedia.org/wikipedia/commons/thumb/c/cb/Flag_of_the_United_Arab_Emirates.svg/1024px-Flag_of_the_United_Arab_Emirates.svg.png",
        redirectTo: "/saudi-arabia",
    },
    {
        id: 8,
        name: "Oman",
        mapImage: "https://dynamichealthstaff.com/public/front/home/images/flag/oman.png",
        flagImage: "https://upload.wikimedia.org/wikipedia/commons/thumb/d/dd/Flag_of_Oman.svg/1024px-Flag_of_Oman.svg.png",
        redirectTo: "/qatar",
    },
    {
        id:9,
        name : "Japan",
        mapImage: japan,
        flagImage:"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAARMAAAC3CAMAAAAGjUrGAAAAh1BMVEX///+8AC27ACm4AA+5ABi7ACe4ABW6ACS4ABK7ACi6ACC6ACK6AB65ABr99fe3AAu3AAD35OfFOFDOYXLoucDjqrPqwMf13uLCLEfScH/blJ7msrvKUWXz2t7z1dvwzNPWf43JS1+/Ezfai5f78PLho63GQFfVfInNWm2/DTXBJEHRbn3sxszHSQTJAAAEQ0lEQVR4nO3diXLaMBAG4NryIXxigg8IYHAIuXj/5yuUpiWxGZDRMev83wNkvDuKtJYW+dcvAAAAAAAAAAAAAAAAAAAAAAAAADhKylW9L4piX6/KxPTDGJes8vXiPU0Z557ncc7S9H2xzlc/NjP1085nTuDa1jnbDRzm7+Yr04+nX/Hg8Mi1LrDdiIez6U8aLsuXmIeX8vEvL6HHZ0vTj6pJsUmDawn5K0g3henH1aCo2MV/mQ4uq4aelbpiI4GMHI1YVZt+bIWy17FoRv5kZfyamX50VebO1Yn1gtCZm354JbLK65mRI68a4FDJU5Gptc1Nc9MhyDZL78rIUTozHYRUySS+OyWWFU8GVNhmli8hJdbhrwxmUlnGfVbgLqNoIMX+0rOvR3sj2xtEUspYXkoOSYlL0wHdL4tkpuSQlID8nJI095UlbW5DffWZ9C3nLwsnpoO6z4eMuuS7mHTxlt9fvXahXOaXXElKLIvTXXwq2fPrJ7cyHVpfT46ilFiW82Q6uH5KNZPJSUqzStnIefHr5m9Mh9fHVOUwOQwUitv5b3Jr+u/sxnSA4vJ7dl9v4dErUtSOkiPbdIiilA8TggPlXcM4eTcdpJg9U56SQ4W/Nx2mkAeVtckn/8F0mCK26qr6c/HWdKACnvXkxKE0y25UvRB/5RIq8BO1Zf1/Yzpbs1P1xcmJNzUd6s1m8jemu4V0dmZt9QXbiU2mvs90FGwnjMrWkrbphNCEMo+05SSi0uempbA/IVPeV7LaTa4bETnUSCxdy85h4bFoVG2ZqtO/LpzGwrPUtxQfFmMafUu11nFCoxdfY3lyKFBoHPM86tk8OXEeTYd7k1xFH87FnNDYVtKakxg5oZoTzCdtetcdGi/Ge631CY1zr5XWOpbG79XxvtOG9+IOlcacENk/wT5bB+zHtmHfvg3nOx1G2iZZMueAOC/ugL6Cti36T9oWmvqUFqYDFZCjn61FU9+jQ6nvEf2xHbT0UTMa+0n/oN++Db/L6OCrHii2bzpEYc/Kf+f1bDpEcYp3IO030wH2MFW79DA6rzpndioLfHdnOrxe1P4OnegtDrivoAPutWhTdiJI5PSvE+7J6YD7lDrg3q225E36/WxvhDZhu0m/xy8iPL9+knzfY0S0WPtqKTEptkOjv/6qMpQ1p7jhIEbJUdbIWX3CZgBzyadkJ+PVx9mRX3G+eJFwb/mL6SBkexT6dEiby2h0TAvJJnd9B2EyoKnkzNzrezroe1T61oRli3GfUsUeL4Y5SE72DRfNis0bYmegwh4bLvSdJt4McG5tme7S4MbBEqQ7kkcWPSzXwfXvvlmhF78M5O3mNsVHzKPL9xqMAh5//JQhcqZ+mkTMCb4dttt+4LBwsh76vHrRts7Xr5UzTtmf742ydOxUrz/5e6P/bctVXRRFvSpJNagBAAAAAAAAAAAAAAAAAAAAAAAAqPQbnZ1LfskQUQkAAAAASUVORK5CYII=",
        redirectTo : "/japan",
    }
];

interface CountryData {
    name: string;
    trainings: string;
    courses: string;
    jobOpportunities: string;
    successStories: string;
}

interface LocationState {
    countryData: any[]; // Define the type of countryData
}
const Training: React.FC = () => {
    const navigate = useNavigate()
    const qatarData: CountryData = {
        name: 'Qatar',
        trainings: 'Qatar offers various training programs in fields such as healthcare, technology, finance, and hospitality.',
        courses: 'Numerous courses are offered in Qatar including diploma courses, certificate courses, and degree programs in universities and institutes.',
        jobOpportunities: 'Qatar provides employment opportunities in sectors like oil and gas, construction, healthcare, IT, and finance. The country is known for its high-paying jobs and tax-free salaries.',
        successStories: 'Many professionals have found success in Qatar, with stories of career growth, lucrative opportunities, and cultural experiences.'
    };

    // const handleCountryClick = (country: { id: number; name: string; mapImage: string; flagImage: string; redirectTo: string; }) => {
    //     <>
    //         {console.log("clicked")}
    //         {navigate(`/placements/countries/${country.name.split(' ').join(' ').toLowerCase()}`)}
    //         {/* <CountryComponent country={qatarData} /> */}
    //         <Countrylist  country={country}/>
    //         {/* <CountryComponent /> */}

    //     </>
    // };

    const handleCountryClick = (country: { id: number; name: string; mapImage: string; flagImage: string; redirectTo: string; }) => {
        console.log("country clicked is :",country.id," ",country.name)
        navigate(`/placements/countries/${country.name.split(' ').join(' ').toLowerCase()}`, { state: country });
    };

    
    const handleCompareClick = () => {
        navigate('/comparision-page');
    }

    return (
        <div>
            <div className=" mx-auto home-hero-section for-clinic-page clients">
                <div className="container flex head-home-sec gap-extra">
                    <div className="head-home-sec-centre gap">
                        <h3 className="font-white regular title-custom text-center title-heading-banner montserrat ">
                            <span className="font-sky-blue bolders banner-grandient">
                                Revitalize
                            </span>{" "}
                            Your Medical Agency{" "}
                        </h3>
                        <h5 className="font-white  text-center regular title-custom montserrat">
                            Revolutionize your agency's impact in the medical industry with Tera Connects — an all-encompassing platform designed to streamline, elevate, and amplify agency operations.
                        </h5>
                        <Link to="/contact-us">
                            <a href="/contact-us/" className="common-btn-custom">
                                Get Started
                                <span>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="26"
                                        height="27"
                                        viewBox="0 0 26 27"
                                        fill="none"
                                    >
                                        <path
                                            d="M8.99945 7.14938L16.8203 14.4116L8.99946 21.6738"
                                            stroke="#fff"
                                            stroke-width="2"
                                        />
                                    </svg>
                                </span>
                            </a>
                        </Link>
                    </div>
                    <div className="head-home-sec-centre">
                        {/* <img src={bm} alt="Service" className="" /> */}
                    </div>
                </div>
            </div>
            <div className="training-hero">
                <h3 className="training-title">Which Country is better for you?</h3>
                <div className="country-selection-page" >
                    {countries.map((country) => (
                        <CountryCard
                            key={country.id}
                            country={country}
                            onClick={() => handleCountryClick(country)}
                        />
                    ))}
                </div>
            </div>
            {/* <div className="compare-section">
                <div className="compare-text"  onClick={handleCompareClick}>
                    Click here to compare
                </div>
            </div> */}
            <div className="background-white" id="download-app-now">
                <JoinNetwork
                    heading="Join Our Network"
                    subheading="Unlock growth with Tera Partner—connecting providers and medical establishments nationwide for 10X business success."
                    googlePlayUrl="https://play.google.com/store/apps/details?id=com.teraplatforms.terapartner"
                    appStoreUrl="https://apps.apple.com/in/app/tera-partner-healthcare/id6453868120"
                    imageUrl={qrterapartner}
                />
            </div>
        </div>
    )
}
export default Training;