import React from 'react';
import Blog from 'components/blog/blog'; // Adjust the import path as needed

function App() {
  return (
    <div>
   
    </div>
  );
}

export default App;
