import React from "react";
import FeatureService from 'components/Sections/featureservice/featureservice';
import OfferService from "components/Sections/serviceoffer/serviceoffer";
import Whoweare from "components/Sections/who-we-are/whoweare";

import TestimonialSlider from 'components/Sections/testimonal-comp/TestimonialSlider';
import featureservice1 from './images/featureservice1.png'
import featureservice2 from './images/featureservice2.png'
import featureservice3 from './images/featureservice3.png'
import featureservice4 from './images/featureservice4.png'


import offerservice1 from './images/serviceoffer1.png'
import offerservice2 from './images/serviceoffer2.png'
import offerservice3 from './images/serviceoffer3.png'
import offerservice4 from './images/serviceoffer4.png'

import backgroundfooter from './images/Rectangle-4.webp'
import bannerimage from './images/associationsbanner.png';

import JoinNetwork from "components/Sections/mobileappdownload/mobileappdownload";
import qrtera from './images/mobqr.png'


import './associations.css'
import { Link } from "react-router-dom";


const featureServiceDataList = [
  {
    image: featureservice1,
    title: 'Effortless Hiring with Advanced ATS',
    subtitle: 'Tera Connects simplifies your hiring process with an advanced Applicant Tracking System (ATS), ensuring a seamless and efficient experience to find the right talent for your clients',
    
  },
  {
    image: featureservice3,
    title: 'Diverse Talent Pool Access',
    subtitle: ' Access a broad talent pool, ranging from professionals like doctors and nurses to versatile manpower. Tera Connects provides comprehensive staffing solutions to meet all your diverse hiring needs',
  },
  {
    image: featureservice2,
    title: 'Tailored Solutions for Medical Recruitment',
    subtitle: 'Our platform is tailored for medical recruitment agencies, offering specialised solutions to address the unique demands of the healthcare industry, ensuring you find the right candidates',
  },

  {
    image: featureservice4,
    title: 'Direct Client Interaction for Seamless Communication',
    subtitle: 'Forge direct connections with clients looking to hire. Tera Connects facilitates clear communication, creating opportunities to deeply understand and fulfil their staffing requirements',
  },
  // Add more feature service data objects as needed
];


const offerServiceDatalist = [
  {
    image: offerservice1,
    title: 'Medical Recruitment Agencies',
    subtitle: 'Are you a medical recruitment agency seeking the ideal platform to connect with top-tier healthcare professionals? Tera Connects is your gateway to a vast network of doctors, nurses, and technicians, ensuring your clients access the finest talent effortlessly',
  },
  {
    image: offerservice2,
    title: 'Manpower Providers in the Medical Industry',
    subtitle: "If you specialise in providing manpower for the medical industry, Tera Connects is your go-to platform. Whether it's professionals like doctors and nurses or manpower for security and technical roles, our platform connects you with clients looking to hire",
  },


  // Add more feature service data objects as needed
];





function forclients() {
  return (
    <div className="nc-PageHomeDemo6 relative [ nc-section-rounded-md ]">
    <div className=" mx-auto home-hero-section for-clinic-page clients">
       <div className="container flex head-home-sec gap-extra">
         <div className="head-home-sec-centre gap">
           <h3 className="font-white regular title-custom text-center title-heading-banner montserrat "><span className="font-sky-blue bolders banner-grandient">Revitalise </span> Your Healthcare Agency  </h3>
           <h5 className="font-white  text-center regular title-custom montserrat">Revolutionise your agency's impact in the medical industry with Tera Connects—an all-encompassing platform designed to streamline, elevate, and amplify agency operations.</h5>
           <Link to="/contact-us">
                  <a href="/contact-us/" className="common-btn-custom">Get Started
                     <span><svg xmlns="http://www.w3.org/2000/svg" width="26" height="27" viewBox="0 0 26 27" fill="none">
                       <path d="M8.99945 7.14938L16.8203 14.4116L8.99946 21.6738" stroke="#fff" stroke-width="2"/>
                      </svg></span>
                  </a>
                </Link> 
          
          
     
 
         </div>
         <div className="head-home-sec-centre">
         <img src={bannerimage} alt="Service" className=""/>
         </div>
       </div>
     </div>
 

     <div className='tabsteraconnects background-white'>
   <div className="container">
   <h2 className='servicepages-headings montserrat font-medium lg:mb-20 mb-10'> We Empower a Diverse Range of Agencies in the<span className='font-extrabold'> Healthcare Industry</span></h2>
   {/* <h5 className="font-black regular title-custom montserrat lg:mb-20 mb-10">Tera Connects addresses the challenges faced by medical organisations with an array of solutions</h5> */}
   <div className="offer-service featureservice-display-wrap association-offer-service">
        {/* Map over the list of feature service data and render FeatureService for each */}
        {offerServiceDatalist.map((data, index) => (
         <OfferService  key={index} data={data} />
       ))}
   </div>
 </div>
 </div>


 <div className='tabsteraconnects background-white'>
   <div className="container">
   <h2 className='servicepages-headings montserrat font-medium lg:mb-20 mb-10'> Featured<span className='font-extrabold'> Services</span></h2>
   <div className="featureservice-display-wrap">
        {/* Map over the list of feature service data and render FeatureService for each */}
        {featureServiceDataList.map((data, index) => (
         <FeatureService key={index} data={data} />
       ))}
   </div>
 </div>
 </div>
 
 

 
 

 
 
 
 
 
 
 
 
 <div className="home-page-contact-us">
       <div className="home-page-last-wrapper">
         <div className="flex flex-col justify-center items-start container">
           <h2 className="font-sky-blue bolds title-custom montserrat text-start mb-5">Let’s Start Our Journey</h2>
           <h4 className="font-white regular title-custom montserrat mb-5">Discover how we can meet your healthcare needs
</h4>
<a href="#download-app-now" className="common-btn-custom blue-btn">Install App<span><svg xmlns="http://www.w3.org/2000/svg" width="44" height="45" viewBox="0 0 44 45" fill="none">
  <rect width="44" height="45" rx="22" fill="none"/>
  <path d="M22.5 28.25L15.3125 21.0625L17.325 18.9781L21.0625 22.7156V11H23.9375V22.7156L27.675 18.9781L29.6875 21.0625L22.5 28.25ZM11 34V26.8125H13.875V31.125H31.125V26.8125H34V34H11Z" fill="white"/>
</svg></span></a>
         </div>
         <div className="hame-last-image">
           <img src={backgroundfooter}/>
         </div>
       </div>
     </div>
 
  <div className='container background-white'>
  <div className='sec-padding'>
      <h2 className='servicepages-headings montserrat font-medium lg:mb-10 mb-10'> Read  <span className='font-extrabold'>Testimonials</span></h2>
      <TestimonialSlider perView={1} />
</div>
</div>



<div className="appdownload-main" id="download-app-now">
    <JoinNetwork
        heading="Join Our Network"
        subheading="Discover the Power of Connection with Tera Connects - Where Your Medical Needs Find Their Perfect Match."
        googlePlayUrl="https://play.google.com/store/apps/details?id=com.teraplatforms.jobseeker"
        appStoreUrl="https://apps.apple.com/in/app/tera-recruit-job-search/id1659265164"
        imageUrl= {qrtera}
      />
</div>
 
 
     
     </div>
    
  );
}

export default forclients;
