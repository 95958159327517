import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Page } from "./types";
import Footer from "components/Footer/Footer";
import PageHome from "app/(home)/page";
import PageArchive from "app/(archives)/archive/page";
import PageArchiveVideo from "app/(archives)/archive-3/page";
import PageArchiveAudio from "app/(archives)/archive-2/page";
import PageAuthor from "app/author/page";
import PageSingle from "app/(singles)/(default)/single/page";
import PageSingleTemplate2 from "app/(singles)/(default)/single-2/page";
import PageSingleTemplate3 from "app/(singles)/(has-sidebar)/single-3/page";
import PageSingleGallery from "app/(singles)/(default)/single-gallery/page";
import PageSingleAudio from "app/(singles)/(default)/single-audio/page";
import PageSingleVideo from "app/(singles)/(default)/single-video/page";
import PageSearch from "app/(search)/search/page";
import PageSearchV2 from "app/(search)/search-2/page";
import PageAbout from "app/about/page";
import PageContact from "app/(others)/contact/page";
import Page404 from "app/not-found";
import PageLogin from "app/(others)/login/page";
import PageSignUp from "app/(others)/signup/page";
import PageForgotPass from "app/(others)/forgot-pass/page";
import PageSubcription from "app/(others)/subscription/page";
import PageHomeDemo2 from "app/(home)/blog/page";
import PageHomeDemo3 from "app/(home)/about-us/page";
import PageHomeDemo4 from "app/(home)/services/page";
import staffandhr from "app/(home)/services/staffing-and-hr/page";
import operations from "app/(home)/services/operational-services/page";
import brandingandpromotion from "app/(home)/services/branding-and-promotions/page";
import medicalcentersetup from "app/(home)/services/medical-center-setup/page";
import PageHomeDemo6 from "app/(home)/contact-us/page";
import privacypolicy from "app/(home)/privacy-policy/page";
import termandcondion from "app/(home)//terms-and-conditions/page";
import buysell from "app/(home)/Buy-sell/page";
import Jobs from "app/(home)/jobs/page";
import JobListingComponent from "app/(home)/jobs/jobs-listing/page";
import JobPost from "app/(home)/jobs/jobs-listing/job-post/jobpost";
import productpage from "app/(home)/productpage/productpage";
import foragences from "app/(home)/For-Agencies/page";
import forassociation from "app/(home)/For-Associations/page";
import forclients from "app/(home)/For-clients/page";
import forclinicians from "app/(home)/For-Clinicians/page";
import Candidateprofile from "app/(home)/candidateprofilepage/candidate";
import Clientprofile from "app/(home)/clientprofilepage/page";
import Cliniciansprofile from "app/(home)/cliniciansprofilepage/page";
import Events from "app/(home)/event/page";
import EventsView from "app/(home)/event/eventview/eventview";

/*----------careers---------*/
import Carrier from "app/(home)/Careers/page";
import contentwriter from "app/(home)/Careers/content-writer/page";
import fullstackdeveloper from "app/(home)/Careers/fullstack-developer/page";
import mobiledeveloper from "app/(home)/Careers/mobile-developer/page";
import hrandmarketing from "app/(home)/Careers/hr-and-marketing/page";
import marketingpartner from "app/(home)/Careers/marketing-partner/page";
import fluttermobiledeveloper from "app/(home)/Careers/flutter-mobile-developer/page";
import marketingexecutive from "app/(home)/Careers/marketing-executive/page";
import medicalcontentwriter from "app/(home)/Careers/medical-content-writer/page";
import medicalrecruiter from "app/(home)/Careers/medical-recruiter/page";
import nursingtrainer from "app/(home)/Careers/nursing-trainer/page";
import businessdevelopmentassociate from "app/(home)/Careers/bda/page";
import insidesales from "app/(home)/Careers/inside-sales/page";

import forpartners from "app/(home)/For-Partners/page";
import SiteHeader from "app/SiteHeader";
import PageSingleTemplate4 from "app/(singles)/(has-sidebar)/single-4/page";
import DashboardSubmitPost from "app/(others)/dashboard/submit-post/page";
import DashboardPosts from "app/(others)/dashboard/posts/page";
import DashboardEditProfile from "app/(others)/dashboard/edit-profile/page";
import DashboardSubcription from "app/(others)/dashboard/subscription/page";
import DashboardBilingAddress from "app/(others)/dashboard/billing-address/page";
import login from "app/(home)/login/page";
import Signup from "app/(home)/signup/page";

import ClientsReferrals from "app/(others)/referrals/clientreferrals/clients";
import CandidatesReferrals from "app/(others)/referrals/candidatereferrals/candidates";
import VendorsReferrals from "app/(others)/referrals/vendorreferrals/vendors";
import ReferralsDashboard from "app/(others)/referrals/dashboard/dashboard";
import shop from "app/(home)/shop/page";
import pricing from "app/(home)/pricing/page";
import ScrollToTopButton from "components/Sections/scrolltotop/scrolltotop";
import WhatsappchatButton from "components/Sections/whatsappchat/whatsappchat";
import PersonalDetails from "app/(others)/referrals/myaccount/PersonalDetails";
import { UserProvider } from "components/UserContext";
import LoginComponent from "app/(home)/login/page";
import RequireAuth from "./requireAuth";
import refundpolicy from "app/(home)/jobs/refund-policy/page";
import cancellationpolicy from "app/(home)/jobs/cancellation-policy/page";
import usermanual from "app/(home)/usermanual/page";
import clients from "app/(home)/usermanual/clients";
import clinician from "app/(home)/usermanual/clinician";
import partners from "app/(home)/usermanual/partners";
import students from "app/(home)/usermanual/students";
import Stories from "app/(home)/successStories/stories";
import Training from "app/(home)/Placements/training";
import training from "app/(home)/Placements/training";
import { components } from "react-select";
import CountryCard from "app/(home)/Placements/countryCard";
import Countrylist from "app/(home)/Placements/countries/countrynew";
import courses from "app/(home)/Trainings/course";
import CourseDetail from "app/(home)/Trainings/coursedesc/offerings";
import offerings from "app/(home)/Trainings/coursedesc/offerings";
import programs from "app/(home)/Trainings/programs";
import ProgramCourses from "app/(home)/Trainings/course";
import Chatbot from "components/Sections/chatbotbutton/chatbot";
import campusambassador from "app/(home)/campusambassador/campusambassador";
import TopHeader from "app/TopHeader";
import Licenses from "app/(home)/Licenses";
import ClientDeepLink from "app/deepLinks/ClientDeepLink";
import PartnerDeepLink from "app/deepLinks/PartnerDeepLink";
import MediProHubLink from "app/deepLinks/MediProHubLink";

export const pages: Page[] = [
  { path: "/", component: PageHome },
  { path: "/#", component: PageHome },
  { path: "/blog", component: PageHomeDemo2 },
  { path: "/about-us", component: PageHomeDemo3 },
  { path: "/ops-maintenance", component: PageHomeDemo4 },
  { path: "/hiring-careers/staffing-and-hr", component: staffandhr },
  { path: "/services/operational-services", component: operations },
  {
    path: "/ops-maintenance/branding-and-marketing",
    component: brandingandpromotion,
  },
  { path: "/services/medical-center-setup", component: medicalcentersetup },
  { path: "/contact-us", component: PageHomeDemo6 },
  { path: "/privacy-policy", component: privacypolicy },
  { path: "/refund-policy", component: refundpolicy },
  { path: "/cancellation-policy", component: cancellationpolicy },
  { path: "/usermanual", component: usermanual },
  { path: "/campusambassador", component: campusambassador },
  { path: "/partners", component: partners },
  { path: "/clients", component: clients },
  { path: "/clinician", component: clinician },
  { path: "/students", component: students },
  { path: "/sucessStories", component: Stories },
  { path: "/terms-and-conditions", component: termandcondion },
  { path: "/licenses-required", component: Licenses },

  { path: "/Buy-sell", component: buysell },
  { path: "/hiring-careers/for-agencies", component: foragences },
  { path: "/For-Associations", component: forassociation },
  { path: "/hiring-careers/for-clients", component: forclients },
  { path: "/hiring-careers/for-professionals", component: forclinicians },
  { path: "/ops-maintenance/vendors-suppliers", component: forpartners },
  { path: "/placements", component: training },
  { path: "/training", component: programs },
  { path: "/training/:programName", component: ProgramCourses },
  { path: "/training/:programdName/:name", component: offerings },
  { path: "/placements/countries/:name", component: Countrylist },
  { path: "/pricing", component: pricing },
  { path: "/shop", component: shop },
  { path: "/jobs", component: Jobs },
  { path: "/jobs/jobs-listing", component: JobListingComponent },
  { path: "/jobs/jobs-listing/jobpost", component: JobPost },
  { path: "/productpage", component: productpage },
  { path: "/cp/:candidateUri", component: Candidateprofile },
  { path: "/clientprofilepage", component: Clientprofile },
  { path: "/cliniciansprofilepage", component: Cliniciansprofile },
  { path: "/event", component: Events },
  { path: "/event/eventview", component: EventsView },

  /*----------career---------*/
  { path: "/Careers", component: Carrier },
  { path: "/Careers/content-writer", component: contentwriter },
  { path: "/Careers/fullstack-developer", component: fullstackdeveloper },
  { path: "/Careers/mobile-developer", component: mobiledeveloper },
  { path: "/Careers/hr-and-marketing/", component: hrandmarketing },
  { path: "/Careers/marketing-partner", component: marketingpartner },
  { path: "/Careers/inside-sales", component: insidesales },
  {
    path: "/Careers/flutter-mobile-developer",
    component: fluttermobiledeveloper,
  },
  { path: "/Careers/marketing-executive", component: marketingexecutive },
  { path: "/Careers/medical-content-writer", component: medicalcontentwriter },
  { path: "/Careers/medical-recruiter/", component: medicalrecruiter },
  { path: "/Careers/nursing-trainer", component: nursingtrainer },
  { path: "/Careers/bda", component: businessdevelopmentassociate },
  { path: "/login", component: login },
  { path: "/signup", component: Signup },
  // archives page -------------------------------------------------------
  { path: "/archive/*", component: PageArchive },
  { path: "/archive-2/*", component: PageArchiveAudio },
  { path: "/archive-3/*", component: PageArchiveVideo },
  { path: "/author/*", component: PageAuthor },
  // single page -------------------------------------------------------
  { path: "/single/*", component: PageSingle },
  { path: "/single-2/*", component: PageSingleTemplate2 },
  { path: "/single-audio/*", component: PageSingleAudio },
  { path: "/single-video/*", component: PageSingleVideo },
  { path: "/single-gallery/*", component: PageSingleGallery },
  { path: "/single-3/*", component: PageSingleTemplate3 },
  { path: "/single-4/*", component: PageSingleTemplate4 },
  { path: "/single-5/*", component: PageSingleTemplate2 },
  // search -------------------------------------------------------
  { path: "/search", component: PageSearch },
  { path: "/search-2", component: PageSearchV2 },
  // other pages -------------------------------------------------------
  { path: "/about", component: PageAbout },
  { path: "/contact", component: PageContact },
  { path: "/page404", component: Page404 },
  // { path: "/login", component: PageLogin },
  { path: "/signup", component: PageSignUp },
  { path: "/forgot-pass", component: PageForgotPass },
  { path: "/subscription", component: PageSubcription },
  { path: "/clientApp", component: ClientDeepLink },
  { path: "/vendorApp", component: PartnerDeepLink },
  { path: "/clinicianApp", component: MediProHubLink },
];

export const authPages: Page[] = [
  { path: "/dashboard", component: DashboardSubmitPost },
  { path: "/dashboard/posts", component: DashboardPosts },
  { path: "/dashboard/edit-profile", component: DashboardEditProfile },
  { path: "/dashboard/subscription", component: DashboardSubcription },
  { path: "/dashboard/billing-address", component: DashboardBilingAddress },
  { path: "/dashboard/submit-post", component: DashboardSubmitPost },
  // { path: "/referrals", component: ReferralsDashboard },
  { path: "/referrals/dashboard", component: ReferralsDashboard },
  { path: "/referrals/clients", component: ClientsReferrals },
  { path: "/referrals/candidates", component: CandidatesReferrals },
  { path: "/referrals/vendors", component: VendorsReferrals },
  // My Account
  { path: "/referrals/myaccount", component: PersonalDetails },
];

const MyRoutes = () => {
  return (
    <BrowserRouter>
      <UserProvider>
        <TopHeader />
        <SiteHeader />
        <Routes>
          {pages.map(({ component: Component, path }, index) => (
            <Route key={index} element={<Component />} path={path} />
          ))}
          {authPages.map(({ component: Component, path }, index) => (
            <Route
              key={index}
              element={
                <RequireAuth redirectTo="/login">
                  <Component />
                </RequireAuth>
              }
              path={path}
            />
          ))}
          <Route path="/login" element={<LoginComponent />} />
          <Route path="*" element={<Page404 />} />
        </Routes>
        <Footer />
        <Chatbot />
        {/* < WhatsappchatButton /> */}
        <ScrollToTopButton />
      </UserProvider>
    </BrowserRouter>
  );
}; // Export useUser for components to use
export default MyRoutes;
