import React, { useRef, useState, useEffect } from 'react';

interface PricingCardProps {
  dropdownOptions: string[];
  optionData: Record<string, { price: string; label: string; labeltwo: string; recommendedBadgeData?: string; features: string[]; crownData?: string }>;
  buttonUrl: string;
  defaultOption?: string; // New prop for default selection
}

const PricingCard: React.FC<PricingCardProps> = ({ dropdownOptions, optionData, buttonUrl, defaultOption }) => {
  const [selectedOption, setSelectedOption] = useState<string>(defaultOption || dropdownOptions[0]);

  const handleDropdownChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedOption(event.target.value);
  };

  // Update selectedOption when defaultOption changes
  useEffect(() => {
    if (defaultOption) {
      setSelectedOption(defaultOption);
    }
  }, [defaultOption]);

  return (
    <div className="free-lableing-pricig paid-paricing-card">
      <div className="box-free-pricing">
        <div className="group">
          <div className='crown-recomended-wrapper'>
            {optionData[selectedOption].crownData && (
              <div className="pricing-dynamic">
                <div className="crown">
                  <p>
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="27" viewBox="0 0 32 27" fill="none">
                      <path d="M4.33748 20.0754L0.976562 2.05251L10.2191 10.2447L16.1007 0.414062L21.9823 10.2447L31.2248 2.05251L27.8639 20.0754H4.33748ZM27.8639 24.9908C27.8639 25.9738 27.1917 26.6292 26.1834 26.6292H6.01794C5.00966 26.6292 4.33748 25.9738 4.33748 24.9908V23.3523H27.8639V24.9908Z" fill="#F1B40A" />
                    </svg>
                  </p>
                </div>
              </div>
            )}
            {optionData[selectedOption].recommendedBadgeData && (
              <div className="recomended-badge">
                <p>{optionData[selectedOption].recommendedBadgeData}</p>
              </div>
            )}
          </div>
          <div className="text-wrapper-price montserrat">
            {optionData[selectedOption].price}
            <span className="div montserrat">/ {optionData[selectedOption].label}</span>
          </div>
          <div className="text-wrapper-below-price montserrat">{optionData[selectedOption].labeltwo}</div>
        </div>
      </div>
      <div className="label-pricing-card">
        <ul className="list-pricing-table">
          {optionData[selectedOption].features.map((feature, index) => (
            <li className="montserrat" key={index}>{feature}</li>
          ))}
        </ul>
      </div>
      {/* Select dropdown */}
      <div className="dropdown-container-select">
        <select id="pricingDropdown" value={selectedOption} onChange={handleDropdownChange}>
          {dropdownOptions.map((option, index) => (
            <option key={index} value={option}>
              {option}
            </option>
          ))}
        </select>
        <div className="button-pricing">
        <a href={buttonUrl} className="common-btn-custom blue-btn montserrat">Get Now</a>
        </div>
      </div>
    
    
    </div>
  );
};

export default PricingCard;
