import React, { useState, useEffect, useRef } from "react";
import Breadcrumb from "./breadCrumb";

interface Section {
  id: string;
  title: string;
  subsections: Subsection[];
  videoLink?: string; // Optional field for video link
  imageUrl?: string;
}

interface Subsection {
  id: string;
  title: string;
}

const sectionsData: Section[] = [
  {
    id: 'section1',
    title: 'Section 1',
    subsections: [
      { id: 'subsection1', title: 'Subsection 1.1' },
      { id: 'subsection2', title: 'Subsection 1.2' },
    ],
    videoLink: "https://www.youtube.com/embed/gs5AWJUF0hM",
    imageUrl: "https://lh3.googleusercontent.com/p/AF1QipOxF0NjA9VBs0hNmLTcxY04G1x8G5gZe1QqNoUP=s1360-w1360-h1020",
  },
  {
    id: 'section2',
    title: 'Section 2',
    subsections: [
      { id: 'subsection3', title: 'Subsection 2.1' },
      { id: 'subsection4', title: 'Subsection 2.2' },
    ],
  },
  {
    id: 'section3',
    title: 'Section 3',
    subsections: [
      { id: 'subsection1', title: 'Subsection 1.1' },
      { id: 'subsection2', title: 'Subsection 1.2' },
    ],
  },
  {
    id: 'section4',
    title: 'Section 4',
    subsections: [
      { id: 'subsection3', title: 'Subsection 2.1' },
      { id: 'subsection4', title: 'Subsection 2.2' },
    ],
  },
  {
    id: 'section5',
    title: 'Section 5',
    subsections: [
      { id: 'subsection1', title: 'Subsection 1.1' },
      { id: 'subsection2', title: 'Subsection 1.2' },
    ],
  },
  {
    id: 'section6',
    title: 'Section 6',
    subsections: [
      { id: 'subsection3', title: 'Subsection 2.1' },
      { id: 'subsection4', title: 'Subsection 2.2' },
    ],
  },
  {
    id: 'section7',
    title: 'Section 7',
    subsections: [
      { id: 'subsection1', title: 'Subsection 1.1' },
      { id: 'subsection2', title: 'Subsection 1.2' },
    ],
    imageUrl: "https://lh3.googleusercontent.com/p/AF1QipOxF0NjA9VBs0hNmLTcxY04G1x8G5gZe1QqNoUP=s1360-w1360-h1020",

  },
  {
    id: 'section8',
    title: 'Section 8',
    subsections: [
      { id: 'subsection3', title: 'Subsection 2.1' },
      { id: 'subsection4', title: 'Subsection 2.2' },
    ],
  },
  {
    id: 'section9',
    title: 'Section 9',
    subsections: [
      { id: 'subsection1', title: 'Subsection 1.1' },
      { id: 'subsection2', title: 'Subsection 1.2' },
    ],
  },
  {
    id: 'section10',
    title: 'Section 10',
    subsections: [
      { id: 'subsection3', title: 'Subsection 2.1' },
      { id: 'subsection4', title: 'Subsection 2.2' },
    ],
  },
  {
    id: 'section11',
    title: 'Section 11',
    subsections: [
      { id: 'subsection1', title: 'Subsection 1.1' },
      { id: 'subsection2', title: 'Subsection 1.2' },
    ],
  },
  {
    id: 'section12',
    title: 'Section 12',
    subsections: [
      { id: 'subsection3', title: 'Subsection 2.1' },
      { id: 'subsection4', title: 'Subsection 2.2' },
    ],
  },
  {
    id: 'section13',
    title: 'Section 13',
    subsections: [
      { id: 'subsection1', title: 'Subsection 1.1' },
      { id: 'subsection2', title: 'Subsection 1.2' },
    ],
  },
  {
    id: 'section14',
    title: 'Section 14',
    subsections: [
      { id: 'subsection3', title: 'Subsection 2.1' },
      { id: 'subsection4', title: 'Subsection 2.2' },
    ],
  },
  {
    id: 'section15',
    title: 'Section 15',
    subsections: [
      { id: 'subsection1', title: 'Subsection 1.1' },
      { id: 'subsection2', title: 'Subsection 1.2' },
    ],
  },
  {
    id: 'section16',
    title: 'Section 16',
    subsections: [
      { id: 'subsection3', title: 'Subsection 2.1' },
      { id: 'subsection4', title: 'Subsection 2.2' },
    ],
  },
  {
    id: 'section17',
    title: 'Section 17',
    subsections: [
      { id: 'subsection1', title: 'Subsection 1.1' },
      { id: 'subsection2', title: 'Subsection 1.2' },
    ],
  },
  {
    id: 'section18',
    title: 'Section 18',
    subsections: [
      { id: 'subsection3', title: 'Subsection 2.1' },
      { id: 'subsection4', title: 'Subsection 2.2' },
    ],
  },
];


const Clients: React.FC = () => {
  // const [activeSection, setActiveSection] = useState<string | null>(null);
  // const [currentSectionIndex, setCurrentSectionIndex] = useState<number>(0);
  // const [scrollbarWidth, setScrollbarWidth] = useState<number>(0);
  // const [hoveredSection, setHoveredSection] = useState<string | null>(null);
  // const sectionRefs = useRef<{ [key: string]: React.RefObject<HTMLDivElement> }>({});

  // const scrollToSection = (id: string) => {
  //   setTimeout(() => {
  //     const element = document.getElementById(id);
  //     if (element) {
  //       element.scrollTo(0, 0);
  //     }
  //   }, 10); // Adjust the delay as needed
  // };
  // const handleSectionClick = (id: string) => {
  //   const sectionIndex = sectionsData.findIndex(section => section.id === id);
  //   if (sectionIndex !== -1) {
  //     setCurrentSectionIndex(sectionIndex);
  //     scrollToSection(id);
  //     setActiveSection(id);
  //   }
  // };
  // const handleSectionHover = (id: string) => {
  //   setHoveredSection(id);
  // };

  // const handlePrevPage = () => {
  //   const newIndex = Math.max(currentSectionIndex - 1, 0);
  //   setCurrentSectionIndex(newIndex);
  //   setActiveSection(sectionsData[newIndex].id); // Update active section when navigating to previous section
  //   scrollToSection(sectionsData[newIndex].id);
  // };

  // const handleNextPage = () => {
  //   const newIndex = Math.min(currentSectionIndex + 1, sectionsData.length - 1);
  //   setCurrentSectionIndex(newIndex);
  //   setActiveSection(sectionsData[newIndex].id); // Update active section when navigating to next section
  //   scrollToSection(sectionsData[newIndex].id);
  // };


  // useEffect(() => {
  //   setActiveSection(sectionsData[0].id); // Set the first section as active when component mounts
  // }, []);

  // useEffect(() => {
  //   const handleScroll = () => {
  //     for (const sectionId in sectionRefs.current) {
  //       const sectionRef = sectionRefs.current[sectionId];
  //       if (sectionRef.current && isElementInViewport(sectionRef.current)) {
  //         setActiveSection(sectionId);
  //         break;
  //       }
  //     }
  //   };
  //   document.addEventListener('scroll', handleScroll);

  //   return () => {
  //     document.removeEventListener('scroll', handleScroll);
  //   };
  // }, []);

  return (
    // <div className="background-white">
    //   <div className="md:flex">
    //     {/* Sidebar */}
    //     <div className="md:w-1/4 overflow-y-auto bg-slate-100 overflow-x-hidden sticky h-full" style={{ position: "sticky", top: "28px", bottom: "0px", maxHeight: "500px"}}>
    //       <div className="sidebar" >
    //         {[...Array(Math.ceil(sectionsData.length / 4))].map((_, index) => (
    //           <div key={index}>
    //             <h3 className="header font-semibold">{`Header ${index + 1}`}</h3>
    //             <ul>
    //               {sectionsData.slice(index * 4, (index + 1) * 4).map((section) => (
    //                 <li
    //                   key={section.id}
    //                   className={`sidebar-item ${activeSection === section.id ? "active" : ""}`}
    //                   onClick={() => {
    //                     handleSectionClick(section.id);
    //                     // scrollToSection(section.id);
    //                   }}
    //                   onMouseEnter={() => setHoveredSection(section.id)}
    //                   onMouseLeave={() => setHoveredSection(null)}
    //                 >
    //                   <span className={`line ${activeSection === section.id ? "active" : ""}`}></span>
    //                   <button style={{ color: activeSection === section.id ? 'blue' : 'black' }}>{section.title}</button>
    //                   {activeSection === section.id && <span className="dot"></span>}
    //                   {hoveredSection === section.id && <span className="dot" style={{ backgroundColor: 'grey' }}></span>}
    //                 </li>
    //               ))}
    //             </ul>
    //           </div>
    //         ))}
    //       </div>
    //     </div>

    //     {/* Content */}
    //     <div className="w-full md:w-3/4 p-10 ml-4 mr-4 sticky ">
    //       <div className="background-white">
    //       <Breadcrumb sectionsData={sectionsData} currentSectionIndex={currentSectionIndex} setCurrentSectionIndex={setCurrentSectionIndex} currentPage="clients"/>
    //         <div className="content">
    //           <div key={currentSectionIndex} id={`section${currentSectionIndex + 1}`} ref={sectionRefs.current[`section${currentSectionIndex + 1}`]}>
    //             <h2 className='mb-4'>{sectionsData[currentSectionIndex]?.title}</h2>
    //             {/* Render video if available */}
    //             {sectionsData[currentSectionIndex]?.videoLink && (
    //               <div className='mb-4'>
    //                 <h3 className='mb-2'>Video Title</h3>
    //                 <iframe width="300" height="215" src={sectionsData[currentSectionIndex]?.videoLink} title="YouTube video" allowFullScreen></iframe>
    //               </div>
    //             )}
    //             {/* Render image if available */}
    //             {/* Render subsections */}
    //             {sectionsData[currentSectionIndex].subsections.map((subsection) => (
    //               <div key={subsection.id} id={subsection.id}>
    //                 <h3 className=''>{subsection.title}</h3>
    //                 <p className='mt-4'>
    //                   Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris interdum justo eu ligula tempor, vel tristique lacus vehicula. Ut sed tellus vel velit venenatis lacinia. Curabitur in dictum libero. Proin malesuada, velit eu consectetur fringilla, leo urna fermentum sapien, eget ultricies dolor mauris eu mi.
    //                 </p>
    //                 {sectionsData[currentSectionIndex].imageUrl && (
    //                   <div className='mt-4 mb-4'>
    //                     <h3 className='mb-2'>Image</h3>
    //                     <img src={sectionsData[currentSectionIndex].imageUrl} width="300" height="200" alt="Section Image" />
    //                   </div>
    //                 )}
    //               </div>
    //             ))}
    //           </div>
    //         </div>
    //         <div className="w-full p-4 flex justify-between">
    //           {currentSectionIndex !== 0 && (
    //             <button className="absolute left-10 bottom-10 flex items-center" onClick={handlePrevPage}>
    //               <span className="mr-1">&lt;</span>
    //               <span>{sectionsData[currentSectionIndex - 1].title}</span>
    //             </button>
    //           )}

    //           {/* Next Section Button */}
    //           {currentSectionIndex !== sectionsData.length - 1 && (
    //             <button className="absolute right-10 bottom-10 flex items-center" onClick={handleNextPage}>
    //               <span>{sectionsData[currentSectionIndex + 1].title}</span>
    //               <span className="ml-1">&gt;</span>
    //             </button>
    //           )}</div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
    <>Clients</>
  )
}

export default Clients;

function isElementInViewport(el: HTMLElement) {
  const rect = el.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}