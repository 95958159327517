// TestimonialSlider.tsx

import React from 'react';
import MySlider from "components/MySlider";
import Testimonial from './Testimonal';
import './TestimonialSlider.css';

interface TestimonialData {
  id: number;
  name: string;
  heading: string;
  description: string;
  rating: number;
  imageSrc: string;
  organisation: string;
}

interface TestimonialSliderProps {
  perView: number;
  testimonialData?: TestimonialData[];
}

const defaultTestimonial: TestimonialData = {
  id: 0,
  name: "Default Testimonial",
  heading: "“",
  description: "No testimonial data available.",
  rating: 0,
  imageSrc: "",
  organisation: "Default Organisation",
};

const TestimonialSlider: React.FC<TestimonialSliderProps> = ({ perView, testimonialData = [defaultTestimonial] }) => {
  const lastTestimonials = testimonialData.slice(-10);

  return (
    <MySlider
      data={lastTestimonials}
      renderItem={(testimonial, index) => (
        <Testimonial
          key={index}
          name={testimonial.name}
          heading={testimonial.heading}
          organisation={testimonial.organisation}
          description={testimonial.description}
          rating={testimonial.rating}
          imageSrc={testimonial.imageSrc}
        />
      )}
      itemPerRowBreakpoints={{
        320: 1,
        500: 1,
        1024: 1,
        1280: 1,
      }}
    />
  );
};

export default TestimonialSlider;
