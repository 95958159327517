import { useEffect, useState } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { Step } from "./contactform";
import "./locationform.css";
import { getAllTeraServicesApi } from "services/lookupService";
import ClientImg from "./images/Client-Other-svg.svg";

interface SelectServicesProps {
  activeStep: any;
  setActiveStep: any;
  steps: Step[];
  orgTypeDetails: any;
  onUserDataChange: (data: any) => void;
}

interface ServiceProps {
  service: KeyValue;
  selectedServices: string;
  handleServiceToggle: (label: string) => void;
}
interface KeyValue {
  value: string;
  label: string;
  logo: string;
}
type TeraServicesResponse = KeyValue[];

const schema = yup.object().shape({
  seekingServices: yup.array().when("userTypeID", {
    is: (val: number) => val === 5,
    then: (schema) => schema.required("You must select services"),
    otherwise: (schema) => schema.notRequired(),
  }),
});

const SelectServicesForm: React.FC<SelectServicesProps> = ({
  activeStep,
  setActiveStep,
  steps,
  orgTypeDetails,
  onUserDataChange,
}) => {
  const [image, setImage] = useState<string>("");
  const [servicesData, setServicesData] = useState<TeraServicesResponse>([]);
  const [selectedServices, setSelectedServices] = useState<string>("");
  const [error, setError] = useState("");

  const methods = useForm({
    mode: "onChange",
    defaultValues: {},
    resolver: yupResolver(schema),
  });

  const { getValues, handleSubmit } = methods;

  useEffect(() => {
    getAllTeraServicesApi()
      .then((response) => {
        setServicesData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching services:", error);
      });
  }, []);

  const Service: React.FC<ServiceProps> = ({
    service,
    selectedServices,
    handleServiceToggle,
  }) => {
    const isChecked = selectedServices.split(",").includes(service.label);

    const handleLabelClick = () => {
      handleServiceToggle(service.label);
    };

    return (
      <div className="flex items-center mb-4">
        <input
          type="checkbox"
          className="mr-2"
          checked={isChecked}
          onChange={() => handleServiceToggle(service.label)}
        />
        <img
          src={service.logo}
          alt={service.label}
          className="max-w-30 max-h-18 mr-2"
        />
        <div
          className={`cursor-pointer rounded-lg border px-3 py-3 w-full text-center items-center ${
            isChecked ? "bg-blue-400 text-white" : "bg-gray-200"
          }`}
          onClick={handleLabelClick}
        >
          {service.label}
        </div>
      </div>
    );
  };
  const handleServicesFormDetails = () => {
    let values = getValues();
    if (!selectedServices) {
      setError("Please select any services.");
      return;
    }
    let formData = {
      ...values,
      ...orgTypeDetails,
      seekingServices: selectedServices,
    };
    onUserDataChange(formData);
    setActiveStep(activeStep + 1);
  };
  const handleServiceToggle = (label: string) => {
    setError("");
    const updatedServices = selectedServices.includes(label)
      ? selectedServices
          .split(",")
          .filter((service) => service !== label)
          .join(",")
      : selectedServices
      ? `${selectedServices},${label}`
      : label;
    setSelectedServices(updatedServices);
  };
  return (
    <FormProvider {...methods}>
      <>
        <div className="flex select-services">
          <div className="w-3/4">
            <div className="mt-0">
              <h1
                style={{
                  color: "white",
                  fontSize: "30px",
                  paddingTop: "18px",
                  paddingBottom: "18px",
                }}
              >
                Looking for what type of Services?{" "}
              </h1>
              <div className="grid gap-9 md:grid-cols-2 w-full mt-6">
                {servicesData.map((service) => (
                  <Service
                    key={service.value}
                    service={service}
                    selectedServices={selectedServices}
                    handleServiceToggle={handleServiceToggle}
                  />
                ))}
                <div className="mt-3">
                  {error && <p style={{ color: "#f44336" }}>{error}</p>}
                </div>
              </div>
            </div>
          </div>
          <div className="w-1/4 p-16 select-services-img">
            <img src={ClientImg} alt="Your Image" />
          </div>
        </div>
        <div className="button-container">
          {activeStep !== 0 && (
            <button
              onClick={() => setActiveStep(activeStep - 1)}
              className="button flex gap-2"
            >
              <svg
                className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 10"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M13 5H1m0 0l4-4m-4 4l4 4"
                />
              </svg>
              Previous
            </button>
          )}
          {activeStep !== steps.length - 1 && (
            <button
              onClick={() => {
                handleSubmit(handleServicesFormDetails)();
              }}
              className="button"
            >
              Next
              <svg
                className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 10"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M1 5h12m0 0L9 1m4 4L9 9"
                />
              </svg>
            </button>
          )}
        </div>
      </>
    </FormProvider>
  );
};
export default SelectServicesForm;
