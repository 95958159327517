import { Popover } from "@headlessui/react";
import { useCallback } from "react";
import { Link } from "react-router-dom";

const DownloadsPopover = () => {

 const handleRedirect = useCallback((appType:"client"|"partner"|"mediprohub")=>{
  const userAgent = navigator.userAgent || navigator.vendor || (window as any).opera;
  try{
    if(/android/i.test(userAgent)){
      if(appType === "client"){
        window.location.href = "https://play.google.com/store/apps/details?id=com.teraplatforms.teraconnects"
      }else if(appType === "partner"){
        window.location.href = "https://play.google.com/store/apps/details?id=com.teraplatforms.terapartner"
      }else if(appType === "mediprohub"){
        window.location.href = "https://play.google.com/store/apps/details?id=com.teraplatforms.jobseeker"
      }
    }
    // IOS detection

    else if(/iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream){
      if(appType ==="client"){
        window.location.href = "https://apps.apple.com/in/app/tera-connects/id6448921572";
      }else if(appType === "partner"){
        window.location.href = "https://apps.apple.com/in/app/tera-partner-healthcare/id6453868120"
      }else if(appType === "mediprohub"){
        window.location.href = "https://apps.apple.com/in/app/tera-recruit-job-search/id1659265164"
      }
    }
// web redirect
    else{
      if (appType === "client") {
        window.location.href = "https://teraconnects.com/hiring-careers/for-clients#download-app-now";
      } else if (appType === "partner") {
        window.location.href = "https://teraconnects.com/ops-maintenance/vendors-suppliers#download-app-now";
      } else if (appType === "mediprohub") {
        window.location.href = "https://teraconnects.com/hiring-careers/for-professionals#download-app-now";
      }
    }
  }catch (error){
    console.error("Redirection error:", error);
  }
},[])
  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <Popover.Button
            className={`text-sm text-black-500 hover:text-blue-700 focus:outline-none flex items-center ${
              open ? "border-none" : "border-b-2 border-transparent"
            }`}
          >
            Download App
            <svg
              className={`ml-1 w-4 h-4 transition-transform ${
                open ? "rotate-180" : ""
              }`}
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M19 9l-7 7-7-7"
              />
            </svg>
          </Popover.Button>
          <Popover.Panel className="absolute z-50 bg-white border border-gray-200 rounded-md shadow-lg mt-1 w-40">
            <div className="p-6 flex flex-col gap-4">
              <button
                onClick={() => handleRedirect("client")}
                className="text-sm text-black-500 hover:text-blue-700"
              >
                Tera Client
              </button>
              <button
                onClick={() => handleRedirect("partner")}
                className="text-sm text-black-500 hover:text-blue-700"
              >
                Tera Partner
              </button>
              <button
                onClick={() => handleRedirect("mediprohub")}
                className="text-sm text-black-500 hover:text-blue-700"
              >
                MediProHub
              </button>
            </div>
          </Popover.Panel>
        </>
      )}
    </Popover>
  );
};

export default DownloadsPopover;
