import React from "react";
import "./jobapplyform.css";
import FillFormToApply from "./fillformtoapply";

interface JoinNetworkProps {
  heading: string;
  subheading: string;
  googlePlayUrl: string;
  appStoreUrl: string;
  imageUrl: string;
  jobTitle: string;
  healthCareCategoryID: any;
  jobId: any;
}

const JoinNetworkForm: React.FC<JoinNetworkProps> = ({
  heading,
  subheading,
  googlePlayUrl,
  appStoreUrl,
  imageUrl,
  jobTitle,
  healthCareCategoryID,
  jobId,
}) => {
  return (
    <div className="qr-moba">
      <div className="background-white items-start hole-wrapper-mob cards-home">
        <div className="lg:w-6/12 justify-end flex flex-col p-3">
          <h2 className="font-black bold py-4 title-custom montserrat">
            {heading}
          </h2>
          <h5 className="font-sky regular py-4 title-custom montserrat">
            {subheading}
          </h5>
          <div className="flex justify-center qr-scan-img">
            <img
              className="mob-image-footer max-w-[36%]"
              src={imageUrl}
              alt="Mobile Image"
            />
          </div>
          <div className="button-wrapper-footer">
            <h5 className="title-custom font-black bold py-3">
              <a
                target="_blank"
                href={googlePlayUrl}
                className="flex justify-around common-btn-custom "
              >
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="31"
                    height="35"
                    viewBox="0 0 31 35"
                    fill="none"
                  >
                    <path
                      d="M29.9116 19.1296L24.274 22.3203L19.2175 17.2955L24.3142 12.2304L29.9116 15.3981C30.242 15.5849 30.5168 15.856 30.708 16.1838C30.8992 16.5116 31 16.8843 31 17.2639C31 17.6434 30.8992 18.0161 30.708 18.3439C30.5168 18.6717 30.242 18.9428 29.9116 19.1296ZM0.161119 1.32912C0.0543637 1.58829 -0.000380618 1.86592 1.99177e-06 2.14621V32.3801C1.99177e-06 32.6922 0.0647363 32.9828 0.178381 33.2432L16.2253 17.2941L0.161119 1.32912ZM17.7214 15.8081L22.4082 11.1501L3.20076 0.280424C2.78906 0.0438351 2.30957 -0.0468932 1.8399 0.0229252L17.7214 15.8081ZM17.7214 18.7815L1.89744 34.5091C2.32612 34.5609 2.77783 34.4861 3.20076 34.2458L22.3679 23.3992L17.7214 18.7815Z"
                      fill="black"
                    />
                  </svg>
                </span>
                <span className="px-4">Google Play</span>
              </a>
            </h5>
            <h5 className="title-custom font-black bold py-4">
              <a
                target="_blank"
                href={appStoreUrl}
                className="flex justify-around common-btn-custom py-3"
              >
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="35"
                    viewBox="0 0 30 35"
                    fill="none"
                  >
                    <path
                      d="M24.5242 33.5346C22.6224 35.3782 20.5459 35.0871 18.547 34.2138C16.4317 33.3211 14.491 33.2823 12.2593 34.2138C9.46474 35.417 7.98984 35.0677 6.32087 33.5346C-3.14954 23.7731 -1.75226 8.90762 8.99898 8.36423C11.6189 8.50008 13.4431 9.80032 14.9762 9.91676C17.2662 9.451 19.4591 8.11195 21.9044 8.28661C24.8347 8.51949 27.0471 9.68388 28.5026 11.7798C22.4477 15.4088 23.8838 23.3849 29.4341 25.6167C28.3279 28.5277 26.8918 31.4192 24.5048 33.554L24.5242 33.5346ZM14.7821 8.2478C14.491 3.92013 18.0036 0.349318 22.0402 0C22.603 5.0069 17.4991 8.73296 14.7821 8.2478Z"
                      fill="black"
                    />
                  </svg>
                </span>
                <span className="px-6">App Store</span>
              </a>
            </h5>
          </div>
        </div>
        <div className="lg:w-6/12 flex flex-col">
          <FillFormToApply
            jobTitle={jobTitle}
            healthCareCategoryID={healthCareCategoryID}
            jobId={jobId}
          />
        </div>
      </div>
    </div>
  );
};

export default JoinNetworkForm;
