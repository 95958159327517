import API from "./api";
import { Login_API_URLS } from "./constants";
export function loginApi(args) {
  return API.post(Login_API_URLS.loginUrl, args);
}
export function forgotPasswordApi(mobileNumber) {
  return API.post(
    `${Login_API_URLS.forgotPasswordUrl}?mobileNumber=${mobileNumber}`
  );
}
export function updatePasswordWithOTPApi(mobileNumber, otpValue, password) {
  return API.post(
    `${Login_API_URLS.updatePasswordWithOTPUrl}?mobileNumber=${mobileNumber}&otp=${otpValue}&newPassword=${password}`
  );
}
export function signInWithToken(token, mobile) {
  return API.post(
    `${Login_API_URLS.reAuthUrl}?token=${token}&mobileNumber=${mobile}`
  );
}
