import React, { useEffect, useState } from "react";
import { NumericFormat } from "react-number-format";
import { Link } from "react-router-dom";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
// JobListingMainCard.tsx
import defaultOrgImg from "../images/Default-Org-svg.svg";
export interface JobData {
  action: string;
  branchName: string;
  cityId: number;
  cityName: string;
  clientName: string;
  contractDurationInDays: string;
  createdDate: string;
  departmentName: string;
  description: string;
  dutyType: string;
  dutyTypeId: number;
  employmentType: string;
  employmentTypeID: number;
  experienceRangeFrom: number;
  experienceRangeTo: number;
  expiryDate: string;
  healthCareCategory: string;
  healthCareCategoryID: number;
  healthCareRole: string;
  isEditable: boolean;
  isWithRecruitment: boolean;
  jobCode: string;
  jobPriority: string;
  noOfApplicants: number;
  numberOfPositions: number;
  salaryRangeFrom: number;
  salaryRangeTo: number;
  salaryType: string;
  salaryTypeId: number;
  shifts: string;
  shiftType: string;
  shiftTypeId: number;
  stateId: number;
  stateName: string;
  status: string;
  title: string;
  updatedDate: string;
  id: number;
  logoPath: string;
}
interface CheckboxItem {
  value: string;
  label: string;
}
interface JobListingProps {
  jobData: JobData;
  selectedValues: {
    selectedCategoryIdValue: any;
    selectedCheckboxes: CheckboxItem[];
    selectedExperience: any;
    selectedSalaryRange: any;
    selectedCompanyCheckboxes: CheckboxItem[];
  };
}
const JobListing: React.FC<JobListingProps> = ({ jobData, selectedValues }) => {
  const [postedAgo, setPostedAgo] = useState<string>("");
  const selectedValuesString = JSON.stringify(selectedValues);
   const [loading, setLoading] = useState<boolean>(true);
  useEffect(() => {
    const calculateTimeDifference = () => {
      const currentDate = new Date();
      const jobDate = new Date(jobData?.createdDate);
      const timeDifference = currentDate.getTime() - jobDate.getTime();
      const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
      let postedAgo;
      if (daysDifference === 0) {
        postedAgo = "Today";
      } else if (daysDifference === 1) {
        postedAgo = "1 day ago";
      } else {
        postedAgo = `${daysDifference} days ago`;
      }
      setPostedAgo(postedAgo);
      // setPostedAgo(`${daysDifference} days ago`);
      setLoading(false);
    };
    calculateTimeDifference();
  }, [jobData.createdDate]);
  if (loading) {
    return (
      <div className="joblistings-detail">
        <div className="jobs-prodiver-detail">
          <div className="job-info-main">
            <h4 className="job-name">
              <Skeleton width={630} height={30} />
            </h4>
            <h5 className="job-provider-name">
              <Skeleton width={630} height={30} />
            </h5>
          </div>
          <div className="job-provider-info">
            <p className="job-detail-minor job-viwed">
              <Skeleton width={30} height={15} />
            </p>
            <span className="job-detail-minor posted-ago">
              <Skeleton width={80} height={15} />
            </span>
          </div>
        </div>
        <div className="jobs-requirment">
          <div className="uppersection">
            <p className="job-detail-minor required-exprence ">
              <Skeleton width={50} height={15} />
            </p>
            <p className="job-detail-minor salary-offer">
              <Skeleton width={60} height={15} />
            </p>
            <p className="job-detail-minor location">
              <Skeleton width={80} height={15} />
            </p>
          </div>
          <div className="lowersections">
            <p className="job-detail-minor detail-other">
              <Skeleton width={50} height={15} />
            </p>
          </div>
        </div>
      </div>
    );
  }
  const {
    id,
    title,
    clientName,
    branchName,
    createdDate,
    experienceRangeFrom,
    experienceRangeTo,
    salaryRangeFrom,
    salaryRangeTo,
    stateName,
    employmentType,
    cityName,
    shiftType,
    logoPath,
  } = jobData;
  return (
    // <Link
    //   to="/jobs/jobs-listing/jobpost"
    //   state={{
    //     jobId: jobData?.id || 0,
    //     healthCareCategoryID: jobData?.healthCareCategoryID || 0,
    //     selectedValuesForSimilarJobs: selectedValues,
    //   }}
    <Link
    to={{
      pathname: "/jobs/jobs-listing/jobpost",
      search: `?jobId=${jobData?.id || 0}&healthCareCategoryID=${jobData?.healthCareCategoryID || 0}&selectedValuesForSimilarJobs=${encodeURIComponent(selectedValuesString)}`,
    }}
      state={{
        jobId: jobData?.id || 0,
        healthCareCategoryID: jobData?.healthCareCategoryID || 0,
        selectedValuesForSimilarJobs: selectedValues,
      }}
  >
  <div className="joblistings-detail">
        <div className="jobs-prodiver-detail">
          <div className="job-info-main">
            {/* <h4 className="job-name truncate hover:text-clip">{title}</h4> */}
            <h4 className="job-name break-all">{title}</h4>

            <h5 className="job-provider-name">
              {clientName}
              <span className="">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="21"
                  viewBox="0 0 22 21"
                  fill="none"
                >
                  <path
                    d="M7.6 21L5.7 17.8L2.1 17L2.45 13.3L0 10.5L2.45 7.7L2.1 4L5.7 3.2L7.6 0L11 1.45L14.4 0L16.3 3.2L19.9 4L19.55 7.7L22 10.5L19.55 13.3L19.9 17L16.3 17.8L14.4 21L11 19.55L7.6 21ZM9.95 14.05L15.6 8.4L14.2 6.95L9.95 11.2L7.8 9.1L6.4 10.5L9.95 14.05Z"
                    fill="#00A0C0"
                  />
                </svg>
              </span>
            </h5>
          </div>
          <div className="job-provider-info">
            {/* <p className="job-detail-minor job-viwed">{6} Viewed</p> */}
            <div className="our-solution-icons">
              {logoPath ? (
                <img src={logoPath} alt="" className="logo w-4/5" />
              ) : (
                <img
                  src={defaultOrgImg}
                  alt=""
                  className="w-4/5 default-org-img"
                />
              )}
            </div>
          </div>
        </div>
        <div className="jobs-requirment">
          <div className="uppersection">
            <p className="job-detail-minor required-exprence ">
              {experienceRangeFrom}-{experienceRangeTo}
            </p>

            <p className="job-detail-minor salary-offer">
              {salaryRangeFrom?.toLocaleString("en-IN")}-
              {salaryRangeTo?.toLocaleString("en-IN")}
            </p>
            <p className="job-detail-minor location">
              {cityName},{stateName}
            </p>
          </div>
          <div className="lowersections">
            <p className="job-detail-minor detail-other">{employmentType}</p>
            <p className="job-detail-minor detail-other">{postedAgo}</p>
            <p className="job-detail-minor detail-other">{shiftType} Shift</p>
          </div>
        </div>
  </div>
    </Link>
  );
};

export default JobListing;
