import React, { useEffect, useState } from "react";
import "react-phone-number-input/style.css";

import ReCAPTCHA from "react-google-recaptcha";
import PhoneInputWithCountrySelect, {
  parsePhoneNumber,
} from "react-phone-number-input";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

interface LoginProps {
  onSubmit: (
    phoneNumber: string,
    // phone: string,
    password: string,
    loginDetails: LoginDetails
  ) => void;
}
export interface LoginDetails {
  loginIP: string;
  browser: string | null;
  device: string;
  geolocation: {
    x: number;
    y: number;
  };
  latitude: number;
  longitude: number;
}
const schema = yup.object().shape({
  phoneNumber: yup
    .string()
    .nullable()
    .required("Enter phone number")
    .test("is-valid-phone", "Enter valid phone number", function (value) {
      const onlyNumber = parsePhoneNumber(
        value.toString()
      )?.nationalNumber.toString();
      return /^[6-9]\d{9}$/.test(onlyNumber ?? "");
    }),
});

const Login: React.FC<LoginProps> = ({ onSubmit }) => {
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loginDetails, setLoginDetails] = useState<LoginDetails>({
    loginIP: "",
    browser: "",
    device: "",
    geolocation: {
      x: 0,
      y: 0,
    },
    latitude: 0,
    longitude: 0,
  });
  const showCaptcha = window.location.hostname !== "localhost";
  const siteKey: string = process.env.REACT_APP_MAP_KEY ?? "";
  const [captchaVerified, setCaptchaVerified] = useState(
    window.location.hostname === "localhost"
  );
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  useEffect(() => {
    fetch("https://api.ipify.org?format=json")
      .then((response) => response.json())
      .then((data) => {
        setLoginDetails((prevState) => {
          return {
            ...prevState,
            loginIP: data.ip,
          };
        });
      });
    let browser = navigator.userAgent.match(/(Edg\/[\d.]+)\s*(.*)$/i);

    if (!browser) {
      browser = navigator.userAgent.match(
        /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
      );
    }
    setLoginDetails((prevState) => ({
      ...prevState,
      browser: browser ? browser[0] : null,
      device: navigator.platform,
    }));
    navigator.geolocation.getCurrentPosition((position) => {
      setLoginDetails((prevState) => ({
        ...prevState,
        geolocation: {
          x: position.coords.latitude,
          y: position.coords.longitude,
        },
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
      }));
    });
  }, []);

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const onSubmitVal = (data: any) => {
    if (data?.phoneNumber) {
      onSubmit(data?.phoneNumber, password, loginDetails);
    }
  };
  const handleCaptchaChange = (value: any) => {
    setCaptchaVerified(() => true);
  };

  return (
    <form onSubmit={handleSubmit(onSubmitVal)}>
      <div>
        <div className="pt-2">
          <div className="w-full contactforam-wram-inside">
            <label className="tera-contact-form-label montserrat">
              Phone Number
            </label>
            <Controller
              name="phoneNumber"
              control={control}
              render={({ field }) => (
                <>
                  <PhoneInputWithCountrySelect
                    {...field}
                    international
                    defaultCountry="IN"
                    countryCallingCodeEditable={false}
                    countries={["IN"]}
                  />
                  <p className="error">
                    {errors.phoneNumber && errors.phoneNumber?.message}
                  </p>
                </>
              )}
            />
          </div>
        </div>
      </div>
      <div className="password-dept">
        <label>Password:</label>
        <div
          className={`password-field ${showPassword ? "show" : "hide"} pt-3`}
        >
          <input
            className="pass-word-field pt-3"
            type={showPassword ? "text" : "password"}
            value={password}
            onChange={handlePasswordChange}
            placeholder="Your Password"
            required
          />
          <span
            onClick={handleTogglePasswordVisibility}
            className="toggle-password pt-3"
          >
            {showPassword ? "Hide" : "Show"}
          </span>
        </div>
      </div>
      {showCaptcha && (
        <ReCAPTCHA
          sitekey={siteKey}
          onChange={handleCaptchaChange}
          onExpired={() => setCaptchaVerified(() => false)}
        />
      )}
      <button
        type="submit"
        className="mt-3"
        disabled={captchaVerified === false}
      >
        Login
      </button>
    </form>
  );
};

export default Login;
