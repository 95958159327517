
import React, { useState } from 'react';
import { GoogleMap, LoadScript, Marker, InfoWindow } from '@react-google-maps/api';

const containerStyle = {
  width: '100%',
  height: '400px',
};

const center = {
  lat: 10.75776,
  lng: 92.52136
};

export interface LocationDataSummary {
  city: string;
  geoLocation: {
    x: string;
    y: string;
  };
  latitude: number;
  longitude: number;
}

const GoogleMapComponent: React.FC<{ locationData: LocationDataSummary[] }> = ({ locationData }) => {
  const [selectedMarker, setSelectedMarker] = useState<LocationDataSummary | null>(null);

  const handleMarkerClick = (marker: LocationDataSummary) => {
    setSelectedMarker(marker);
  };

  return (
    <LoadScript googleMapsApiKey="AIzaSyDFIeD0hTStLuttsg1DiwK4s0_r0qM_9ro">
      <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={4}>
        {(locationData||[]).map((location, index) => (
          <Marker
            key={index}
            position={{ lat: Number(location.geoLocation.x), lng: Number(location.geoLocation.y) }}
            title={location.city}
            onClick={() => handleMarkerClick(location)}
          />
        ))}

        {selectedMarker && (
          <InfoWindow
            position={{
              lat: Number(selectedMarker.geoLocation.x),
              lng: Number(selectedMarker.geoLocation.y),
            }}
            onCloseClick={() => setSelectedMarker(null)}
          >
            <div>
              <h3>{selectedMarker.city}</h3>
            </div>
          </InfoWindow>
        )}
      </GoogleMap>
    </LoadScript>
  );
};

export default GoogleMapComponent;

