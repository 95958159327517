import React, { useState, useEffect } from 'react';
import Jobcard from './jobscard';
import MySlider from 'components/MySlider';
import CardListData from './jobcard.json';

interface JobbyhospitalpropsProps {
  perView: number;
}

const CategorySlider: React.FC<JobbyhospitalpropsProps> = ({ perView }) => {
  const [sliderData, setSliderData] = useState<any[]>([]);

  useEffect(() => {
    // Assuming categoriesData is an array, you might need to adjust the structure
    setSliderData(CardListData);
  }, []);

  // Render each item in the slider
  const renderSliderItem = (Jobcard: any, index: number) => (
    // Customize the rendering of each item in the slider
    <a href={Jobcard.joburl}>
    <div className="our-solution-card cards-home job-short-card">
      <div className="our-solution-icons">
        <div className='company-icos-on-job-card'>
        <img src={Jobcard.img} alt={`Icon for ${Jobcard.mainHeading}`} /> 
        </div>
        <span className='posted-date'>{Jobcard.dateago} ago</span>
      </div>
      <h3 className="our-solution-heading montserrat">{Jobcard.mainHeading}</h3>
      <h5 className="our-solution-sub-heading montserrat">{Jobcard.subHeading}</h5>
    <div className='joblocation'>
        <span className='locarion-text'>
        {Jobcard.location}
        </span>
    </div>
    </div>
    </a>
  );

  return (
    <div className='jobbyhospitalsliderslider'>
      <MySlider
        className="category-slider"
        data={sliderData}
        renderItem={renderSliderItem}
        itemPerRow={3} // Adjust the number of items per row as needed
        itemPerRowBreakpoints={{
          320: 1,
          500: 1,
          800:2,
          1024: 2,
          1280: 3,
        }}
      />
    </div>
  );
};

export default CategorySlider;
