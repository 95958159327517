import React from "react";
import "./CountryCard.css";
interface Country {
    id: number;
    name: string;
    mapImage: string;
    flagImage: string;
    redirectTo: string;
}

interface CountryCardProps {
    country: Country;
    onClick: (redirectTo: string) => void;
}

const CountryCard: React.FC<CountryCardProps> = ({ country, onClick }) => {
    const handleClick = () => {
        onClick(country.redirectTo);
    };

    return (
        <div
            className="country-card"
            onClick={handleClick}
        >
            <div
                className="card-background"
                style={{
                    backgroundImage: `url(${country.mapImage})`,
                }}
            ></div>
            <img className="flag" src={country.flagImage} alt={country.name} />
            <div className="country-name ">{country.name}</div>
            {/* <div className="content-details text-blue">
                <h5>{country.name}</h5>
            </div> */}
        </div>
    );
};

export default CountryCard;
