import React, { ReactNode, useState } from "react";
import { useWindowSize } from "react-use";
import { useSwipeable } from "react-swipeable";
import { variants } from "utils/animationVariants";
import { AnimatePresence, motion, MotionConfig } from "framer-motion";
import PrevBtn from "components/NextPrev/PrevBtn";
import NextBtn from "components/NextPrev/NextBtn";

export interface MySliderProps<T> {
  className?: string;
  itemPerRow?: number;
  data: T[];
  renderItem?: (item: T, indx: number) => ReactNode;
  arrowBtnClass?: string;
  selectedImageIndex?: number;
  onImageSelect?: (index: number) => void;
}

export default function MySlider<T>({
  className = "",
  itemPerRow = 1,
  data,
  renderItem = () => <div></div>,
  arrowBtnClass = "top-1/2 -translate-y-1/2",
  selectedImageIndex = 0,
  onImageSelect = () => {},
}: MySliderProps<T>) {
  const [currentIndex, setCurrentIndex] = useState(selectedImageIndex);
  const [direction, setDirection] = useState(0);

  const numberOfItems = 5;

  function getRepeatedData() {
    const repeatedData = [];
    const dataLength = data.length;

    if (dataLength === 2) {
      // Repeat the two images in a cyclic manner
      for (let i = 0; i < numberOfItems; i++) {
        repeatedData.push(data[i % 2]);
      }
    } else if (dataLength < numberOfItems) {
      // Repeat the images in a cyclic manner
      for (let i = 0; i < numberOfItems; i++) {
        repeatedData.push(data[i % dataLength]);
      }
    } else {
      // Use the original data if it's larger than the number of items
      repeatedData.push(...data);
    }

    return repeatedData;
  }

  function changeItemId(newVal: number) {
    const repeatedDataLength = getRepeatedData().length;

    // Check if we're going beyond the end or before the start
    if (newVal >= repeatedDataLength) {
      newVal = 0; // Go back to the beginning
    } else if (newVal < 0) {
      newVal = repeatedDataLength - 1; // Go to the end
    }

    if (newVal > currentIndex) {
      setDirection(1);
    } else {
      setDirection(-1);
    }
    setCurrentIndex(newVal);
    onImageSelect(newVal);
  }

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      changeItemId(currentIndex + 1);
    },
    onSwipedRight: () => {
      changeItemId(currentIndex - 1);
    },
    trackMouse: true,
  });

  if (!numberOfItems) {
    return <div></div>;
  }

  // Render the selected image separately
  const selectedImage = getRepeatedData()[currentIndex];

  return (
    <div className={`nc-MySlider ${className}`}>
      <div className="selected-gallery">
        {/* Render the selected image */}
        {renderItem(selectedImage, currentIndex)}
      </div>

      <MotionConfig
        transition={{
          x: { type: "spring", stiffness: 300, damping: 30 },
          opacity: { duration: 0.2 },
        }}
      >
        <div className={`relative flow-root`} {...handlers}>
          <div className={`flow-root overflow-hidden rounded-xl`}>
            <motion.ul
              initial={false}
              className="relative whitespace-nowrap -mx-2 xl:-mx-4 "
            >
              <AnimatePresence initial={false} custom={direction}>
                {getRepeatedData().map((item, indx) => (
                  <motion.li
                    className={`relative inline-block px-2 xl:px-4 whitespace-normal ${
                      currentIndex === indx ? "active" : ""
                    }`}
                    custom={direction}
                    initial={{
                      x: `${(currentIndex - 1) * -100}%`,
                    }}
                    animate={{
                      x: `${currentIndex * -100}%`,
                    }}
                    variants={variants(200, 1)}
                    key={indx}
                    style={{
                      width: `calc(1/${numberOfItems} * 100%)`,
                    }}
                    onClick={() => changeItemId(indx)} // Set currentIndex when an item is clicked
                  >
                    {renderItem(item, indx)}
                  </motion.li>
                ))}
              </AnimatePresence>
            </motion.ul>
          </div>

          {/* Always display Prev button */}
          <PrevBtn
            onClick={() => changeItemId(currentIndex - 1)}
            className={`w-9 h-9 xl:w-12 xl:h-12 text-lg absolute -left-3 xl:-right-6 z-[1] top-1/2 -translate-y-1/2 bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-6000 dark:hover:border-neutral-500 rounded-full inline-flex items-center justify-center hover:border-neutral-300 focus:outline-none ${arrowBtnClass}`}
          />

          {/* Always display Next button */}
          <NextBtn
            onClick={() => changeItemId(currentIndex + 1)}
            className={`w-9 h-9 xl:w-12 xl:h-12 text-lg absolute -right-3 xl:-right-6 z-[1] top-1/2 -translate-y-1/2 bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-6000 dark:hover:border-neutral-500 rounded-full inline-flex items-center justify-center hover:border-neutral-300 focus:outline-none ${arrowBtnClass}`}
          />
        </div>
      </MotionConfig>
    </div>
  );
}
