import React, { useState, useEffect } from 'react';
import MySlider from 'components/MySlider';
import CardListData from './opening.json';
import "./opening.css"

import { Link } from "react-router-dom"; 

interface JobbyhospitalpropsProps {
  perView: number;
}

const CategorySlider: React.FC<JobbyhospitalpropsProps> = ({ perView }) => {
  const [sliderData, setSliderData] = useState<any[]>([]);

  useEffect(() => {
    // Assuming categoriesData is an array, you might need to adjust the structure
    setSliderData(CardListData);
  }, []);

  // Function to trim the subheading to 30 words
  const trimSubheading = (text: string) => {
    const words = text.split(' ');
    if (words.length > 22) {
      return words.slice(0, 30).join(' ') + '...';
    }
    return text;
  };

  // Render each item in the slider
  const renderSliderItem = (openings: any, index: number) => (
    // Customize the rendering of each item in the slider
    <Link className='montserrat' to={openings.applyNowUrl}>
    <div className="tera-opening" key={index}>
      <p className='montserrat'>{openings.openingText}</p>
      <h2 className='montserrat'>{openings.heading}</h2>
      <h5 className='montserrat'>{trimSubheading(openings.subheading)}</h5>
      <Link className='montserrat' to={openings.applyNowUrl}>Apply Now</Link>
    </div>
    </Link>
  );

  return (
    <div className='jobbyhospitalsliderslider'>
      <MySlider
        className="category-slider"
        data={sliderData}
        renderItem={renderSliderItem}
        itemPerRow={3} // Adjust the number of items per row as needed
        itemPerRowBreakpoints={{
          500: 1,
          1024: 2,
          1280: 3,
        }}
      />
    </div>
  );
};

export default CategorySlider;
