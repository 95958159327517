import React, { useState, useEffect } from "react";

const nursingtrainer = () => {
    return (
        <div className="page-job-post content-writer">
            <div className="container">
                <div className="job-post-wrapper">
                    <div className="jobdetail-side">
                        <div className="joblistings-detail">
                            <div className="jobs-prodiver-detail">
                                <div className="job-info-main">
                                    <h4 className="job-name">Nursing Trainer / Skill Development Coach</h4>
                                    <h5 className="job-provider-name"></h5>
                                </div>
                                <div className="job-provider-info"></div>
                            </div>
                            <div className="jobs-requirment">
                                <div className="uppersection">
                                    <p className="job-detail-minor required-exprence ">
                                        3-5 years
                                    </p>
                                    <p className="job-detail-minor salary-offer">Not Disclosed</p>
                                    <p className="job-detail-minor location">On-Site, Hyderabad, Telangana</p>
                                </div>
                                <div className="lowersections">
                                    <p className="job-detail-minor detail-other ">
                                        Education : Bachelor's degree in Nursing (Master's degree preferred).
                                    </p>
                                    {/* <p className="job-detail-minor detail-other">
                                        Key Skills : Flutter, Dart, Android, iOS
                                    </p> */}
                                </div>
                            </div>
                            <div className="job-apply-section">
                                <div className="job-provider-info post-page">
                                    {/* <p className="job-detail-minor openings"> 1 Opening</p>
                                    <span className="job-detail-minor posted-ago">
                                        4/12/ 2023
                                    </span> */}
                                </div>
                                <div className="job-btns-selection">
                                    <a
                                        className="applynowbtn apply-save-btn"
                                        href="mailto:careers@teraconnects.com"
                                    >
                                        Apply
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className="career-joblisting-detail job-description">
                            <div className="product-specification">
                                <h2 className="product-specs-title-for-product-single for-list-prod montserrat">
                                    Job Description
                                </h2>
                                <ul className="product-desc-list tera-job-description">
                                    We are seeking a highly skilled and experienced Nursing Trainer or Skill Development Coach to join our team.
                                    The ideal candidate will be responsible for designing, implementing,
                                    and delivering training programs to enhance the skills and knowledge of nursing staff.
                                    This role requires a strong background in nursing education, clinical expertise, and
                                    the ability to effectively train and mentor healthcare professionals.
                                </ul>

                            </div>
                        </div>

                        <div className="career-joblisting-detail job-description">
                            <div className="product-specification">
                                <h2 className="product-specs-title-for-product-single for-list-prod montserrat">
                                    Responsibilities
                                </h2>
                                {/* <ul className="product-desc-list tera-job-description"> */}
                                <ul className="">
                                    <li className="specs-list-content montserrat">
                                        Develop comprehensive training programs and curricula tailored to the needs of nursing staff.
                                    </li>
                                    <li className="specs-list-content montserrat">
                                        Plan, organize, and deliver training sessions, workshops, and educational activities both in-person and online.
                                    </li>
                                    <li className="specs-list-content montserrat">
                                        Provide hands-on training and demonstrations to nursing staff on clinical procedures, patient care techniques, and best practices.
                                    </li>
                                    <li className="specs-list-content montserrat">
                                        Assess training needs and evaluate the effectiveness of training programs through feedback, assessments, and performance metrics.
                                    </li>
                                    <li className="specs-list-content montserrat">
                                        Collaborate with nursing leadership and other healthcare professionals to identify training priorities and address areas for improvement.
                                    </li>
                                    <li className="specs-list-content montserrat">
                                        Stay updated on the latest developments in nursing education, healthcare technology, and clinical practices to ensure training content remains current and relevant.
                                    </li>
                                    <li className="specs-list-content montserrat">
                                        Mentor and support nursing staff in their professional development by providing guidance, coaching, and constructive feedback.
                                    </li>
                                    <li className="specs-list-content montserrat">
                                        Maintain accurate records of training activities, attendance, and outcomes to track progress and compliance with regulatory requirements.
                                    </li>
                                    <li className="specs-list-content montserrat">
                                        Participate in quality improvement initiatives and interdisciplinary committees to promote a culture of continuous learning and excellence in patient care.
                                    </li>
                                    <li className="specs-list-content montserrat">
                                        Adhere to all relevant policies, procedures, and standards of practice in accordance with regulatory guidelines and organizational requirements.
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="career-joblisting-detail job-description">
                            <div className="product-specification">
                                <h2 className="product-specs-title-for-product-single for-list-prod montserrat">
                                    Qualifications
                                </h2>
                                {/* <ul className="product-desc-list tera-job-description"> */}
                                <ul className="">
                                    <li className="specs-list-content montserrat">
                                        Bachelor's degree in Nursing (Master's degree preferred).
                                    </li>
                                    <li className="specs-list-content montserrat">
                                        Valid nursing license and relevant certifications (e.g., BLS, ACLS).
                                    </li>
                                    <li className="specs-list-content montserrat">
                                        Minimum of 3 years of clinical nursing experience in acute care or similar setting.
                                    </li>
                                    <li className="specs-list-content montserrat">
                                        Proven experience in nursing education, training, or professional development.
                                    </li>
                                    <li className="specs-list-content montserrat">
                                        Strong knowledge of nursing principles, patient care standards, and clinical procedures.
                                    </li>
                                    <li className="specs-list-content montserrat">
                                        Excellent communication, presentation, and interpersonal skills.
                                    </li>
                                    <li className="specs-list-content montserrat">
                                        Ability to work independently and collaboratively in a fast-paced environment.
                                    </li>
                                    <li className="specs-list-content montserrat">
                                        Proficiency in Microsoft Office and other relevant software applications.
                                    </li>
                                    <li className="specs-list-content montserrat">
                                        Commitment to lifelong learning and professional development.
                                    </li>

                                </ul>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default nursingtrainer;
