import API from "./api";
import { Referral_API_URLS } from "./constants";
export function getInReviewMAClinicianReferralsApi(args) {
    return API.post(Referral_API_URLS.getInReviewMAClinicianReferralsUrl,args);
  }
  export function getActiveMAClinicianReferralsApi(args) {
    return API.post(Referral_API_URLS.getActiveMAClinicianReferralsUrl,args);
  }
  export function getInReviewMAClientReferralsApi(args) {
    return API.post(Referral_API_URLS.getInReviewMAClientReferralsUrl,args);
  }
  export function getActiveMAClientReferralsApi(args) {
    return API.post(Referral_API_URLS.getActiveMAClientReferralsUrl,args);
  }
  export function getInActiveMAClientReferralsApi(args) {
    return API.post(Referral_API_URLS.getInActiveMAClientReferralsUrl,args);
  }
  export function getInReviewMAVendorReferralsApi(args) {
    return API.post(Referral_API_URLS.getInReviewMAVendorReferralsUrl,args);
  }
  export function getActiveMAVendorReferralsApi(args) {
    return API.post(Referral_API_URLS.getActiveMAVendorReferralsUrl,args);
  }
  export function getInActiveMAVendorReferralsApi(args) {
    return API.post(Referral_API_URLS.getInActiveMAVendorReferralsUrl,args);
  }