import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
interface CheckboxItem {
  value: string;
  label: string;
}
interface handleFilterJobsBasesOnStatesProps {
  handleFilterJobsBasedOnLocation: (
    selectedCategoryId: any,
    selectedCategoryIds: string[],
    experienceRange: any,
    salaryRange: any,
    selectedCompaniesCheckboxes: any
  ) => void;
  data: CheckboxItem[];
  selectedCategoryIdValue: any;
  experienceRange: any;
  selectedSalaryRange: any;
  selectedStateIdFromGetJobsByStatesFilter: any;
  selectedCompanyCheckboxesValues: any;
}

const CheckboxStatesFilter: React.FC<handleFilterJobsBasesOnStatesProps> = ({
  handleFilterJobsBasedOnLocation,
  selectedCategoryIdValue,
  data,
  experienceRange,
  selectedSalaryRange,
  selectedCompanyCheckboxesValues,
  selectedStateIdFromGetJobsByStatesFilter,
}) => {
  const [showAll, setShowAll] = useState(false);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState<any[]>([]);
  const visibleOptions = showAll ? data : data.slice(0, 4);
  const navigate = useNavigate();
  React.useEffect(() => {
    if (selectedStateIdFromGetJobsByStatesFilter) {
      setSelectedCheckboxes([Number(selectedStateIdFromGetJobsByStatesFilter)]);
    }
  }, [Number(selectedStateIdFromGetJobsByStatesFilter)]);

  const handleCheckboxChange = (id: string) => {
    const updatedSelectedCheckboxes = [...selectedCheckboxes];

    if (updatedSelectedCheckboxes.includes(id)) {
      updatedSelectedCheckboxes.splice(
        updatedSelectedCheckboxes.indexOf(id),
        1
      );
    } else {
      updatedSelectedCheckboxes.push(id);
    }
    setSelectedCheckboxes(updatedSelectedCheckboxes);
    navigate(
      `/jobs/jobs-listing/?selectedCategory=${selectedCategoryIdValue}&selectedExperience=${
        experienceRange?.max || 50
      }&selectedSalaryRange=${selectedSalaryRange?.max}&selectedStates=${
        updatedSelectedCheckboxes || []
      }&selectedOrganizations=${selectedCompanyCheckboxesValues || []}`
    );
    handleFilterJobsBasedOnLocation(
      selectedCategoryIdValue,
      updatedSelectedCheckboxes,
      experienceRange || { min: 0, max: 0 },
      selectedSalaryRange || { min: 0, max: 0 },
      selectedCompanyCheckboxesValues
    );
  };

  const handleShowMoreToggle = () => {
    setShowAll(!showAll);
  };

  return (
    <div className="checkbox-filter">
      {visibleOptions.map((item) => (
        <React.Fragment key={item.value}>
          <div className="checkbox-items">
            <input
              type="checkbox"
              id={item.value}
              className="filter-checkbox h-18 w-18  border-neutral-700 rounded-md"
              style={{ borderRadius: "4px" }}
              checked={selectedCheckboxes.includes(item.value)}
              onChange={() => handleCheckboxChange(item.value)}
            />
            <label htmlFor={item.value} className="checkbox-label">
              {item.label}
            </label>
          </div>
        </React.Fragment>
      ))}

      {!showAll && data.length > 4 && (
        <div className="text-right ml-12">
          <span
            onClick={handleShowMoreToggle}
            className="inline-block cursor-pointer text-base font-semibold text-blue-500"
          >
            View More...
          </span>
        </div>
      )}
    </div>
  );
};

export default CheckboxStatesFilter;
